import { Outlet } from 'react-router-dom';
import DefaultFooter from '../shared/DefaultFooter';
import DefaultHeader from '../shared/DefaultHeader';

const CtaLessLayout = () => {
  return (
    <>
      <DefaultHeader />
      <Outlet />
      <DefaultFooter />
    </>
  );
};

export default CtaLessLayout;