import { useState } from "react";
import { Link } from "react-router-dom";

const SecShowsListItem = ({ item, type, setListIsHovering }) => {
  const [isHovering, setIsHovering] = useState(false);
  const handleMouseOver = () => {
    setIsHovering(true);
    setListIsHovering(true);
  };
  const handleMouseOut = () => {
    setIsHovering(false);
    setListIsHovering(false);
  };

  return (
    <>
      <div
        className={isHovering ? "shows-item active" : "shows-item"}
        onMouseOver={handleMouseOver}
        onMouseOut={handleMouseOut}
      >
        {type === "youtube" && item && (
          <Link
            to={
              "/video/" + item?.snippet?.resourceId?.videoId + "?type=youtube"
            }
            className="shows-layout-item"
          >
            <div className="shows-layout-inner">
              <div className="shows-layout-thumbnail">
                <div className="thumbnail-overlay">
                  <i className="fa-solid fa-fw fa-play mr-2"></i> Watch Now
                </div>
                <img
                  src={
                    "https://img.youtube.com/vi/" +
                    item.snippet?.resourceId.videoId +
                    "/mqdefault.jpg"
                  }
                  alt=""
                />
              </div>
              <div className="shows-layout-content">
                <h4 className="shows-title">{item?.snippet?.title}</h4>
              </div>
            </div>
          </Link>
        )}

        {type === "vimeo" && item && item.uri && (
          <Link
            to={"/video/" + item.uri.split("/")[2] + "?type=vimeo"}
            className="shows-layout-item"
          >
            <div className="shows-layout-inner">
              <div className="shows-layout-thumbnail">
                <div className="thumbnail-overlay">
                  <i className="fa-solid fa-fw fa-play mr-2"></i> Watch Now
                </div>
                <img src={item.pictures.base_link} alt="" />
              </div>
              <div className="shows-layout-content">
                <h4 className="shows-title">{item?.name}</h4>
              </div>
            </div>
          </Link>
        )}
      </div>
    </>
  );
};

export default SecShowsListItem;
