import { useEffect } from "react";
import SecVideoList from "./Components/SecVideoList";
import SecVideoPlay from "./Components/SecVideoPlay";
import "./Css/VideoDetails.css";
import { useSelector, useDispatch } from "react-redux";
import { fetchVideoPlaylist } from "../../store/playlist";
import LoaderFull from "../../components/LoaderFull";

const VideoDetails = () => {
  const dispatch = useDispatch();
  const { videoplayList, loading } = useSelector((state) => state.playlist);
  useEffect(() => {
    dispatch(fetchVideoPlaylist({ status: true, type: "youtube,vimeo" }));
  }, []);
  return (
    <>
      {!loading && (
        <>
          <SecVideoPlay />
          <section className="sec-videos">
            {videoplayList?.length > 0 &&
              videoplayList.map((item, index) => {
                return <SecVideoList item={item} key={index} />;
              })}
          </section>{" "}
        </>
      )}
      <LoaderFull loaderActive={loading} />
    </>
  );
};

export default VideoDetails;
