import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import "./Css/cms.css";
import { useSelector, useDispatch } from "react-redux";
import { fetchCategories } from "../../store/category";
import { fetchFaqs } from "../../store/faq";
import LoaderFull from "../../components/LoaderFull";

function Help() {
  const dispatch = useDispatch();
  const { categoryList } = useSelector((state) => state.categories);
  const { faqList, loading } = useSelector((state) => state.faqs);
  const [keyword, setKeyword] = useState("");

  useEffect(() => {
    dispatch(
      fetchCategories({
        category_type: "faqs",
        keyword: keyword,
        sortByField: "ordering",
        sortByValue: 1,
      })
    );
  }, [dispatch, keyword]);

  useEffect(() => {
    dispatch(
      fetchFaqs({ keyword: keyword, sortByField: "ordering", sortByValue: 1 })
    );
  }, [dispatch, keyword]);

  return (
    <>
      <section className="help-page-section">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-8">
              <div className="search-part">
                <div className="search-part-main">
                  <div className="input-style ">
                    <input
                      className="form-control"
                      value={keyword}
                      onChange={(e) => setKeyword(e.target.value)}
                    />
                    <div className="float-label">Search</div>
                  </div>
                  <div className="icon">
                    <i className="fa-light fa-magnifying-glass"></i>
                  </div>
                </div>
                <div className="contact-text">
                  Search the Help center to find answers or , if you're still
                  having trouble{" "}
                  <Link to="/support-contact" className="link-contact">
                    Contact Us
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="help-search-content">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-8">
              <div className="frequently-search">
                <div className="header-title">
                  <div className="text">Most Popular Articles</div>
                </div>
                <div className="questions-part">
                  <div className="left-side">
                    {faqList.length > 0 ? (
                      faqList.map((item, index) => {
                        return (
                          <>
                            <div className="question-item" key={index}>
                              <Link
                                to={"/faq-details/" + item.category._id}
                                className="question-link"
                              >
                                {item.question}
                              </Link>
                            </div>
                          </>
                        );
                      })
                    ) : (
                      <h2 className="title">No Data</h2>
                    )}
                  </div>
                </div>
                <div className="question-topic-part">
                  <div className="topic-part">
                    {categoryList.length > 0 ? (
                      categoryList.map((item, index) => {
                        return (
                          <React.Fragment key={index}>
                            <Link
                              to={"/faq-details/" + item._id}
                              className="topic-item"
                            >
                              {item.name}
                            </Link>
                          </React.Fragment>
                        );
                      })
                    ) : (
                      <h2 className="title">No Data</h2>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <LoaderFull loaderActive={loading} />
    </>
  );
}

export default Help;
