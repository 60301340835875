import { useState, useEffect } from "react";
import ProfileMetaItem from "./ProfileMetaItem";
import ProfileAboutEdit from "./ProfileAboutEdit";
import {
  addTags,
  addUserTags,
  getProfileDetails,
  removeInterest,
} from "../../../store/user";
import { getAbout } from "../../../store/user";
import { fetchSubject } from "../../../store/subject";
import { useDispatch, useSelector } from "react-redux";
const ProfileAbout = () => {
  const dispatch = useDispatch();
  const { subjectlist } = useSelector((state) => state.subjects);

  const [profileActive, setProfileActive] = useState(false);
  const [aboutData, setAboutData] = useState({});
  const [type, setType] = useState();
  const [active, setActive] = useState(false);

  const [subject, setSubject] = useState("");
  const [subjectFilter, setSubjectFilter] = useState([]);
  const [subjectArr, setSubjectArr] = useState([]);

  const { profileDetails } = useSelector((state) => state.users);

  const subjectData = subjectlist;
  const assignedSubject = profileDetails?.abouts?.subject
    ? profileDetails?.abouts?.subject
    : [];

  useEffect(() => {
    dispatch(fetchSubject({}));
  }, [dispatch]);

  useEffect(() => {
    const loadAbout = async () => {
      try {
        const aboutData = await getAbout({});
        setAboutData(aboutData.data);
      } catch (error) {}
    };

    loadAbout();
  }, [profileActive]);

  const clickAbout = () => {
    setProfileActive(true);
    setType("about");
  };

  const clickExperience = () => {
    setProfileActive(true);
    setType("experience");
  };

  const clickEducation = () => {
    setProfileActive(true);
    setType("education");
  };

  const inputSubject = (value) => {
    if (value) {
      const filterList = subjectData.filter((item) => {
        return item.name.toLowerCase().includes(value.toLowerCase());
      });

      let result = filterList.filter(
        (o1) => !assignedSubject?.some((o2) => o1._id === o2._id)
      );

      setSubjectFilter(result);
    } else {
      setSubjectFilter([]);
    }
  };

  const selectSubject = async (name, id = null) => {
    try {
    if (id === null) {
      let newTag = await addTags({
        name: subject,
      });

      if (newTag) {
        let a = [{ _id: newTag._id, name: newTag.name, status: true }];
        const dta = await addUserTags({ subject: [...assignedSubject, ...a] });
        if (dta.status === 200) {
          loadProfile();
          setSubject("");
          dispatch(fetchSubject({}));
        }
      }
    } else {
      let a = [{ _id: id, name: name, status: true }];
      
        const dta = await addUserTags({ subject: [...assignedSubject, ...a] });
        if (dta.status === 200) {
          loadProfile();
          setSubject("");
        }
      
    }

    setSubjectFilter([]);
  } catch (error) {}
  };

  function triggerAbout(id) {
    dispatch(removeInterest({ about_id: aboutData?._id, interest_id: id }))
      .then(async () => {
        dispatch(getProfileDetails());
      })
      .catch((err) => {
        // console.log(err);
      });
  }

  const loadProfile = async () => {
    try {
      dispatch(getProfileDetails());
    } catch (error) {}
  };

  return (
    <>
      <ProfileAboutEdit
        profileActive={profileActive}
        aboutData={aboutData}
        type={type}
        setProfileActive={setProfileActive}
        loadProfile={loadProfile}
      />
      <div className="profile-content-section">
        <div className="profile-content-header">
          <h2 className="profile-content-title">
            About {profileDetails?.first_name}
          </h2>
          <div className="edit-action">
            <button className="edit" type="button" onClick={() => clickAbout()}>
              Edit
            </button>
          </div>
        </div>
        <div className="profile-content-about">
          <p>{aboutData?.title}</p>
        </div>
      </div>
      <div className="profile-content-section">
        <h2 className="profile-content-title">Experience</h2>
        <div className="profile-meta-list">
          {profileDetails?.experiences?.map((item, index) => (
            <ProfileMetaItem
              key={item._id}
              id={item._id}
              title={item.title}
              subtitle={item.company_name}
              startDate={item.start_date}
              endDate={item.end_date}
              isWorking={item.is_currently_working}
              type={"experience"}
              loadProfile={loadProfile}
            />
          ))}
        </div>
        <div className="add-action">
          <button
            type="button"
            className="add-part"
            onClick={() => clickExperience()}
          >
            <i className="fa-light fa-plus mr-2"></i>Add Position
          </button>
        </div>
      </div>
      <div className="profile-content-section">
        <h2 className="profile-content-title">Education</h2>
        <div className="profile-meta-list">
          {profileDetails?.educations?.map((item, index) => (
            <ProfileMetaItem
              key={index}
              id={item._id}
              title={item.institution}
              subtitle={item.graduation[0].name}
              type={"education"}
              loadProfile={loadProfile}
            />
          ))}
        </div>
        <div className="add-action">
          <button
            type="button"
            className="add-part"
            onClick={() => clickEducation()}
          >
            <i className="fa-light fa-plus mr-2"></i>Add Education
          </button>
        </div>
      </div>
      <div className="profile-content-section">
        <h2 className="profile-content-title">Interests</h2>
        <div className="profile-interest-list">
          {profileDetails?.abouts?.subject?.map((item, index) => (
            <div className="profile-interest-item" key={index}>
              {item.name}
              <button
                type="button"
                className="remove-action"
                onClick={() => {
                  triggerAbout(item._id);
                }}
              >
                <i className="fa-light fa-xmark"></i>
              </button>{" "}
            </div>
          ))}
        </div>

        <div className={"input-style mt-5" + (subject ? "hasValue" : "")}>
          <input
            className="form-control"
            value={subject}
            onFocus={() => setActive(true)}
            onBlur={() => {
              setTimeout(() => {
                setActive(false);
              }, 300);
            }}

            onKeyDown={(e) => {
              if (e.key === "Enter" && (subject.length >= 2) &&
              (!subjectFilter.map((it) => it.name).includes(subject)) && 
              !(profileDetails?.abouts?.subject?.map(it => it.name).includes(subject))) {
                selectSubject("");
              }
            }}

            onChange={(e) => {
              inputSubject(e.target.value);
              setSubject(e.target.value);
            }}
          />
          <div
            className="float-label"
            style={{
              fontSize: subject && "13px",
              margin: subject && "-24px 0 0",
            }}
          >
            Add interest
          </div>

          {active && (
            <div className="suggestions">
              <div className="suggestion-list">
                {subjectFilter.length > 0 &&
                  subjectFilter.map((item, index) => (
                    <button
                      type="button"
                      className="suggestion-item"
                      key={index}
                      onClick={() => selectSubject(item.name, item._id)}
                    >
                      {item.name}
                    </button>
                  ))}
                {(subject.length >= 2) &&
                  !(subjectFilter.map((it) => it.name).includes(subject)) && 
                  !(profileDetails?.abouts?.subject?.map(it => it.name).includes(subject)) && 
                  (
                    <button
                      type="button"
                      className="suggestion-item"
                      onClick={() => selectSubject("")}
                    >
                      <i class="fa-light fa-plus mr-2"></i>Add New
                    </button>
                  )}
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default ProfileAbout;
