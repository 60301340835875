import { useEffect } from "react";
import { useState } from "react";
import { fetchPages } from "../../../store/Pages/page";

const SecMembershipSummary = ({ setRegistrationActive }) => {
  const [caught, setCaught] = useState("");

  const openRegistration = () => {
    setRegistrationActive((current) => !current);
  };

  useEffect(() => {
    const loadpage = async () => {
      try {
        setCaught([]);
        const list = await fetchPages({ slug: "caught-it" });
        setCaught(list.description);
      } catch (error) {}
    };
    loadpage();
  }, []);

  return (
    <>
      <section className="sec-membership-summary">
        <div className="membership-summary-image">
          <img src="assets/img/brand.svg" alt="" />
        </div>
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="membership-summary-content">
                <h2 className="membership-summary-title">
                  SERIOUSLY,
                  <br />
                  YOU STILL HAVEN'T <br />
                  CAUGHT IT.
                </h2>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-6 col-lg-5 offset-md-6 offset-lg-5">
              <div className="membership-summary-content">
                <div dangerouslySetInnerHTML={{ __html: caught }}></div>
                <div className="summary-action">
                  <button
                    type="button"
                    className="btn-action"
                    onClick={openRegistration}
                  >
                    I want to be part of it
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default SecMembershipSummary;
