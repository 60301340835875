import { Link } from "react-router-dom";
import {
  deleteDraftPost,
  getDraftEvent,
  getDraftPost,
} from "../../../store/user";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import LoaderFull from "../../../components/LoaderFull";
import moment from "moment/moment";
import { useState } from "react";
import { deleteEvent } from "../../../store/event";
import API_URL from "../../../config/apiHost";

const ProfileDrafts = () => {
  const dispatch = useDispatch();
  const { draftEventList, loading, draftPostList } = useSelector(
    (state) => state.users
  );
  const [delId, setDelId] = useState(null);
  const [deletedPostId, setDeletedPostId] = useState('');
  const [deleteEventId, setDeleteEventId] = useState('');
  // const [postId, setPostId] = useState(null);

  useEffect(() => {
    dispatch(getDraftEvent({ status: 2, tags: [] }));
  }, [deleteEventId]);

  useEffect(() => {
    dispatch(getDraftPost());
  }, [deletedPostId]);


  const postDraftDelete = async () => {
   const data = await deleteDraftPost({ id: delId });
   if(data.status === 200){
    setDeletedPostId(delId)
   }
  }

  const EventDraftDelete = async (id) => {
    const data = await deleteEvent({ id: id });
    if(data.status === 200){
      setDeleteEventId(id)
    }
   }

  // console.log("postId", draftPostList);

  return (
    <>
      <section className="drafts-section">
        <div className="drafts-lists">
        {draftEventList.length > 0 && "Draft Events"}
          {draftEventList &&
            draftEventList.map((item) => {
              // console.log("draftEventList",item);
              return (
                <>
                  <div className="drafts-item" key={item._id}>
                    <div className="drafts-image">
                      <img
                        src={item.image ? item.image : API_URL+"uploads/events/no-image.png"}
                        // alt= {API_URL+"uploads/events/no-image.png"}
                      />
                    </div>
                    <div className="drafts-content">
                      <div className="drafts-title">{item.title}</div>

                      <div className="drafts-time">
                        <div className="date">
                          {moment(new Date(item.start_date)).format('LLL')}
                        </div>
                        <div className="time">
                        {moment(item.start_time, "HH:mm:ss").format("hh:mm A")}{" "}
              - {moment(item.end_time, "HH:mm:ss").format("hh:mm A")}
                        </div>
                      </div>
                      <div className="drafts-action">
                        <Link
                          to={"/edit-event/" + item.slug + "/" + item.event_id}
                          className="edit-action"
                        >
                          <i className="fa-solid fa-pen mr-3"></i>Resume editing
                        </Link>
                        <button
                          type="button"
                          className="edit-action alt"
                          data-toggle="modal"
                          data-target="#deleteModal"
                          onClick={() => EventDraftDelete(item._id)}
                        >
                          <i className="fa-regular fa-trash-can mr-2"></i>Delete
                          draft
                        </button>
                      </div>
                    </div>
                  </div>
                </>
              );
            })}
            {draftPostList.length > 0 && "Draft Posts"}
          {draftPostList.length > 0 &&
            draftPostList.map((item) => {
              return (
                <>
                  <div className="drafts-item" key={item._id}>
                    <div className="drafts-image">
                      <img
                        src={item?.images[0]?.imgLink ? item?.images[0]?.imgLink : API_URL+"uploads/events/no-image.png"}
                        alt="VR Technology Is Transforming E-Commerce Significantly"
                      />
                    </div>
                    <div className="drafts-content">
                      <div className="drafts-title">{item.title}</div>
                      {item.subtitle ? (
                        <div className="drafts-title">{item.subtitle}</div>
                      ) : (
                        ""
                      )}
                      <div className="drafts-time">
                        <div className="date">
                          {moment(new Date(item.created_at)).format('LLL')}
                        </div>
                      </div>
                      <div className="drafts-action">
                        <Link
                          to={"/edit-article/" + item._id}
                          className="edit-action"
                        >
                          <i className="fa-solid fa-pen mr-3"></i>Resume editing
                        </Link>
                        <button
                          type="button"
                          className="edit-action alt"
                          data-toggle="modal"
                          data-target="#deleteModal"
                          onClick={() => {
                            setDelId(item._id);
                          }}
                        >
                          <i className="fa-regular fa-trash-can mr-2"></i>Delete
                          draft
                        </button>
                      </div>
                    </div>
                  </div>
                </>
              );
            })}
        </div>
      </section>

      <div
        className="modal event-delete-modal fade"
        data-backdrop="static"
        id="deleteModal"
        tabIndex="-1"
        aria-labelledby="deleteModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered modal-sm">
          <div className="modal-content">
            <div className="modal-body">
              <div className="delete-content">
                <div className="icon">
                  <i className="fa-solid fa-triangle-exclamation"></i>
                </div>
                <div className="text">
                  <div className="main-title">Cancel draft?</div>
                  <div className="des">This action cannot be undone.</div>
                </div>
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-close"
                data-dismiss="modal"
              >
                Cancel
              </button>
              <button
                type="button"
                className="btn btn-confirm"
                data-dismiss="modal"
                onClick={() => {
                  postDraftDelete(delId);
                }}
              >
                Delete Draft
              </button>
            </div>
          </div>
        </div>
      </div>
      <LoaderFull loaderActive={loading} />
    </>
  );
};

export default ProfileDrafts;
