import Fade from "react-reveal/Fade";
import Pulse from "react-reveal/Pulse";
import { useSelector } from "react-redux";

const SecHomeBannerAlt = ({ functions }) => {
  const { homeContentdata } = useSelector((state) => state.pages);

  return (
    <>
      <section className="home-banner">
        <div className="container">
          <div className="banner-wrapper">
            <div className="banner-content">
              <Fade top>
                <h1 className="banner-title">
                  {homeContentdata?.home_banner?.title}
                </h1>
              </Fade>
              <Fade bottom>
                <div
                  className="banner-text"
                  dangerouslySetInnerHTML={{
                    __html: homeContentdata?.home_banner?.description,
                  }}
                ></div>
              </Fade>
              <Pulse>
                <div className="banner-action">
                  <button
                    type="button"
                    className="btn-action alt"
                    onClick={functions}
                  >
                    Discover
                  </button>
                </div>
              </Pulse>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default SecHomeBannerAlt;
