import moment from "moment/moment";
import { useState } from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import LoaderFull from "../../../components/LoaderFull";
import { globalEvents, moreGlobalEvents } from "../../../store/event";
import ScheduleEventItem from "./ScheduleEventItem";

const ScheduleThemes = ({ eventIds, setEventIds }) => {
  const { globalEventlist, loading, count } = useSelector(
    (state) => state.events
  );

  const [limit, setLimit] = useState(10);
  const [offset, setOffset] = useState(0);

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(
      globalEvents({ status: 1, limit: limit, offset: offset, tags: [] })
    );
    setOffset(offset + limit);
  }, [dispatch]);
  const [listIsHovering, setListIsHovering] = useState(false);

  const loadData = async (limit, offset) => {
    document.getElementById("load1").innerHTML = "Loading...";

    dispatch(
      moreGlobalEvents({
        status: 1,
        limit: limit,
        offset: offset,
        tags: [],
      })
    )
      .then(() => {
        document.getElementById("load1").innerHTML = "Load More";
      })
      .catch(() => {
        document.getElementById("load1").innerHTML = "Load More";
      });

    setOffset(offset + limit);
  };

  return (
    <>
      <div className="schedule-event">
        <div
          className={"schedule-event-list " + (listIsHovering ? "hovered" : "")}
        >
          {(Array.isArray(globalEventlist) && globalEventlist.length > 0) ?  
            globalEventlist.map((item, eventIndex) =>
              item.end_time !== "" ? (
                <ScheduleEventItem
                  key={eventIndex}
                  id={item._id}
                  name={item.title}
                  category={""}
                  time={
                    moment(new Date(item.start_date)).format(
                      "ddd DD MMM, YYYY"
                    ) +
                    ", " +
                    moment(item.start_time, "HH:mm:ss").format("hh:mm A") +
                    "-" +
                    moment(item.end_time, "HH:mm:ss").format("hh:mm A")
                  }
                  link={"/event-details/" + item.slug + "/" + item.event_id}
                  image={item.image}
                  setListIsHovering={setListIsHovering}
                  eventIds={eventIds}
                  setEventIds={setEventIds}
                />
              ) : (
                <ScheduleEventItem
                  key={eventIndex}
                  id={item._id}
                  name={item.title}
                  category={""}
                  time={
                    moment(new Date(item.start_date)).format(
                      "ddd DD MMM, YYYY"
                    ) +
                    ", " +
                    moment(item.start_time, "HH:mm:ss").format("hh:mm A")
                  }
                  link={"/event-details/" + item.slug + "/" + item.event_id}
                  image={item.image}
                  setListIsHovering={setListIsHovering}
                  eventIds={eventIds}
                  setEventIds={setEventIds}
                />
              )
            ) : "No Data"
            }
        </div>
        {globalEventlist && count > offset && (
          <div className="schedule-action">
            <button
              type="button"
              className="btn-action"
              id="load1"
              onClick={() => loadData(limit, offset)}
            >
              Load More
            </button>
          </div>
        )}
      </div>
      <LoaderFull loaderActive={loading} />
    </>
  );
};

export default ScheduleThemes;
