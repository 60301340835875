import { Outlet } from "react-router-dom";
import DefaultHeader from "../shared/DefaultHeader";

const SearchLayout = () => {
  return (
    <>
      <DefaultHeader />
      <Outlet />
    </>
  );
};

export default SearchLayout;
