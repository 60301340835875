import { useState } from "react";
import { Link } from "react-router-dom";
import ScheduleEvents from "./Components/ScheduleEvents";
import ScheduleFilter from "./Components/ScheduleFilter";
import ScheduleInterested from "./Components/ScheduleInterested";
import ScheduleThemes from "./Components/ScheduleThemes";
import "./Css/Schedule.css";

const Schedule = () => {
  const [filter, setFilter] = useState("all_events");
  const [eventIds, setEventIds] = useState([]);
  const [notification, setNotification] = useState(false);

  let token = localStorage.getItem("accesstoken");
  document.title = "CEO | Schedule";

  return (
    <>
      <section className="sec-schedule">
        <div className="container">
          <div className="custom-row">
            <div className="panel-content">
              <ScheduleFilter filter={filter} setFilter={setFilter} />
            </div>
          </div>
          <div className="custom-row">
            <div className="panel-content">
              {filter == "all_events" && (
                <ScheduleEvents eventIds={eventIds} setEventIds={setEventIds} />
              )}
              {filter == "interested" && (
                <ScheduleInterested
                  filter={filter}
                  eventIds={eventIds}
                  setEventIds={setEventIds}
                />
              )}
              {filter == "themes" && <ScheduleThemes />}
            </div>
            <div className="panel-side">
              <div className="panel-side-content">
                {filter == "all_events" && token &&(
                  <Link to="/create-event">
                    <div className="create">
                      <button type="button" className="btn-create">
                        <i className="fa-regular fa-fw fa-plus mr-2"></i>Create
                        an event
                      </button>
                    </div>
                  </Link>
                )}
                {filter == "interested" && (
                  <div className="notification-settings">
                    <div className="notification">
                      {notification && (
                        <div className="notification-content">
                          <div className="notification-radio-group">
                            <div className="radio">
                              <input
                                type="radio"
                                className="radio-input"
                                name="radio"
                              />
                              <div className="radio-label">One day earlier</div>
                            </div>
                            <div className="radio">
                              <input
                                type="radio"
                                className="radio-input"
                                name="radio"
                              />
                              <div className="radio-label">At the day</div>
                            </div>
                            <div className="radio">
                              <input
                                type="radio"
                                className="radio-input"
                                name="radio"
                              />
                              <div className="radio-label">30 min earlier</div>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Schedule;
