import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getFavorites, fetchFeed, updatePost } from "../../../store/feed";
import LoadMore from "../../../components/LoadMore";
import SharedModal from "./SharedModal";
import { useContext } from "react";
import { SocketContext } from "../../../context/socket";
import { useCallback } from "react";
import API_URL from "../../../config/apiHost";
import LinkPost from "./LinkPost";
import SkeletonLoader from "../../../components/SkeletonLoader";
import PostLoader from "../../../components/PostLoader";
import { getProfileDetails } from "../../../store/user";
const FromCEO = ({ tags }) => {
  const socket = useContext(SocketContext);
  const { settingData } = useSelector((state) => state.settings);
  const dispatch = useDispatch();
  const [posts, setPost] = useState([]);
  const [unqPosts, setUnqPost] = useState([]);
  const [postChildren, setpostChildren] = useState([]);
  const [files, setFiles] = useState([]);
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(false);
  const [paging, setPaging] = useState({});
  const [limit, setLimit] = useState(20);
  const [favList, setFavorites] = useState([]);
  const [userId, setUserId] = useState(null);
  const [postId, setPostId] = useState(false);
  const { profileDetails } = useSelector((state) => state.users);
  const [editPostTitle, setEditPostTitle] = useState("");
  const [editPostLinkDesc, setEditPostLinkDesc] = useState("");
  const [editPostLinkTitle, setEditPostLinkTitle] = useState("");
  const [metaData, setMetaData] = useState({});
  const [isEdit, setIsEdit] = useState(null);
  const [reValidate, setreValidate] = useState(null);

  useEffect(() => {
    const getProfileData = async () => {
      setLoading(true);
      const fav = await getFavorites();
      setFavorites(fav.data);

      dispatch(getProfileDetails({}));

      if (profileDetails) {
        setUserId(profileDetails?._id);
        try {
          const feed = await fetchFeed({
            targetId: settingData?.community_id,
            targetType: "community",
            limit: limit,
            tags: tags,
            isDeleted: false,
          });
          if (feed.status === 200) {
            setPost(feed.data.posts);
            setpostChildren(feed.data.postChildren);
            setFiles(feed.data.files);
            setUsers(feed.data.users);
            setPaging(feed.data.paging);
          }
          setLoading(false);
        } catch (error) {}
      } else {
        window.location.href = "/";
      }
    };
    getProfileData();
  }, [tags, reValidate]);

  const loadData = async (token) => {
    document.getElementById("load").innerHTML = "Loading...";
    const morefeed = await fetchFeed({
      targetId: settingData?.community_id,
      targetType: "community",
      limit: limit,
      token: token,
      tags: tags,
    });

    if (morefeed.status === 200) {
      setPost([...posts, ...morefeed.data.posts]);
      setpostChildren([...postChildren, ...morefeed.data.postChildren]);
      setFiles([...files, ...morefeed.data.files]);
      setUsers([...users, ...morefeed.data.users]);
      setPaging(morefeed.data.paging);
    }
    document.getElementById("load").innerHTML = "Load More";
  };

  const sharePost = (postId) => {
    setPostId(postId);
  };

  const eidtPostDetails = (postId, title, metadata, type) => {
    setMetaData(metadata);
    if (type === "article") {
      setEditPostLinkTitle(metadata.title);
      setEditPostLinkDesc(metadata.description);
    } else if (type === "event") {
      setEditPostLinkTitle(metadata.preview_event_json.title);
      setEditPostLinkDesc(metadata.preview_event_json.description);
    } else {
      setEditPostLinkTitle(metadata.preview_json.title);
      setEditPostLinkDesc(metadata.preview_json.description);
    }
    setPostId(postId);
    setEditPostTitle(title);
  };

  const webhookEventResponse = useCallback(
    (data) => {
      if (
        data.data.event === "v3.post.didAddReaction" &&
        data.data.data.reactor.userId !== profileDetails._id
      ) {
        let mainPosts = posts.map((u) =>
          u._id !== data.data.data.posts[0]._id ? u : data.data.data.posts[0]
        );
        setPost(mainPosts);
      }
      if (
        data.data.event === "v3.post.didRemoveReaction" &&
        data.data.data.reactor.userId !== profileDetails._id
      ) {
        let mainPosts = posts.map((u) =>
          u._id !== data.data.data.posts[0]._id ? u : data.data.data.posts[0]
        );
        setPost(mainPosts);
      }

      if (data.data.event === "v3.post.didCreate") {
        const initial = (arr) => arr.slice(0, -1);
        if (data.data.data.posts[0].targetType === "community") {
          setUnqPost([data.data.data.posts[0], ...posts]);
          setpostChildren([...data.data.data.postChildren, ...postChildren]);
          setUsers([...initial(data.data.data.users), ...users]);
          setFiles([...initial(data.data.data.files), ...files]);
        }
      }

      if (data.data.event === "v3.post.didDelete") {
        let mainPosts = posts.filter(
          (u) => u._id !== data.data.data.posts[0]._id
        );
        setPost(mainPosts);
        setLoading(false);
      }
      if (data.data.event === "v3.post.didUpdate") {
        let mainPosts = posts.map((u) =>
          u._id !== data.data.data.posts[0]._id ? u : data.data.data.posts[0]
        );
        setPost(mainPosts);
        setLoading(false);
      }
    },
    [posts]
  );
  useEffect(() => {
    let tmpPost = [...unqPosts];
    let jsonObject = tmpPost.map(JSON.stringify);
    let uniqueSet = new Set(jsonObject);
    let uniqueArray = Array.from(uniqueSet).map(JSON.parse);
    setPost(uniqueArray);
  }, [unqPosts]);
  useEffect(() => {
    try {
      socket.on("webhookEventResponse", webhookEventResponse);
    } catch (error) {
      console.log(error);
    }
    return () => {
      socket.off("webhookEventResponse", webhookEventResponse);
    };
  }, [webhookEventResponse, socket]);

  const [deleteLink, setDeleteLink] = useState(true);

  async function handleSubmit() {
    setLoading(true);
    if (deleteLink) {
      await updatePost({
        postId: postId,
        data: {
          text: editPostTitle,
        },
        metadata: metaData.isArticle
          ? {
              ...metaData,
              title: editPostLinkTitle,
              description: editPostLinkDesc,
            }
          : metaData.isEvent
          ? {
              ...metaData,
              preview_event_json: {
                ...metaData.preview_event_json,
                title: editPostLinkTitle,
                description: editPostLinkDesc,
              },
            }
          : {
              ...metaData,
              preview_json: {
                ...metaData.preview_json,
                title: editPostLinkTitle,
                description: editPostLinkDesc,
              },
            },
      });
    } else {
      await updatePost({
        postId: postId,
        data: {
          text: editPostTitle,
        },
        metadata: metaData.isArticle
          ? {
              ...metaData,
              title: editPostLinkTitle,
              description: editPostLinkDesc,
            }
          : metaData.isEvent
          ? {
              ...metaData,
              preview_event_json: {
                ...metaData.preview_event_json,
                title: editPostLinkTitle,
                description: editPostLinkDesc,
              },
            }
          : {
              ...metaData,
              preview_json: {
                ...metaData.preview_json,
                title: editPostLinkTitle,
                description: editPostLinkDesc,
              },
            },
      });
    }

    setTimeout(() => {
      if (loading) {
        setLoading(false);
      }
    }, 5000);
  }
  return (
    <>
      <div className="feed-list">
        {posts.length > 0 &&
          posts.map((item, index) => {

            const user =
              users.length > 0 &&
              users.find((u) => u.userId == item.postedUserId);

            const favData =
              favList.length > 0 &&
              favList.find((fv) => fv.post_id == item.postId);
            let wishlist = favData !== undefined ? favData.status : false;
            const like = item.myReactions.includes("like");
            const love = item.myReactions.includes("love");

            if (item?.metadata?.isLink) {
              if (loading) {
                return (
                  <div className={"feed-item"} key={index}>
                    <div className="feed-item-inner">
                      <PostLoader />
                    </div>
                  </div>
                );
              }
              return (
                <React.Fragment key={index}>
                  <LinkPost
                    user={user}
                    date={item.createdAt}
                    tick={true}
                    url={item.metadata.preview_json.url}
                    imgSrc={item.metadata.preview_json.images[0]}
                    postTitle={item.data.text}
                    linkTitle={item.metadata.preview_json.title}
                    linkDesc={item.metadata.preview_json.description}
                    commentCount={item.commentsCount}
                    reactionsCount={
                      item.reactions?.like ? item.reactions.like : 0
                    }
                    loveReactionCount={
                      item.reactions?.love ? item.reactions.love : 0
                    }
                    like={like}
                    love={love}
                    wishlist={wishlist}
                    postId={item.postId}
                    myfeed={profileDetails?._id === item.postedUserId}
                    sharePost={sharePost}
                    setLoading={setLoading}
                    type={"link"}
                    deleteLink={deleteLink}
                    setDeleteLink={setDeleteLink}
                    eidtPostDetails={eidtPostDetails}
                    setEditPostTitle={setEditPostTitle}
                    setEditPostLinkDesc={setEditPostLinkDesc}
                    setEditPostLinkTitle={setEditPostLinkTitle}
                    handleSubmit={handleSubmit}
                    setIsEdit={setIsEdit}
                    isEdit={isEdit}
                    item={item}
                  />
                </React.Fragment>
              );
            }

            if (item?.metadata && item?.metadata?.isArticle) {
              if (loading) {
                return (
                  <div className={"feed-item"} key={index}>
                    <div className="feed-item-inner">
                      <PostLoader />
                    </div>
                  </div>
                );
              }
              return (
                <React.Fragment key={index}>
                  <LinkPost
                    user={user}
                    date={item.createdAt}
                    tick={true}
                    url={"/post/" + postId}
                    imgSrc={
                      item?.metadata?.images
                        ? item?.metadata?.images[0]?.imgLink
                        : API_URL + "uploads/events/no-image.png"
                    }
                    postTitle={item.data.text}
                    linkTitle={item.metadata.title}
                    linkDesc={item.metadata.description}
                    commentCount={item.commentsCount}
                    reactionsCount={
                      item.reactions?.like ? item.reactions.like : 0
                    }
                    loveReactionCount={
                      item.reactions?.love ? item.reactions.love : 0
                    }
                    like={like}
                    love={love}
                    wishlist={wishlist}
                    postId={item.postId}
                    myfeed={profileDetails?._id === item.postedUserId}
                    sharePost={sharePost}
                    setLoading={setLoading}
                    type={"article"}
                    deleteLink={deleteLink}
                    setDeleteLink={setDeleteLink}
                    eidtPostDetails={eidtPostDetails}
                    setEditPostTitle={setEditPostTitle}
                    setEditPostLinkDesc={setEditPostLinkDesc}
                    setEditPostLinkTitle={setEditPostLinkTitle}
                    handleSubmit={handleSubmit}
                    setIsEdit={setIsEdit}
                    isEdit={isEdit}
                    item={item}
                  />
                </React.Fragment>
              );
            }

            if (item?.metadata?.isEvent) {
              if (loading) {
                return (
                  <div className={"feed-item"} key={index}>
                    <div className="feed-item-inner">
                      <PostLoader />
                    </div>
                  </div>
                );
              }
              return (
                <React.Fragment key={index}>
                  <LinkPost
                    user={user}
                    date={item.createdAt}
                    tick={true}
                    url={`/event-details/${item.metadata.preview_event_json.event_slug}/${item.metadata.preview_event_json.event_id}`}
                    imgSrc={
                      item.metadata.preview_event_json.hasPic
                        ? `${API_URL}uploads/events/500/${item.metadata.preview_event_json.image}`
                        : item.metadata.preview_event_json.image
                    }
                    postTitle={item.data.text}
                    linkTitle={item.metadata.preview_event_json.title}
                    linkDesc={item.metadata.preview_event_json.description}
                    commentCount={item.commentsCount}
                    reactionsCount={
                      item.reactions?.like ? item.reactions.like : 0
                    }
                    loveReactionCount={
                      item.reactions?.love ? item.reactions.love : 0
                    }
                    like={like}
                    love={love}
                    wishlist={wishlist}
                    postId={item.postId}
                    myfeed={profileDetails?._id === item.postedUserId}
                    sharePost={sharePost}
                    setLoading={setLoading}
                    type={"event"}
                    deleteLink={deleteLink}
                    setDeleteLink={setDeleteLink}
                    eidtPostDetails={eidtPostDetails}
                    setEditPostTitle={setEditPostTitle}
                    setEditPostLinkDesc={setEditPostLinkDesc}
                    setEditPostLinkTitle={setEditPostLinkTitle}
                    handleSubmit={handleSubmit}
                    setIsEdit={setIsEdit}
                    isEdit={isEdit}
                    item={item}
                  />
                </React.Fragment>
              );
            }
          })}
        {loading && posts.length === 0 && <SkeletonLoader />}
      </div>

      <div className="feed-action">
        {JSON.stringify(paging) !== "{}" && paging.next && (
          <div className="feed-action">
            <LoadMore next={paging.next} loadData={loadData} />
          </div>
        )}
      </div>
      <SharedModal postId={postId} userId={profileDetails?._id} />
    </>
  );
};

export default FromCEO;
