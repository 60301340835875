import { useNavigate, useParams } from "react-router-dom";
import SecShowsList from "./Components/SecShowsList";
import "./Css/Shows.css";
import { fetchPlaylistDetails } from "../../store/playlist";
import { useEffect, useState } from "react";
import ShareModal from "../Story/Components/shareModal";

const Shows = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [playList, setPlaylist] = useState({});
  const [modal, setModal] = useState(false);

  useEffect(() => {
    const loadPlaylist = async () => {
      const pldetails = await fetchPlaylistDetails({ id: id });
      if (pldetails !== undefined) {
        setPlaylist(pldetails);
      }
    };

    loadPlaylist();
  }, [id]);

  return (
    <>
      <section className="section-shows">
        <div className="container">
          <div className="shows-content">
            <button
              type="button"
              className="btn-back mb-5"
              onClick={() => navigate(-1)}
            >
              <i className="fa-light fa-fw fa-chevron-left"></i>
            </button>
            <h1 className="shows-title">{playList?.name}</h1>
            <div className="shows-text">
              <p
                dangerouslySetInnerHTML={{ __html: playList?.description }}
              ></p>
            </div>
            <div className="share-part">
              <button
                type="button"
                className="share-action"
                data-toggle="modal"
                data-target="#shareModal"
                style={{ backgroundColor: "transparent" }}
                onClick={() => setModal(true)}
              >
                <i className="fa-light fa-fw fa-arrow-up-from-bracket"></i>
              </button>
            </div>
          </div>

          <div className="shows-share">
            {modal ? (
              <ShareModal playlistdetails={playList} playlist_id={id} />
            ) : (
              ""
            )}
          </div>

          <div className="shows-container">
            <SecShowsList
              playlist_id={playList?.playlist_id}
              type={playList?.type}
            />
          </div>
        </div>
      </section>
    </>
  );
};

export default Shows;
