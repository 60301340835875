import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { globalEvents, tagList } from "../../../store/event";

const ScheduleFilter = ({ filter, setFilter }) => {
  const dispatch = useDispatch();
  const [tags, setTags] = useState([]);
  const [tagLists, setTagLists] = useState([]);
  const [themes, setThemes] = useState(false);

  useEffect(() => {
    tagList({ tag_type: 1, keyword: "", status: true })
      .then((res) => {
        setTagLists(res);
      })
      .catch(() => {});
  }, []);

  const selectTag = (e) => {
    let tagArray = [...tags];
    let index = tagArray.findIndex((tag) => tag === e.target.value);
    if (index === -1) {
      tagArray.push(e.target.value);
    } else {
      tagArray.splice(index, 1);
    }
    setTags(tagArray);
  };

  const filterRadio = (e) => {
    setFilter(e.target.value);
  };

  function searchById() {
    dispatch(globalEvents({ status: 1, tags: tags }));
  }

  return (
    <>
      <div className="schedule-filter">
        <div className="filter">
          <input
            type="radio"
            name="filter"
            value="all_events"
            className="filter-radio"
            defaultChecked
            onClick={(e) => filterRadio(e)}
          />
          <div className="filter-label">All events</div>
        </div>
        {localStorage.getItem("accesstoken") && (
          <div className="filter">
            <input
              type="radio"
              name="filter"
              value="interested"
              className="filter-radio"
              onClick={(e) => filterRadio(e)}
            />
            <div className="filter-label">I'm interested in</div>
          </div>
        )}

        <div className="filter filter-drop">
          <input
            type="radio"
            name="filter"
            value="themes"
            className="filter-radio"
            onClick={(e) => filterRadio(e)}
          />
          <div className="filter-label">
            Browse themes
            {filter === "themes" &&
              (tags.length && !themes ? (
                <button
                  className="filter-count"
                  onClick={(e) => setThemes((current) => !current)}
                >
                  {tags.length}
                </button>
              ) : (
                <button
                  className="filter-icon"
                  onClick={(e) => setThemes((current) => !current)}
                >
                  {filter == "themes" && themes ? (
                    <i className="fa-light fa-fw fa-angle-up"></i>
                  ) : (
                    <i className="fa-light fa-fw fa-angle-down"></i>
                  )}
                </button>
              ))}
          </div>
        </div>
      </div>
      {filter == "themes" && themes ? (
        <div className="themetags">
          <div className="themetag-list">
            {tagLists &&
              tagLists.map((item, index) => (
                <div className="tag" key={index}>
                  <input
                    type="checkbox"
                    className="tag-check"
                    name="theme-tags"
                    value={item._id}
                    checked={tags.findIndex((tag) => tag === item._id) !== -1}
                    onChange={(e) => selectTag(e)}
                  />
                  <div className="tag-label">{item.title}</div>
                </div>
              ))}
          </div>
          <div className="themetags-action">
            <button
              type="button"
              className="btn-action"
              onClick={(e) => {
                setThemes((current) => !current);
                searchById();
              }}
            >
              Filter
            </button>
            <button
              type="button"
              className="btn-action alt"
              onClick={(e) => setThemes((current) => !current)}
            >
              Cancel
            </button>
          </div>
        </div>
      ) : (
        ""
      )}
    </>
  );
};

export default ScheduleFilter;
