import { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { fetchCategories } from "../../store/category";
import { fetchFaqs } from "../../store/faq";

const FaqDetails = () => {
  const [keyword, setKeyword] = useState("");
  const { id } = useParams();
  const dispatch = useDispatch();
  const { categoryList } = useSelector((state) => state.categories);
  const { faqList } = useSelector((state) => state.faqs);

  useEffect(() => {
    dispatch(fetchCategories({ category_type: "faqs", keyword: keyword }));
  }, [dispatch, keyword]);

  useEffect(() => {
    dispatch(
      fetchFaqs({
        category: id,
      })
    );
  }, [dispatch, id]);
  
  return (
    <>
      <section className="faq-details-section">
        <div className="container">
          <div className="row">
            <div className="faq-content-part">
              <div className="side-bar">
                <div className="search-part-main">
                  <div className="input-style ">
                    <input
                      className="form-control"
                      value={keyword}
                      onChange={(e) => setKeyword(e.target.value)}
                    />
                    <div className="float-label">Search</div>
                  </div>
                  <div className="icon">
                    <i className="fa-light fa-magnifying-glass"></i>
                  </div>
                </div>
                <div className="faq-category-list">
                  <div className="text">Categories</div>
                  <div className="category-list">
                    {categoryList.length > 0 &&
                      categoryList.map((item) => {
                        return (
                          <>
                            <div className="category-link" key={item._id}>
                              <Link
                                to={"/faq-details/" + item._id}
                                className="link"
                              >
                                {item.name}
                              </Link>
                            </div>
                          </>
                        );
                      })}
                  </div>
                </div>
              </div>
              <div className="details-faq-content">
                <Link to="/help" className="back-btn">
                  back
                </Link>
                <div className="detail-content">
                  {faqList.length > 0 ? (
                    faqList.map((item) => {
                      return (
                        <>
                          <div className="title" key={item._id}>
                            {item.question}
                          </div>
                          <div
                            className="descriptions"
                            dangerouslySetInnerHTML={{ __html: item.answer }}
                          ></div>
                        </>
                      );
                    })
                  ) : (
                    <h2 className="title">No Data</h2>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default FaqDetails;
