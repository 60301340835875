import { useState, useRef } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import LoaderFull from "../../components/LoaderFull";

import {
  createPost,
  createMyPost,
  imageUploadPost,
  imageDeletePost,
  draftPost,
} from "../../store/feed";
import "./Css/Article.css";
import API_URL from "../../config/apiHost";
import ConfirmationModal from "../../components/ConfirmationModal";

const CreateArticle = () => {
  const { settingData } = useSelector((state) => state.settings);
  const [attachments, setaAttachments] = useState([]);
  const [images, setImages] = useState([]);
  const [draftImages, setDraftImages] = useState([]);
  const [msg, setMsg] = useState("");
  const [errorMsg, setErrorMsg] = useState("");
  const [loading, setLoading] = useState(false);
  const [activeImg, setActiveImg] = useState(true);
  const [description, setDescription] = useState("");
  const [caption, setCaption] = useState("");
  const [extraText, setExtraText] = useState("");
  const [activePreview, setActivePreview] = useState(false);

  const [search, setSearch] = useState("");
  const [subtitle, setSubtitle] = useState("");

  let navigate = useNavigate();

  const imgRef = useRef(null);

  const executeScroll = () => imgRef.current.scrollIntoView();

  const uploadFile = async (e) => {
    setLoading(true);

    let formData = new FormData();

    formData.append("file", e.target.files[0]);
    formData.append("type", "images");

    await imageUploadPost(formData)
      .then((res) => {
        setErrorMsg("");
        if (res.status === 200) {
          let tmp = {
            fileId: res.data[0].fileId,
            type: "image",
          };
          let img = {
            fileId: res.data[0].fileId,
            blobImg: URL.createObjectURL(e.target.files[0]),
          };

          let draftImg = {
            fileId: res.data[0].fileId,
            imgLink: res.data[0].fileUrl + "?size=full",
          };
          setDraftImages([...draftImages, draftImg]);
          setaAttachments([...attachments, tmp]);
          setImages([...images, img]);
          setErrorMsg("");
          setLoading(false);
          e.target.value = "";
        } else if (res.status === 400) {
          setErrorMsg(res.message);
          setLoading(false);
          e.target.value = "";
          executeScroll();
        }
      })
      .catch(() => {
        setLoading(false);
      });
  };

  function imageDeletePostFunc(id) {
    setLoading(true);
    let a = { file_id: id };

    imageDeletePost(a)
      .then((res) => {
        if (res.status === 200) {
          let tmp = attachments;
          let img = images;
          let draftImg = draftImages;

          let tmpAttchment = tmp.filter((item) => {
            return item.fileId !== id;
          });

          let tmpImg = img.filter((item) => {
            return item.fileId !== id;
          });

          let draftTmpImg = draftImg.filter((item) => {
            return item.fileId !== id;
          });

          setErrorMsg("");
          setLoading(false);
          setaAttachments(tmpAttchment);
          setImages(tmpImg);
          setDraftImages(draftTmpImg);
        } else if (res.status === 400) {
          setErrorMsg(res.message);
          setLoading(false);
          executeScroll();
        }
      })
      .catch(() => {
        setLoading(false);
      });
  }

  async function handleSubmit() {
    setLoading(true);
    await createPost({
      data: {
        text: extraText.trim(),
      },
      attachments: attachments,
      metadata: {
        isArticle: true,
        title: search.trim(),
        subtitle: subtitle.trim(),
        description: description.trim(),
        images: draftImages,
        caption: caption,
      },
      targetType: "community",
      targetId: settingData?.community_id,
    }).then(async (res) => {
      await createMyPost({
        post_id: res.data.posts[0]._id,
      }).then(() => {
        setLoading(false);
        navigate("/feed");
      });
    });
  }

  async function handleDraftSubmit() {
    setLoading(true);
    try {
      await draftPost({
        title: search,
        subtitle: subtitle,
        description: description,
        images: draftImages,
        // images: picture,
        caption: caption,
      }).then((res) => {
        // console.log(res);
        setLoading(false);
        navigate("/drafts");
      });
    } catch (err) {
      setLoading(false);
    }
  }

  return (
    <>
      <div className="event-panel-edit">
        <div className="edit-panel add-new">
          <div className="panel-header">
            <div className="container-fluid">
              <div className="row">
                <div className="col-lg-6 d-flex ml-auto">
                  <div
                    className="panel-close"
                    style={{ cursor: "pointer" }}
                    data-toggle="modal"
                    data-target="#deleteModal"
                  >
                    <i className="fa-light fa-fw fa-times"></i>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="py-5">
            <div className="container">
              <div className="row justify-content-center">
                <div className="col-md-8">
                  {activePreview && (
                    <>
                      <div className="share-item">
                        <div className="share-image">
                          <img
                            src={
                              images.length !== 0
                                ? images[0]?.blobImg
                                  ? images[0]?.blobImg
                                  : images[0]?.imgLink
                                : API_URL + "uploads/events/no-image.png"
                            }
                            alt=""
                          />
                        </div>
                        <div className="share-content">
                          <div className="share-title">{search}</div>
                          <div className="share-title">{subtitle}</div>
                          <div className="share-action">
                            <button
                              className="edit-action"
                              onClick={() => setActivePreview(false)}
                            >
                              <i className="fa-solid fa-pen mr-3"></i>Review and
                              edit
                            </button>
                          </div>
                        </div>
                      </div>
                      <div
                        className={
                          "mt-5 input-style " + (extraText ? "hasValue" : "")
                        }
                      >
                        <input
                          value={extraText}
                          onChange={(e) => {
                            setExtraText(e.target.value);
                          }}
                          type="text"
                          className="form-control"
                          name="search"
                          autoComplete="off"
                        />
                        <div className="input-count">
                          {extraText?.length || 0} / 200
                        </div>
                        <div className="float-label">
                          Say something about that
                        </div>
                      </div>
                      {msg !== "" && extraText === "" && (
                        <div className="text-danger">{msg}</div>
                      )}
                      <div className="event-timing-start">
                        <div className="save-action d-flex">
                          <button
                            type="button"
                            className="btn-save"
                            onClick={() => {
                              extraText === ""
                                ? setMsg("This cannot be empty")
                                : handleSubmit();
                            }}
                          >
                            Publish post
                          </button>
                          <button
                            type="button"
                            className="btn-save ml-3"
                            onClick={handleDraftSubmit}
                          >
                            Save to draft
                          </button>
                        </div>
                      </div>
                    </>
                  )}
                  {!activePreview && (
                    <div
                      className={"input-style " + (search.trim().length > 0 ? "hasValue" : "")}
                    >
                      <input
                        value={search}
                        onChange={(e) => {
                          setSearch(e.target.value);
                        }}
                        placeholder="Title"
                        type="text"
                        className="form-control"
                        name="search"
                        autoComplete="off"
                      />
                    </div>
                  )}
                  {!activePreview && (
                    <div
                      className={
                        "input-style mt-3 text-secondary" +
                        (subtitle ? "hasValue" : "")
                      }
                    >
                      <input
                        value={subtitle}
                        onChange={(e) => {
                          setSubtitle(e.target.value);
                        }}
                        placeholder="Subtitle (optional)"
                        type="text"
                        className="form-control sub-title"
                        name="search"
                        autoComplete="off"
                      />
                    </div>
                  )}

                  <div
                    className={
                      "event-other-details " + (search ? "active" : "")
                    }
                  >
                    {!activePreview && (
                      <p className="text-danger" ref={imgRef}>
                        {errorMsg}
                      </p>
                    )}
                    {!activePreview && (
                      <div className="event-timing-start">
                        {activeImg && (
                          <div className="event-location-edit">
                            <div className="location-content">
                              <div className="upload-image">
                                {images.length !== 1 && (
                                  <div className="upload-photo">
                                    <input
                                      className="input-file"
                                      type="file"
                                      accept="image/*"
                                      onChange={(e) => uploadFile(e)}
                                    />
                                    <div className="upload">
                                      <div className="uploadicon">
                                        <i className="fa-light fa-cloud-arrow-up"></i>
                                      </div>
                                      <div className="text">
                                        Upload Cover Image
                                      </div>
                                    </div>
                                  </div>
                                )}
                                <div
                                  className={
                                    "preview-image " +
                                    (images.length === 1 ? "active" : "")
                                  }
                                >
                                  <div className="img-wrap">
                                    {images.length === 1 && (
                                      <img
                                        src={images[0]?.blobImg}
                                        alt="image"
                                      />
                                    )}
                                  </div>
                                  {images.length === 1 && (
                                    <button
                                      type="button"
                                      className="delete-action"
                                      style={{ zIndex: 2 }}
                                      onClick={() =>
                                        imageDeletePostFunc(images[0]?.fileId)
                                      }
                                    >
                                      <i className="fa-light fa-trash-can"></i>
                                    </button>
                                  )}
                                </div>
                              </div>
                              {/* {images && (
                                <div className="uploaded-images">
                                  {images.map((item, i) => (
                                    <div
                                      key={i}
                                      className={
                                        "mt-3 preview-image " +
                                        (item?.blobImg ? "active" : "")
                                      }
                                    >
                                      <div className="img-wrap">
                                        <img src={item?.blobImg} alt="" />
                                      </div>
                                      <button
                                        type="button"
                                        className="delete-action"
                                        onClick={() =>
                                          imageDeletePostFunc(item.fileId)
                                        }
                                      >
                                        <i className="fa-light fa-trash-can"></i>
                                      </button>
                                    </div>
                                  ))}
                                </div>
                              )} */}
                            </div>
                          </div>
                        )}

                        {images === [] && (
                          <div className="input-style mb-5">
                            <input
                              value={caption}
                              onChange={(e) => {
                                setCaption(e.target.value);
                              }}
                              placeholder="Add Caption (optional)"
                              type="text"
                              className="form-control sub-title border-bottom"
                              name="search"
                              autoComplete="off"
                            />
                          </div>
                        )}

                        <div className="input-style mb-3">
                          <textarea
                            className="form-control"
                            value={description}
                            onChange={(e) => setDescription(e.target.value)}
                            rows={3}
                            placeholder="Start Writing..."
                            maxLength={1000}
                          ></textarea>
                          {description.length} / 1000
                        </div>
                        {!activePreview && search && (
                          <div className="save-action">
                            <button
                              type="button"
                              className="btn-save"
                              onClick={() => setActivePreview(true)}
                            >
                              Continue and save draft
                            </button>
                          </div>
                        )}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ConfirmationModal />
      <LoaderFull loaderActive={loading} />
    </>
  );
};

export default CreateArticle;
