import React from "react";

const SecFilter = ({ handleDateRangeSearch, searchValue }) => {
  let checkedmonth = searchValue === "month" ? "checked" : "";
  let checkedweek = searchValue === "weeks" ? "checked" : "";
  let checkedfeatured = searchValue === "featured" ? "checked" : "";
  return (
    <>
      <div className="page-filter">
        <div className="filter">
          <input
            type="radio"
            name="filter"
            className="filter-radio"
            onChange={() => handleDateRangeSearch("featured", false)}
            checked={checkedfeatured ? "checked" : ""}
          />
          <div className="filter-label">Most popular</div>
        </div>
        <div className="filter">
          <input
            type="radio"
            name="filter"
            className="filter-radio"
            checked={checkedweek ? "checked" : ""}
            onChange={() => handleDateRangeSearch("weeks", true)}
          />
          <div className="filter-label">Last week</div>
        </div>
        <div className="filter">
          <input
            type="radio"
            name="filter"
            checked={checkedmonth ? "checked" : ""}
            className="filter-radio"
            onChange={() => handleDateRangeSearch("month", true)}
          />
          <div className="filter-label">Last month</div>
        </div>
      </div>
    </>
  );
};

export default SecFilter;
