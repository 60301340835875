import { useState } from "react";
import FeedFilters from "./Components/FeedFilters";
import FeedFollows from "./Components/FeedFollows";
import MyFeeds from "./Components/MyFeeds";
import FromCEO from "./Components/FromCEO";
import Share from "../Share/Share";
import "./Css/Feed.css";
import { useDispatch } from "react-redux";
import { getSettings } from "../../store/settings";
import { useEffect } from "react";

const Feed = () => {
  const [filter, setFilter] = useState("ceo");
  const [tags, setTags] = useState([]);
  const [toggleShare, setToggleShare] = useState(false);
  const togglePanel = () => {
    setToggleShare(true);
  };
  document.title = "CEO | Feed";

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getSettings());
  }, [dispatch]);
  


  return (
    <>
      <section className="sec-feed">
        <div className="container">
          <div className="custom-row">
            <div className="panel-content">
              <FeedFilters
                filter={filter}
                setFilter={setFilter}
                tags={tags}
                setTags={setTags}
              />
            </div>
          </div>
          <div className="custom-row">
            <div className="panel-content">
              {window.location.pathname.split("/")[1] === "feed" ||
              window.location.pathname.split("/")[1] === "global-feed" ? (
                <FromCEO tags={tags} setTags={setTags} />
              ) : (
                ""
              )}
              {window.location.pathname.split("/")[1] === "my-feed" ? (
                <MyFeeds />
              ) : (
                ""
              )}
            </div>
            <div className="panel-side">
              <div className="panel-side-content">
                <div className="share">
                  <button
                    type="button"
                    className="btn-share"
                    onClick={togglePanel}
                  >
                    <i className="fa-regular fa-fw fa-plus mr-2"></i>Share
                    something
                  </button>
                </div>
                <FeedFollows />
              </div>
            </div>
          </div>
        </div>
      </section>
      <Share toggleShare={toggleShare} setToggleShare={setToggleShare} />
    </>
  );
};

export default Feed;
