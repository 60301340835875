import { useState, useEffect, useContext, useCallback, useRef } from "react";
import { useParams } from "react-router-dom";
import {
  channelDetails,
  messageDelete,
  messageList,
  seenChannel,
  sendMessage,
  updateChannel,
  uploadMsgFile,
} from "../../../../store/feed";
import { SocketContext } from "../../../../context/socket";
import { useSelector } from "react-redux";
import CommentItem from "./CommentItem";
import moment from "moment";
import API_URL from "../../../../config/apiHost";
import LoaderFull from "../../../../components/LoaderFull";
function Chat() {
  const allowedExts = ["jpg", "jpeg", "png", "pdf"];
  const chatContainer = useRef();
  const socket = useContext(SocketContext);
  const { id } = useParams();
  const [messages, setMessages] = useState([]);
  const [users, setUsers] = useState([]);
  const [files, setFiles] = useState([]);
  const [text, setMessage] = useState("");
  const [loader, setLoader] = useState(false);
  const [loading, setLoading] = useState(false);
  const { profileDetails } = useSelector((state) => state.users);
  const [displayName, setdisplayName] = useState("");
  const [userId, setUserId] = useState("");
  const [prev, setPrev] = useState("");
  const [scrollup, setScrollUp] = useState(false);
  const [attachFile, setAttachFile] = useState(false);
  const [isSent, setSend] = useState(false);
  const [src, setSrc] = useState(false);
  const [isExt, setExt] = useState(false);
  const [fileName, setFileName] = useState([]);

  const [isOnline, setIsOnline] = useState(false);

  socket.on("online", (data) => {
    if (userId === data) {
      setIsOnline(true);
    }
  });

  socket.on("offline", (data) => {
    if (userId === data) {
      setIsOnline(false);
    }
  });

  useEffect(() => {
    socket.emit("checkOnlineUser", userId);
  }, [userId, socket]);

  const webhookEventResponse = useCallback(
    async (data) => {
      // console.log(data);
      let pushFiles = [];
      let modifyMsg = [];
     
      if (
        data.data.event === "message.didCreate" ||
        (data.data.event === "message.didUpdate" &&
          data.data.data.messages[0].channelId === id)
      ) {
       // console.log('socketnewMSg',data.data.data);
        if (data.data.data.messages[0].type === "image") {
          pushFiles = [
            {
              fileId: data.data.data.messages[0].data?.key,
              type: "image",
              fileUrl:
                "https://api.us.amity.co/api/v3/files/" +
                data.data.data.messages[0].data?.key +
                "/download",
            },
          ];

          modifyMsg = [
            {
              _id: data.data.data.messages[0]._id,
              channelSegment: data.data.data.messages[0].channelSegment,
              fileId: data.data.data.messages[0].data?.key,
              type: "image",
              data: { caption: data.data.data.messages[0].data?.caption },
              userId: data.data.data.messages[0].userId,
              createdAt: data.data.data.messages[0].createdAt,
              reactions: data.data.data.messages[0].reactions,
            },
          ];
        }
        if (data.data.data.messages[0].type === "file") {
          pushFiles = [
            {
              fileId: data.data.data.messages[0].data?.key,
              type: "file",
              fileUrl:
                "https://api.us.amity.co/api/v3/files/" +
                data.data.data.messages[0].data?.key +
                "/download",
              attributes: {
                name: data.data.data.messages[0].data?.fileName,
              },
            },
          ];
          modifyMsg = [
            {
              _id: data.data.data.messages[0]._id,
              channelSegment: data.data.data.messages[0].channelSegment,
              fileId: data.data.data.messages[0].data?.key,
              type: "file",
              data: { caption: data.data.data.messages[0].data?.caption },
              userId: data.data.data.messages[0].userId,
              createdAt: data.data.data.messages[0].createdAt,
              reactions: data.data.data.messages[0].reactions,
            },
          ];
        }
        const uniquechat =
          messages.length > 0 &&
          messages.find((tm, i) => tm._id === data.data.data.messages[0]._id);
        if (!uniquechat) {
          if (
            data.data.data.messages[0].type === "image" ||
            data.data.data.messages[0].type === "file"
          ) {
            setFiles([...files, ...pushFiles]);
            setMessages([...messages, ...modifyMsg]);
            setUsers([...users, ...data.data.data.users]);
          } else {
            setMessages([...messages, ...data.data.data.messages]);
            setUsers([...users, ...data.data.data.users]);
          }

          // const last = data.data.data.messages.findLast(x => true);
          try {
            await seenChannel({
              channelId: id,
              readToSegment: data.data.data.messages[0]?.channelSegment,
            });
          } catch (error) {}
          if (!scrollup) {
            scrollToMyRef();
          }
        } else {
          let mainComments = messages.map((u) =>
            u.messageId !== data.data.data.messages[0].messageId
              ? u
              : data.data.data.messages[0]
          );
          setMessages(mainComments);
        }
      }
    },
    [messages]
  );

  useEffect(() => {
    socket.on("webhookEventResponse", webhookEventResponse);
    return () => {
      socket.off("webhookEventResponse", webhookEventResponse);
    };
  }, [webhookEventResponse, socket]);

  useEffect(() => {
    const loadMessages = async () => {
      setLoading(true);
      const load = await messageList({ channelId: id, last: 30 });

      setMessages(load.data.messages);
      setFiles(load.data.files);
      setUsers(load.data.users);
      if (load.data.paging) {
        setPrev(load.data.paging.previous ? load.data.paging.previous : "");
      }
      const channel = await channelDetails({ channelId: id });
      scrollToMyRef();
      setLoading(false);
      const getUser =
        channel.data.users.length > 0 &&
        channel.data.users.find((d, i) => d.userId !== profileDetails._id);
      setdisplayName(getUser?.displayName);
      setUserId(getUser?.userId);
      const last = load.data.messages.findLast((x) => true);
      await seenChannel({ channelId: id, readToSegment: last?.channelSegment });
    };
    loadMessages();
  }, [id]);

  const onFormSubmit = async () => {
   
    if (attachFile) {
      setLoader(true);
      await submitAttachMent(text.trim());
    } else {
      if (text.trim() !== "") {
        setLoader(true);
        const sentMsg = await sendMessage({
          text: text,
          type: "text",
          channelId: id,
        });
        if (sentMsg.status === 200) {
          document.getElementById(`inputBox`).innerHTML = "";
         // console.log('newMSg',sentMsg.data);
          setMessage("");
          setMessages([...messages, ...sentMsg.data.messages]);
          setUsers([...users, ...sentMsg.data.users]);
          setLoader(false);
          await updateChannel({
            channelId: id,
            metadata: { last_message: text },
          });
          //const last = sentMsg.data.messages.findLast(x => true);
          await seenChannel({
            channelId: id,
            readToSegment: sentMsg.data.messages[0]?.channelSegment,
          });
          scrollToMyRef();
        }
      } else {
        setMessage("");
        alert("Please enter message");
      }
    }
  };

  const scrollToMyRef = () => {
    const scroll =
      chatContainer.current.scrollHeight - chatContainer.current.clientHeight;
    const offset = 100;
    chatContainer.current.scrollTo(0, scroll + offset);
  };

  const onScroll = async (e) => {
    const window = e.target;
    //  console.log('scroll height',window.scrollHeight);
    //   console.log('client height',window.clientHeight);
    if (window.scrollTop === 0) {
      setScrollUp(true);
      const load = await messageList({ channelId: id, last: 30, token: prev });
      if (prev) {
        setMessages([...load.data.messages, ...messages]);
        setPrev(load.data.paging.previous ? load.data.paging.previous : "");
        chatContainer.current.scrollTo(0, 1500);
      }
    }
  };
  const ref1 = useRef(null);
  // const [isActive] = useClickOutside([ref1], event => console.log(event));
  // useEffect(()=>{
  //   var elements = document.getElementsByClassName("dot_action_list")

  //   for (var i = 0; i < elements.length; i++){
  //       elements[i].style.display = "none";
  //   }
  // },[isActive])

  const chatMenu = (id) => {
    var elements = document.getElementsByClassName("dot_action_list");

    for (var i = 0; i < elements.length; i++) {
      elements[i].style.display = "none";
    }
    document.getElementById("chat" + id).style.display = "block";
  };

  const removeData = async (id) => {
    // console.log(id);
    const del = await messageDelete({ messageId: id });
    // console.log(del);
  };

  const takeFile = async (attachFile) => {
    setAttachFile(attachFile);
    setSend(false);
  };

  const submitAttachMent = async (text) => {
    let upfile = [];
    if (isExt === "pdf") {
      upfile = await uploadMsgFile({
        channelId: id,
        type: "files",
        file: attachFile,
        caption: text ? text : "",
      });
    } else {
      upfile = await uploadMsgFile({
        channelId: id,
        type: "images",
        file: attachFile,
        caption: text ? text : "",
      });
    }

    if (upfile.status === 200) {
      let pushFiles = [];
      let modifyMsg = [];
      if (isExt === "pdf") {
        pushFiles = [
          {
            fileId: upfile.data.messages[0].data?.key,
            type: "file",
            fileUrl:
              "https://api.us.amity.co/api/v3/files/" +
              upfile.data.messages[0].data?.key +
              "/download",
            attributes: {
              name: upfile.data.messages[0].data?.fileName,
            },
          },
        ];
        modifyMsg = [
          {
            _id: upfile.data.messages[0]._id,
            channelSegment: upfile.data.messages[0].channelSegment,
            fileId: upfile.data.messages[0].data?.key,
            type: "file",
            data: { caption: upfile.data.messages[0].data?.caption },
            userId: upfile.data.messages[0].userId,
            createdAt: upfile.data.messages[0].createdAt,
          },
        ];
      } else {
        pushFiles = [
          {
            fileId: upfile.data.messages[0].data?.key,
            type: "image",
            fileUrl:
              "https://api.us.amity.co/api/v3/files/" +
              upfile.data.messages[0].data?.key +
              "/download",
          },
        ];

        modifyMsg = [
          {
            _id: upfile.data.messages[0]._id,
            channelSegment: upfile.data.messages[0].channelSegment,
            fileId: upfile.data.messages[0].data?.key,
            type: "image",
            data: { caption: upfile.data.messages[0].data?.caption },
            userId: upfile.data.messages[0].userId,
            createdAt: upfile.data.messages[0].createdAt,
          },
        ];
      }
      setFiles([...files, ...pushFiles]);

      setMessages([...messages, ...modifyMsg]);
      setUsers([...users, ...upfile.data.users]);
      setLoader(false);
      setMessage("");
      setSend(true);
      setSrc(false);
      setAttachFile(false);
      if (isExt === "pdf") {
        await updateChannel({
          channelId: id,
          metadata: { last_message: "📁file" },
        });
      } else {
        await updateChannel({
          channelId: id,
          metadata: { last_message: "📷photo" },
        });
      }
      await seenChannel({
        channelId: id,
        readToSegment: upfile.data.messages[0]?.channelSegment,
      });
    }
  };



  function getBase64(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  }

  const uploadFile = (e) => {
    const file = e.target.files[0];
    setFileName(e.target.files[0].name);
    const ext = e.target.files[0].type.split("/")[1];
    setExt(ext);
    if (!allowedExts.includes(ext)) {
      alert("Please upload a valid file.");
    } else {
      getBase64(file).then((data) => setSrc(data));
      takeFile(file);
    }
  };

  const removeFile = () => {
    setSrc(false);
    setAttachFile(false);
  };

  var el = document.getElementById("inputBox");
  if (el) {
    el.onkeyup = function (e) {
      // console.log('keyup');
      if (!e) {
        e = window.event;
      }
      var keyCode = e.which || e.keyCode,
        target = e.target || e.srcElement;

      if (keyCode === 13 && !e.shiftKey) {
        //console.log('Just enter');
        onFormSubmit();
        if (e.preventDefault) {
          e.preventDefault();
        } else {
          e.returnValue = false;
        }
        target.innerHTML = "";
      }
    };

    el.onkeydown = function () {
      // console.log('keydown');
    };
  }

  // console.log(isExt);
  return (
    <>
      <section className="message-container">
        <div className="message-panel">
          <div className="message-header">
            <div className="message-user">
              <div className="user-name">{displayName}</div>
              <div className={"user-status" + (isOnline ? " online" : "")}>
                {isOnline ? "Online" : "Offline"}
              </div>
            </div>
            {/* <div className="message-action">
              <div className="dropdown">
                <button
                  type="button"
                  className="dropdown-toggle"
                  data-toggle="dropdown"
                >
                  <i className="far fa-fw fa-ellipsis-v"></i>
                </button>
                <div className="dropdown-menu dropdown-menu-right">
                  <button className="dropdown-item" type="button">
                    Block
                  </button>
                  <button className="dropdown-item" type="button">
                    Another action
                  </button>
                  <button className="dropdown-item" type="button">
                    Something else here
                  </button>
                </div>
              </div>
            </div> */}
          </div>
          <div className="message-body">
            <div
              className="comment-list scrollbar"
              ref={chatContainer}
              onScroll={onScroll}
            >
              {messages &&
                messages.length > 0 &&
                messages.map((item, index) => {
                  const direction =
                    item.userId === profileDetails._id ? "right" : "left";
                  const findUsers =
                    users.length > 0 &&
                    users.find((d, i) => d.userId == item.userId);
                  const image =
                    item.type === "image" &&
                    files.find((it, i) => it.fileId === item.fileId);
                  const file =
                    item.type === "file" &&
                    files.find((it, i) => it.fileId === item.fileId);

                  const parentMsg =
                    item.parentId !== null &&
                    messages.find((it, i) => it.messageId === item.parentId);
                  const arr = item.myReactions;
                  for (
                    let index = 0;
                    index < item?.myReactions?.length;
                    index++
                  ) {
                    if (arr[index] === "like") {
                      arr[index] = "👍";
                      break;
                    }
                    if (arr[index] === "love") {
                      arr[index] = "💘";
                      break;
                    }
                    if (arr[index] === "smile") {
                      arr[index] = "😁";
                      break;
                    }
                  }

                  return (
                    <CommentItem
                      key={index}
                      messageId={item.messageId}
                      myReactions={arr}
                      reactions={item.reactions}
                      parentMsg={parentMsg}
                      name={findUsers?.displayName}
                      image={
                        API_URL +
                        "uploads/profile-images/100/" +
                        findUsers?.userId +
                        ".png"
                      }
                      time={moment(item.createdAt).format("h:mm A")}
                      comment={
                        item.type === "text"
                          ? item?.data?.text
                          : item?.data?.caption
                      }
                      isFile={item.type === "text" ? false : true}
                      isImage={item.type === "file" ? false : true}
                      fileType={item.type === "file" ? "pdf" : "jpg"}
                      fileName={file?.attributes?.name}
                      fileLink={
                        item.type === "file" ? file?.fileUrl : image?.fileUrl
                      }
                      direction={direction}
                    />
                  );
                })}
            </div>
          </div>
          <div className="message-footer">
            {src && (
              <div className="message-file-container">
                {isExt === "pdf" && (
                  <div className="type-file">
                    <div className="file-icon">
                      <i className="fat fa-fw fa-file"></i>
                    </div>
                    <div className="file-info">
                      <div className="file-name">{fileName}</div>
                      <div className="file-type">pdf</div>
                    </div>
                    <button
                      type="button"
                      className="file-remove"
                      onClick={() => removeFile()}
                    >
                      <i className="fal fa-fw fa-times"></i>
                    </button>
                  </div>
                )}
                {isExt !== "pdf" && (
                  <div className="type-image">
                    <img src={src} alt="" />
                    <button
                      type="button"
                      className="image-remove"
                      onClick={() => removeFile()}
                    >
                      <i className="fal fa-fw fa-times"></i>
                    </button>
                  </div>
                )}
              </div>
            )}

            <div className="message-input-container">
              <div className="upload-file">
                <input
                  type="file"
                  className="upload-input"
                  onChange={(e) => uploadFile(e)}
                />
                <i className="fa-thin fa-fw fa-paperclip"></i>
              </div>
              <div className="message-input-field">
                {/* <input type="text" className="message-input" placeholder="Type here ..."/> */}
                <div
                  contentEditable="true"
                  className="message-input"
                  id="inputBox"
                  onInput={(e) => setMessage(e.target.textContent)}
                ></div>
              </div>
              {loader && (
                <button type="button" className="send-message">
                  <i className="fas fa-fw fa-sync fa-spin"></i>
                </button>
              )}

              {!loader && (
                <button
                  type="button"
                  className="send-message"
                  onClick={() => {
                    onFormSubmit();
                  }}
                >
                  <i className="fas fa-fw fa-arrow-alt-right"></i>
                </button>
              )}
            </div>
          </div>
        </div>
      </section>

      <LoaderFull loaderActive={loading} />
    </>
  );
}

export default Chat;
