import { useState, useEffect } from "react";
import "./Css/cms.css";
import { fetchPages } from "../../store/Pages/page";
import "moment-timezone";
import LoaderFull from "../../components/LoaderFull";

function Privacy() {
  const [aboutdata, setAboutData] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    const loadpage = async () => {
      try {
        setAboutData([]);
        const list = await fetchPages({ slug: "privacy-policy" });
        setAboutData(list);
      } catch (error) {}
      setLoading(false);
    };
    loadpage();
  }, []);

  function createMarkup() {
    return { __html: aboutdata?.description };
  }

  return (
    <>
      {!loading && (
        <section className="cms-section">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-8">
                <h1 className="d-flex justify-content-start cms-title">
                  {aboutdata.title}
                </h1>

                <div
                  className="update-text"
                  dangerouslySetInnerHTML={{ __html: aboutdata?.subtitle }}
                ></div>
                <div className="cms-content">
                  <div className="description">
                    <div dangerouslySetInnerHTML={createMarkup()} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      )}
      <LoaderFull loaderActive={loading} />
    </>
  );
}

export default Privacy;
