import { useEffect, useState } from "react";
import CommunityConnections from "./Components/CommunityConnections";
import CommunityDiscover from "./Components/CommunityDiscover";
import CommunityFilter from "./Components/CommunityFilter";
import CommunityRequest from "./Components/CommunityRequest";
import "./Css/Community.css";
import {
  getCommunity,
  getCommunityRequest,
  getDiscover,
} from "../../store/user";
import { useDispatch, useSelector } from "react-redux";
import ProfileMessage from "../Profile/Components/ProfileMessage";

const Community = () => {
  document.title = "CEO | Community";

  const { communityList, loading } = useSelector((state) => state.users);
  const { communityRequestList } = useSelector((state) => state.users);
  const { discoverList, discoverLoading } = useSelector((state) => state.users);

  const [filter, setFilter] = useState("discover");
  const [notification, setNotification] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    if (filter === "requests") {
      dispatch(getCommunity({ status: 1, keyword: "", limit: 10, offset: 0 }));
    }
    if (filter === "connections") {
      dispatch(getCommunity({ status: 2, keyword: "", limit: 10, offset: 0 }));
    }
    dispatch(getCommunityRequest({ status: 1, keyword: "", limit: 9999 }));
  }, [filter, dispatch]);

  useEffect(() => {
    dispatch(getDiscover({ keyword: "", limit: 10, offset: 0 }));
  }, [dispatch]);

  useEffect(() => {
    if (
      communityRequestList &&
      communityRequestList?.data !== [] &&
      communityRequestList?.data?.length > 0
    ) {
      setNotification(true);
    } else {
      setNotification(false);
    }
  }, [communityRequestList, dispatch]);

  return (
    <>
      <section className="community-section">
        <div className="container">
          <div className="custom-row">
            <div className="side-panel">
              <CommunityFilter
                filter={filter}
                setFilter={setFilter}
                notification={notification}
              />
            </div>
            <div className="community-panel">
              {filter === "discover" && (
                <CommunityDiscover
                  discoverList={discoverList}
                  loading1={discoverLoading}
                />
              )}
              {filter === "requests" && (
                <CommunityRequest
                  communityList={communityList}
                  loading={loading}
                />
              )}
              {filter === "connections" && (
                <CommunityConnections
                  communityList={communityList}
                  loading={loading}
                />
              )}
              {filter === "inbox" && <ProfileMessage />}
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Community;
