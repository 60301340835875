import { createSlice } from "@reduxjs/toolkit";
import { apiCallBegan } from "./api";
import BASE_URL from "../config/host";

export const faqSlice = createSlice({
  name: "faqs",
  loading: false,
  moreloading: false,
  initialState: {
    faqList: [],
    count: 0,
    loading: false,
  },
  reducers: {
    faqRequested: (state) => {
      state.loading = true;
    },
    faqRequestFailed: (state) => {
      state.loading = false;
      state.faqList = "";
    },
    faqListReceived: (state, action) => {
      state.loading = false;
      state.faqList = action.payload.data;
      state.count = action.payload.count;
    },
  },
});

export const { faqRequestFailed, faqListReceived, faqRequested } =
  faqSlice.actions;
export const fetchFaqs = (body) =>
  apiCallBegan({
    url: "/faq/list",
    method: "post",
    data: body,
    baseUrl: BASE_URL,
    onSuccess: faqListReceived.type,
    onStart: faqRequested.type,
    onError: faqRequestFailed.type,
  });
