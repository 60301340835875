import { useState } from "react";

import { useDispatch, useSelector } from "react-redux";

import { Link } from "react-router-dom";
import UnfriendModal from "../../../components/UnfriendModal";
import { createChannel, deleteChannel } from "../../../store/feed";
import { addNotification } from "../../../store/notification";
import {
  sendConnectReq,
  unfriendUser,
  removeConnectionReq,
  getCommunity,
  acceptUser,
  getCommunityRequest,
} from "../../../store/user";
const CommunityCardItem = ({
  name,
  id,
  senderId,
  channelId,
  image,
  designation,
  connected,
  link,
}) => {
  const [loadrequest, setLoadRequest] = useState(false);
  const [disabled, setDisabled] = useState(false); 
  const dispatch = useDispatch();
  const { profileDetails } = useSelector((state) => state.users);

  const accepted = async (id) => {
    setLoadRequest(true);
    console.log("id", id, "senderId", senderId);
    const createChn = await createChannel({ userIds: [senderId] });

    if (createChn.status === 200) {
      await acceptUser({
        id: id,
        channelId: createChn.data.channels[0]._id,
        senderId: senderId,
      });
    }

    document.getElementById("actBtn" + id).innerHTML = "Accepted";
    document.getElementById("actBtn" + id).disabled = true;
    setDisabled(true);
    setLoadRequest(false);
    await addNotification({
      source_id: profileDetails._id,
      receiver_id: senderId,
      source_type: "connection",
      content: "{{userName}} accepted your connection request",
    });
  };

  async function sendReq(id) {
    setLoadRequest(true);
    const con = await sendConnectReq({ receiver_user_id: id });
    if (con.status === 200) {
      document.getElementById("sendBtn" + id).innerHTML = "Request Sent!";
      setLoadRequest(false);
      setDisabled(true);
      await addNotification({
        source_id: profileDetails._id,
        receiver_id: id,
        source_type: "connection",
        content: "{{userName}} send you a connection request",
      });
      
    } else {
      document.getElementById("sendBtn" + id).innerHTML = "Already send";
    }
  }

  async function removeReq(id) {
    await removeConnectionReq({ id: id });
    dispatch(getCommunity({ status: 1, keyword: "", offset: 0 }));
    dispatch(getCommunityRequest({ status: 1, keyword: "" }));
  }

  async function unfriend(id) {
    await unfriendUser({ id: id });
    await deleteChannel({ channelId: channelId });

    dispatch(getCommunity({ status: 2, keyword: "", offset: 0 }));
    document.getElementById("delModal" + id).innerHTML = "Done!";
  }
  return (
    <>
      <div className="connection-card">
        <Link to={"/profile/" + link} className="connection-inner">
          <div className="connection-image">
            <img src={image} alt="" />
          </div>
          <div className="connection-name">{name}</div>
          <div className="connection-designation">{designation}</div>
        </Link>
        <div className="connection-action">
          {connected === 2 && (
            <div className="profile-connection-action flex">
              <Link
                to={`${channelId ? "/message/" + channelId : "/messages"}`}
                className={"btn-action" + (connected ? " alt" : "")}
              >
                <i className="fal fa-fw fa-comment mr-2"></i>Message
              </Link>
              <button
                id={"delModal" + id}
                type="button"
                data-toggle="modal"
                data-target={"#deleteModal" + id}
                className={"btn-action btn-alt" + (connected ? " alt" : "")}
              >
                Unfriend
              </button>
            </div>
          )}
          {connected === 1 && (
            <div className="profile-connection-action flex">
              <button
                type="button"
                className="btn-action"
                onClick={() => accepted(id)}
                id={"actBtn" + id}
              >
                {loadrequest ? (
                  <i className="fal fa-fw fa-spin fa-spinner mr-2"></i>
                ) : (
                  <i className="fal fa-fw fa-user-plus mr-2"></i>
                )}
                Accept
              </button>
              <button
                type="button"
                data-toggle="modal"
                data-target={"#deleteModal" + id}
                className={"btn-action btn-alt" + (connected ? " alt" : "")}
              >
                Remove
              </button>
            </div>
          )}

          {connected === 3 && (
            <div className="profile-connection-action flex">
              <button
                type="button"
                className="btn-action"
                onClick={() => sendReq(id)}
                id={"sendBtn" + id}
                disabled={disabled}
              >
                {loadrequest ? (
                  <i className="fal fa-fw fa-spin fa-spinner mr-2"></i>
                ) : (
                  <i className="fal fa-fw fa-user-plus mr-2"></i>
                )}
                Connect
              </button>
            </div>
          )}
        </div>
      </div>
      <UnfriendModal
        id={id}
        name={name}
        removeReq={removeReq}
        unfriend={unfriend}
        connected={connected}
      />
    </>
  );
};

export default CommunityCardItem;
