import { useEffect } from "react";
import { useState } from "react";
import API_URL from "../../../config/apiHost";

function UserImage({ commentuser }) {
  const [url, seturl] = useState(
    API_URL + "uploads/profile-images/100/" + commentuser?.userId + ".png"
  );

  useEffect(() => {
    async function checkIfImage(url) {
      try {
        const response = await fetch(url);
        const contentType = response.headers.get("Content-Type");
        seturl(contentType.startsWith("image/"));
      } catch (error) {
        seturl(false);
      }
    }

    checkIfImage(
      API_URL + "uploads/profile-images/100/" + commentuser?.userId + ".png"
    );
  }, [commentuser]);
  return (
    <>
      <div className="user-img">
        {!url ? (
          <img
            src={"assets/img/no-img-user.png"}
            alt={commentuser?.displayName}
          />
        ) : (
          <img
            src={
              API_URL +
              "uploads/profile-images/100/" +
              commentuser?.userId +
              ".png"
            }
            alt={commentuser?.displayName}
          />
        )}
      </div>
    </>
  );
}

export default UserImage;
