import { useEffect, useState } from "react";
import ReactPlayer from "react-player";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useNavigate } from "react-router-dom";
import { fetchVideoDetails } from "../../../store/video";
import ShareModal from "../../Story/Components/shareModal";

const SecVideoPlay = () => {
  const [bindText, setBindText] = useState(false);
  const [characterLimit] = useState(400);
  const navigate = useNavigate();
  const [videoDetails, setVideoDetails] = useState({});
  const dispatch = useDispatch();
  const { alias } = useParams();
  const { settingData } = useSelector((state) => state.settings);
  const getQueryParams = (query = null) =>
    (query || window.location.search.replace("?", ""))
      .split("&")
      .map((e) => e.split("=").map(decodeURIComponent))
      .reduce((r, [k, v]) => ((r[k] = v), r), {});
  useEffect(() => {
    const loadVideoDetails = async () => {
      const detail = await fetchVideoDetails({
        id: alias,
        type: getQueryParams().type,
        key: settingData.youtube_api,
        vimeo_key: settingData.vimeo_api,
      });
      setVideoDetails(detail);
    };

    loadVideoDetails();
  }, [dispatch, alias]);
  function htmlDecode(input) {
    var doc = new DOMParser().parseFromString(input, "text/html");
    return doc.documentElement.textContent;
  }

  const [modal, setModal] = useState(false);
  const sharePost = () => {
    setModal(true);
  };

  return (
    <>
      {getQueryParams().type === "youtube" && (
        <section className="section-video-play">
          <div className="container mb-5">
            <button
              type="button"
              className="btn-back"
              onClick={() => navigate(-1)}
            >
              <i className="fa-light fa-fw fa-chevron-left"></i>
            </button>
          </div>
          <div className="container">
            <div className="video-content">
              <h1 className="video-title">{videoDetails?.title}</h1>

              {videoDetails?.description !== null ? (
                <>
                  {videoDetails?.description?.length > characterLimit ? (
                    <>
                      {bindText ? (
                        <div className={"video-text"}>
                          {htmlDecode(videoDetails?.description)}
                        </div>
                      ) : (
                        <div className={"video-text"}>
                          {htmlDecode(
                            videoDetails?.description.substring(
                              0,
                              characterLimit
                            )
                          ) + "..."}
                        </div>
                      )}

                      <div className="video-action">
                        <button
                          type="button"
                          className="btn-showMore"
                          onClick={() => setBindText(!bindText)}
                        >
                          {bindText ? "Show Less" : "Show More"}
                        </button>
                      </div>
                    </>
                  ) : (
                    <>
                      <div className={"video-text"}>
                        {htmlDecode(videoDetails?.description)}
                      </div>
                    </>
                  )}
                </>
              ) : (
                ""
              )}

              <div className="share-part">
                <button
                  type="button"
                  className="share-action"
                  data-toggle="modal"
                  data-target="#shareModal"
                  style={{ backgroundColor: "transparent" }}
                  onClick={() => sharePost(alias)}
                >
                  <i className="fa-light fa-fw fa-arrow-up-from-bracket"></i>
                </button>
              </div>
              {modal ? (
                <ShareModal
                  videoID={alias}
                  videoDetails={videoDetails}
                  type={getQueryParams().type}
                />
              ) : (
                ""
              )}
            </div>
            <div className="video-container">
              <ReactPlayer
                className="react-player"
                controls={true}
                url={"https://www.youtube.com/watch?v=" + alias}
              />
            </div>
          </div>
        </section>
      )}

      {getQueryParams().type === "vimeo" && videoDetails !== undefined && (
        <section className="section-video-play">
          <div className="container">
            <div className="video-content">
              <h1 className="video-title">{videoDetails?.name}</h1>
              <div className="video-text">
                {videoDetails?.description
                  ? htmlDecode(videoDetails?.description)
                  : ""}
              </div>

              <div className="share-part">
                <button
                  type="button"
                  className="share-action"
                  data-toggle="modal"
                  data-target="#shareModal"
                  style={{ backgroundColor: "transparent" }}
                  onClick={() => sharePost(alias)}
                >
                  <i className="fa-light fa-fw fa-arrow-up-from-bracket"></i>
                </button>
              </div>
              {modal ? (
                <ShareModal
                  videoID={alias}
                  videoDetails={videoDetails}
                  type={getQueryParams().type}
                />
              ) : (
                ""
              )}
            </div>
            <div className="video-container">
              <ReactPlayer
                className="react-player"
                controls={true}
                url={videoDetails?.link}
              />
            </div>
          </div>
        </section>
      )}
    </>
  );
};

export default SecVideoPlay;
