import { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import LoaderFull from "../../../components/LoaderFull";
import useDebounce from "../../../helpers/useDebounce";
import { getDiscover, getMoreDiscover } from "../../../store/user";
import CommunityCardItem from "./CommunityCardItem";

const CommunityDiscover = ({ discoverList, loading1 }) => {

  const [search, setSearch] = useState("");
  const debouncedSearch = useDebounce(search, 400);

  const dispatch = useDispatch();

  const [limit, _] = useState(10);
  const [offset, setOffset] = useState(limit);

  useEffect(() => {
    dispatch(getDiscover({ keyword: debouncedSearch, limit: 12, offset: 0 }));
  }, [debouncedSearch]);

  const loadData = async (limit, offset) => {
    document.getElementById("load1").innerHTML = "Loading...";

    dispatch(getMoreDiscover({ keyword: debouncedSearch, limit: limit, offset: offset }))
    .then(() => {
      setOffset(offset + limit);
        if(discoverList.count > offset) {
            document.getElementById("load1").innerHTML = "Load More";
        }
      })
      .catch(() => {
        if(discoverList.count > offset) {
          document.getElementById("load1").innerHTML = "Load More";
        }
      });

  };


  return (
    <>
      <div className="discover-section">
        <div className="text">People you may know</div>
        <div className="community-sec-search">
          <form className="form-content" id="search-form">
            <div className="form-group">
              <div className={"input-style " + (search ? "hasValue" : "")}>
                <input
                  type="text"
                  className="form-control"
                  name="search"
                  autoComplete="off"
                  value={search}
                  onChange={(e) => {
                    setSearch(e.target.value);
                  }}
                />
                <button
                  type="button"
                  className="input-reset"
                  onClick={() => setSearch("")}
                >
                  <i className="fa-light fa-fw fa-times"></i>
                </button>
                <div className="float-label">Find Someone</div>
              </div>
            </div>
          </form>
        </div>

        <div className="discover-part">
          <div className="header-part">
          </div>
          <div className="community-cards-part">
            {discoverList &&
              discoverList.data?.map((item, index) => (
                <div className="connection-item" key={index}>
                  <CommunityCardItem
                    id={item._id}
                    name={item.name}
                    image={item.image}
                    designation={item?.headline}
                    connected={3}
                    link={item.user_slug}
                  />
                </div>
              ))}
          </div>
        </div>
      </div>
      {discoverList && ( discoverList.count > offset ) && (
        <div className="schedule-action">
          <button
            type="button"
            className="btn-action"
            id="load1"
            onClick={() => loadData(limit, offset)}
          >
            Load More
          </button>
        </div>
      )}
      <LoaderFull loaderActive={loading1} />
    </>
  );
};

export default CommunityDiscover;
