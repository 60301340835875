import { useEffect } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, EffectFade } from "swiper";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/effect-fade";
import { useDispatch, useSelector } from "react-redux";
import LoaderFull from "../../../components/LoaderFull";
import { fetchPlaylistForBanner } from "../../../store/playlist";
import SecVideoBannerItem from "./SecVideoBannerItem";
const SecVideoBanner = () => {
  const dispatch = useDispatch();
  const { bannerplayList, loading } = useSelector((state) => state.playlist);

  useEffect(() => {
    dispatch(
      fetchPlaylistForBanner({
        status: true,
        featured: true,
        type: "youtube,vimeo",
        sortByField: "ordering",
        sortByValue: 1,
      })
    );
  }, [dispatch]);

  return (
    <>
      <section className="sec-video-banner">
        <Swiper
          modules={[Pagination, EffectFade]}
          spaceBetween={0}
          slidesPerView={1}
          pagination={{ clickable: true }}
          effect="fade"
          rewind={1}
        >
          {bannerplayList.length !== 0 &&
            bannerplayList.map((item) => (
              <SwiperSlide key={item._id}>
                <SecVideoBannerItem item={item} />
              </SwiperSlide>
            ))}
        </Swiper>
      </section>

      <LoaderFull loaderActive={loading} />
    </>
  );
};
export default SecVideoBanner;
