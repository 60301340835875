import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { fetchCountry } from '../../../store/country';
import { updateProfile } from '../../../store/user';
import { addStep } from '../../../store/user';
const ProfileFormLocation = ({formView, setFormView}) => {
  const dispatch = useDispatch();
  const { countrylist } = useSelector(state => state.countries);
  const [country, setCountry] = useState('');
  const [district, setDistrict] = useState('');
  const [countryFilter, setCountryFilter] = useState([]);
  const countryData = countrylist;
  
  const switchPanel = () => {
    setFormView({ ...formView, second: { active: false, completed: true }, third: { active: true, completed: false } });
    let dt = { ...formView, second: { active: false, completed: true }, third: { active: true, completed: false } } ;
    dispatch(addStep({step:dt}));
    dispatch(updateProfile({country:country,city:district}))

  }

  useEffect(() => {
    dispatch(fetchCountry({}))
  }, [dispatch]);
 
  const inputCountry = value =>{
    if(value){
      const filterList = countryData.filter((item) => {
        return item.name.toLowerCase().includes(value.toLowerCase())
      })
      setCountryFilter(filterList);
    }else{
      setCountryFilter([]);
    }
  }

  const selectCountry = value =>{
    setCountry(value);
    setCountryFilter([]);
  }

  return (
    <>
      <div className='profile-forms'>
        <div className='container'>
          <div className='row'>
            <div className='col-lg-4'>
              <div className='profile-forms-content'>
                <h1 className='profile-forms-title'>Where are you?</h1>
              </div>
            </div>
            <div className='col-lg-6 offset-lg-2'>
              <form className={"form-input-content " + ((country && district) ? 'active' : '')}>
                <div className="form-group">
                  <div className={"input-style z-up " + (country ? 'hasValue' : '')}>
                    <input type="text" className="form-control" name='country' autoComplete='off' value={country} onChange={(e) => {inputCountry(e.target.value); setCountry(e.target.value)} }/>
                    <div className="float-label">Select Country</div>
                    {
                      countryFilter.length > 0 &&
                      <div className='suggestions'>
                        <div className='suggestion-list'>
                          {countryFilter.map((item, index) => (
                            <button type='button' className='suggestion-item' key={index} onClick={() => selectCountry(item.name)}>{item.name}</button>
                          ))}
                        </div>
                      </div>
                    }
                  </div>
                </div>
                {
                  country?(
                    <>
                    <div className="form-group">
                      <div className={"input-style " + (district ? 'hasValue' : '')}>
                        <input type="text" className="form-control" name='district' autoComplete='off' value={district} onChange={(e) => setDistrict(e.target.value)}/>
                        <div className="float-label">City/District</div>
                      </div>
                    </div>
                    </>
                  ):''
                }
                <div className="form-group form-action"> 
                  <button type="button" className="btn-action btn-activeable" onClick={() => switchPanel()}>Continue</button>
                  <Link to="/" className="btn-action alt">Skip for now</Link>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ProfileFormLocation;