import { useEffect, useState } from "react";
import {
  addUserTags,
  editExperience,
  editEducation,
  delEducation,
  delExperience,
  getProfileDetails,
} from "../../../store/user";
import Flatpickr from "react-flatpickr";
import { useDispatch } from "react-redux";
import moment from "moment/moment";

const ProfileAboutEdit = ({
  profileActive,
  type,
  details,
  setProfileActive,
  loadProfile,
}) => {
  const dispatch = useDispatch();

  const [about, setAbout] = useState("");
  const [count, setCount] = useState("");
  const [error, setError] = useState(false);

  const [id, setId] = useState(null);

  const [role, setRole] = useState(details?.title ? details?.title : "");
  const [company, setCompany] = useState(
    details?.company_name ? details?.company_name : ""
  );
  const [startDate, setStartDate] = useState(
    details?.start_date ? details?.start_date : ""
  );
  const [endDate, setEndDate] = useState(
    details?.end_date ? details?.end_date : ""
  );
  const [is_currently_working, setIs_currently_working] = useState(
    details?.is_currently_working ? details?.is_currently_working : ""
  );

  useEffect(() => {
    setId(details?._id ? details?._id : "");
    setRole(details?.title ? details?.title : "");
    setCompany(details?.company_name ? details?.company_name : "");
    setStartDate(details?.start_date ? details?.start_date : "");
    setEndDate(details?.end_date ? details?.end_date : null);
    setIs_currently_working(
      details?.is_currently_working ? details?.is_currently_working : false
    );

    setInstitution(details?.institution ? details?.institution : "");
    setGraduation(
      details && details.graduation ? details?.graduation[0].name : ""
    );
  }, [details]);

  // useEffect(() => {
  //   setGraduation(details ? details?.graduation[0].name : '')
  // }, [details?.graduation]);

  const [institution, setInstitution] = useState("");
  const [graduation, setGraduation] = useState("");

  const closePanel = (event) => {
    setProfileActive(false);
  };

  const handleKeyDown = (e) => {
    e.target.style.height = `40px`;
    e.target.style.height = `${e.target.scrollHeight}px`;
  };

  const handleWordCount = (e) => {
    const charCount = e.target.value.length;
    setCount(charCount);
  };

  const saveAbout = async () => {
    const dat = await addUserTags({ title: about });
    setProfileActive(false);
  };

  const saveExperience = async () => {
    if (moment(startDate).diff(moment(endDate)) >= 0) {
      setError(true);
      //console.log("start date should be less than end date");
    }
    if (role !== "" && company !== "" && startDate !== "" && endDate !== "") {
      if (is_currently_working) {
        const strt =  new Date(startDate);
        dispatch(
          editExperience({
            id: id,
            title: role,
            company_name: company,
            start_date: startDate,
            end_date: strt.setDate(strt.getDate() + 1),
            is_currently_working: true,
          })
        ).then(() => {
          dispatch(getProfileDetails());
        });
      } else {
        dispatch(
          editExperience({
            id: id,
            title: role,
            company_name: company,
            start_date: startDate,
            end_date: endDate,
            is_currently_working: false,
          })
        ).then(() => {
          dispatch(getProfileDetails());
        });
      }
      // loadProfile();
      setProfileActive(false);
    }
    //console.log(dat);
  };

  const saveEducation = async () => {
    if (institution !== "" && graduation !== "") {
      dispatch(
        editEducation({
          id: id,
          institution: institution,
          graduation: [{ name: graduation }],
        })
      ).then(() => {
        dispatch(getProfileDetails());
      });
     
      setProfileActive(false);
    } else {
      setError(true);
    }
    //console.log(dat);
  };

  function deleteExp() {
    dispatch(delExperience({ id: id }))
      .then(() => {
        dispatch(getProfileDetails());
        setError(false);
        setCompany("");
        setRole("");
        setEndDate(null);
        setStartDate(null);
        setInstitution("");
        setGraduation("");
        setProfileActive(false);
      })
      .catch((err) => {});
  }

  function deleteEdu() {
    dispatch(delEducation({ id: id }))
      .then(() => {
        dispatch(getProfileDetails());
        setProfileActive(false);
      })
      .catch((err) => {});
  }

  useEffect(() => {
    setAbout("");
    setCount("");
  }, [details]);

  //console.log(is_currently_working);

  return (
    <>
      <div
        className={"profile-panel-section " + (profileActive ? "active" : "")}
      >
        <div className="panel-backdrop"></div>
        <div className="side-panel-content">
          <div className="panel-header">
            <div className="panel-title">
              {type === "about" && "About Me"}
              {type === "experience" && "Edit experience"}
              {type === "education" && "Edit education"}
            </div>
            <button
              type="button"
              className="panel-close"
              onClick={() => {
                closePanel();
                setError(false);
                setCompany("");
                setRole("");
                setEndDate(null);
                setStartDate(null);
                setInstitution("");
                setGraduation("");
              }}
            >
              <i className="fa-light fa-fw fa-times"></i>
            </button>
          </div>
          {type === "about" && (
            <div className="panel-body">
              <form className="form-content">
                <div className="form-group">
                  <div
                    className={
                      "input-style input-style-textarea " +
                      (about ? "hasValue" : "")
                    }
                  >
                    <textarea
                      className="form-control"
                      name="about"
                      autoComplete="off"
                      value={about}
                      onChange={(e) => {
                        setAbout(e.target.value);
                        handleKeyDown(e);
                        handleWordCount(e);
                      }}
                      onPaste={handleKeyDown}
                      maxLength="300"
                    ></textarea>
                    <div className="input-count">{count} / 300</div>
                    <div className="float-label">
                      Tell us a little about yourself
                    </div>
                  </div>
                </div>

                <div className="form-group form-action">
                  <button
                    type="button"
                    className="btn-action"
                    onClick={() => saveAbout()}
                  >
                    Save
                  </button>
                </div>
              </form>
            </div>
          )}

          {type === "experience" && (
            <div className="panel-body">
              {moment(startDate).diff(moment(endDate)) >= 0 && (
                <p style={{ color: "red" }}>
                  Start date cannot be less than end date
                </p>
              )}
              {error && (
                <p style={{ color: "red" }}>Enter role and company name.</p>
              )}
              <form className="form-content">
                <div className="form-group">
                  <div
                    className={
                      "input-style input-style-textarea " +
                      (role ? "hasValue" : "")
                    }
                  >
                    <textarea
                      className="form-control"
                      name="role"
                      autoComplete="off"
                      value={role}
                      onChange={(e) => {
                        setRole(e.target.value);
                        handleKeyDown(e);
                      }}
                    ></textarea>
                    {/* <div className='input-count'>{count} / 300</div> */}
                    <div className="float-label">Role Name</div>
                  </div>
                </div>

                <div className="form-group">
                  <div
                    className={
                      "input-style input-style-textarea " +
                      (company ? "hasValue" : "")
                    }
                  >
                    <textarea
                      className="form-control"
                      name="company"
                      autoComplete="off"
                      value={company}
                      onChange={(e) => {
                        setCompany(e.target.value);
                        handleKeyDown(e);
                      }}
                    ></textarea>
                    {/* <div className='input-count'>{count} / 300</div> */}
                    <div className="float-label">Company Name</div>
                  </div>
                </div>

                <div className="form-group">
                  <div
                    className={"input-style " + (startDate ? "hasValue" : "")}
                  >
                    {/* <input type="text" className="form-control" name='startDate' autoComplete='off' value={startDate} onChange={(e) => setStartDate(e.target.value)}/> */}
                    <Flatpickr
                      className="form-control"
                      value={startDate}
                      onChange={([date]) => {
                        setStartDate(new Date(date).toISOString());
                        //let newFormValues = [...formValues];
                        // newFormValues[index]['start_date'] = new Date(date).toISOString();
                        // setFormValues(newFormValues);
                      }}
                    />
                    <div className="input-icon">
                      <i className="fa-light fa-fw fa-calendar"></i>
                    </div>
                    <div className="float-label">Start Date</div>
                  </div>
                </div>

                {!is_currently_working && (
                  <div className="form-group">
                    <div
                      className={"input-style " + (endDate ? "hasValue" : "")}
                    >
                      {/* <input type="text" className="form-control" name='startDate' autoComplete='off' value={startDate} onChange={(e) => setStartDate(e.target.value)}/> */}
                      <Flatpickr
                        className="form-control"
                        value={endDate}
                        onChange={([date]) => {
                          setEndDate(new Date(date).toISOString());
                          //let newFormValues = [...formValues];
                          // newFormValues[index]['start_date'] = new Date(date).toISOString();
                          // setFormValues(newFormValues);
                        }}
                      />
                      <div className="input-icon">
                        <i className="fa-light fa-fw fa-calendar"></i>
                      </div>
                      <div className="float-label">End Date</div>
                    </div>
                  </div>
                )}

                <div className="form-group">
                  <div className="custom-checkbox">
                    <input
                      type="checkbox"
                      className="custom-checkbox-input"
                      name="is_currently_working"
                      value={is_currently_working || false}
                      checked={is_currently_working}
                      onChange={(e) =>
                        setIs_currently_working(e.target.checked)
                      }
                    />
                    <div className="custom-checkbox-label">
                      This is my current job
                    </div>
                  </div>
                </div>

                {role &&
                  company &&
                  !(moment(startDate).diff(moment(endDate)) >= 0) && (
                    <div
                      className="form-group form-action"
                      style={{ gap: "10px" }}
                    >
                      <button
                        type="button"
                        className="btn-action"
                        onClick={() => saveExperience()}
                      >
                        Save
                      </button>

                   {id !=='' &&  <button
                        type="button"
                        className="btn-action delete"
                        style={{
                          backgroundColor: "black",
                          border: "2px solid white",
                        }}
                        onClick={() => deleteExp()}
                      >
                        Delete
                      </button>}
                    </div>
                  )}
              </form>
            </div>
          )}

          {type === "education" && (
            <div className="panel-body">
              {error && (
                <p style={{ color: "red" }}>
                  Enter institution name and graduation
                </p>
              )}
              <form className="form-content">
                <div className="form-group">
                  <div
                    className={
                      "input-style input-style-textarea " +
                      (institution ? "hasValue" : "")
                    }
                  >
                    <textarea
                      className="form-control"
                      name="about"
                      autoComplete="off"
                      value={institution}
                      onChange={(e) => {
                        setInstitution(e.target.value);
                      }}
                    ></textarea>
                    {/* <div className='input-count'>{count} / 300</div> */}
                    <div className="float-label">Institution name</div>
                  </div>
                </div>

                <div className="form-group">
                  <div
                    className={
                      "input-style input-style-textarea " +
                      (graduation ? "hasValue" : "")
                    }
                  >
                    <textarea
                      className="form-control"
                      name="about"
                      autoComplete="off"
                      value={graduation}
                      onChange={(e) => {
                        setGraduation(e.target.value);
                      }}
                    ></textarea>
                    {/* <div className='input-count'>{count} / 300</div> */}
                    <div className="float-label">Graduation</div>
                  </div>
                </div>

                <div className="form-group form-action" style={{ gap: "10px" }}>
                  <button
                    type="button"
                    className="btn-action"
                    onClick={() => saveEducation()}
                  >
                    Save
                  </button>
                  {id !== '' && <button
                    type="button"
                    className="btn-action delete"
                    style={{
                      backgroundColor: "black",
                      border: "2px solid white",
                    }}
                    onClick={() => deleteEdu()}
                  >
                    Delete
                  </button>}
                </div>
              </form>
            </div>
          )}
          {/* <ProfileExperienceEdit/>
          <ProfileEducationEdit/> */}
        </div>
      </div>
    </>
  );
};

export default ProfileAboutEdit;
