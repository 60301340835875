import moment from "moment";
import { useState } from "react";
import ShareModal from "./shareModal";
const SecStoriesDetailsContent = ({ isListen, blogdetails }) => {
  function createMarkup() {
    return { __html: blogdetails.html };
  }
  const [modal, setModal] = useState(false);
  const sharePost = () => {
    setModal(true);
  };
  return (
    <>
      <section className="sec-stories-details">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              {isListen ? (
                <div className="stories-audio-container">Audio Placeholder</div>
              ) : (
                ""
              )}
              <div className="stories-details-content">
                <div
                  className="stories-details-text"
                  id="text"
                  dangerouslySetInnerHTML={createMarkup()}
                ></div>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-lg-12">
              <div className="stories-details-content">
                <figure className="stories-details-image">
                  <figcaption>
                    {moment(blogdetails?.created_at).format(
                      "Do MMMM YYYY, h:mm A"
                    )}
                  </figcaption>
                </figure>
              </div>
            </div>
          </div>

          <div className="share-part">
            <button
              type="button"
              className="share-action"
              data-toggle="modal"
              data-target="#shareModal"
              style={{ backgroundColor: "transparent" }}
              onClick={() => sharePost(blogdetails?.id)}
            >
              <i className="fa-light fa-fw fa-arrow-up-from-bracket"></i>
            </button>
          </div>
          {modal ? (
            <ShareModal blogID={blogdetails?.id} blogdetails={blogdetails} />
          ) : (
            ""
          )}
        </div>
      </section>
    </>
  );
};

export default SecStoriesDetailsContent;
