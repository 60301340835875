import SecStoriesSliderItem from "./SecStoriesSliderItem";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import { useDispatch, useSelector } from "react-redux";
import { fetchBlogs } from "../../../store/blog";
import { useEffect, useState } from "react";

const SecStoriesSlider = () => {
  const dispatch = useDispatch();
  const [limit] = useState("all");
  const { settingData } = useSelector((state) => state.settings);
  const { bloglist } = useSelector((state) => state.blogs);
  useEffect(() => {
    dispatch(
      fetchBlogs({
        limit: limit,
        key: settingData?.ghost_api,
        ghost_url: settingData?.ghost_url,
        featured: false,
      })
    );
  }, []);

  console.log(bloglist);

  return (
    <>
      <div className="stories-slider">
        <Swiper
          spaceBetween={0}
          slidesPerView={1.1}
          enabled={0}
          breakpoints={{
            480: {
              enabled: false,
              slidesPerView: 1.1,
              spaceBetween: 0,
            },
            768: {
              enabled: true,
              slidesPerView: 2.5,
              spaceBetween: 20,
            },
            1024: {
              enabled: true,
              slidesPerView: 3,
              spaceBetween: 20,
            },
            1400: {
              enabled: true,
              slidesPerView: 3,
              spaceBetween: 20,
            },
            1440: {
              enabled: true,
              slidesPerView: 4,
              spaceBetween: 20,
            },
            1900: {
              enabled: true,
              slidesPerView: 5,
              spaceBetween: 20,
            },
          }}
        >
          {bloglist.length > 0 &&
            bloglist.map((item, index) => {
              return (
                <SwiperSlide key={index}>
                  <SecStoriesSliderItem item={item} />
                </SwiperSlide>
              );
            })}
        </Swiper>
      </div>
    </>
  );
};

export default SecStoriesSlider;
