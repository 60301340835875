import { Link } from "react-router-dom";

function SecVideoBannerItem({ item }) {
  return (
    <>
      {item && (
        <Link to={"/playlist/" + item._id} className="swiper-slide">
          <div className="video">
            <div className="video-image">
              <img src={item.image} alt="" />
            </div>
            <div className="container">
              <div className="video-content">
                <h1 className="video-title">{item.name}</h1>
                <div className="video-time"></div>
              </div>
            </div>
          </div>
        </Link>
      )}
    </>
  );
}

export default SecVideoBannerItem;
