import moment from "moment/moment";
import { useState } from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import LoaderFull from "../../../components/LoaderFull";
import { interestedEvents } from "../../../store/event";
import ScheduleEventItem from "./ScheduleEventItem";

const ScheduleInterested = ({ eventIds, setEventIds }) => {
  const { interestEventList , loading } = useSelector((state) => state.events);

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(interestedEvents({}));
  }, [dispatch]);


  const [listIsHovering, setListIsHovering] = useState(false);

  return (
    <>
      <div className="schedule-event">
        <div className="schedule-event-list">
          {(Array.isArray(interestEventList) && interestEventList.length > 0) ?
            interestEventList.map((item, eventIndex) => (
              item.end_time !=="" ? 
              <ScheduleEventItem
                key={eventIndex}
                id={item._id}
                name={item.title}
                category={""}
                time={
                  moment(new Date(item.start_date)).format("ddd DD MMM, YYYY") +
                  ", " +
                  moment(item.start_time, "HH:mm:ss").format(
                    "hh:mm A"
                  )  +
                    "-" +
                   moment(item.end_time, "HH:mm:ss").format(
                      "hh:mm A"
                    )
                }
                link={"/event-details/" + item.slug + "/" + item.event_id}
                image={item.image}
                setListIsHovering={setListIsHovering}
                filter={"interested"}
                eventIds={eventIds}
                setEventIds={setEventIds}
              />
              :
              <ScheduleEventItem
                key={eventIndex}
                id={item._id}
                name={item.title}
                category={""}
                time={
                  moment(new Date(item.start_date)).format("ddd DD MMM, YYYY") +
                  ", " +
                  moment(item.start_time, "HH:mm:ss").format(
                    "hh:mm A"
                  )
                }
                link={"/event-details/" + item.slug + "/" + item.event_id}
                image={item.image}
                setListIsHovering={setListIsHovering}
                filter={"interested"}
                eventIds={eventIds}
                setEventIds={setEventIds}
              />
            )) : "No Data"
          }
        </div>
      </div>
      <LoaderFull loaderActive={loading} />
    </>
  );
};

export default ScheduleInterested;
