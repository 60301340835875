import { Link } from 'react-router-dom';
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { fetchVideos } from '../../../store/video';
import { useState } from 'react';

function VideoItem({item}) {
    const [videoList, setVideoList] = useState([]);
    const { settingData } = useSelector((state) => state.settings);
    useEffect(() => {
        const loadvideos = async () => {
          try {
            const list = await fetchVideos({
              playlist_id: item?.playlist_id,
              type: item.for,
              key: settingData.youtube_api,
              vimeo_key: settingData.vimeo_api
            });
    
            setVideoList(list);
            //console.log(list);
          } catch (error) {}
        };
        if(item?.playlist_id && !videoList.length && settingData.youtube_api){
          loadvideos();
          //console.log(playlist_id);
        }
       
      
      }, [item.playlist_id,settingData]);

    return (
        <>
         <div className="search-video-list">
                <Swiper 
                breakpoints={{
                480: {
                  slidesPerView: 1,
                  spaceBetween: 10,
                },
                768: {
                  slidesPerView: 3,
                  spaceBetween: 20,
                },
                1024: {
                  slidesPerView: 4,
                  spaceBetween: 20,
                },
              }}
                >
                 {videoList.map((it)=>{
                    return(<><SwiperSlide>
                        <div className="search-video-item">
                          <Link to={item.for==='youtube'?"/video/"+it?.snippet?.resourceId?.videoId+"?type=youtube":"/video/"+it.uri.split("/")[2]+"?type=vimeo"} className="item-inner">
                            <div className="item-thumbnail">
                              <img src={item.for==='youtube'?'https://img.youtube.com/vi/'+it.snippet?.resourceId.videoId+'/mqdefault.jpg':it.pictures.base_link} alt="" />
                            </div>
                            <div className="item-content">
                              <h4 className="item-title">{item.for==='youtube'?it?.snippet?.title:it?.name}</h4>
                              {/* <div className='item-text'>5 min</div> */}
                            </div>
                          </Link>
                        </div>
                      </SwiperSlide></>);
                 })} 
                </Swiper>
              </div>
        </>
    )
}

export default VideoItem
