import { useState, useEffect } from "react";
import SecMembershipRegistration from "./Components/SecMembershipRegistration";
import SecMembershipBanner from "./Components/SecMembershipBanner";
import SecMembershipFeatures from "./Components/SecMembershipFeatures";
import SecMembershipMembers from "./Components/SecMembershipMembers";
import SecMembershipDetails from "./Components/SecMembershipDetails";
import SecMembershipBenefit from "./Components/SecMembershipBenefit";
import SecMembershipSummary from "./Components/SecMembershipSummary";
import "./Css/Membership.css";
import { fetchFeatures, fetchMembers } from "../../store/Pages/page";

const Membership = () => {
  const [registrationActive, setRegistrationActive] = useState(false);
  const [sectionData, setSectionData] = useState([]);
  const [member, setMembers] = useState([]);
  useEffect(() => {
    const loadpage = async () => {
      try {
        setSectionData([]);
        const list = await fetchFeatures({ limit: 10, status: true });
        console.log(list);
        setSectionData(list);
      } catch (error) {}
    };
    const loadMember = async () => {
      try {
        const list = await fetchMembers({
          type: "membership",
          limit: 99999,
          sortByField: "ordering",
          sortByValue: 1,
          status: true,
        });
        setMembers(list);
      } catch (error) {}
    };
    loadpage();
    loadMember();
  }, []);

  return (
    <>
      <SecMembershipRegistration
        registrationActive={registrationActive}
        setRegistrationActive={setRegistrationActive}
      />
      <SecMembershipBanner />
      <SecMembershipMembers member={member} />
      <SecMembershipFeatures />
      {sectionData.map((item, index) => (
        <SecMembershipDetails
          key={index}
          icon={item.icon}
          name={item.title}
          text={item.description}
          type={item.button_text}
          image={item.image}
          link={item.button_link}
          bgClass={item.bgclass}
          setRegistrationActive={setRegistrationActive}
        />
      ))}
      <SecMembershipBenefit />
      <SecMembershipSummary setRegistrationActive={setRegistrationActive} />
    </>
  );
};

export default Membership;
