import React from 'react';

const SearchFilter = ({storyCount,vidCount,castCount,filter}) => {
  return (
    <>
      <div className="search-filter">
        <div className="filter">
          <input type="radio" name='filter' className='filter-radio' defaultChecked  onChange={()=>filter('all')}/>
          <div className='filter-label'>All results</div>
        </div>
        <div className="filter">
          <input type="radio" name='filter' className='filter-radio' onChange={()=>filter('videos')}/>
          <div className='filter-label'>Videos ({vidCount})</div>
        </div>
        <div className="filter">
          <input type="radio" name='filter' className='filter-radio' onChange={()=>filter('podcasts')}/>
          <div className='filter-label'>Podcasts ({castCount})</div>
        </div>
        <div className="filter">
          <input type="radio" name='filter' className='filter-radio' onChange={()=>filter('stories')}/>
          <div className='filter-label'>Stories ({storyCount})</div>
        </div>
      </div>
    </>
  );
};

export default SearchFilter;