import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import PodcastItem from './PodcastItem';

const SearchPodcast = ({castData, setCastCount}) => {

  useEffect(()=>{
    setCastCount(castData.length);
  },[castData]);

  return (
    <>
      <div className="search-podcast">
      {
      castData.length > 0 && castData.map((item, index) =>  {
        return(<>
        <div className="search-podcast-header">
          <h2 className="search-podcast-title">{item.name}</h2>
          <a className="btn-viewAll" href={`https://open.spotify.com/playlist/${item?.playlist_id}`}>See All</a>
        </div>
        <PodcastItem item={item}/>
        </>)
      })}
      </div>
    </>
  );
};

export default SearchPodcast;