import React, { useEffect, useState } from "react";
import SecFilter from "../../../components/SecFilter";
import SecPodcastList from "./SecPodcastList";
import { fetchPodcastPlaylist } from "../../../store/playlist";
import LoaderFull from "../../../components/LoaderFull";
import moment from "moment/moment";

const SecAllPodcast = () => {
  const [dateRange, setDateRange] = useState([]);
  const [hasNext, setHasNext] = useState(false);
  const [searchValue, setSearch] = useState([]);
  const [featured, setFeatured] = useState(false);
  const [limit, setLimit] = useState(10);
  const [podcastplayList, setPodcast] = useState([]);
  const [loading, setLoading] = useState(false);

  const handleDateRangeSearch = (value, dateStatus) => {
    if (dateStatus === true) {
      setSearch(value);
      const start = moment()
        .subtract(1, value)
        .startOf(value)
        .format("YYYY-MM-DD");
      const end = moment().subtract(1, value).endOf(value).format("YYYY-MM-DD");
      const range = {
        start: new Date(start).toISOString(),
        end: new Date(end).toISOString(),
      };

      setDateRange(range);
      setFeatured(false);
    } else {
      setFeatured(true);
      setSearch(value);
      setDateRange([]);
    }
  };

  useEffect(() => {
    const loadPodcast = async () => {
      setLoading(true);
      let podcastdata = [];
      if (dateRange && dateRange.start) {
        podcastdata = await fetchPodcastPlaylist({
          limit: limit,
          status: true,
          type: "spotify",
          dateRange: dateRange,
          sortByField: "ordering",
          sortByValue: 1,
        });
      } else if (featured === true) {
        podcastdata = await fetchPodcastPlaylist({
          limit: limit,
          status: true,
          type: "spotify",
          featured: featured,
          sortByField: "ordering",
          sortByValue: 1,
        });
      } else {
        podcastdata = await fetchPodcastPlaylist({
          limit: limit,
          status: true,
          type: "spotify",
          sortByField: "ordering",
          sortByValue: 1,
        });
      }
      setLoading(false);
      setPodcast(podcastdata.data);
      setHasNext(podcastdata.hasNext);
    };

    loadPodcast();
  }, [dateRange, featured, limit]);

  return (
    <>
      <section className="sec-podcast">
        <div className="container">
          <SecFilter
            handleDateRangeSearch={handleDateRangeSearch}
            searchValue={searchValue}
          />
        </div>
        {podcastplayList &&
          podcastplayList.length > 0 &&
          podcastplayList.map((item, index) => {
            return (
              <React.Fragment key={index}>
                {" "}
                <SecPodcastList item={item} />
              </React.Fragment>
            );
          })}
        {hasNext && (
          <div className="podcast-action">
            <button
              type="button"
              className="btn-action"
              onClick={() => setLimit(limit + 10)}
            >
              Load More
            </button>
          </div>
        )}
      </section>
      <LoaderFull loaderActive={loading} />
    </>
  );
};

export default SecAllPodcast;
