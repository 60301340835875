import { createSlice } from "@reduxjs/toolkit";
import { apiCallBegan } from "./api";
import BASE_URL from "../config/host";

export const countrySlice = createSlice({
  name: "countries",
  loading: false,
  initialState: {
    countrylist: [],
  },
  reducers: {
    listRequested: (state) => {
      state.loading = true;
    },
    listRequestFailed: (state) => {
      state.loading = false;
      state.countrylist = "";
    },
    listReceived: (state, action) => {
      state.loading = false;
      state.countrylist = action.payload.data ? action.payload.data : [];
    },
  },
});

export const { listReceived, listRequested, listRequestFailed } =
  countrySlice.actions;

export const fetchCountry = (body) =>
  apiCallBegan({
    url: "/countries",
    method: "post",
    data: body,
    baseUrl: BASE_URL,
    onSuccess: listReceived.type,
    onStart: listRequested.type,
    onError: listRequestFailed.type,
  });
