import { useState } from "react";
import { ReactionBarSelector } from '@charkour/react-reactions';
import { deleteLike, manageLike } from "../../../../store/feed";
const CommentItem = ({
  messageId,
  myReactions,
  reactions,
  parentMsg,
  name,
  image,
  time,
  comment,
  isFile,
  isImage,
  fileType,
  fileName,
  fileLink,
  direction,
}) => {
  const [emot, setEmot] = useState(false);
  const [emotNames, setEmotName] = useState(myReactions || ['😀']);
  const ClickEmoj = () =>{
    setEmot(!emot);
  }

  const selectImages = async(e,messageId) => {
    setEmot(!emot);
    if(e === 'like'){
      
      if(emotNames.includes('👍')){
        const arr = emotNames.filter((item) => {
          return(item !== '👍');
        });
        setEmotName(arr);
        await deleteLike({referenceId:messageId,referenceType:'message',reactionName:e});
       
      }else{
        setEmotName([...emotNames,...['👍']]);
        await manageLike({referenceId:messageId,referenceType:'message',reactionName:e});
       
      }

    }else if(e === 'love'){
      if(emotNames.includes('💘')){
        const arr = emotNames.filter((item) => {
          return(item !== '💘');
        });
        setEmotName(arr);
        await deleteLike({referenceId:messageId,referenceType:'message',reactionName:e});
       
      }else{
        setEmotName([...emotNames,...['💘']]);
        await manageLike({referenceId:messageId,referenceType:'message',reactionName:e});
       
      }
    }else{
      if(emotNames.includes('😁')){
        const arr = emotNames.filter((item) => {
          return(item !== '😁');
        });
        setEmotName(arr);
        await deleteLike({referenceId:messageId,referenceType:'message',reactionName:e});
       
      }else{
        setEmotName([...emotNames,...['😁']]);
        await manageLike({referenceId:messageId,referenceType:'message',reactionName:e});
       
      }
    }

   
  }

 



  const react = [{label: "like", node: <div>👍</div>, key: "like"},{label: "love", node: <div>💘</div>, key: "love"},{label: "haha", node: <div>😄</div>, key: "smile"}] ;
  return (
    <>
    {emot && <ReactionBarSelector  style={{background:"#000000"}} bg={'#000000'} reactions={react} iconSize="25px" onSelect={(e)=>selectImages(e,messageId)}/>}
      <div className={"comment" + (direction === "right" ? " right" : "")}>
        <div className="comment-image">
          <img src={image} alt="" />
        </div>
        <div className="comment-wrap">
          <div className="comment-user">
            {name}, <span>{time}</span>
          </div>
          <div className="comment-content">
            {!isFile && comment != "" && (
             <>
              <div className="comments">{comment}</div>
              {/* {direction === "left" && <div className="emoji" style={{cursor:"pointer"}} onClick={()=>ClickEmoj()}>{emotNames.length !== 0?emotNames:'😀'}</div>}
              {direction === "right" && <div className="emoji">{reactions?.like && '👍'} {reactions?.love && '💖'} {reactions?.smile && '😁'}</div>} */}
              </>
            )}
            {isFile && comment != "" && isImage && (
              <>
              <div className="comments-and-image">
                <div className="image">
                  <img src={fileLink+"?size=medium"} alt="" />
                </div>
                {comment && <div className="text">{comment}</div>}
                
              </div>
              {/* {direction === "left" && <div className="emoji" style={{cursor:"pointer"}} onClick={()=>ClickEmoj()}>{emotNames.length !== 0?emotNames:'😀'}</div>}
              {direction === "right" && <div className="emoji">{reactions?.like && '👍'} {reactions?.love && '💖'} {reactions?.smile && '😁'}</div>} */}
              </>
            )}
            {isFile && isImage && comment === "" && (
              <div className="comments-image">
                <img src={fileLink+"?size=medium"} alt="" />
              </div>
            )}
            {isFile && !isImage && comment === "" && (
              <div className="comments-file">
                <div className="file-type">{fileType}</div>
                <div className="file-name">{fileName}</div>
                <div className="file-action">
                  <a href={fileLink} target="_blank" className="file-link">
                    <i className="far fa-fw fa-arrow-to-bottom"></i>
                  </a>
                </div>
              </div>
            )}
            {isFile && !isImage && comment !== "" && (
              <>
              <div className="comments-and-file">
                <div className="file">
                  <div className="file-type">{fileType}</div>
                  <div className="file-name">{fileName}</div>
                  <div className="file-action">
                    <a href={fileLink} target="_blank" className="file-link">
                      <i className="far fa-fw fa-arrow-to-bottom"></i>
                    </a>
                  </div>
                </div>
                {comment && <div className="text">{comment}</div>}
                
              </div>
              {/* {direction === "left" && <div className="emoji" style={{cursor:"pointer"}} onClick={()=>ClickEmoj()}>{emotNames.length !== 0?emotNames:'😀'}</div>} */}
              {/* {direction === "right" && <div className="emoji">{reactions?.like && '👍'} {reactions?.love && '💖'} {reactions?.smile && '😁'}</div>} */}
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default CommentItem;
