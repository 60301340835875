import { useState, useEffect } from "react";
import LoaderFull from "../../components/LoaderFull";
import SecHomeBannerAlt from "./Components/SecHomeBannerAlt";
import SecHomeEvents from "./Components/SecHomeEvents";
import SecHomeStories from "./Components/SecHomeStories";
import SecHomeVideo from "./Components/SecHomeVideo";
import SecMembershipBenefit from "../Membership/Components/SecMembershipBenefit";
import SecHomeCTA from "./Components/SecHomeCTA";
import "./Css/Home.css";
import SecMembershipBanner from "../Membership/Components/SecMembershipBanner";
import SecMembershipFeatures from "../Membership/Components/SecMembershipFeatures";
import { useDispatch } from "react-redux";
import { fetchHomeContent } from "../../store/Pages/page";

const Home = () => {
  document.title = "CEO | Home";

  const [loaderActive, setLoaderActive] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchHomeContent());
  }, [dispatch]);
  const handleClickScroll = () => {
    const element = document.getElementById("scroll-section");
    var headerOffset = 96;
    var elementPosition = element.getBoundingClientRect().top;
    var offsetPosition = elementPosition + window.pageYOffset - headerOffset;

    window.scrollTo({
      top: offsetPosition,
      behavior: "smooth",
    });
  };

  return (
    <>
      <LoaderFull
        loaderActive={loaderActive}
        setLoaderActive={setLoaderActive}
      />
      <SecHomeBannerAlt functions={handleClickScroll} />
      <SecMembershipBanner />
      <SecMembershipFeatures />
      <SecHomeVideo />
      <SecHomeStories />
      <SecHomeEvents />
      <SecMembershipBenefit />
      <SecHomeCTA />
    </>
  );
};

export default Home;
