import { useState } from "react";
import {
  TwitterIcon,
  TwitterShareButton,
  WhatsappShareButton,
  WhatsappIcon,
  FacebookShareButton,
  FacebookIcon,
} from "react-share";
  
  const EventShareModal = ({eventData}) => {
   
    const [isCopy, setIscopy] = useState(false);

    const copyLink = () => {
      setIscopy(true);
      navigator.clipboard.writeText(window.location.href)
      setTimeout(() => {
        setIscopy(false);
      }, 1000);
    };

    return (
      <>
        <div
          className="modal event-delete-modal fade"
          data-backdrop="static"
          id="shareModal"
          tabIndex="-1"
          aria-labelledby="deleteModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-dialog-centered modal-sm">
            <div className="modal-content">
              <div className="modal-body">
                <div className="delete-content">
                    <div className="main-title">Social Share</div>
                    <div className="share-list">
                      <button className="btn-copy" onClick={copyLink}>
                        <i className="fa-solid fa-copy"></i>
                      </button>
                      {isCopy && <p>Link Copied</p>}
                      <TwitterShareButton
                        title={eventData?.title}
                        url={window.location.href}
                      >
                        <TwitterIcon size={42} round={true} />
                      </TwitterShareButton>
                      <FacebookShareButton
                        title={eventData?.title}
                        url={window.location.href}
                      >
                        <FacebookIcon size={42} round={true} />
                      </FacebookShareButton>
                      <WhatsappShareButton
                        title={eventData?.title}
                        url={window.location.href}
                      >
                        <WhatsappIcon size={42} round={true} />
                      </WhatsappShareButton>
                    </div>
                </div>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-secondary"
                  data-dismiss="modal"
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  };
  
  export default EventShareModal;
  