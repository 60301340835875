import OthersProfileMetaItem from "./OthersProfileMetaItem";

const OthersProfileAbout = ({
  ExperienceData,
  EducationData,
  AboutData,
  name,
}) => {

  return (
    <>
      <div className="others-profile-content">
        <h2 className="profile-content-title">About {name}</h2>
        <div className="profile-content-about">
          <p>{AboutData ? AboutData.title : ""}</p>
        </div>
      </div>
      <div className="others-profile-content">
        <h2 className="profile-content-title">Experience</h2>
        <div className="profile-meta-list">
          {ExperienceData.map((item, index) => (
            <OthersProfileMetaItem
              key={index}
              title={item.title}
              subtitle={item.company_name}
              startDate={item.start_date}
              endDate={item.end_date}
              isWorking={item.is_currently_working}
              type={"experience"}
            />
          ))}
        </div>
      </div>
      <div className="others-profile-content">
        <h2 className="profile-content-title">Education</h2>
        <div className="profile-meta-list">
          {EducationData.map((item, index) => (
            <OthersProfileMetaItem
              key={index}
              title={item.graduation[0].name}
              subtitle={item.institution}
              type={"education"}
            />
          ))}
        </div>
      </div>
      <div className="others-profile-content">
        <h2 className="profile-content-title">Interests</h2>
        <div className="profile-interest-list">
          {AboutData &&
            AboutData?.subject &&
            AboutData.subject.map((item, index) => (
              <div className="profile-interest-item" key={index}>
                {item.name}
              </div>
            ))}
        </div>
      </div>
    </>
  );
};

export default OthersProfileAbout;
