import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import MyFeeds from "../Feed/Components/MyFeeds";
import ProfileAbout from "./Components/ProfileAbout";
import ProfileConnections from "./Components/ProfileConnections";
import ProfileDrafts from "./Components/ProfileDrafts";
import ProfileEvents from "./Components/ProfileEvents";
import ProfileFilter from "./Components/ProfileFilter";
import "./Css/Profile.css";
import { updateProfile, getProfileDetails, getCommunityRequest } from "../../store/user";
import ProfileContactSaved from "./Components/ProfileContactSaved";
import ProfileMessage from "./Components/ProfileMessage";
import PostDetails from "../Feed/Components/PostDetails";
import Share from "../Share/Share";
import ProfileModal from "./Components/ProfileModal";
import ProfileSidePanel from "./Components/ProfileSidePanel";
import $ from "jquery";
import Chat from "./Components/Chat/Chat";
import Resizer from "react-image-file-resizer";
import { useEffect } from "react";

const Profile = () => {
  document.title = "CEO | Profile";

  const { profileDetails } = useSelector((state) => state.users);
  const { communityRequestList } = useSelector(
    (state) => state.users
  );

  const allowedExts = ["jpg", "jpeg", "png"];
  const [picture, setPicture] = useState("");
  const [savedpicture, setSavePicture] = useState(false);
  const [notification ,setNotification] = useState(false);
  const dispatch = useDispatch();


  const resizeFile = (file) =>
    new Promise((resolve) => {
      Resizer.imageFileResizer(
        file,
        300,
        300,
        "JPEG",
        90,
        0,
        (uri) => {
          resolve(uri);
        },
        "base64"
      );
    });

  const getUploadImg = async (e) => {
    // let base64String = "";
    let file = e.target.files[0];

    const ext = e.target.files[0].type.split("/")[1];
    if (!allowedExts.includes(ext)) {
      alert("Please upload a valid image.");
    } else {
      const image = await resizeFile(file);
      setPicture(image);
    }
  };

  const savePhoto = (croppedImg = null) => {
    if (croppedImg) {
      dispatch(updateProfile({ image: croppedImg })).then(() => {
        window.location.reload();
      });
      setSavePicture(croppedImg);
      setPicture("");
      document.getElementById("headerImg").src = croppedImg;
      dispatch(getProfileDetails({}));
    } else {
      dispatch(updateProfile({ image: picture })).then(() => {
      });
      setSavePicture(picture);

      setPicture("");
    }

    $("#profileModal").modal("hide");
  };

  const [toggleShare, setToggleShare] = useState(false);
  const togglePanel = () => {
    setToggleShare(true);
  };

  useEffect(() => {
    dispatch(getCommunityRequest({ status: 1, keyword: "" }));
  },[])
  
  useEffect(() => {
    if(communityRequestList?.data?.length > 0){
      setNotification(true);
    } else {
      setNotification(false);
    }
  },[communityRequestList?.data?.length])

  
  return (
    <>
      <section className="profile-section">
        <div className="container">
          <div className="custom-row">
            <ProfileSidePanel
              profileDetails={profileDetails}
              savedpicture={savedpicture}
              togglePanel={togglePanel}
            />
            <div className="panel-content">
              <ProfileFilter notification={notification} />
              {window.location.pathname.split("/")[1] === "post" && (
                <PostDetails />
              )}
              {window.location.pathname.split("/")[1] === "profile" ||
              window.location.pathname.split("/")[1] === "posts" ? (
                <MyFeeds />
              ) : (
                ""
              )}
              {window.location.pathname.split("/")[1] === "connections" && (
                <ProfileConnections />
              )}
              {window.location.pathname.split("/")[1] === "messages" && (
                <ProfileMessage />
              )}
              {window.location.pathname.split("/")[1] === "message" && <Chat />}
              {window.location.pathname.split("/")[1] === "events" && (
                <ProfileEvents />
              )}
              {window.location.pathname.split("/")[1] === "about" && (
                <ProfileAbout />
              )}
              {window.location.pathname.split("/")[1] === "drafts" && (
                <ProfileDrafts />
              )}
              {window.location.pathname.split("/")[1] === "saved-content" && (
                <ProfileContactSaved />
              )}
            </div>
          </div>
        </div>
      </section>

      <ProfileModal
        profileDetails={profileDetails}
        picture={picture}
        setPicture= {setPicture}
        getUploadImg={getUploadImg}
        savePhoto={savePhoto}
      />
      <Share toggleShare={toggleShare} setToggleShare={setToggleShare} />
    </>
  );
};

export default Profile;
