import { Link } from "react-router-dom";
import API_URL from "../../../config/apiHost";
import FeedReaction from "./FeedReaction";
import UserImage from "./UserImage";
import UserInfo from "./UserInfo";

export default function LinkPost({
  user,
  date,
  tick,
  url,
  imgSrc,
  linkTitle,
  linkDesc,
  commentCount,
  reactionsCount,
  loveReactionCount,
  like,
  love,
  wishlist,
  postId,
  myfeed,
  sharePost = () => {},
  setLoading,
  postTitle,
  type,
  isEdit,
  setIsEdit,
  isOpaque,
  setIsOpaque = () => {},
  deleteLink,
  setDeleteLink,
  eidtPostDetails,
  setEditPostTitle,
  handleSubmit,
  dontShow = true,
  ...props
}) {
  const editPost = () => {
    sharePost(null);
    setIsEdit(false);
    setIsOpaque(false);
    setDeleteLink(true);
  };

  if (type === "link") {
    return (
      <>
        <div
          className={"feed-item " + (postId === isOpaque ? "opaqueItem" : "")}
        >
          {postId === isEdit ? (
            <div className="feed-item-inner">
              <div className="feed-header">
                <div className="feed-user">
                  <Link
                    to={`/profile/${user?.metadata?.slug}`}
                    className="user-image"
                  >
                    <UserImage commentuser={user} />
                  </Link>

                  <div className="user-content">
                    <Link
                      to={`/profile/${user?.metadata?.slug}`}
                      className="user-name"
                    >
                      <UserInfo commentuser={user} date={date} tick={tick} />
                    </Link>
                  </div>
                </div>
              </div>
              <div className="feed-comment">
                <div className="form-group">
                  <div className={"input-style"}>
                    <input
                      type="text"
                      className="form-control"
                      name=""
                      autoComplete=""
                      defaultValue={postTitle}
                      onChange={(e) => setEditPostTitle(e.target.value)}
                    />
                  </div>
                </div>
              </div>
              <div className="feed-share">
                {imgSrc && <div className="feed-share-image">
                  <img src={imgSrc ? imgSrc : API_URL+"uploads/events/no-image.png"} alt={"linkImage"} />
                </div>}
                <div className="feed-share-content">
                  <input
                    type="text"
                    defaultValue={linkTitle}
                    className="feed-share-title form-control"
                    onChange={(e) => props.setEditPostLinkTitle(e.target.value)}
                  />
                  <textarea
                    rows={2}
                    defaultValue={linkDesc}
                    onChange={(e) => props.setEditPostLinkDesc(e.target.value)}
                    className="feed-share-link form-control"
                  />
                </div>
              </div>
              <div className="feed-action">
                <button
                  type="button"
                  className="btn-action alt"
                  onClick={() => {
                    handleSubmit();
                    editPost();
                  }}
                >
                  Save
                </button>
                <button
                  type="button"
                  className="btn-action"
                  onClick={() => editPost()}
                >
                  Cancel
                </button>
              </div>
            </div>
          ) : (
            <div className="feed-item-inner">
              <div className="feed-header">
                <div className="feed-user">
                  <Link
                    to={`/profile/${user?.metadata?.slug}`}
                    className="user-image"
                  >
                    <UserImage commentuser={user} />
                  </Link>

                  <div className="user-content">
                    <Link
                      to={`/profile/${user?.metadata?.slug}`}
                      className="user-name"
                    >
                      <UserInfo commentuser={user} date={date} tick={tick} />
                    </Link>
                  </div>
                </div>
              </div>
              <Link to={"/post/" + postId}>
                <div className="m-2 mb-4">{postTitle?.length > 60 ? postTitle.substring(0,50) + "..." : postTitle}</div>
              </Link>
              <a href={url} target="_blank" className="feed-share">
                <div className="feed-share-image">
                  <img src={imgSrc ? imgSrc : API_URL+"uploads/events/no-image.png"} alt={"linkImage"} />
                </div>

                <div className="feed-share-content">
                  <div className="feed-share-title">
                  {linkTitle?.length > 60 ? linkTitle.substring(0,50) + "..." : linkTitle}
                    <i className="fa-solid fa-external-link ml-3"></i>
                  </div>
                  <div className="feed-share-link">{linkDesc?.length > 60 ? linkDesc.substring(0,50) + "..." : linkDesc}</div>
                </div>
              </a>
              <FeedReaction
                commentCount={commentCount}
                reactionsCount={reactionsCount}
                loveReactionCount={loveReactionCount}
                like={like}
                love={love}
                wishlist={wishlist}
                postId={postId}
                myfeed={myfeed}
                sharePost={sharePost}
                setLoading={setLoading}
                postuserId={user?.userId}
                setIsEdit={setIsEdit}
                isOpaque={isOpaque}
                setIsOpaque={setIsOpaque}
                eidtPostDetails={() =>
                  eidtPostDetails(
                    postId,
                    postTitle,
                    props.item.metadata,
                    "link"
                  )
                }
                dontShow={dontShow}
              />
            </div>
          )}
        </div>
      </>
    );
  } else if (type === "article") {
    return (
      <>
        <div className="feed-item">
          {postId === isEdit ? (
            <div className="feed-item-inner">
              <div className="feed-header">
                <div className="feed-user">
                  <Link
                    to={`/profile/${user?.metadata?.slug}`}
                    className="user-image"
                  >
                    <UserImage commentuser={user} />
                  </Link>

                  <div className="user-content">
                    <Link
                      to={`/profile/${user?.metadata?.slug}`}
                      className="user-name"
                    >
                      <UserInfo commentuser={user} date={date} tick={tick} />
                    </Link>
                  </div>
                </div>
              </div>
              <div className="feed-comment">
                <div className="form-group">
                  <div className={"input-style"}>
                    <input
                      type="text"
                      className="form-control"
                      name=""
                      autoComplete=""
                      defaultValue={postTitle}
                      onChange={(e) => setEditPostTitle(e.target.value)}
                    />
                  </div>
                </div>
              </div>
              <div className="feed-share">
                <div className="feed-share-image">
                  <img src={imgSrc ? imgSrc : API_URL+"uploads/events/no-image.png"} alt={"linkImage"} />
                </div>
                <div className="feed-share-content">
                  <input
                    type="text"
                    defaultValue={linkTitle}
                    className="feed-share-title form-control"
                    onChange={(e) => props.setEditPostLinkTitle(e.target.value)}
                  />
                  <textarea
                    rows={2}
                    defaultValue={linkDesc}
                    onChange={(e) => props.setEditPostLinkDesc(e.target.value)}
                    className="feed-share-link form-control"
                  />
                </div>
              </div>
              <div className="feed-action">
                <button
                  type="button"
                  className="btn-action alt"
                  onClick={() => {
                    handleSubmit();
                    editPost();
                  }}
                >
                  Save
                </button>
                <button
                  type="button"
                  className="btn-action"
                  onClick={() => editPost()}
                >
                  Cancel
                </button>
              </div>
            </div>
          ) : (
            <div className="feed-item-inner">
              <div className="feed-header">
                <div className="feed-user">
                  <Link
                    to={`/profile/${user?.metadata?.slug}`}
                    className="user-image"
                  >
                    <UserImage commentuser={user} />
                  </Link>

                  <div className="user-content">
                    <Link
                      to={`/profile/${user?.metadata?.slug}`}
                      className="user-name"
                    >
                      <UserInfo commentuser={user} date={date} tick={tick} />
                    </Link>
                  </div>
                </div>
              </div>
              <div className="m-2 mb-4">{postTitle?.length > 60 ? postTitle.substring(0,50) + "..." : postTitle}</div>

              <Link to={"/post/" + postId}>
                <div className="feed-share">
                  <div className="feed-share-image">
                    <img
                      src={
                        imgSrc
                          ? imgSrc
                          : API_URL + "uploads/events/no-image.png"
                      }
                      alt={"linkImage"}
                    />
                  </div>

                  <div className="feed-share-content">
                    <div className="feed-share-title">
                    {linkTitle?.length > 30 ? linkTitle.substring(0,30) + "..." : linkTitle}
                      <i className="fa-solid fa-external-link ml-3"></i>
                    </div>
                    <div className="feed-share-link">{linkDesc?.length > 60 ? linkDesc.substring(0,50) + "..." : linkDesc}</div>
                  </div>
                </div>
              </Link>
              <FeedReaction
                commentCount={commentCount}
                reactionsCount={reactionsCount}
                loveReactionCount={loveReactionCount}
                like={like}
                love={love}
                wishlist={wishlist}
                postId={postId}
                myfeed={myfeed}
                sharePost={sharePost}
                setLoading={setLoading}
                postuserId={user?.userId}
                setIsEdit={setIsEdit}
                isOpaque={isOpaque}
                setIsOpaque={setIsOpaque}
                eidtPostDetails={() =>
                  eidtPostDetails(
                    postId,
                    postTitle,
                    props.item.metadata,
                    "article"
                  )
                }
                dontShow={dontShow}
              />
            </div>
          )}
        </div>
      </>
    );
  } else {
    return (
      <>
        <div className="feed-item">
          {postId === isEdit ? (
            <div className="feed-item-inner">
              <div className="feed-header">
                <div className="feed-user">
                  <Link
                    to={`/profile/${user?.metadata?.slug}`}
                    className="user-image"
                  >
                    <UserImage commentuser={user} />
                  </Link>

                  <div className="user-content">
                    <Link
                      to={`/profile/${user?.metadata?.slug}`}
                      className="user-name"
                    >
                      <UserInfo commentuser={user} date={date} tick={tick} />
                    </Link>
                  </div>
                </div>
              </div>
              <div className="feed-comment">
                <div className="form-group">
                  <div className={"input-style"}>
                    <input
                      type="text"
                      className="form-control"
                      name=""
                      autoComplete=""
                      defaultValue={postTitle}
                      onChange={(e) => setEditPostTitle(e.target.value)}
                    />
                  </div>
                </div>
              </div>
              <div className="feed-share">
                <div className="feed-share-image">
                  <img src={imgSrc ? imgSrc : API_URL+"uploads/events/no-image.png"} alt={"linkImage"} />
                </div>
                <div className="feed-share-content">
                  <input
                    type="text"
                    defaultValue={linkTitle}
                    className="feed-share-title form-control"
                    onChange={(e) => props.setEditPostLinkTitle(e.target.value)}
                  />
                  <textarea
                    rows={2}
                    defaultValue={linkDesc}
                    onChange={(e) => props.setEditPostLinkDesc(e.target.value)}
                    className="feed-share-link form-control"
                  />
                </div>
              </div>
              <div className="feed-action">
                <button
                  type="button"
                  className="btn-action alt"
                  onClick={() => {
                    handleSubmit();
                    editPost();
                  }}
                >
                  Save
                </button>
                <button
                  type="button"
                  className="btn-action"
                  onClick={() => editPost()}
                >
                  Cancel
                </button>
              </div>
            </div>
          ) : (
            <div className="feed-item-inner">
              <div className="feed-header">
                <div className="feed-user">
                  <Link
                    to={`/profile/${user?.metadata?.slug}`}
                    className="user-image"
                  >
                    <UserImage commentuser={user} />
                  </Link>

                  <div className="user-content">
                    <Link
                      to={`/profile/${user?.metadata?.slug}`}
                      className="user-name"
                    >
                      <UserInfo commentuser={user} date={date} tick={tick} />
                    </Link>
                  </div>
                </div>
              </div>
              <div className="m-2 mb-4">{postTitle?.length > 60 ? postTitle.substring(0,50) + "..." : postTitle}</div>
              <Link to={url} className="feed-share">
                <div className="feed-share-image">
                  <img src={imgSrc ? imgSrc : API_URL+"uploads/events/no-image.png"} alt={"eventImage"} />
                </div>

                <div className="feed-share-content">
                  <div className="feed-share-title">
                    {linkTitle?.length > 30 ? linkTitle.substring(0,30) + "..." : linkTitle}
                  </div>
                  <div className="feed-share-link">{linkDesc?.length > 60 ? linkDesc.substring(0,50) + "..." : linkDesc}</div>
                </div>
              </Link>
              <FeedReaction
                commentCount={commentCount}
                reactionsCount={reactionsCount}
                loveReactionCount={loveReactionCount}
                like={like}
                love={love}
                wishlist={wishlist}
                postId={postId}
                myfeed={myfeed}
                sharePost={sharePost}
                setLoading={setLoading}
                postuserId={user?.userId}
                setIsEdit={setIsEdit}
                isOpaque={isOpaque}
                setIsOpaque={setIsOpaque}
                eidtPostDetails={() =>
                  eidtPostDetails(
                    postId,
                    postTitle,
                    props.item.metadata,
                    "event"
                  )
                }
                dontShow={dontShow}
              />
            </div>
          )}
        </div>
      </>
    );
  }
}
