import moment from 'moment/moment';
import React from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getExperience, getEducation } from '../../../store/user';
import ProfileExperienceEdit from './ProfileExperienceEdit';

const ProfileMetaItem = ({ id, title, subtitle, startDate, endDate, isWorking, type, loadProfile }) => {
  const dispatch = useDispatch();

  const { experienceGetList, educationGetList } = useSelector(state => state.users);
  const [modalActive, setModalActive] = useState(false);
  
  const [details, setDetails] = useState([]);

  // useEffect(() => {
  //   dispatch(getExperience({id: id}))
  // }, [])

  async function handleClick() {
    if(type === 'experience') {
      dispatch(getExperience({id: id}))
    } else {
      dispatch(getEducation({id: id}))
    }
  }

  useEffect(() => {
    setDetails(experienceGetList)
  }, [experienceGetList])

  useEffect(() => {
    setDetails(educationGetList)
  }, [educationGetList])

   //console.log(details);
  
  return (
    <>
      <ProfileExperienceEdit 
        profileActive={modalActive}
        type={type}
        setProfileActive={setModalActive}
        details={details}
        loadProfile={loadProfile}
      />
      <div className='profile-meta-item'>
        <div className='profile-content-header'>
          {title &&
            <div className='profile-meta-title'>{title}</div>
          }
          <div className='edit-action'>
            <button className='edit' type='button' onClick={() => {setModalActive(true); handleClick();}}>Edit</button>
          </div>
        </div>

        {subtitle &&
          <div className='profile-meta-subtitle'>{subtitle}</div>
        }
        {/* {startDate && endDate &&
          
        } */}
        {isWorking ?
          <div className='profile-meta-date'>{moment(new Date(startDate)).format("MMM YYYY")} - Present</div> : type === 'experience' && <div className='profile-meta-date'>{moment(new Date(startDate)).format("MMM YYYY")} - {moment(new Date(endDate)).format("MMM YYYY")}</div>
        }
      </div>
    </>
  );
};

export default ProfileMetaItem;