import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { tagList } from "../../../store/event";

const FeedFilters = ({ filter, setFilter, tags, setTags }) => {
  const [tagLists, setTagLists] = useState([]);
  const [selectedTags, setSelectedTag] = useState([]);
  const [themes, setThemes] = useState(false);

  useEffect(() => {
    if (window.location.pathname.split("/")[1] === "global-feed") {
      tagList({ tag_type: 2, keyword: "", status: true })
        .then((res) => {
          setTagLists(res);
        })
        .catch(() => {});
    }
  }, [window.location.pathname.split("/")[1]]);

  const selectTag = (e) => {
    let tagArray = [...selectedTags];
    let index = tagArray.findIndex((tag) => tag === e.target.value);
    if (index === -1) {
      tagArray.push(e.target.value);
    } else {
      tagArray.splice(index, 1);
    }
    setSelectedTag(tagArray);
  };
  const navigate = useNavigate();
  const filterRadio = (e) => {
    navigate("/" + e.target.value);
  };

  return (
    <>
      <div className="feed-filter">
        <div className="filter">
          <input
            type="radio"
            name="filter"
            value="global-feed"
            className="filter-radio"
            checked={
              window.location.pathname.split("/")[1] === "feed" ||
              window.location.pathname.split("/")[1] === "global-feed"
            }
            onChange={(e) => filterRadio(e)}
          />
          <div className="filter-label">CEO Feed</div>
          {/* {tags.length && !themes ? (
            <button
              className="filter-count"
              onClick={(e) => setThemes((current) => !current)}
            >
              {tags.length}
            </button>
          ) : (
            <button
              className="filter-icon"
              onClick={(e) => setThemes((current) => !current)}
            >
              {themes ? (
                <i className="fa-light fa-fw fa-angle-up"></i>
              ) : (
                <i className="fa-light fa-fw fa-angle-down"></i>
              )}
            </button>
          )} */}
        </div>
        <div className="filter">
          <input
            type="radio"
            name="filter"
            value="my-feed"
            className="filter-radio"
            checked={window.location.pathname.split("/")[1] === "my-feed"}
            onChange={(e) => filterRadio(e)}
          />
          <div className="filter-label">My feed</div>
        </div>
      </div>
      {themes ? (
        <div className="themetags">
          Filter by tags
          <div className="themetag-list">
            {tagLists.length > 0 &&
              tagLists.map((item, index) => (
                <div className="tag" key={index}>
                  <input
                    type="checkbox"
                    className="tag-check"
                    name="theme-tags"
                    value={item.title}
                    checked={
                      selectedTags.findIndex((tag) => tag === item.title) !== -1
                    }
                    onChange={(e) => selectTag(e)}
                  />
                  <div className="tag-label">{item.title}</div>
                </div>
              ))}
          </div>
          <div className="themetags-action">
            <button
              type="button"
              className="btn-action"
              onClick={(e) => {
                setThemes((current) => !current);
                setTags(selectedTags);
              }}
            >
              Filter
            </button>
            <button
              type="button"
              className="btn-action alt"
              onClick={(e) => setThemes((current) => !current)}
            >
              Cancel
            </button>
          </div>
        </div>
      ) : (
        ""
      )}
    </>
  );
};

export default FeedFilters;
