import React, { useContext, useState, useCallback, useEffect } from "react";
import { commentList } from "../../../store/feed";
import CommentReply from "./CommentReply";
import UserInfo from "./UserInfo";
import UserImage from "./UserImage";
import LoadMore from "../../../components/LoadMore";
import CommentAction from "./CommentAction";
import { SocketContext } from ".././../../context/socket";
function CommentList({
  postId,
  setParentComment,
  setCommentCount,
  commentCount,
  postuserId,
  setParentUserId,
}) {
  const socket = useContext(SocketContext);
  const [comments, setComments] = useState([]);
  const [subcomments, setSubComments] = useState([]);
  const [users, setUsers] = useState([]);
  const [paging, setPaging] = useState([]);
  const [limit, setLimit] = useState(5);
  const [hitLike, setIsLike] = useState(null);
  const [hitLove, setIsLove] = useState(null);
  const searchParams = new URLSearchParams(window.location.search).get(
    "isComment"
  );

  useEffect(() => {
    const getCommentList = async () => {
      const data = await commentList({ referenceId: postId, limit: limit });
      setComments(data.comments);
      setSubComments(data.commentChildren);
      setUsers(data.users);
      setPaging(data.paging);
    };
    getCommentList();
  }, []);

  const webhookEventResponse = useCallback(
    (data) => {
      if (
        data.data.data &&
        data.data.data.comments.length > 0 &&
        data.data.data.comments[0].referenceId === postId
      ) {
        if (data.data.event === "v3.comment.didCreate") {
          setCommentCount(commentCount + 1);
          setComments([...data.data.data.comments, ...comments]);
          setUsers([...data.data.data.users, ...users]);

          document.getElementById("saveCmt").innerHTML = "Submit";
        }
        if (data.data.event === "v3.comment.didReply") {
          setCommentCount(commentCount + 1);
          setSubComments([...subcomments, ...data.data.data.comments]);
          setUsers([...data.data.data.users, ...users]);

          document.getElementById("saveCmt").innerHTML = "Submit";
        }
        if (data.data.event === "v3.comment.didDelete") {
          setCommentCount(commentCount - 1);
          const getsubcomment = subcomments.filter(
            (item) => item.commentId !== data.data.data.comments[0].commentId
          );
          setSubComments(getsubcomment);
          const getcomment = comments.filter(
            (item) => item.commentId !== data.data.data.comments[0].commentId
          );
          setComments(getcomment);
        }
        if (data.data.event === "v3.comment.didRemoveReaction") {
          let mainComments = comments.map((u) =>
            u.commentId !== data.data.data.comments[0].commentId
              ? u
              : data.data.data.comments[0]
          );
          setComments(mainComments);
          let subComments = subcomments.map((u) =>
            u.commentId !== data.data.data.comments[0].commentId
              ? u
              : data.data.data.comments[0]
          );
          setSubComments(subComments);
        }
        if (data.data.event === "v3.comment.didAddReaction") {
          let mainComments = comments.map((u) =>
            u.commentId !== data.data.data.comments[0].commentId
              ? u
              : data.data.data.comments[0]
          );
          setComments(mainComments);
          let subComments = subcomments.map((u) =>
            u.commentId !== data.data.data.comments[0].commentId
              ? u
              : data.data.data.comments[0]
          );
          setSubComments(subComments);
        }
      }
    },
    [comments, subcomments, hitLike, hitLove]
  );

  useEffect(() => {
    socket.on("webhookEventResponse", webhookEventResponse);
    return () => {
      socket.off("webhookEventResponse", webhookEventResponse);
    };
  }, [webhookEventResponse, socket]);

  const loadData = () => {
    const loadComment = async () => {
      document.getElementById("load").innerHTML = "Loading...";
      const loadComment = await commentList({
        limit: limit,
        referenceId: postId,
        token: paging.next,
      });
      setComments([...comments, ...loadComment.comments]);
      setSubComments([...subcomments, ...loadComment.commentChildren]);
      setUsers([...users, ...loadComment.users]);
      setPaging(loadComment.paging);
      document.getElementById("load").innerHTML = "Load More";
    };

    loadComment();
  };

  const replyComment = (id, parentuserId) => {
    setParentComment(id);
    setParentUserId(parentuserId);
    document.getElementById("addCmt").scrollIntoView();
    document.getElementById("cmt").focus();
  };

  useEffect(() => {
    if (searchParams && document.getElementById("addCmt")) {
      document.getElementById("addCmt").scrollIntoView();
      document.getElementById("cmt").focus();
    }
  }, [searchParams, document.getElementById("addCmt")]);
  //console.log(comments);

  return (
    <>
      <div className="comment-list">
        {comments &&
          comments.length > 0 &&
          comments.map((item, index) => {
            const commentreplies =
              subcomments.length > 0 &&
              subcomments.filter((i) => i.rootId == item._id);
            const commentuser =
              users.length > 0 && users.find((i) => i.userId == item.userId);
            const liked = item.myReactions && item.myReactions.includes("like");
            const loved = item.myReactions && item.myReactions.includes("love");

            return (
              <React.Fragment key={index}>
                <div className="comment-item" id={"rmv" + item._id}>
                  <div className="comment">
                    <div className="user-comment">
                      <UserImage commentuser={commentuser} />
                      <div className="user">
                        <UserInfo
                          commentuser={commentuser}
                          date={item.createdAt}
                        />
                        <div className="comments">{item.data.text}</div>
                      </div>
                    </div>
                    <CommentAction
                      id={item._id}
                      postId={postId}
                      parentuserId={item.userId}
                      org_id={item._id}
                      reactionsCount={
                        item.reactions?.like ? item.reactions?.like : 0
                      }
                      loveReactionsCount={
                        item.reactions?.love ? item.reactions?.love : 0
                      }
                      replyComment={replyComment}
                      liked={liked}
                      loved={loved}
                      userId={item.userId}
                      postuserId={postuserId}
                      setIsLike={setIsLike}
                      setIsLove={setIsLove}
                    />
                  </div>
                  <div className="comment-reply-wrap">
                    {commentreplies.length > 0 &&
                      commentreplies.map((data, i) => {
                        const liked = data.myReactions
                          ? data.myReactions.includes("like")
                          : false;
                        return (
                          <React.Fragment key={i}>
                            <CommentReply
                              data={data}
                              postId={postId}
                              users={users}
                              replyComment={replyComment}
                              parentuserId={data.userId}
                              liked={liked}
                              loved={loved}
                              reactionsCount={
                                data.reactions?.like ? data.reactions?.like : 0
                              }
                              loveReactionsCount={
                                data.reactions?.love ? data.reactions?.love : 0
                              }
                              userId={data.userId}
                              postuserId={postuserId}
                              setIsLike={setIsLike}
                              setIsLove={setIsLove}
                            />
                          </React.Fragment>
                        );
                      })}
                  </div>
                </div>
              </React.Fragment>
            );
          })}
      </div>
      {JSON.stringify(paging) !== "{}" && paging.next && (
        <div className="feed-action">
          <LoadMore next={paging.next} loadData={loadData} />
        </div>
      )}
    </>
  );
}

export default CommentList;
