import { createSlice } from "@reduxjs/toolkit";
import { apiCallBegan } from "./api";
import BASE_URL from "../config/host";

export const bannerSlice = createSlice({
  name: "banners",
  loading: false,
  initialState: {
    bannerList: [],
    count: 0,
    loading: false,
  },
  reducers: {
    bannerRequested: (state) => {
      state.loading = true;
    },
    bannerRequestFailed: (state) => {
      state.loading = false;
      state.bannerList = "";
    },
    bannerListReceived: (state, action) => {
      state.loading = false;
      if (action.payload.count > 0) {
        state.bannerList = action.payload.data;
        state.count = action.payload.count;
      }
    },
  },
});

export const { bannerRequestFailed, bannerRequested, bannerListReceived } =
  bannerSlice.actions;

export const fetchBanners = (body) =>
  apiCallBegan({
    url: "/banner/list",
    method: "post",
    data: body,
    baseUrl: BASE_URL,
    onSuccess: bannerListReceived.type,
    onStart: bannerRequested.type,
    onError: bannerRequestFailed.type,
  });
