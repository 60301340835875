import axios from "axios";
import BASE_URL from "../config/host";
export const getNotifications = async (body) => {
  const res = await axios.post(BASE_URL + "/notifications", body, {
    headers: {
      "Content-type": "application/json",
      Authorization: `Bearer ${localStorage.getItem("accesstoken")}`,
    },
  });
  if (res.data.status === 200) {
    return res.data;
  }
};

export const addNotification = async (body) => {
  const res = await axios.post(BASE_URL + "/notification/add", body, {
    headers: {
      "Content-type": "application/json",
      Authorization: `Bearer ${localStorage.getItem("accesstoken")}`,
    },
  });
  if (res.data.status === 200) {
    return res.data;
  }
};

export const updateNotification = async (body) => {
  const res = await axios.post(BASE_URL + "/notification/update", body, {
    headers: {
      "Content-type": "application/json",
      Authorization: `Bearer ${localStorage.getItem("accesstoken")}`,
    },
  });
  if (res.data.status === 200) {
    return res.data;
  }
};
