import React from "react";
import { useNavigate } from "react-router-dom";

const CloseHeader = () => {
  const navigate = useNavigate();
  return (
    <>
      <section className="header-alt">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-6 d-flex ml-auto">
              <button
                type="button"
                className="panel-close-header"
                onClick={() => navigate(-1)}
              >
                <i className="fa-light fa-fw fa-times"></i>
              </button>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default CloseHeader;
