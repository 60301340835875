import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import LoaderFull from "../../../components/LoaderFull";
import { getCommunity, getCommunityRequest } from "../../../store/user";
import ProfileOwnConnections from "./ProfileOwnConnections";
import ProfileRequest from "./ProfileRequest";

const ProfileConnections = () => {
  const { communityList, loading } = useSelector((state) => state.users);
  const { communityRequestList, loading1 } = useSelector(
    (state) => state.users
  );

  const [keyword, setKeyword] = useState("");

  const [profileView, setprofileView] = useState("connection");
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getCommunity({ status: 2, keyword: keyword }));
  }, [dispatch, profileView, keyword]);

  useEffect(() => {
    dispatch(getCommunityRequest({ status: 1, keyword: "" }));
  }, [ dispatch, profileView]);



  return (
    <>
      <div className="connection-switch">
        <button
          type="button"
          id="connectionView"
          className={
            "btn-switch " + (profileView === "connection" ? "active" : "")
          }
          onClick={() => setprofileView("connection")}
        >
          My Connections
        </button>
        <button
          type="button"
          id="requestView"
          className={
            "btn-switch " + (profileView === "request" ? "active" : "")
          }
          onClick={() => setprofileView("request")}
        >
          Requests <span>{communityRequestList?.data?.length ? communityRequestList?.data?.length : 0}</span>
        </button>
      </div>
      <div className="mt-5">
        {profileView === "request" ? (
          <ProfileRequest
            communityList={communityRequestList}
            loading={loading}
            keyword={keyword}
          />
        ) : (
          <ProfileOwnConnections
            communityList={communityList}
            keyword={keyword}
            setKeyword={setKeyword}
            loading={loading1}
          />
        )}
      </div>

      <LoaderFull loaderActive={loading} />
    </>
  );
};

export default ProfileConnections;
