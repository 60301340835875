import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';

const SearchStory = ({storyData,setStoryCount}) => {
 
  useEffect(()=>{
    setStoryCount(storyData.length);
  },[storyData])

  return (
    <>
      <div className="search-stories">
      {storyData.length > 0 && <>
        <div className="search-stories-header">
          <h2 className="search-stories-title">Stories</h2>
          <Link className="btn-viewAll" to="/stories">See All</Link>
        </div>
        
        <div className="search-stories-list">
          {storyData.map((item, index) => (
                  <div className='search-stories-item' key={index}>
                    <Link to={"/story/"+item.id} className="item-inner">
                      <div className='item-thumbnail'><img src={item.image} alt=''/></div>
                      <div className='item-content'>
                        <div className='item-title'>{item.name}</div>
                        {/* <div className='item-text'>Gail Miller</div> */}
                      </div>
                    </Link>
                  </div>
          ))}
        </div>
        </>
}
      </div>
    </>
  );
};

export default SearchStory;