import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import SecVideoList from "../../Video/Components/SecVideoList";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { fetchPlaylist } from "../../../store/playlist";
import Fade from "react-reveal/Fade";
import { youtube_parser } from "../../../helpers/getSlug";

const SecHomeVideo = () => {
  const dispatch = useDispatch();
  const { playList, loading } = useSelector((state) => state.playlist);
  const { homeContentdata } = useSelector((state) => state.pages);
  const [videoId, setVideoId] = useState("");

  useEffect(() => {
    dispatch(
      fetchPlaylist({
        type: "youtube,vimeo",
        status: true,
        featured: true,
        sortByField: "ordering",
        sortByValue: 1,
      })
    );
  }, [window.location.href]);

  useEffect(() => {
    homeContentdata?.home_video?.video_url !== undefined &&
      setVideoId(youtube_parser(homeContentdata?.home_video?.video_url));
  }, [homeContentdata]);
  return (
    <>
      {!loading && (
        <section className="home-video">
          <div className="container">
            <div className="video-content">
              <Fade>
                <h2 className="video-content-title">
                  {homeContentdata?.home_video?.title}
                </h2>
              </Fade>
              {videoId !== "" && (
                <Fade>
                  <Link
                    to={"/video/" + videoId + "?type=youtube"}
                    className="video-thumbnail"
                  >
                    <img
                      src={
                        "https://img.youtube.com/vi/" +
                        videoId +
                        "/maxresdefault.jpg"
                      }
                      alt=""
                    />
                  </Link>
                </Fade>
              )}
            </div>
          </div>
          {playList.length > 0 &&
            playList.map((item, index) => {
              return <SecVideoList item={item} featured={true} key={index} />;
            })}
          <div className="container">
            <div className="video-action">
              <Fade bottom>
                <Link to="/video" className="btn-action">
                  <i className="fa-solid fa-fw fa-play mr-2"></i>Browse more
                  Videos
                </Link>
              </Fade>
            </div>
          </div>
        </section>
      )}
    </>
  );
};

export default SecHomeVideo;
