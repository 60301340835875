import React, { useEffect, useState } from "react";
import { createMyPost, createPost, feedDetails } from "../../../store/feed";
import { getUsers } from "../../../store/user";
import { tagList } from "../../../store/event";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { addNotification } from "../../../store/notification";
import {
  FacebookIcon,
  FacebookShareButton,
  TwitterIcon,
  TwitterShareButton,
  WhatsappIcon,
  WhatsappShareButton,
} from "react-share";
function SharedModal({ postId }) {
  const [postData, setPostData] = useState([]);
  const [attachments, setAttachments] = useState([]);
  const [postChildren, setpostChildren] = useState([]);
  const [active, setActive] = useState(false);
  const [metadata, setMetadata] = useState();
  const [files, setFiles] = useState([]);
  const [text, setText] = useState("");
  const [loading, setLoading] = useState(false);
  const [users, setUsers] = useState([]);
  const [usersId, setUsersId] = useState([]);
  const [userData, setUserData] = useState([]);
  const [dataUsers, setdataUsers] = useState([]);
  const [postuserId, setpostuserId] = useState("");
  const [tags, setTags] = useState([]);
  const [tagFilter, setTagFilter] = useState([]);
  const [selectedTagIds, setSelectedTagIds] = useState([]);
  const [keyword, setKeyword] = useState("");
  const [keywordTag, setKeywordTag] = useState("");
  const [selectedTags, setSelectedTags] = useState([]);
  const [isCopy, setIscopy] = useState(false);
  const { profileDetails } = useSelector((state) => state.users);
  const { settingData } = useSelector((state) => state.settings);
  const navigate = useNavigate();

  useEffect(() => {
    tagList({ tag_type: 1, keyword: keywordTag, status: true })
      .then((res) => {
        setTagFilter(res);
      })
      .catch(() => {});
  }, [keywordTag]);

  const copyLink = () => {
    setIscopy(true);
    navigator.clipboard.writeText(window.location.origin + `/post/${postId}`);
    setTimeout(() => {
      setIscopy(false);
    }, 1000);
  };

  useEffect(() => {
    const postDetails = async () => {
      setLoading(true);
      try {
        const details = await feedDetails({ postId: postId });
        const userData = await getUsers({ type: "user" });

        setdataUsers(userData.data);

        setpostuserId(details.data.posts[0].postedUserId);
        setText(details.data.posts[0].data.text);
        setPostData(details.data.posts);
        setMetadata(details.data.posts[0]?.metadata);
        setFiles(
          details.data.files.filter(
            (item) => item.fileId != "725be8f8505c4210abef592271eaf54a"
          )
        );
        setpostChildren(details.data.postChildren);

        let file = [];
        details.data.postChildren.map((item, index) => {
          if (item.dataType === "image") {
            let FileId = item.data.fileId;
            let find = details.data.files.find((tm, i) => (tm.fileId = FileId));
            if (find) {
              file.push({ fileId: find.fileId, type: find.type });
            }
          }
          if (item.dataType === "video") {
            let videoFileId = item.data.videoFileId.original;
            let find = details.data.files.find(
              (tm, i) => (tm.fileId = videoFileId)
            );
            if (find) {
              file.push({ fileId: find.fileId, type: find.type });
            }
          }
        });
        setAttachments(file);
        setLoading(false);
      } catch (error) {}
    };
    if (postId) {
      postDetails();
    }
  }, [postId]);

  useEffect(() => {
    if (keyword === "") {
      async function fetchdata() {
        await getUsers({ type: "user" })
          .then((res) => {
            setUserData(res.data);
          })
          .catch(() => {});
      }
      fetchdata();
    } else {
      const condition = new RegExp(keyword, "i");

      let result = userData.filter(function (el) {
        return condition.test(el.name);
      });
      setUserData(result);
    }
  }, [keyword]);

  const newPost = async () => {
    setLoading(true);
    if (usersId.length > 0) {
      await createPost({
        data: {
          text: text,
        },
        metadata: metadata,
        attachments: attachments,
        targetType: "community",
        targetId: settingData?.community_id,
        mentionees: [
          {
            type: "user",
            userIds: usersId,
          },
        ],
        tags: selectedTags,
      }).then(async (res) => {
        await createMyPost({
          post_id: res.data.posts[0]._id,
        }).then(() => {
          setLoading(false);
          navigate("/feed");
        });
      });
      await addNotification({
        source_id: postId,
        receiver_id: postuserId,
        source_type: "shared",
        content: "{{userName}} shared your post",
      });

      users.length > 0 &&
        users.map(async (it) => {
          await addNotification({
            source_id: postId,
            receiver_id: it._id,
            source_type: "shared",
            content: "{{userName}} mentioned you in a post",
          });
          setLoading(false);
        });
    } else {
      await createPost({
        data: {
          text: text,
        },
        metadata: metadata,
        attachments: attachments,
        targetType: "community",
        targetId: settingData?.community_id,
        tags: selectedTags,
      }).then(async (res) => {
        await createMyPost({
          post_id: res.data.posts[0]._id,
        }).then(() => {
          setLoading(false);
          navigate("/feed");
        });
      });

      await addNotification({
        source_id: postId,
        receiver_id: postuserId,
        source_type: "shared",
        content: "{{userName}} shared your post",
      });

      setLoading(false);
    }
  };


  return (
    <>
      <div
        className="modal own-profile-modal fade"
        id="shareModal"
        tabIndex="-1"
        aria-labelledby="shareModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered modal-lg">
          {!loading && (
            <div className="modal-content">
              <div className="modal-header">
                <h4 className="modal-title" id="shareModalLabel">
                  Post Share
                </h4>

                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <i className="fa-light fa-xmark"></i>
                </button>
              </div>
              {postData &&
                postData.length > 0 &&
                postData[0]?.metadata?.isLink && (
                  <a
                    href={postData[0]?.metadata?.preview_json.url}
                    target="_blank"
                    className="feed-share"
                    rel="noreferrer"
                  >
                    <div className="feed-share-image">
                      <img
                        src={postData[0]?.metadata.preview_json.images[0]}
                        alt={"linkImage"}
                      />
                    </div>
                    <div className="feed-share-content">
                      <div className="feed-share-title">
                        {postData[0]?.metadata.preview_json.title}
                        <i className="fa-solid fa-external-link ml-3"></i>
                      </div>
                      <div className="feed-share-link">
                        {postData[0]?.metadata.preview_json.description}
                      </div>
                    </div>
                  </a>
                )}
              {postData.length > 0 && (
                <div className="modal-body">
                  <div className="row mb-4">
                    <div className="col-sm-12">
                      <input
                        type="textarea"
                        className="form-control"
                        placeholder="Write Something.."
                        value={text}
                        onChange={(e) => setText(e.target.value)}
                      />
                    </div>
                  </div>
                  <div className="row mb-4">
                    <div className="col-sm-12">
                      {files.length > 0 &&
                        files.map((item, index) => {
                          if (item.type === "image") {
                            return (
                              <React.Fragment key={index}>
                                <img src={item.fileUrl} alt={"image" + index} />
                              </React.Fragment>
                            );
                          }
                          if (item.type === "video") {
                            return (
                              <React.Fragment>
                                {item.fileUrl && (
                                  <img
                                    src={item.fileUrl}
                                    alt={"video" + index}
                                  />
                                )}
                              </React.Fragment>
                            );
                          }
                        })}
                    </div>
                  </div>

                  <div
                    className="profile-content-section"
                    style={{ zIndex: 1 }}
                  >
                    <h2 className="profile-content-title">Mention Users</h2>
                    <div className="profile-interest-list">
                      {users &&
                        users?.map((item, index) => (
                          <div className="profile-interest-item" key={index}>
                            {item.name}
                            <button
                              type="button"
                              className="remove-action"
                              onClick={() => {
                                let tmp = users;
                                let tmpIds = usersId;
                                let newTmp = tmp.filter((val) => {
                                  return val._id !== item._id;
                                });
                                let newTmpIds = tmpIds.filter((val) => {
                                  return val !== item._id;
                                });
                                setUsers(newTmp);
                                setUsersId(newTmpIds);
                              }}
                            >
                              <i className="fa-light fa-xmark"></i>
                            </button>{" "}
                          </div>
                        ))}
                    </div>

                    <div
                      className={
                        "input-style mt-5" + (keyword ? "hasValue" : "")
                      }
                    >
                      <input
                        className="form-control"
                        value={keyword}
                        onFocus={() => setActive(true)}
                        onBlur={() =>
                          setTimeout(() => {
                            setActive(false);
                          }, 200)
                        }
                        onChange={(e) => setKeyword(e.target.value)}
                      />
                      <div
                        className="float-label"
                        style={{
                          fontSize: keyword && "13px",
                          margin: keyword && "-24px 0 0",
                        }}
                      >
                        Search Users
                      </div>
                      {active && userData && userData?.length > 0 && (
                        <div className="suggestions" style={{ zIndex: 1 }}>
                          <div className="suggestion-list">
                            {userData.map((item, index) => {
                              if (item._id !== profileDetails._id) {
                                return (
                                  <button
                                    type="button"
                                    className="suggestion-item"
                                    key={index}
                                    onClick={(e) => {
                                      if (!usersId.includes(item._id)) {
                                        let a = {
                                          _id: item._id,
                                          name: item.name,
                                        };
                                        setUsers([...users, a]);
                                        setUsersId([...usersId, item._id]);
                                      }
                                    }}
                                  >
                                    {item.name}
                                  </button>
                                );
                              }
                              return null;
                            })}
                          </div>
                        </div>
                      )}
                    </div>
                  </div>

                  {/* Tags */}

                  <div className="profile-content-section">
                    <h2 className="profile-content-title">Add Tags</h2>
                    <div className="profile-interest-list">
                      {tags &&
                        tags?.map((item, index) => (
                          <div className="profile-interest-item" key={index}>
                            {item.title}
                            <button
                              type="button"
                              className="remove-action"
                              onClick={() => {
                                let tmp = tags;
                                let tmpIds = selectedTagIds;
                                let newTmp = tmp.filter((val) => {
                                  return val._id !== item._id;
                                });
                                let newTmpIds = tmpIds.filter((val) => {
                                  return val !== item._id;
                                });
                                setTags(newTmp);
                                setSelectedTagIds(newTmpIds);
                              }}
                            >
                              <i className="fa-light fa-xmark"></i>
                            </button>
                          </div>
                        ))}
                    </div>

                    <div
                      className={
                        "input-style mt-5" + (keywordTag ? " hasValue" : "")
                      }
                    >
                      <input
                        className="form-control"
                        value={keywordTag}
                        onChange={(e) => setKeywordTag(e.target.value)}
                      />

                      <div className="float-label">Add Tags</div>
                    </div>
                    {keywordTag && tagFilter && tagFilter?.length > 0 && (
                      <div className="suggestions">
                        <div className="suggestion-list">
                          {tagFilter.map((item, index) => (
                            <button
                              type="button"
                              className="suggestion-item"
                              key={index}
                              onClick={(e) => {
                                let a = {
                                  _id: item._id,
                                  title: item.title,
                                };
                                setTags([...selectedTags, a]);
                                setSelectedTagIds([
                                  ...selectedTagIds,
                                  item._id,
                                ]);
                              }}
                            >
                              {item.title}
                            </button>
                          ))}
                        </div>
                      </div>
                    )}
                  </div>
                  {/* social share */}
                  <div className="profile-content-section">
                    <h2 className="profile-content-title">Social Share</h2>

                    <div className="share-actions">
                      <button
                        type="button"
                        className="btn-copy"
                        onClick={copyLink}
                      >
                        <i className="fa-solid fa-copy"></i>
                      </button>
                      {isCopy && <p>Link Copied</p>}
                      <TwitterShareButton
                        title={postData[0]?.data?.text}
                        url={window.location.origin + `/post/${postId}`}
                      >
                        <TwitterIcon size={42} round={true} />
                      </TwitterShareButton>
                      <FacebookShareButton
                        title={postData[0]?.data?.text}
                        url={window.location.origin + `/post/${postId}`}
                      >
                        <FacebookIcon size={42} round={true} />
                      </FacebookShareButton>
                      <WhatsappShareButton
                        title={postData[0]?.data?.text}
                        url={window.location.origin + `/post/${postId}`}
                      >
                        <WhatsappIcon size={42} round={true} />
                      </WhatsappShareButton>{" "}
                    </div>
                  </div>
                </div>
              )}

              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-save"
                  data-dismiss="modal"
                  onClick={() => newPost()}
                >
                  <i className="fa-light fa-fw fa-arrow-up-from-bracket"></i>
                  Share
                </button>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
}

export default SharedModal;
