import LoaderFull from "../../../components/LoaderFull";
import CommunityCardItem from "../../Community/Components/CommunityCardItem";

const ProfileRequest = ({ communityList, loading, keyword }) => {
  //console.log(communityList, loading);
  // const dispatch = useDispatch();
  // useEffect(() => {
  //   dispatch(getCommunity({ status: 1, keyword: "" }));
  // }, [dispatch]);

  // useEffect(() => {
  //   dispatch(getCommunityRequest({ status: 1, keyword: "" }));
  // }, [keyword, dispatch]);
  return (
    <>
      <div className="connection">
        <div className="connection-list">
          {Array.isArray(communityList?.data) && communityList?.data?.map((item, index) => (
            <div className="connection-item" key={index}>
              <CommunityCardItem
                name={item.user_name}
                id={item._id}
                senderId={item.sender_user_id}
                image={item.user_image}
                designation={item.user_headline}
                connected={item.status}
                link={item.user_slug}
              />
            </div>
          ))}
        </div>
      </div>
      {/* <div className='connection-action'>
        <button type="button" className="btn-action">Load More</button>
      </div> */}
      <LoaderFull loaderActive={loading} />
    </>
  );
};

export default ProfileRequest;
