import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import {
  manageFavorite,
  manageLike,
  deleteLike,
  feedDelete,
  userPostDelete,
} from "../../../store/feed";
import { addNotification } from "../../../store/notification";
function FeedReaction({
  commentCount,
  reactionsCount,
  loveReactionCount,
  like,
  love,
  wishlist,
  postId,
  myfeed,
  sharePost,
  setLoading,
  setreValidate,
  others,
  postuserId,
  setIsEdit,
  setIsOpaque,
  eidtPostDetails,
  dontShow = true,
}) {
  const [totalReaction, setReactionCount] = useState(reactionsCount);
  const [totalLove, setLoveCount] = useState(loveReactionCount);
  const [isLike, setLike] = useState(like);
  const [isLove, setLove] = useState(love);
  const [fav, setFavorites] = useState(wishlist);
  const { profileDetails } = useSelector((state) => state.users);
  const checkPost = async (e) => {
    setFavorites(e.target.checked);
    await manageFavorite({ post_id: postId });
  };

  const clickLike = async (e) => {
    setLike(e.target.checked);
    if (e.target.checked === true) {
      setReactionCount(totalReaction + 1);
      await manageLike({
        referenceId: postId,
        referenceType: "post",
        reactionName: "like",
      });

      if (postuserId !== profileDetails?._id) {
        await addNotification({
          source_id: postId,
          receiver_id: postuserId,
          source_type: "react",
          content: "{{userName}} reacted on your post",
        });
      }

    } else {
      setReactionCount(totalReaction - 1);
      await deleteLike({
        referenceId: postId,
        referenceType: "post",
        reactionName: "like",
      });
    }
  };

  const clickLove = async (e) => {
    setLove(e.target.checked);
    if (e.target.checked === true) {
      setLoveCount(totalLove + 1);
      await manageLike({
        referenceId: postId,
        referenceType: "post",
        reactionName: "love",
      });
      if (postuserId !== profileDetails?._id) {
        await addNotification({
          source_id: postId,
          receiver_id: postuserId,
          source_type: "react",
          content: "{{userName}} reacted on your post",
        });
      }
    } else {
      setLoveCount(totalLove - 1);
      await deleteLike({
        referenceId: postId,
        referenceType: "post",
        reactionName: "love",
      });
    }
  };

  async function postDelete(id) {
    setLoading(true);
    await feedDelete({ postId: id });
    await userPostDelete({ postId: id });
    setreValidate(id);
  }

  const editPost = async (postId) => {
    setIsEdit(postId);
    setIsOpaque(postId);
  };

  useEffect(() => {
    setReactionCount(reactionsCount);
    setLoveCount(loveReactionCount);
  }, [reactionsCount, loveReactionCount]);

  return (
    <>
      <div className="feed-options">
        <div className="reaction">
          <div>{totalReaction}</div>
          <div className="option-like">
            <input
              type="checkbox"
              className="option-input"
              onChange={(e) => clickLike(e)}
              checked={isLike}
            />
            <div className="option-label ml-1"></div>
          </div>
        </div>
        <div className="reaction">
          <div>{totalLove}</div>
          <div className="option-heart">
            <input
              type="checkbox"
              className="option-input"
              onChange={(e) => clickLove(e)}
              checked={isLove}
            />
            <div className="option-label ml-1"></div>
          </div>
        </div>
        <div className="reaction">
          <button type="button" className="btn-option">
            <div>{commentCount}</div>
            <Link to={`/post/${postId}?isComment=true`}>
              <i className="fa-solid fa-comment ml-2"></i>
            </Link>
          </button>
        </div>
        {dontShow && !others && (
          <div className="reaction">
            <div className="option-bookmark ">
              <input
                type="checkbox"
                className="option-input"
                onChange={(e) => checkPost(e)}
                checked={fav}
              />
              <div className="option-label"></div>
            </div>
          </div>
        )}
        {dontShow && !others && !myfeed && (
          <div className="reaction">
            <button
              type="button"
              className="btn-option"
              data-toggle="modal"
              data-target="#shareModal"
              onClick={() => sharePost(postId)}
            >
              <i className="fa-light fa-fw fa-arrow-up-from-bracket"></i>
            </button>
          </div>
        )}

        {!others && myfeed && (
          <div className="reaction">
            <button
              type="button"
              className="btn-option"
              onClick={(e) => {
                editPost(postId);
                eidtPostDetails();
              }}
            >
              <i className="fa-light fa-fw fa-pencil"></i>
            </button>
          </div>
        )}

        {!others && myfeed && (
          <div className="reaction">
            <button
              type="button"
              className="btn-option"
              data-toggle="modal"
              data-target={"#postdeleteModal" + postId}
            >
              <i className="fa-light fa-fw fa-trash-can"></i>
            </button>
          </div>
        )}
      </div>
      <div
        className="modal event-delete-modal fade"
        data-backdrop="static"
        id={"postdeleteModal" + postId}
        tabIndex="-1"
        aria-labelledby="deleteModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered modal-sm">
          <div className="modal-content">
            <div className="modal-body">
              <div className="delete-content">
                <div className="icon">
                  <i className="fa-solid fa-triangle-exclamation"></i>
                </div>
                <div className="text">
                  <div className="main-title">Delete Post?</div>

                  <div className="des">
                    Are you sure you want to delete the post?
                  </div>
                </div>
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-close"
                data-dismiss="modal"
                onClick={() => postDelete(postId)}
              >
                Confirm
              </button>

              <button
                type="button"
                className="btn btn-confirm"
                data-dismiss="modal"
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default FeedReaction;
