import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { addStep } from '../../../store/user';
import { getSubject,addUserTags } from '../../../store/user';

const ProfileFormAbout = ({formView, setFormView}) => {
  const [about, setAbout] = useState('');
  const [tags, setTags] = useState([]);
  const [selectedTags, setSelectedTags] = useState([]);
  const [count, setCount] = useState('');
  const dispatch  = useDispatch();
  const { subjects } = useSelector(state => state.users);
  const switchPanel = async() => {
    setFormView({ ...formView, fifth: { active: false, completed: true }, sixth: { active: true, completed: false }});
   let dt = { ...formView, fifth: { active: false, completed: true }, sixth: { active: true, completed: false }}
   await addStep({step:dt,is_complete:true});
   
   try {
      const dta = await addUserTags({subject:selectedTags,title : about});
      // console.log(dta);
   } catch (error) {
    
   }
  }

  const handleKeyDown = (e) => {
    e.target.style.height = `40px`;
    e.target.style.height = `${e.target.scrollHeight}px`;
  }

  const handleWordCount = (e) => {
    const charCount = e.target.value.length;
    setCount(charCount);
  }

  useEffect(() => {
   setTags(subjects);
  }, [subjects]);


  useEffect(() => {
    dispatch(getSubject({}))
   }, []);


  const selectTag = (e) =>{
    let tagArray = [...tags];
   
    if(e.target.checked === true){
      let found  = tagArray.find((tag) => {return tag._id === e.target.value});
      setSelectedTags([...selectedTags,found]);
    }else{
      let remove  = selectedTags.filter((tag) => {return tag._id !== e.target.value});
      setSelectedTags(remove);
    }
    
   
  }
 
 // console.log(tagArray);
  return (
    <>
      <div className='profile-forms'>
        <div className='container'>
          <div className='row'>
            <div className='col-lg-4'>
              <div className='profile-forms-content'>
                <h1 className='profile-forms-title'>This is your time to shine.</h1>
              </div>
            </div> 
            <div className='col-lg-6 offset-lg-2'>
              <form className={"form-input-content " + ((about && tags.length) ? 'active' : '')}>
                <div className="form-group">
                  <div className={"input-style input-style-textarea " + (about ? 'hasValue' : '')}>
                    <textarea className="form-control" name='about' autoComplete='off' value={about} onChange={(e) => {setAbout(e.target.value); handleKeyDown(e); handleWordCount(e)}} onPaste={handleKeyDown} maxLength="300"></textarea>
                    <div className='input-count'>{count} / 300</div>
                    <div className="float-label">Tell us a little about yourself</div>
                  </div>
                </div>
                <div className='form-group'>
                  <div className='cloudtags'>
                    <div className='title'>Select subjects that interest you</div>
                    <div className='cloudtag-list'>
                      {subjects.length > 0 && subjects.map((item,i)=>{
                        return (<>  
                        <div className='tag' key={i}>
                        <input type='checkbox' className='tag-check' name='cloud-tags' value={item._id} onClick={(e) => selectTag(e)}/>
                        <div className='tag-label'>{item.name}</div>
                      </div></>);
                      })}
                     </div>
                  </div>
                </div>
                <div className="form-group form-action"> 
                  <button type="button" className="btn-action btn-activeable" onClick={() => switchPanel()}>Finish</button>
                  <Link to="/" className="btn-action alt">Skip for now</Link>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ProfileFormAbout;