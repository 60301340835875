const CommunityFilter = ({ setFilter, notification }) => {
  const filterRadio = (e) => {
    setFilter(e.target.value);
  };
  return (
    <>
      <div className="community-filter-section">
        <div className="filter">
          <input
            type="radio"
            name="filter"
            value="discover"
            className="filter-radio"
            defaultChecked
            onClick={(e) => filterRadio(e)}
          />
          <div className="filter-label">Discover</div>
        </div>
        <div className="filter">
          <input
            type="radio"
            name="filter"
            value="requests"
            className="filter-radio"
            onClick={(e) => filterRadio(e)}
          />
          <div className="filter-label">
            Request
            {notification ? (
              <span
                style={{
                  height: "7px",
                  width: "7px",
                  backgroundColor: "#ce2c2c",
                  borderRadius: "50%",
                  marginLeft: "7px",
                  marginBottom: "10px",
                }}
              ></span>
            ) : null}
          </div>
        </div>
        <div className="filter">
          <input
            type="radio"
            name="filter"
            value="connections"
            className="filter-radio"
            onClick={(e) => filterRadio(e)}
          />
          <div className="filter-label">My Connections</div>
        </div>
        <div className="filter">
          <input
            type="radio"
            name="filter"
            value="inbox"
            className="filter-radio"
            onClick={(e) => filterRadio(e)}
          />
          <div className="filter-label">Inbox</div>
        </div>
      </div>
    </>
  );
};

export default CommunityFilter;
