import SecPodcastPlay from './Components/SecPodcastPlay';
import './Css/PodcastDetails.css';

export default function PodcastDetails() {
  return (
    <>
      <SecPodcastPlay />
    </>
  );
}
