const OthersProfileFilter = ({ setFilter, fname }) => {
  const filterRadio = (e) => {
    setFilter(e.target.value);
  };

  return (
    <>
      <div className="others-profile-filter">
        
      <div className="filter">
          <input
            type="radio"
            name="filter"
            value="posts"
            className="filter-radio"
            defaultChecked
            onClick={(e) => filterRadio(e)}
          />
          <div className="filter-label">
            {fname ? `${fname}'s posts` : ""}
          </div>
        </div>

        <div className="filter">
          <input
            type="radio"
            name="filter"
            value="about"
            className="filter-radio"
            onClick={(e) => filterRadio(e)}
          />
          <div className="filter-label">About</div>
        </div>
        
        <div className="filter">
          <input
            type="radio"
            name="filter"
            value="connections"
            className="filter-radio"
            onClick={(e) => filterRadio(e)}
          />
          <div className="filter-label">Connections</div>
        </div>
      </div>
    </>
  );
};

export default OthersProfileFilter;
