import { useEffect, useState } from "react";
import SecPodcastListItem from "./SecPodcastListItem";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import { fetchTracks } from "../../../store/track";

const SecPodcastList = ({ item }) => {
  const [trackList, setTrackList] = useState([]);
  useEffect(() => {
    const loadtracks = async () => {
      try {
        setTrackList([]);
        if (item?.playlist_id !== undefined) {
          const list = await fetchTracks({ playlist_id: item?.playlist_id });
          const filter = list.filter((item) => item.track !== null);
          setTrackList(filter);
        }
      } catch (error) {}
    };
    loadtracks();
  }, []);

  return (
    <>
      <div className="podcast-layout">
        <div className="container">
          <div className="podcast-layout-header">
            <h2 className="podcast-layout-title">{item.name}</h2>
            <a
              className="btn-viewAll"
              href={`https://open.spotify.com/playlist/${item?.playlist_id}`}
              target="_blank"
            >
              View on Spotify
            </a>
          </div>
        </div>
        <div className="podcast-layout-list">
          <Swiper
            spaceBetween={0}
            slidesPerView={1.5}
            breakpoints={{
              480: {
                slidesPerView: 1.5,
                spaceBetween: 0,
              },
              768: {
                slidesPerView: 2.5,
                spaceBetween: 10,
              },
              1024: {
                slidesPerView: 3,
                spaceBetween: 10,
              },
              1200: {
                slidesPerView: 5,
                spaceBetween: 10,
              },
              1600: {
                slidesPerView: 6,
                spaceBetween: 10,
              },
            }}
          >
            {trackList.length > 0 &&
              trackList.map((ele, i) => {
                return (
                  <SwiperSlide key={i}>
                    <SecPodcastListItem item={ele} />
                  </SwiperSlide>
                );
              })}
          </Swiper>
        </div>
      </div>
    </>
  );
};

export default SecPodcastList;
