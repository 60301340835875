import { Route, Routes, BrowserRouter } from "react-router-dom";
import ScrollToTop from "./ScrollToTop";
import DefaultLayout from "./layouts/DefaultLayout";
import SearchLayout from "./layouts/SearchLayout";
import CtaLessLayout from "./layouts/CtaLessLayout";
import Home from "./pages/Home/Home";
import Video from "./pages/Video/Video";
import Community from "./pages/Community/Community";
import VideoDetails from "./pages/Video/VideoDetails";
import Shows from "./pages/Video/Shows";
import Podcast from "./pages/Podcast/Podcast";
import PodcastDetails from "./pages/Podcast/PodcastDetails";
import Stories from "./pages/Story/Stories";
import StoriesDetails from "./pages/Story/StoriesDetails";
import Search from "./pages/Search/Search";
import SearchResult from "./pages/Search/SearchResult";
import Membership from "./pages/Membership/Membership";
import ProfileLanding from "./pages/ProfileCompletion/ProfileLanding";
import Profile from "./pages/Profile/Profile";
import Feed from "./pages/Feed/Feed";
import BackCtaLessLayout from "./layouts/BackCtaLessLayout";
import FreeAuthRoute from "./auth/FreeAuthRoute";
import { PublicAuthRoute } from "./auth/PublicAuthRoute";
import { ProtectedRoute } from "./auth/ProtectedRoute";
import OthersProfile from "./pages/OthersProfile/OthersProfile";
import Schedule from "./pages/Schedule/Schedule";
import Privacy from "./pages/Cms/Privacy";
import Terms from "./pages/Cms/Terms";
import Help from "./pages/Cms/Help";
import Cookies from "./pages/Cms/Cookies";
import ProfileEventDetails from "./pages/Profile/Components/ProfileEventDetails";
import GlobalEventDetails from "./pages/Profile/Components/GlobalEventDetails";
import FaqDetails from "./pages/Cms/FaqDetails";
import SupportContact from "./pages/Cms/SupportContact";
import Events from "./pages/Events/Events";
import ShareLinks from "./pages/ShareLinks/ShareLinks";
import BlankLayout from "./layouts/BlankLayout";
import CreateArticle from "./pages/Article/CreateArticle";
import EventCreate from "./pages/Events/EventCreate";
import VerifyRoute from "./auth/VerifyRoute";
import NotFound from "./components/NotFound";
import EditArticle from "./pages/Article/EditArticle";

const CreateRoutes = () => {
  return (
    <BrowserRouter>
      <ScrollToTop>
        <Routes>
          <Route path="/" element={<DefaultLayout />}>
            <Route
              path="/"
              exact
              element={
                <PublicAuthRoute>
                  <Home />
                </PublicAuthRoute>
              }
            />
          </Route>
          <Route path="/video" element={<DefaultLayout />}>
            <Route
              path="/video"
              exact
              element={
                <PublicAuthRoute>
                  <Video title={"CEO | Videos"} />
                </PublicAuthRoute>
              }
            />
          </Route>
          <Route path="/video/:alias" element={<DefaultLayout />}>
            <Route path="/video/:alias" exact element={<VideoDetails />} />
          </Route>

          <Route path="/playlist/:id" element={<DefaultLayout />}>
            <Route path="/playlist/:id" exact element={<Shows />} />
          </Route>
          <Route path="/posts" element={<DefaultLayout />}>
            <Route path="/posts" exact element={<Profile />} />
          </Route>

          <Route path="/post/:id" element={<DefaultLayout />}>
            <Route path="/post/:id" exact element={<Profile />} />
          </Route>

          <Route path="/connections" element={<DefaultLayout />}>
            <Route path="/connections" exact element={<Profile />} />
          </Route>

          <Route path="/messages" element={<DefaultLayout />}>
            <Route path="/messages" exact element={<Profile />} />
          </Route>
          <Route path="/message/:id" element={<DefaultLayout />}>
            <Route path="/message/:id" exact element={<Profile />} />
          </Route>

          <Route path="/about" element={<CtaLessLayout />}>
            <Route path="/about" exact element={<Profile />} />
          </Route>
          <Route path="/drafts" element={<CtaLessLayout />}>
            <Route path="/drafts" exact element={<Profile />} />
          </Route>
          <Route path="/saved-content" element={<CtaLessLayout />}>
            <Route path="/saved-content" exact element={<Profile />} />
          </Route>
          <Route path="/my-feed" element={<CtaLessLayout />}>
            <Route path="/my-feed" exact element={<Feed />} />
          </Route>
          <Route path="/global-feed" element={<CtaLessLayout />}>
            <Route path="/global-feed" exact element={<Feed />} />
          </Route>

          <Route path="/podcast" element={<DefaultLayout />}>
            <Route
              path="/podcast"
              exact
              element={
                <PublicAuthRoute>
                  <Podcast />
                </PublicAuthRoute>
              }
            />
          </Route>
          <Route path="/podcast/:track_id" element={<DefaultLayout />}>
            <Route
              path="/podcast/:track_id"
              exact
              element={<PodcastDetails />}
            />
          </Route>
          <Route path="/stories" element={<DefaultLayout />}>
            <Route
              path="/stories"
              exact
              element={
                <PublicAuthRoute>
                  <Stories />
                </PublicAuthRoute>
              }
            />
          </Route>
          <Route
            path="/community"
            element={
              <ProtectedRoute>
                <CtaLessLayout />
              </ProtectedRoute>
            }
          >
            <Route
              path="/community"
              exact
              element={
                <ProtectedRoute>
                  <Community />
                </ProtectedRoute>
              }
            />
          </Route>
          <Route path="/story/:id" element={<DefaultLayout />}>
            <Route path="/story/:id" exact element={<StoriesDetails />} />
          </Route>
          <Route path="/search" element={<SearchLayout />}>
            <Route path="/search" exact element={<Search />} />
          </Route>
          <Route path="/search-result" element={<SearchLayout />}>
            <Route path="/search-result" exact element={<SearchResult />} />
          </Route>

          <Route
            path="/membership"
            element={
              <FreeAuthRoute>
                <CtaLessLayout />
              </FreeAuthRoute>
            }
          >
            <Route
              path="/membership"
              exact
              element={
                <FreeAuthRoute>
                  <Membership />
                </FreeAuthRoute>
              }
            />
          </Route>
          <Route path="/complete-profile/:token" element={<CtaLessLayout />}>
            <Route
              path="/complete-profile/:token"
              exact
              element={
                <VerifyRoute>
                  <ProfileLanding />
                </VerifyRoute>
              }
            />
          </Route>
          <Route
            path="/profile"
            element={
              <ProtectedRoute>
                <CtaLessLayout />
              </ProtectedRoute>
            }
          >
            <Route
              path="/profile"
              exact
              element={
                <ProtectedRoute>
                  <Profile />
                </ProtectedRoute>
              }
            />
          </Route>
          <Route
            path="/feed"
            element={
              <ProtectedRoute>
                <CtaLessLayout />
              </ProtectedRoute>
            }
          >
            <Route
              path="/feed"
              exact
              element={
                <ProtectedRoute>
                  <Feed />
                </ProtectedRoute>
              }
            />
          </Route>

          <Route path="/profile/:usrSlug" element={<BackCtaLessLayout />}>
            <Route path="/profile/:usrSlug" exact element={<OthersProfile />} />
          </Route>
          <Route path="/schedule" element={<CtaLessLayout />}>
            <Route
              path="/schedule"
              exact
              element={
                <PublicAuthRoute>
                  <Schedule />
                </PublicAuthRoute>
              }
            />
          </Route>

          <Route path="/terms-of-use" element={<CtaLessLayout />}>
            <Route path="/terms-of-use" exact element={<Terms />} />
          </Route>
          <Route path="/privacy-policy" element={<CtaLessLayout />}>
            <Route path="/privacy-policy" exact element={<Privacy />} />
          </Route>
          <Route path="/cookies" element={<CtaLessLayout />}>
            <Route path="/cookies" exact element={<Cookies />} />
          </Route>
          <Route path="/help" element={<CtaLessLayout />}>
            <Route path="/help" exact element={<Help />} />
          </Route>
          <Route path="/profile-event-details" element={<CtaLessLayout />}>
            <Route
              path="/profile-event-details/:slug/:id"
              exact
              element={<ProfileEventDetails />}
            />
          </Route>
          <Route path="/event-details" element={<CtaLessLayout />}>
            <Route
              path="/event-details/:slug/:id"
              exact
              element={<GlobalEventDetails />}
            />
          </Route>
          <Route path="/faq-details" element={<CtaLessLayout />}>
            <Route path="/faq-details/:id" exact element={<FaqDetails />} />
          </Route>
          <Route path="/support-contact" element={<CtaLessLayout />}>
            <Route path="/support-contact" exact element={<SupportContact />} />
          </Route>
          <Route path="/events" element={<CtaLessLayout />}>
            <Route path="/events" exact element={<Profile />} />
          </Route>

          <Route path="/share-links" element={<BlankLayout />}>
            <Route path="/share-links" exact element={<ShareLinks />} />
          </Route>
          <Route path="/create-event" element={<BlankLayout />}>
            <Route path="/create-event" exact element={<EventCreate />} />
          </Route>
          <Route path="/edit-event" element={<BlankLayout />}>
            <Route path="/edit-event/:slug/:id" exact element={<Events />} />
          </Route>
          <Route path="/create-article" element={<BlankLayout />}>
            <Route path="/create-article" exact element={<CreateArticle />} />
          </Route>
          <Route path="/edit-article/:id" element={<BlankLayout />}>
            <Route path="/edit-article/:id" exact element={<EditArticle />} />
          </Route>

          <Route path="/not-found" element={<DefaultLayout />}>
            <Route path="/not-found" exact element={<NotFound />} />
          </Route>
        </Routes>
      </ScrollToTop>
    </BrowserRouter>
  );
};

export default CreateRoutes;
