import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { commentDelete } from "../../../store/feed";
import { manageLike, deleteLike } from "../../../store/feed";
import { addNotification } from "../../../store/notification";

function CommentAction({
  id,
  org_id,
  replyComment,
  parentuserId,
  reactionsCount,
  loveReactionsCount,
  liked,
  loved,
  userId,
  postuserId,
  setIsLike,
  setIsLove,
  postId,
}) {
  const [isLike, setLike] = useState(liked);
  const [isLove, setLove] = useState(loved);
  const [countReaction, setReactionCount] = useState(reactionsCount);
  const [countLoveReaction, setLoveReactionCount] =
    useState(loveReactionsCount);
  const { profileDetails } = useSelector((state) => state.users);
  const deleteComment = async () => {
    await commentDelete({ commentId: org_id, isDeleted: true });
  };

  const clickLike = async (org_id, liked) => {
    if (liked === true) {
      setReactionCount(countReaction - 1 < 0 ? 0 : countReaction - 1);
      setLike(false);
      const del = await deleteLike({
        referenceId: org_id,
        referenceType: "comment",
        reactionName: "like",
      });

      setIsLike(del.removedId);
    } else {
      setReactionCount(countReaction + 1);
      setLike(true);
      const lk = await manageLike({
        referenceId: org_id,
        referenceType: "comment",
        reactionName: "like",
      }).then(async () => {
        if (parentuserId !== profileDetails?._id) {
          await addNotification({
            source_id: postId,
            receiver_id: parentuserId,
            source_type: "react",
            content: "{{userName}} reacted on your comment",
          });
        }
      });

      setIsLike(lk.addedId);
    }
  };

  const clickLove = async (org_id, loved) => {
    if (loved === true) {
      setLoveReactionCount(
        countLoveReaction - 1 < 0 ? 0 : countLoveReaction - 1
      );
      setLove(false);
      const del = await deleteLike({
        referenceId: org_id,
        referenceType: "comment",
        reactionName: "love",
      });

      setIsLove(del.removedId);
    } else {
      setLoveReactionCount(countLoveReaction + 1);
      setLove(true);
      const lk = await manageLike({
        referenceId: org_id,
        referenceType: "comment",
        reactionName: "love",
      });

      setIsLove(lk.addedId);
    }
  };

  useEffect(() => {
    setReactionCount(reactionsCount);
    setLoveReactionCount(loveReactionsCount);
  }, [reactionsCount, loveReactionsCount]);

  return (
    <>
      <div className="comment-action">
        <button
          type="button"
          className="comment-action-btn"
          onClick={() => replyComment(id, parentuserId)}
        >
          Reply
        </button>

        <button
          type="button"
          className={liked ? "comment-action-btn liked" : "comment-action-btn"}
          onClick={() => clickLike(org_id, isLike)}
        >
          <span>{countReaction}</span> {isLike ? "Liked" : "Like"}
        </button>
        <button
          type="button"
          className={liked ? "comment-action-btn liked" : "comment-action-btn"}
          onClick={() => clickLove(org_id, isLove)}
        >
          <span>{countLoveReaction}</span> {isLove ? "Loved" : "Love"}
        </button>
        {postuserId !== profileDetails._id && userId === profileDetails._id && (
          <button
            type="button"
            className="comment-action-btn"
            onClick={() => deleteComment()}
          >
            Delete
          </button>
        )}
      </div>
    </>
  );
}

export default CommentAction;
