import { useEffect, useState } from "react";
import SecStoriesSlider from "./SecStoriesSlider";
import { Link } from "react-router-dom";
import { fetchBlogs } from "../../../store/blog";
import { useDispatch, useSelector } from "react-redux";

const SecStoriesGrid = ({ dateRange, featured }) => {
  const dispatch = useDispatch();
  const { bloglist } = useSelector((state) => state.blogs);
  const [limit] = useState(4);
  const { settingData } = useSelector((state) => state.settings);

  useEffect(() => {
    if (settingData?.ghost_api) {
      if (featured === true) {
        dispatch(
          fetchBlogs({
            limit: limit,
            featured: true,
          })
        );
      } else {
        dispatch(
          fetchBlogs({
            limit: limit,
            featured: false,
          })
        );
      }
    }
  }, [dispatch, featured]);

  return (
    <>
      <div className="container px-0">
        <div className="stories-grid">
          {bloglist.length > 0 &&
            bloglist.map((item, index) => {
              function createMarkup() {
                return { __html: item.title };
              }
              return index === 0 ? (
                <div className="stories-layout stories-layout-1" key={index}>
                  <div className="container">
                    <Link
                      to={"/story/" + item.id}
                      className="stories-layout-inner"
                    >
                      <div className="stories-image">
                        <img src={item.feature_image} alt="" />
                      </div>
                      <div className="stories-content">
                        <h2
                          className="stories-title"
                          dangerouslySetInnerHTML={createMarkup()}
                        ></h2>
                      </div>
                    </Link>
                  </div>
                </div>
              ) : index === 1 ? (
                <div className="stories-layout stories-layout-2" key={index}>
                  <div className="container">
                    <Link
                      className="stories-layout-inner"
                      to={"/story/" + item.id}
                    >
                      <div className="stories-content">
                        <div className="stories-image">
                          <img src={item.feature_image} alt="" />
                        </div>
                        <h2
                          className="stories-title"
                          dangerouslySetInnerHTML={createMarkup()}
                        ></h2>
                      </div>
                    </Link>
                  </div>
                </div>
              ) : index === 2 ? (
                <div className="stories-layout stories-layout-3" key={index}>
                  <div className="container">
                    <Link
                      className="stories-layout-inner"
                      to={"/story/" + item.id}
                    >
                      <div className="stories-content">
                        <div className="stories-image">
                          <img src={item.feature_image} alt="" />
                        </div>
                        <h2
                          className="stories-title"
                          dangerouslySetInnerHTML={createMarkup()}
                        ></h2>
                      </div>
                    </Link>
                  </div>
                </div>
              ) : index === 3 ? (
                <div className="stories-layout stories-layout-4" key={index}>
                  <Link
                    className="stories-layout-inner"
                    to={"/story/" + item.id}
                  >
                    <div className="stories-image">
                      <img src={item.feature_image} alt="" />
                    </div>
                    <div className="stories-content">
                      <div className="container">
                        <h2
                          className="stories-title"
                          dangerouslySetInnerHTML={createMarkup()}
                        ></h2>
                      </div>
                    </div>
                  </Link>
                </div>
              ) : (
                ""
              );
            })}
        </div>
      </div>
      <SecStoriesSlider />
    </>
  );
};

export default SecStoriesGrid;
