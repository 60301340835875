import moment from "moment";
import { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { eventDetails, statusChangeEvent } from "../../../store/event";
import axios from "axios";
import BASE_URL from "../../../config/host";
import API_URL from "../../../config/apiHost";
import LoaderFull from "../../../components/LoaderFull";
import { toast } from "react-toastify";
const ProfileEventDetails = () => {
  const { id, slug } = useParams();
  const [loading, setLoading] = useState(false);
  const [Interested, setIntersted] = useState([]);
  const [eventData, setEventData] = useState(null);
  const [count, setCount] = useState();
  const [eventId, setEventId] = useState("");
  const navigate = useNavigate();
  useEffect(() => {
    setLoading(true);
    const eventData1 = eventDetails({ event_id: id });
    eventData1
      .then((res) => {
        setEventData(res);
        setEventId(res?._id);
        setLoading(false);
      })
      .catch((err) => {
        // console.log(err);
      });
  }, []);

  // console.log(eventId)
  // const { statusChangeEvent } = useSelector((state) => state.events);

  const statusChange = async () => {
    const data = await statusChangeEvent({ id: eventId, status: 3 });
    // console.log(data)
    if (data.status === 200) {
      toast.success(data.message);
      navigate("/events");
    } else {
      toast.error(data.message);
    }
  };

  const [toggleEdit, setToggleEdit] = useState(false);
  const togglePanel = (event) => {
    setToggleEdit(true);
  };

  useEffect(() => {
    setLoading(true);
    const interest = async () => {
      const result = await axios.post(
        BASE_URL + "event/interested-event-users",
        {
          event_id: eventData?._id,
        }
      );
      setIntersted(result.data.data);
      setCount(result.data.count);
      setLoading(false);
    };
    interest();
  }, [eventData?._id]);

  console.log(eventData);

  return (
    <>
      <section className="profile-event-content-section">
        <div className="container">
          <Link className="btn-back mb-5" to="/profile">
            <i className="fa-light fa-fw fa-chevron-left"></i>
          </Link>
          <div className="row justify-content-center">
            <div className="col-md-8">
              <div className="details-content">
                <div className="img-section">
                  <img src={eventData ? eventData.image : ""} alt="" />
                </div>
                <div className="event-edit-section">
                  <div className="event-title">
                    {eventData ? eventData.title : ""}
                  </div>
                  <div className="event-author">
                    By {eventData ? eventData.user_name : "🤷‍♂️"}
                  </div>
                </div>
                <div className="event-action">
                  <Link
                    className="link-action"
                    to={"/edit-event/" + slug + "/" + id}
                  >
                    {" "}
                    <i className="fa-solid fa-pen mr-3"></i> Edit event
                  </Link>
                  <button
                    type="button"
                    className="link-action alt"
                    data-toggle="modal"
                    data-target="#deleteModal"
                  >
                    {" "}
                    <i className="fa-light fa-xmark mr-3"></i> Cancel event
                  </button>
                </div>
                <div className="details-event">
                  <div className="title">Details</div>
                  <div className="event-arrangement">
                    <div className="event-timings">
                      <div className="data">
                        {eventData
                          ? moment(new Date(eventData.start_date)).format(
                              "ddd DD, MMM, YYYY"
                            )
                          : ""}
                      </div>
                      {eventData?.end_time !== "" ? (
                        <div className="data">
                          {moment(eventData?.start_time, "HH:mm:ss").format(
                            "hh:mm A"
                          )}{" "}
                          -{" "}
                          {moment(eventData?.end_time, "HH:mm:ss").format(
                            "hh:mm A"
                          )}
                        </div>
                      ) : (
                        <div className="data">
                          {moment(eventData?.start_time, "HH:mm:ss").format(
                            "hh:mm A"
                          )}
                        </div>
                      )}
                    </div>
                    {eventData?.online_event_link !== "" ? (
                      <div className="event-meetings">
                        <a
                          href={eventData?.online_event_link}
                          type="button"
                          className="btn-join"
                        >
                          Join the meeting
                        </a>
                      </div>
                    ) : (
                      <div className="event-meetings">
                        <button
                          type="button"
                          className="btn-join"
                          disable={true}
                        >
                          Join the meeting
                        </button>
                      </div>
                    )}

                    {eventData?.location ? (
                      <div className="event-meetings">
                        <div className="data">
                          Location - {eventData?.location}
                        </div>
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                  {Interested.length !== 0 ? (
                    <div className="event-people">
                      <div className="people">
                        {Interested &&
                          Interested.length > 0 &&
                          Interested.map((item) => {
                            return (
                              <>
                                <div className="img-wrap">
                                  <img
                                    src={
                                      API_URL +
                                      "uploads/profile-images/100/" +
                                      item?.user_id +
                                      ".png"
                                    }
                                    alt=""
                                  />
                                </div>
                              </>
                            );
                          })}
                      </div>
                      {count && (
                        <div className="total">{count} people interested</div>
                      )}
                    </div>
                  ) : (
                    ""
                  )}
                </div>

                {eventData?.description && (
                  <div className="details-description">
                    <div className="title">About the event</div>
                    <div className="descriptions">
                      {eventData ? eventData.description : ""}
                    </div>
                  </div>
                )}

                {eventData?.eventTags.length !== 0 ? (
                  <div className="tags-section">
                    <div className="title">Tags</div>
                    <div className="themetags">
                      <div className="themetag-list">
                        {eventData &&
                          eventData?.eventTags.map((item, index) => (
                            <div className="tag" key={index}>
                              <input
                                type="checkbox"
                                className="tag-check"
                                name="theme-tags"
                                value={item._id}
                              />
                              <div className="tag-label">{item.title}</div>
                            </div>
                          ))}
                      </div>
                    </div>
                  </div>
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>
        </div>
      </section>

      <div
        className="modal event-delete-modal fade"
        data-backdrop="static"
        id="deleteModal"
        tabindex="-1"
        aria-labelledby="deleteModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered modal-sm">
          <div className="modal-content">
            <div className="modal-body">
              <div className="delete-content">
                <div className="icon">
                  <i className="fa-solid fa-triangle-exclamation"></i>
                </div>
                <div className="text">
                  <div className="main-title">Cancel event?</div>
                  <div className="des">
                    This action cannot be undone.Guests will be notified.
                  </div>
                </div>
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-close"
                data-dismiss="modal"
              >
                Close
              </button>
              <button
                type="button"
                className="btn btn-confirm"
                data-dismiss="modal"
                onClick={() => {
                  statusChange(eventId);
                }}
              >
                Confirm
              </button>
            </div>
          </div>
        </div>
      </div>
      <LoaderFull loaderActive={loading} />
    </>
  );
};

export default ProfileEventDetails;
