import { Link } from "react-router-dom";

const CallToAction = () => {
  let token = localStorage.getItem("accesstoken");
  return (
    <>
      {!token && (
        <section className="sec-cta">
          <div className="cta-image">
            <img src="assets/img/brand.svg" alt="" />
          </div>
          <div className="container">
            <div className="cta-content">
              <div className="cta-title">
                Become a member.
                <br />
                Change your life.
              </div>
              <div className="cta-actions">
                <Link className="btn-action" to="/membership">
                  Become a member
                </Link>
              </div>
            </div>
          </div>
        </section>
      )}
    </>
  );
};

export default CallToAction;
