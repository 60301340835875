import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import SecStoriesDetailsBanner from "./Components/SecStoriesDetailsBanner";
import SecStoriesDetailsContent from "./Components/SecStoriesDetailsContent";
import SecStoriesSlider from "./Components/SecStoriesSlider";
import "./Css/StoriesDetails.css";
import { useDispatch, useSelector } from "react-redux";
import { blogDetails } from "../../store/blog";

const StoriesDetails = () => {
  const [isListen, setIsListen] = useState(false);
  const { blogdetails, loading } = useSelector((state) => state.blogs);
  const { settingData } = useSelector((state) => state.settings);

  const { id } = useParams();
  const dispatch = useDispatch();
  useEffect(() => {
    if (settingData && settingData?.ghost_api !== undefined) {
      dispatch(
        blogDetails({
          id: id,
          key: settingData?.ghost_api,
          ghost_url: settingData?.ghost_url,
        })
      );
    }
  }, [id, dispatch, settingData]);

  return (
    <>
      <SecStoriesDetailsBanner
        isListen={isListen}
        setIsListen={setIsListen}
        blogdetails={blogdetails}
        loading={loading}
      />
      <SecStoriesDetailsContent blogdetails={blogdetails} />
      <SecStoriesSlider />
    </>
  );
};

export default StoriesDetails;
