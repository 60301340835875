import BASE_URL from "../../config/host";
import axios from "axios";
import { createSlice } from "@reduxjs/toolkit";
import { apiCallBegan } from "../api";

export const fetchPages = async (body) => {
  const res = await axios.post(BASE_URL + "page/details", body);
  return res.data.data;
};

export const fetchFeatures = async (body) => {
  const res = await axios.post(BASE_URL + "feature/list", body);
  return res.data.data;
};

export const fetchMembers = async (body) => {
  const res = await axios.post(BASE_URL + "become-member/list", body);
  return res.data.data;
};
export const globalSearch = async (body) => {
  const res = await axios.post(BASE_URL + "global-search", body);
  return res.data.data;
};

export const pageSlice = createSlice({
  name: "pages",
  loading: false,
  initialState: {
    homeContentdata: [],
  },
  reducers: {
    Request: (state) => {
      state.loading = true;
    },
    Failed: (state) => {
      state.loading = false;
      state.homeContentdata = "";
    },
    Received: (state, action) => {
      state.loading = false;
      state.homeContentdata = action.payload.data ? action.payload.data : {};
    },
  },
});

export const { Received, Request, Failed } = pageSlice.actions;

export const fetchHomeContent = (body) =>
  apiCallBegan({
    url: "page/home-content",
    method: "post",
    data: body,
    baseUrl: BASE_URL,
    onSuccess: Received.type,
    onStart: Request.type,
    onError: Failed.type,
  });
