import moment from "moment";
function UserInfo({ commentuser, date, tick }) {
  const day = moment(date).format("D");
  const today = moment().format("D");

  return (
    <>
      <div className="user-info">
        <div className="username user-name">
          {commentuser.displayName}
          {""}
          {tick ? (
            <div className="user-tick">
              <i className="fa-regular fa-fw fa-check"></i>
            </div>
          ) : (
            ""
          )}
        </div>

        <div className="comment-time user-time">
          {today === day
            ? moment(date).fromNow()
            : moment(date).format("Do MMMM YYYY, h:mm A")}
        </div>
      </div>
    </>
  );
}

export default UserInfo;
