import { Link } from "react-router-dom";
import { secondsTomins } from "../../../helpers/getSlug";

const SecPodcastListItem = ({ item }) => {
  return (
    <>
      <Link to={"/podcast/" + item?.track?.id} className="podcast-layout-item">
        <div className="podcast-layout-inner">
          <div className="podcast-layout-thumbnail">
            <img src={item?.track?.album?.images[0].url} alt="" />
          </div>
          <div className="podcast-layout-content">
            <h4 className="podcast-title">{item.track?.name}</h4>
            <div className="podcast-text">
              {secondsTomins(item.track?.duration_ms)}
            </div>
          </div>
        </div>
      </Link>
    </>
  );
};

export default SecPodcastListItem;
