import LoaderFull from "../../../components/LoaderFull";
import CommunityCardItem from "../../Community/Components/CommunityCardItem";

const ProfileOwnConnections = ({
  communityList,
  loading1,
  keyword,
  setKeyword,
}) => {
  //console.log(communityList);
  // const dispatch = useDispatch();
  // useEffect(() => {
  //   dispatch(getCommunity({ status: 1, keyword: "" }));
  // }, [dispatch]);

  // useEffect(() => {
  //   dispatch(getCommunityRequest({ status: 2, keyword: keyword }));
  // }, [keyword, dispatch]);
  return (
    <>
      <div className="connection mt-5">
        <div className="connection-search ">
          <div className={"input-style hasValue"}>
            <input
              className="form-control"
              value={keyword}
              onChange={(e) => setKeyword(e.target.value)}
            />
            <div className="float-label">Search</div>
          </div>
          <div className="icon">
            <i className="fa-light fa-magnifying-glass"></i>
          </div>
        </div>
        <div className="connection-list">
          {Array.isArray(communityList.data) &&
            communityList.data.map((item, index) => (
              <div className="connection-item" key={index}>
                <CommunityCardItem
                  id={item._id}
                  name={item.user_name}
                  channelId={item.channel_id}
                  image={item.user_image}
                  designation={item.user_headline}
                  connected={item.status}
                  link={item.user_slug}
                />
              </div>
            ))}
          {communityList.data && communityList.data === [] && <div>No results found</div>}
        </div>
      </div>

      {/* <div className='connection-action'>
        <button type="button" className="btn-action">Load More</button>
      </div> */}
      <LoaderFull loaderActive={loading1} />
    </>
  );
};

export default ProfileOwnConnections;
