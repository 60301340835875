import { useState, useEffect } from "react";
import SecShowsListItem from "./SecShowsListItem";
import { loadMoreVideos } from "../../../store/video";
import { useSelector } from "react-redux";
import LoaderFull from "../../../components/LoaderFull";
import LoadMore from "../../../components/LoadMore";

const SecShowsList = ({ playlist_id, type }) => {
  const [listIsHovering, setListIsHovering] = useState(false);
  const [videoInfo, setVideoInfo] = useState([]);
  const [loader, setLoader] = useState(true);
  const [pageToken, setpageToken] = useState("");
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState();
  const { settingData } = useSelector((state) => state.settings);
  useEffect(() => {
    const loadVideos = async () => {
      try {
        const getVid = await loadMoreVideos({
          playlist_id: playlist_id,
          type: type,
          key: settingData.youtube_api,
          vimeo_key: settingData.vimeo_api,
          page: page,
        });
  
        if (getVid !== undefined) {
          if (type === "youtube") {
            setVideoInfo(getVid.items);
            setpageToken(getVid.nextPageToken);
          } else {
            setPage(getVid.page);
            setVideoInfo(getVid.data);
            setTotal(getVid.total);
          }
  
          setLoader(false);
        }
      } catch (error) {
        setLoader(false);
      }
      
    };
    loadVideos();
  }, [playlist_id]);

  const loadData = async () => {
    setLoader(true);
    const loadVid = await loadMoreVideos({
      playlist_id: playlist_id,
      type: type,
      key: settingData.youtube_api,
      vimeo_key: settingData.vimeo_api,
      pageToken: pageToken,
      page: page + 1,
    });
    if (loadVid !== undefined) {
      if (type === "youtube") {
        setVideoInfo([...videoInfo, ...loadVid?.items]);
        setpageToken(loadVid.nextPageToken);
      } else {
        setVideoInfo([...videoInfo, ...loadVid?.data]);
        setPage(loadVid.page);
      }

      setLoader(false);
    }
  };

  return (
    <>
      <div className={listIsHovering ? "shows-list hovered" : "shows-list"}>
        {videoInfo &&
          videoInfo.length > 0 &&
          videoInfo.map((item, index) => {
            return (
              <SecShowsListItem
                item={item}
                type={type}
                setListIsHovering={setListIsHovering}
                key={index}
              />
            );
          })}
      </div>
      {pageToken && (
        <div className="feed-action">
          <LoadMore loadData={loadData} />
        </div>
      )}
      {total && total > videoInfo.length && (
        <div className="feed-action">
          <LoadMore loadData={loadData} />
        </div>
      )}

      <LoaderFull loaderActive={loader} />
    </>
  );
};

export default SecShowsList;
