import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import {
  getCommunity,
  getCommunityRequest,
  getOtherProfile,
  getProfileDetails,
  sendConnectReq,
} from "../../store/user";
import OtherProfilePosts from "./Components/OtherProfilePosts";
import OthersProfileAbout from "./Components/OthersProfileAbout";
import OthersProfileFilter from "./Components/OthersProfileFilter";
import OthersProfileConection from "./Components/OthersProfileConection";
import "./Css/OthersProfile.css";
import LoaderFull from "../../components/LoaderFull";
import { addNotification } from "../../store/notification";
import { Link } from "react-router-dom";

const OthersProfile = () => {
  const { usrSlug } = useParams();
  const dispatch = useDispatch();
  const { communityList, communityRequestList } = useSelector(
    (state) => state.users
  );
  
  const [filter, setFilter] = useState("posts");
  const [profileData, setProfileData] = useState(null);
  const [common, setCommon] = useState([]);
  const [usrId, setUsrId] = useState(null);
  const [loading, setLoading] = useState(false);
  const [loadrequest, setLoadRequest] = useState(false);
  const { profileDetails } = useSelector((state) => state.users);
  const [isFriend, setIsFriend] = useState(false);
  const [disabled, setDisabled] = useState(false);

  useEffect(() => {
    async function fetchData() {
      setLoading(true);
      const profileData = await getOtherProfile({ user_slug: usrSlug });
      // console.log(profileData)
      setProfileData(profileData.data);
      setIsFriend(profileData.isFriend);
      setUsrId(profileData.data._id);
      setLoading(false);
      setFilter("posts");
    }
    fetchData();
  }, [usrSlug]);

  useEffect(() => {
    dispatch(getCommunity({ status: 2, user_id: usrId, keyword: "" }));
  }, [usrId, dispatch]);

  useEffect(() => {
    dispatch(getCommunityRequest({ status: 2, keyword: "" }));
    dispatch(getProfileDetails());
  }, [dispatch]);

  function intersect(arr1, arr2) {
    const intersection = [];
    Array.isArray(arr1) &&
      arr1.forEach((obj1) => {
        if (
          arr2.some(
            (obj2) =>
              obj1.user_name === obj2.user_name &&
              obj2.user_name !== profileData &&
              profileData?.name
          )
        ) {
          intersection.push(obj1);
        }
      });
    return intersection;
  }

  useEffect(() => {
    Array.isArray(communityRequestList.data) &&
      Array.isArray(communityList.data) &&
      setCommon(intersect(communityRequestList.data, communityList.data));
    
      let connectList = [];
    communityList?.data?.length > 0 &&
      communityList?.data?.map((item) => {
        connectList.push(item.sender_user_id);
      });
    
    let checkCommon = false;

    checkCommon =
      connectList.length !== 0 ? connectList.includes(profileData?._id) : false;


  }, [communityRequestList?.data, communityList?.data]);

  async function sendReq(receiver_user_id) {
    setLoadRequest(true);
    await sendConnectReq({ receiver_user_id: receiver_user_id });
    setDisabled(true);
    document.getElementById("sendBtn").innerHTML = "Request Sent!";
    setLoadRequest(false);
    await addNotification({
      source_id: profileDetails._id,
      sender_id: profileDetails._id,
      receiver_id: receiver_user_id,
      source_type: "connection",
      content: "{{userName}} send you a connection request",
    });
  }

  return (
    <>
      <section className="sec-others-profile">
        <div className="container">
          <div className="custom-row">
            <div className="panel-side">
              <div className="panel-side-content">
                <div className="profile-card">
                  <div className="profile-image">
                    {profileData && profileData?.image && (
                      <img src={profileData?.image} alt="usrDP" />
                    )}
                  </div>
                  <h2 className="profile-name">
                    {profileData && profileData.name}
                  </h2>
                  <div className="profile-designation">
                    {profileData &&
                      profileData?.headline &&
                      profileData.headline}
                  </div>
                  <div className="profile-meta">
                    <div className="meta-item">
                      <div className="meta-data">
                        {communityList && Array.isArray(communityList?.data)
                          ? communityList?.data?.length
                          : 0}
                      </div>
                      <div className="meta-label">Connections</div>
                    </div>
                    {profileDetails?._id !== profileData?._id && <div className="meta-item">
                      <div className="meta-data">{common.length}</div>
                      <div className="meta-label">In common</div>
                    </div>}
                  </div>
                  {profileDetails?._id !== profileData?._id && <>
                  {!(isFriend) && (
                    <div className="profile-actions">
                      <button
                        type="button"
                        className="btn-action"
                        id="sendBtn"
                        disabled={disabled}
                        onClick={() => sendReq(profileData?._id)}
                      >
                        {loadrequest ? (
                          <i className="fal fa-fw fa-spin fa-spinner mr-2"></i>
                        ) : (
                          <i className="fal fa-fw fa-user-plus mr-2"></i>
                        )}
                        Connect
                      </button>
                    </div>
                  )}
                  {isFriend && (
                    <div className="profile-actions">
                      <Link className="btn-action alt" to={"/messages"}>
                        <i className="fal fa-fw fa-comment mr-2"></i>
                        Message
                      </Link>
                    </div>
                  )}
                  </>}
                </div>
              </div>
            </div>
            <div className="panel-content">
              <OthersProfileFilter
                filter={filter}
                setFilter={setFilter}
                fname={profileData && profileData?.first_name}
              />
              {filter == "posts" && usrId && (
                <OtherProfilePosts usrId={usrId} />
              )}
              {filter == "connections" && (
                <OthersProfileConection
                  common={common}
                  otherUserConnection={communityList || []}
                />
              )}
              {filter == "about" && (
                <OthersProfileAbout
                  EducationData={profileData && profileData?.educations}
                  ExperienceData={profileData && profileData?.experiences}
                  AboutData={profileData && profileData?.abouts}
                  name={profileData && profileData?.first_name}
                />
              )}
            </div>
          </div>
        </div>
      </section>
      <LoaderFull loaderActive={loading} />
    </>
  );
};

export default OthersProfile;
