import { useEffect, useState } from "react";
import {
  addUserTags,
  updateExperience,
  updateEducation,
  getProfileDetails,
} from "../../../store/user";
import Flatpickr from "react-flatpickr";
import { useDispatch } from "react-redux";
import moment from "moment/moment";

const ProfileAboutEdit = ({
  profileActive,
  type,
  aboutData,
  setProfileActive,
}) => {
  const dispatch = useDispatch();

  const [about, setAbout] = useState(aboutData?.title || "");
  const [count, setCount] = useState("");
  const [error, setError] = useState(false);

  const [role, setRole] = useState("");
  const [company, setCompany] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [is_currently_working, setIs_currently_working] = useState(false);

  const [institution, setInstitution] = useState("");
  const [graduation, setGraduation] = useState("");

  const closePanel = (event) => {
    setProfileActive(false);
  };

  const handleKeyDown = (e) => {
    e.target.style.height = `40px`;
    e.target.style.height = `${e.target.scrollHeight}px`;
  };

  const handleWordCount = (e) => {
    const charCount = e.target.value.length;
    setCount(charCount);
  };

  const saveAbout = async () => {
    await addUserTags({ title: about });
    setProfileActive(false);
  };

  const saveExperience = async () => {
    if (moment(startDate).diff(moment(endDate)) >= 0) {
      setError(true);
    }
    if (role !== "" && company !== "" && startDate !== "") {

      if (is_currently_working) {
        dispatch(
          updateExperience({
            data: [
              {
                title: role,
                company_name: company,
                start_date: startDate,
                end_date: startDate,
                is_currently_working: true,
              },
            ],
          })
        ).then(() => {
          dispatch(getProfileDetails());
        });
      } else {
        dispatch(
          updateExperience({
            data: [
              {
                title: role,
                company_name: company,
                start_date: startDate,
                end_date: endDate,
                is_currently_working: false,
              },
            ],
          })
        ).then(() => {
          dispatch(getProfileDetails());
        });
      }
      setError(false);
      setCompany("");
      setRole("");
      setEndDate(null);
      setStartDate(null);
      setInstitution("");
      setGraduation("");
      setIs_currently_working(false);
      setProfileActive(false);
    }
  };

  const saveEducation = async () => {
    if (institution !== "" && graduation !== "") {
      dispatch(
        updateEducation({
          data: [
            { institution: institution, graduation: [{ name: graduation }] },
          ],
        })
      ).then(() => {
        dispatch(getProfileDetails());
        setInstitution("");
      setGraduation("");
      })
      setProfileActive(false);
      
    } else {
      setError(true);
    }
  };

  useEffect(() => {
    setAbout(aboutData?.title);
    setCount(aboutData?.title?.length);
  }, [aboutData]);


  const rmydays = (date) => {
    return (date.getDay() === 22);
  }

  return (
    <>
      <div
        className={"profile-panel-section " + (profileActive ? "active" : "")}
      >
        <div className="panel-backdrop"></div>
        <div className="side-panel-content">
          <div className="panel-header">
            <div className="panel-title">
              {type === "about" && "About Me"}
              {type === "experience" && "Add experience"}
              {type === "education" && "Add education"}
            </div>
            <button
              type="button"
              className="panel-close"
              onClick={() => {
                closePanel();
                setError(false);
                setCompany("");
                setRole("");
                setEndDate(null);
                setStartDate(null);
                setInstitution("");
                setGraduation("");
                setIs_currently_working(false);
              }}
            >
              <i className="fa-light fa-fw fa-times"></i>
            </button>
          </div>
          {type === "about" && (
            <div className="panel-body">
              <form className="form-content">
                <div className="form-group">
                  <div
                    className={
                      "input-style input-style-textarea " +
                      (about ? "hasValue" : "")
                    }
                  >
                    <textarea
                      className="form-control"
                      name="about"
                      autoComplete="off"
                      value={about}
                      onChange={(e) => {
                        setAbout(e.target.value);
                        handleKeyDown(e);
                        handleWordCount(e);
                      }}
                      rows={4}
                      style={{ height: "440px", maxHeight: "440px" }}
                      onPaste={handleKeyDown}
                      maxLength="500"
                    ></textarea>
                    <div className="input-count">{count} / 500</div>
                    <div className="float-label">
                      Tell us a little about yourself
                    </div>
                  </div>
                </div>

                <div className="form-group form-action">
                  <button
                    type="button"
                    className="btn-action"
                    onClick={() => saveAbout()}
                  >
                    Save
                  </button>
                </div>
              </form>
            </div>
          )}

          {type === "experience" && (
            <div className="panel-body">
              {moment(startDate).diff(moment(endDate)) >= 0 && (
                <p style={{ color: "red" }}>
                  Start date cannot be less than end date
                </p>
              )}
              {error && (
                <p style={{ color: "red" }}>Enter role and company name.</p>
              )}
              <form className="form-content">
                <div className="form-group">
                  <div
                    className={
                      "input-style input-style-textarea " +
                      (role ? "hasValue" : "")
                    }
                  >
                    <textarea
                      className="form-control"
                      name="role"
                      autoComplete="off"
                      value={role}
                      onChange={(e) => {
                        setRole(e.target.value);
                        handleKeyDown(e);
                      }}
                    ></textarea>
                    <div className="float-label">Role Name</div>
                  </div>
                </div>

                <div className="form-group">
                  <div
                    className={
                      "input-style input-style-textarea " +
                      (company ? "hasValue" : "")
                    }
                  >
                    <textarea
                      className="form-control"
                      name="company"
                      autoComplete="off"
                      value={company}
                      onChange={(e) => {
                        setCompany(e.target.value);
                        handleKeyDown(e);
                      }}
                    ></textarea>
                    <div className="float-label">Company Name</div>
                  </div>
                </div>
                <div className="row">
                <div className="col-6">
                <div className="form-group">
                  <div
                    className={"input-style " + (startDate ? "hasValue" : "")}
                  >
                    <Flatpickr
                      className="form-control"
                      value={startDate}
                      onChange={([date]) => {
                        setStartDate(new Date(date).toISOString());
                      }}
                      options = {{
                        maxDate: moment().toISOString()
                      }}
                      disable= {()=>rmydays(startDate)}
                    />
                    <div className="input-icon">
                      <i className="fa-light fa-fw fa-calendar"></i>
                    </div>
                    <div className="float-label">Start Date</div>
                  </div>
                </div>
                </div>
                <div className="col-6">
                {!is_currently_working && (
                  <div className="form-group">
                    <div
                      className={"input-style " + (endDate ? "hasValue" : "")}
                    >
                      <Flatpickr
                        className="form-control"
                        value={endDate}
                        onChange={([date]) => {
                        setEndDate(new Date(date).toISOString());
                        }}
                      />
                      <div className="input-icon">
                        <i className="fa-light fa-fw fa-calendar"></i>
                      </div>
                      <div className="float-label">End Date</div>
                    </div>
                  </div>
                )}
                </div>
                </div>
                <div className="form-group">
                  <div className="custom-checkbox">
                    <input
                      type="checkbox"
                      className="custom-checkbox-input"
                      name="is_currently_working"
                      value={is_currently_working || false}
                      checked={is_currently_working || false}
                      onChange={(e) =>
                        setIs_currently_working(e.target.checked)
                      }
                    />
                    <div className="custom-checkbox-label">
                      This is my current job
                    </div>
                  </div>
                </div>

                {role &&
                  company &&
                  !(moment(startDate).diff(moment(endDate)) >= 0) && (
                    <div className="form-group form-action">
                      <button
                        type="button"
                        className="btn-action"
                        onClick={() => saveExperience()}
                      >
                        Save
                      </button>
                    </div>
                  )}
              </form>
            </div>
          )}

          {type === "education" && (
            <div className="panel-body">
              {error && (
                <p style={{ color: "red" }}>
                  Enter institution name and graduation
                </p>
              )}
              <form className="form-content">
                <div className="form-group">
                  <div
                    className={
                      "input-style input-style-textarea " +
                      (institution ? "hasValue" : "")
                    }
                  >
                    <textarea
                      className="form-control"
                      name="about"
                      autoComplete="off"
                      value={institution}
                      onChange={(e) => {
                        setInstitution(e.target.value);
                      }}
                    ></textarea>
                    <div className="float-label">Institution name</div>
                  </div>
                </div>

                <div className="form-group">
                  <div
                    className={
                      "input-style input-style-textarea " +
                      (graduation ? "hasValue" : "")
                    }
                  >
                    <textarea
                      className="form-control"
                      name="about"
                      autoComplete="off"
                      value={graduation}
                      onChange={(e) => {
                        setGraduation(e.target.value);
                      }}
                    ></textarea>
                    <div className="float-label">Graduation</div>
                  </div>
                </div>

                <div className="form-group form-action">
                  <button
                    type="button"
                    className="btn-action"
                    onClick={() => saveEducation()}
                  >
                    Save
                  </button>
                </div>
              </form>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default ProfileAboutEdit;
