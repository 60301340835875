import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import SecHomeEventsItem from "./SecHomeEventsItem";
import { Swiper, SwiperSlide } from "swiper/react";
import { globalEvents } from "../../../store/event";
import "swiper/css";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment/moment";
import Fade from "react-reveal/Fade";

const SecHomeEvents = () => {
  const { globalEventlist } = useSelector((state) => state.events);
  const { homeContentdata } = useSelector((state) => state.pages);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(globalEvents({ status: 1, tags: [] }));
  }, [dispatch]);

  const [listIsHovering, setListIsHovering] = useState(false);

  return (
    <>
      <section className="home-events">
        <div className="container">
          <Fade left>
            <h2 className="home-events-title">
              {homeContentdata?.home_events?.title}
            </h2>
          </Fade>
        </div>
        <Fade cascade>
          <div className={"events-list " + (listIsHovering ? "hovered" : "")}>
            <Swiper
              spaceBetween={0}
              slidesPerView={1.2}
              breakpoints={{
                480: {
                  slidesPerView: 2,
                  spaceBetween: 10,
                },
                768: {
                  slidesPerView: 2,
                  spaceBetween: 20,
                },
                1024: {
                  slidesPerView: 3,
                  spaceBetween: 20,
                },
                1200: {
                  slidesPerView: 3,
                  spaceBetween: 40,
                },
                1600: {
                  slidesPerView: 4,
                  spaceBetween: 50,
                },
              }}
            >
              {globalEventlist &&
                globalEventlist.map((item, eventIndex) => (
                  <SwiperSlide key={eventIndex}>
                    <Fade>
                      {item.end_time !== "" ? (
                        <SecHomeEventsItem
                          name={item.title}
                          time={
                            moment(new Date(item.start_date)).fromNow()
                          }
                          link={
                            "/event-details/" + item.slug + "/" + item.event_id
                          }
                          image={item.image}
                          setListIsHovering={setListIsHovering}
                        />
                      ) : (
                        <SecHomeEventsItem
                          name={item.title}
                          time={
                            moment(new Date(item.start_date)).format(
                              "ddd, MMM DD"
                            ) +
                            ", " +
                            moment(item.start_time, "HH:mm:ss").format(
                              "hh:mm A"
                            )
                          }
                          link={
                            "/event-details/" + item.slug + "/" + item.event_id
                          }
                          image={item.image}
                          setListIsHovering={setListIsHovering}
                        />
                      )}
                    </Fade>
                  </SwiperSlide>
                ))}
            </Swiper>
          </div>
        </Fade>
        <div className="container">
          <div className="events-action">
            <Fade bottom>
              <Link to="/schedule" className="btn-action">
                <i className="fa-solid fa-fw fa-calendar mr-2"></i>Browse more
                Events
              </Link>
            </Fade>
          </div>
        </div>
      </section>
    </>
  );
};

export default SecHomeEvents;
