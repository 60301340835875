import { useCallback } from "react";
import { useEffect } from "react";
import { useState } from "react";
import Cropper from "react-easy-crop";
import getCroppedImg from "../../../helpers/cropImage";
function ProfileModal({ profileDetails, picture, getUploadImg, savePhoto, setPicture }) {
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [rotation, setRotation] = useState(0);
  const [zoom, setZoom] = useState(1.0);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
  const [croppedImage, setCroppedImage] = useState();


  const onCropComplete = useCallback(async (croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  }, []);

  useEffect(() => {
    async function fetchData() {

      const croppedImage = await getCroppedImg(picture, croppedAreaPixels).catch(() => { });
      setCroppedImage(croppedImage);
    }
    fetchData();
  }, [croppedAreaPixels]);

  function increaseZoom() {
    if (zoom >= 1 && zoom < 3) {
      setZoom(parseFloat((zoom + 0.1).toFixed(2)));
    }
  }

  function decreaseZoom() {
    if (zoom > 1 && zoom <= 3.1) {
      setZoom(parseFloat((zoom - 0.1).toFixed(2)));
    }
  }

  const removeData = () => {
    setPicture("");
  }

  return <>
    <div
      className="modal own-profile-modal fade"
      id="profileModal"
      tabIndex="-1"
      aria-labelledby="profileModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered modal-sm">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="profileModalLabel">
              Change picture
            </h5>
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <i className="fa-light fa-xmark"></i>
            </button>
          </div>
          <div className="modal-body">
            {!profileDetails?.image ? (
              <div className="before-image-preview">
                {profileDetails?.name?.match(/\b(\w)/g).join("")}
              </div>
            ) : (
              <>
                {picture !== "" && (
                  <div
                    style={{
                      position: "relative",
                      width: "100%",
                      height: 300,
                      background: "#333",
                    }}
                  >
                    <Cropper
                      objectFit="contain"
                      image={picture ? picture : profileDetails?.image}
                      crop={crop}
                      rotation={rotation}
                      zoom={zoom}
                      aspect={1}
                      cropShape={"round"}
                      onCropChange={setCrop}
                      onRotationChange={setRotation}
                      onCropComplete={onCropComplete}
                      onZoomChange={setZoom}
                      minZoom={1}
                    />
                  </div>
                )}

                <div className="after-image-upload">
                  {picture === "" && (
                    <div className="img-wrap">
                      <img id="DP" src={profileDetails?.image} alt="dp" />
                    </div>
                  )}
                  {picture !== "" && (
                    <>
                      <div className="note-text">
                        Size recommendation is 256 x 256 px
                      </div>
                      <div className="image-size-adjust">
                        <form>
                          <div className="form-group">
                            <button
                              className="zoom-action mr-2"
                              type="button"
                              onClick={decreaseZoom}
                            >
                              <i className="fa-regular fa-minus mr-2"></i>
                              Zoom{" "}
                            </button>
                            <input
                              type="range"
                              min={1.0}
                              max={3.0}
                              step={0.1}
                              value={zoom}
                              onChange={(e) => {
                                setZoom(e.target.value);
                              }}
                              className="form-control-range"
                              id="formControlRange"
                            />
                            <button
                              className="zoom-action ml-2"
                              type="button"
                              onClick={increaseZoom}
                            >
                              <i className="fa-regular fa-plus mr-2"></i>
                              Zoom{" "}
                            </button>
                          </div>
                        </form>
                      </div>
                    </>
                  )}
                </div>
              </>
            )}
          </div>
          <div className="modal-footer">
            {/* <button type="button" className="btn btn-save"><i className="fa-light fa-cloud-arrow-up mr-2"></i> Upload Picture</button> */}
            <div className="footer_btn">
              <div className="footer_btn_text">upload</div>
            <input
              type="file"
              className="btn btn-save file_upload_btn"
              name="image"
              onChange={(e) => getUploadImg(e)}
              onClick={(e)=> { 
                e.target.value = null
           }}
            />
            </div>
            {picture !== "" && (
              <button type="button" className="btn btn-remove" onClick={()=>removeData()}>
                <i className="fa-light fa-trash-can mr-2"></i> Remove
              </button>
            )}
            {picture !== "" && (
              <button
                type="button"
                className="btn btn-save"
                onClick={() => savePhoto(croppedImage)}
              >
                Save Photo
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  </>;
}

export default ProfileModal;
