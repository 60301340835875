import { useRef, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import LoaderFull from "../../../components/LoaderFull";
import AudioPlayer from "react-h5-audio-player";
import "react-h5-audio-player/lib/styles.css";

const SecStoriesDetailsBanner = ({ blogdetails, loading }) => {
  const navigate = useNavigate();
  const [isListen, setIsListen] = useState(true);
  const [mp3Url, setMp3Url] = useState("");
  const player = useRef();

  const handleClick = () => {
    setIsListen(true);
    player.current.audio.current.play();
  };

  const stopListening = () => {
    setIsListen(false);
    player.current.audio.current.pause();
  };

  useEffect(() => {
    if (blogdetails && blogdetails.id) {
      let text = document.getElementById("demo");

      if (text && text.value !== undefined && text.value.trim() !== "") {
        let parse2 = text.value.split(" ")[11].split("spreakerUrl:")[1];

        setMp3Url(
          parse2?.split("'")[1].split("/")[4]
            ? `https://api.spreaker.com/v2/episodes/${
                parse2?.split("'")[1].split("/")[4]
              }/play.mp3`
            : ""
        );
      }
    }
  }, [blogdetails]);

  useEffect(() => {
    return () => {
      setMp3Url("");
    };
  }, []);

  function createMarkup() {
    return { __html: blogdetails.title };
  }

  return (
    <>
      <input
        type="hidden"
        id="demo"
        value={
          blogdetails?.codeinjection_head ? blogdetails?.codeinjection_head : ""
        }
      />
      <section className="sec-stories-details-banner">
        <div className="container">
          <div className="banner-content">
            <button
              type="button"
              className="btn-back mb-5"
              onClick={() => navigate(-1)}
            >
              <i className="fa-light fa-fw fa-chevron-left"></i>
            </button>
            <h1
              className="banner-title"
              dangerouslySetInnerHTML={createMarkup()}
            ></h1>

            <div style={{ display: "none" }}>
              <AudioPlayer preload="metadata" src={mp3Url} ref={player} />
            </div>
            {mp3Url !== "" && (
              <div className="banner-action">
                {isListen ? (
                  <button
                    type="button"
                    className="btn-action alt"
                    onClick={stopListening}
                  >
                    <i className="fa-light fa-fw fa-waveform-lines mr-2"></i>{" "}
                    Listening
                  </button>
                ) : (
                  <button
                    type="button"
                    className="btn-action"
                    onClick={handleClick}
                  >
                    <i className="fa-light fa-fw fa-headphones mr-2"></i> Listen
                    the content
                  </button>
                )}
              </div>
            )}
          </div>
          <div className="banner-image">
            <img src={blogdetails.feature_image} alt="" />
          </div>
        </div>
      </section>
      <LoaderFull loaderActive={loading} />
    </>
  );
};

export default SecStoriesDetailsBanner;
