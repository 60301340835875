import UserInfo from "./UserInfo";
import UserImage from "./UserImage";
import CommentAction from "./CommentAction";
function CommentReply({
  data,
  users,
  replyComment,
  parentuserId,
  liked,
  loved,
  reactionsCount,
  loveReactionsCount,
  userId,
  postuserId,
  setIsLike,
  postId,
  setIsLove,
}) {
  const commentuser =
    users.length > 0 && users.find((i) => i.userId == data.userId);
  return (
    <>
      <div className="comment-reply" id={"rmv" + data._id}>
        <div className="user-comment">
          <UserImage commentuser={commentuser} />
          <div className="user">
            <UserInfo commentuser={commentuser} date={data.createdAt} />
            <div className="comments">{data.data.text}</div>
          </div>
        </div>
        <CommentAction
          id={data.parentId}
          postId={postId}
          org_id={data._id}
          replyComment={replyComment}
          parentuserId={parentuserId}
          liked={liked}
          loved={loved}
          reactionsCount={reactionsCount}
          loveReactionsCount={loveReactionsCount}
          userId={userId}
          postuserId={postuserId}
          setIsLike={setIsLike}
          setIsLove={setIsLove}
        />
      </div>
    </>
  );
}

export default CommentReply;
