import { useState } from "react";
import { useRef } from "react";
import { useNavigate, useParams } from "react-router-dom";
import LoaderFull from "../../components/LoaderFull";
import { getUsers } from "../../store/user";

import {
  imageUploadPost,
  imageDeletePost,
  draftFeedDetails,
  editDraftPost,
  createPost,
  deleteDraftPost,
  createMyPost,
} from "../../store/feed";
import "./Css/Article.css";
import { useEffect } from "react";
import { tagList } from "../../store/event";
import API_URL from "../../config/apiHost";
import { useSelector } from "react-redux";

const EditArticle = ({ toggleEdit, setToggleEdit }) => {
  const { id } = useParams();
  const { settingData } = useSelector((state) => state.settings);
  const [search, setSearch] = useState("");
  const [extraText, setExtraText] = useState("");
  const [attachments, setaAttachments] = useState([]);
  const [images, setImages] = useState([]);
  const [videos, setVideos] = useState([]);
  const [msg, setMsg] = useState("");
  const [errorMsg, setErrorMsg] = useState("");
  const [loading, setLoading] = useState(false);
  const [activeImg, setActiveImg] = useState(true);
  const [activeVdo, setActiveVdo] = useState(true);
  const [userData, setUserData] = useState([]);
  const [keyword, setKeyword] = useState("");
  const [keywordTag, setKeywordTag] = useState("");
  const [user, setUser] = useState([]);
  const [userId, setUserId] = useState([]);
  const [tagData, setTagdata] = useState([]);
  const [tag, setTag] = useState([]);
  const [tagName, setTagName] = useState([]);
  const [activePreview, setActivePreview] = useState(false);
  const [subtitle, setSubtitle] = useState("");
  const [description, setDescription] = useState("");
  const [draftImages, setDraftImages] = useState([]);
  const [caption, setCaption] = useState("");
  const [picture, setPicture] = useState("");
  const allowedExts = ["jpg", "jpeg", "png", "gif", "svg"];

  useEffect(() => {
    tagList({ tag_type: 2, keyword: keywordTag, status: true })
      .then((res) => {
        setTagdata(res);
      })
      .catch(() => {});
  }, [keywordTag]);

  useEffect(() => {
    async function fetchdata() {
      await getUsers({ type: "user" })
        .then((res) => {
          setUserData(res.data);
        })
        .catch(() => {});
    }
    fetchdata();
  }, []);

  useEffect(() => {
    const getDetails = async () => {
      setLoading(true);
      const details = await draftFeedDetails({ id: id });
      if (details.status === 200) {
        setLoading(false);
        setSearch(details.data.title);
        setSubtitle(details.data.subtitle);
        setImages(details.data?.images);
        setPicture(details.data?.images);
        setDraftImages(details.data.images);
        setDescription(details.data.description);
        setaAttachments(
          details.data.images.length !== 0
            ? details.data.images.map((item) => {
                return { fileId: item.fileId, type: "image" };
              })
            : []
        );
        if (details?.data?.posts) {
          setUserId(
            details?.data?.posts[0]?.mentionees[0]?.userIds
              ? details?.data?.posts[0].mentionees[0].userIds
              : []
          );
          setSearch(details.data.posts[0].data.text);
          setTagName(
            details.data.posts[0].tags ? details.data.posts[0].tags : []
          );
        }

        let imgs = details.data.files ? details.data.files.slice(0, -1) : [];
        let vdos = details.data.files ? details.data.files.slice(0, -1) : [];

        let tmpImg = [];
        let tmpVdo = [];
        let tmpImgAttch = [];
        let tmpVdoAttch = [];

        vdos.forEach((item) => {
          if (
            ["mp4", "mov", "avi", "wmv", "flv", "mkv", "m4v"].includes(
              item.attributes.extension
            )
          ) {
            tmpVdo.push({
              blobVdo: item.fileUrl + "?size=full",
              fileId: item.fileId,
            });
            tmpVdoAttch.push({
              fileId: item.fileId,
              type: "video",
            });
          }
        });

        setVideos(tmpVdo);
        setImages(tmpImg);

        vdos.forEach((item) => {
          if (tmpVdo.length === 0) {
            tmpImg.push({
              blobImg: item.fileUrl + "?size=full",
              fileId: item.fileId,
            });

            tmpImgAttch.push({
              fileId: item.fileId,
              type: "image",
            });
          }
        });

        setaAttachments([...tmpVdoAttch, ...tmpImgAttch]);

        if (tmpImg.length === 0) {
          setActiveImg(false);
        } else if (tmpVdo.length === 0) {
          setActiveVdo(false);
        }
      }

      setLoading(false);
    };

    getDetails();
  }, [id]);

  // user
  useEffect(() => {
    if (keyword === "") {
      async function fetchdata() {
        await getUsers({ type: "user" })
          .then((res) => {
            setUserData(res.data);
          })
          .catch(() => {});
      }
      fetchdata();
    } else {
      const condition = new RegExp(keyword, "i");

      let result = userData.filter(function (el) {
        return condition.test(el.name);
      });

      setUserData(result);
    }
  }, [keyword]);

  useEffect(() => {
    let tmp = [];
    userData.forEach((item) => {
      if (userId?.length > 0 && userId.includes(item._id)) {
        tmp.push({ _id: item._id, name: item.name });
      }
    });

    setUser(tmp);
  }, [userId, userData]);

  useEffect(() => {
    let tmp = [];
    tagData.forEach((item) => {
      if (tagName?.length > 0 && tagName.includes(item.title)) {
        tmp.push({ _id: item._id, name: item.title });
      }
    });

    setTag(tmp);
  }, [tagName, tagData]);

  let navigate = useNavigate();

  const imgRef = useRef(null);
  const vdoRef = useRef(null);

  const executeScroll = () => imgRef.current.scrollIntoView();
  const executeVdoScroll = () => vdoRef.current.scrollIntoView();

  const uploadFile = async (e) => {
    setLoading(true);

    let formData = new FormData();

    formData.append("file", e.target.files[0]);
    formData.append("type", "images");

    await imageUploadPost(formData)
      .then((res) => {
        setErrorMsg("");
        if (res.status === 200) {
          let tmp = {
            fileId: res.data[0].fileId,
            type: "image",
          };
          let img = {
            fileId: res.data[0].fileId,
            blobImg: URL.createObjectURL(e.target.files[0]),
          };

          let draftImg = {
            fileId: res.data[0].fileId,
            imgLink: res.data[0].fileUrl + "?size=full",
          };
          setDraftImages([...draftImages, draftImg]);
          setPicture([...picture, draftImg])
          setaAttachments([...attachments, tmp]);
          setImages([...images, img]);
          setErrorMsg("");
          setActiveVdo(false);
          setMsg("You'll only be able to upload images.");
          setLoading(false);
          e.target.value = "";
        } else if (res.status === 400) {
          setErrorMsg(res.message);
          setLoading(false);
          e.target.value = "";
          executeScroll();
        }
      })
      .catch(() => {
        setLoading(false);
      });
  };

  console.log(draftImages);

  const getUploadImg = (e) => {
    let base64String = "";
    let file = e.target.files[0];
    const ext = e.target.files[0].type.split("/")[1];
    if (!allowedExts.includes(ext)) {
      alert("Please upload a valid image.");
    } else {
      if (file instanceof File && file.name) {
        const reader = new FileReader();

        reader.onload = () => {
          base64String = reader.result;
          const imageBase64Stringsep = base64String;
          setPicture(imageBase64Stringsep);
        };

        reader.readAsDataURL(file);
      }
    }
  };

  function imageDeletePostFunc(fileid) {
    setLoading(true);
    let a = { file_id: fileid };

    imageDeletePost(a)
      .then((res) => {
        if (res.status === 200) {
          let tmp = attachments;
          let img = images;
          setPicture([]);
          setDraftImages([]);
          let tmpAttchment = tmp.filter((item) => {
            return item.fileId !== fileid;
          });

          let tmpImg = img.filter((item) => {
            return item.fileId !== fileid;
          });
          editDraftPost({  
            id: id,
            images: []
          })

          setErrorMsg("");
          setLoading(false);
          setaAttachments(tmpAttchment);
          setImages(tmpImg);
        } else if (res.status === 400) {
          setErrorMsg(res.message);
          setLoading(false);
          executeScroll();
        }
      })
      .catch(() => {
        setLoading(false);
      });
  }

  async function handleSubmit() {
    setLoading(true);

    await createPost({
      data: {
        text: extraText,
      },
      attachments: attachments,
      metadata: {
        isArticle: true,
        title: search,
        subtitle: subtitle,
        description: description,
        images: draftImages,
        // images: picture,
        caption: caption,
      },
      targetType: "community",
      targetId: settingData?.community_id,
      tags: tagName,
    }).then(async (res) => {
      await deleteDraftPost({ id: id });
      await createMyPost({
        post_id: res.data.posts[0]._id,
      });
      setLoading(false);
      navigate("/feed");
    });
  }

  async function handleDraftSubmit() {
    setLoading(true);
    try {
      await editDraftPost({
        id: id,
        title: search,
        subtitle: subtitle,
        description: description,
        images: draftImages,
        // images: picture,
        caption: caption,
      }).then((res) => {
        // console.log(res);
        setLoading(false);
        navigate("/drafts");
      });
    } catch (err) {
      setLoading(false);
    }
  }

  console.log(picture);

  return (
    <>
      <div className="event-panel-edit">
        <div className="edit-panel add-new">
          <div className="panel-header">
            <div className="container-fluid">
              <div className="row">
                <div className="col-lg-6 d-flex ml-auto">
                  <div
                    className="panel-close"
                    style={{ cursor: "pointer" }}
                    data-toggle="modal"
                    data-target="#deleteModal"
                  >
                    <i className="fa-light fa-fw fa-times"></i>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="py-5">
            <div className="container">
              <div className="row justify-content-center">
                <div className="col-md-8">
                  {activePreview && (
                    <>
                      <div className="share-item">
                        <div className="share-image">
                          <img
                            // src={
                            //   images.length !== 0
                            //     ? images[0]?.blobImg
                            //       ? images[0]?.blobImg
                            //       : images[0]?.imgLink
                            //     : "https://ceo.iosx.in/uploads/events/no-image.png"
                            // }
                            src={
                              draftImages
                                ? draftImages[0]?.imgLink
                                : API_URL + "uploads/events/no-image.png"
                            }
                            alt=""
                          />
                        </div>
                        <div className="share-content">
                          <div className="share-title">{search}</div>
                          <div className="share-title">{subtitle}</div>
                          <div className="share-action">
                            <button
                              className="edit-action"
                              onClick={() => setActivePreview(false)}
                            >
                              <i className="fa-solid fa-pen mr-3"></i>Review and
                              edit
                            </button>
                            {/* <button type="button" className="edit-action alt" data-toggle="modal" data-target="#deleteModal">
                      <i className="fa-regular fa-trash-can mr-2"></i>Delete draft
                    </button> */}
                          </div>
                        </div>
                      </div>
                      <div
                        className={
                          "mt-5 input-style " + (extraText ? "hasValue" : "")
                        }
                      >
                        <input
                          value={extraText}
                          onChange={(e) => {
                            setExtraText(e.target.value);
                          }}
                          type="text"
                          className="form-control"
                          name="search"
                          autoComplete="off"
                        />
                        <div className="input-count">
                          {extraText?.length} / 200
                        </div>
                        <div className="float-label">
                          Say something about that
                        </div>
                      </div>
                      <div className="event-timing-start">
                        <div className="save-action d-flex">
                          {extraText !== "" && (
                            <button
                              type="button"
                              className="btn-save"
                              onClick={handleSubmit}
                            >
                              Publish post
                            </button>
                          )}
                          <button
                            type="button"
                            className="btn-save ml-3"
                            onClick={handleDraftSubmit}
                          >
                            Save to draft
                          </button>
                        </div>
                      </div>
                    </>
                  )}
                  {!activePreview && (
                    <div
                      className={"input-style " + (search ? "hasValue" : "")}
                    >
                      <input
                        value={search}
                        onChange={(e) => {
                          setSearch(e.target.value);
                        }}
                        placeholder="Title"
                        type="text"
                        className="form-control"
                        name="search"
                        autoComplete="off"
                      />
                    </div>
                  )}
                  {!activePreview && (
                    <div
                      className={
                        "input-style mt-3 text-secondary" +
                        (subtitle ? "hasValue" : "")
                      }
                    >
                      <input
                        value={subtitle}
                        onChange={(e) => {
                          setSubtitle(e.target.value);
                        }}
                        placeholder="Subtitle (optional)"
                        type="text"
                        className="form-control sub-title"
                        name="search"
                        autoComplete="off"
                      />
                    </div>
                  )}

                  <div
                    className={
                      "event-other-details " + (search ? "active" : "")
                    }
                  >
                    {!activePreview && (
                      <p className="text-danger" ref={imgRef}>
                        {errorMsg}
                      </p>
                    )}
                    {activeImg && <p className="text">{msg}</p>}
                    {!activePreview && (
                      <div className="event-timing-start">
                        {/* {activeImg && ( */}
                        <div className="event-location-edit">
                          <div className="location-content">
                            <div className="upload-image">
                              {picture.length === 0 && <div className="upload-photo">
                                <input
                                  className="input-file"
                                  type="file"
                                  onChange={(e) => uploadFile(e)}
                                />
                                <div className="upload">
                                  <div className="uploadicon">
                                    <i className="fa-light fa-cloud-arrow-up"></i>
                                  </div>
                                  <div className="text">Upload Cover Image</div>
                                </div>
                              </div>}
                              {draftImages.length === 1 && <div
                                className={
                                  "preview-image " + (draftImages.length === 1 ? "active" : "")
                                }
                              >
                                <div className="img-wrap">
                                  <img src={draftImages[0].imgLink} alt="" />
                                </div>
                                <button
                                  type="button"
                                  className="delete-action"
                                  style={{ zIndex: 2 }}
                                  onClick={() => imageDeletePostFunc(draftImages[0]?.fileId)}
                                >
                                  <i className="fa-light fa-trash-can"></i>
                                </button>
                              </div>}
                            </div>
                          </div>
                        </div>
                        {/* )} */}

                        {images === [] && (
                          <div className="input-style mb-5">
                            <input
                              value={caption}
                              onChange={(e) => {
                                setCaption(e.target.value);
                              }}
                              placeholder="Add Caption (optional)"
                              type="text"
                              className="form-control sub-title border-bottom"
                              name="search"
                              autoComplete="off"
                            />
                          </div>
                        )}

                        <div className="input-style mb-3">
                          <textarea
                            className="form-control"
                            value={description}
                            onChange={(e) => setDescription(e.target.value)}
                            rows={3}
                            placeholder="Start Writing..."
                            maxLength={1000}
                          ></textarea>
                          {description?.length || 0} / 1000
                        </div>

                        {!activePreview && search && (
                          <div className="save-action">
                            <button
                              type="button"
                              className="btn-save"
                              onClick={() => setActivePreview(true)}
                            >
                              Continue and save draft
                            </button>
                          </div>
                        )}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className="modal event-delete-modal fade"
        data-backdrop="static"
        id="deleteModal"
        tabIndex="-1"
        aria-labelledby="deleteModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered modal-sm">
          <div className="modal-content">
            <div className="modal-body">
              <div className="delete-content">
                <div className="icon">
                  <i className="fa-solid fa-triangle-exclamation"></i>
                </div>
                <div className="text">
                  <div className="main-title">Are you sure?</div>
                  <div className="des">
                    You have not saved your draft yet. If you leave It will be
                    lost.
                  </div>
                </div>
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-close"
                data-dismiss="modal"
              >
                Continue editing
              </button>
              <button
                type="button"
                className="btn btn-confirm"
                data-dismiss="modal"
                onClick={() => navigate(-1)}
              >
                Exit without saving
              </button>
            </div>
          </div>
        </div>
      </div>
      <LoaderFull loaderActive={loading} />
    </>
  );
};

export default EditArticle;