import { useEffect, useState } from "react";
import SecVideoListItem from "./SecVideoListItem";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import { fetchVideos } from "../../../store/video";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import Fade from "react-reveal/Fade";

const SecVideoList = (props) => {
  const { name, playlist_id, type } = props?.item;
  const [videoList, setVideoList] = useState([]);

  const { settingData } = useSelector((state) => state.settings);

  useEffect(() => {
    const loadvideos = async () => {
      try {
        const list = await fetchVideos({
          playlist_id: playlist_id,
          type: type,
          key: settingData.youtube_api,
          vimeo_key: settingData.vimeo_api,
        });

        setVideoList(list);
      } catch (error) {}
    };
    if (playlist_id && !videoList.length && settingData.youtube_api) {
      loadvideos();
    }
  }, [playlist_id, videoList.length]);

  return (
    <>
      <div className="video-layout">
        <div className="container">
          <div className="video-layout-header">
            <Fade left>
              <h2 className="video-layout-title">{name}</h2>
            </Fade>
            <Fade right>
              <Link
                to={"/playlist/" + props?.item?._id}
                className="btn-viewAll"
              >
                View playlist
              </Link>
            </Fade>
          </div>
        </div>
        <Fade cascade>
          <div className="video-layout-list">
            <Swiper
              spaceBetween={0}
              slidesPerView={1.1}
              breakpoints={{
                480: {
                  slidesPerView: 1.1,
                  spaceBetween: 0,
                },
                768: {
                  slidesPerView: 2.5,
                  spaceBetween: 20,
                },
                1024: {
                  slidesPerView: 3,
                  spaceBetween: 20,
                },
                1200: {
                  slidesPerView: 4,
                  spaceBetween: 20,
                },
                1600: {
                  slidesPerView: 5,
                  spaceBetween: 20,
                },
              }}
            >
              {Array.isArray(videoList) &&
                videoList.length > 0 &&
                videoList.map((item, i) => {
                  return (
                    <SwiperSlide key={i}>
                      <Fade>
                        <SecVideoListItem item={item} type={type} />
                      </Fade>
                    </SwiperSlide>
                  );
                })}
            </Swiper>
          </div>
        </Fade>
      </div>
    </>
  );
};

export default SecVideoList;
