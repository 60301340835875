import React, { useEffect } from "react";
import { getSavedPosts, getUserPosts } from "../../../store/feed";
import { useState } from "react";
import LoaderFull from "../../../components/LoaderFull";
import { useSelector } from "react-redux";
import { getProfile } from "../../../store/user";
import LinkPost from "../../Feed/Components/LinkPost";
import API_URL from "../../../config/apiHost";

const OtherProfilePosts = ({ usrId }) => {
  const [posts, setPost] = useState([]);
  const [btnDisable, setBtnDisable] = useState(false);
  const [postIds, setPostids] = useState([]);
  const [postChildren, setpostChildren] = useState([]);
  const [files, setFiles] = useState([]);
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(false);
  const [paging, setPaging] = useState({});
  const [profileInfo, setProfileData] = useState([]);
  const [limit, setLimit] = useState(10);
  const [favList, setFavorites] = useState([]);
  const [reValidate, setreValidate] = useState(null);
  const { profileDetails } = useSelector((state) => state.users);

  useEffect(() => {
    const getProfileData = async () => {
      setLoading(true);
      setPostids([]);
      setPost([]);
      setFiles([]);
      setUsers([]);
      setpostChildren([]);

      const profileData = await getProfile({ user_id: usrId });
      setProfileData(profileData);
      const getFavorite = await getUserPosts({ user_id: usrId });

      setFavorites(getFavorite.data === [] ? [] : getFavorite.data);
      getFavorite.data.length > 0 &&
        getFavorite.data.map((item) => {
          postIds.push(item.post_id);
        });

      setPostids([...new Set(postIds)]);

      let remove = postIds.filter((id) => id !== reValidate);
      setPostids([...new Set(remove)]);

      try {
        const savePosts = await getSavedPosts({
          postIds: [...new Set(postIds.filter((id) => id !== reValidate))],
        });
        if (savePosts.status === 200) {
          setPost(savePosts.data.posts.reverse());
          setpostChildren(savePosts.data.postChildren);
          setFiles(savePosts.data.files);
          setUsers(savePosts.data.users);
        }
      } catch (error) {}
      setLoading(false);
    };
    getProfileData();
  }, [Object.values(profileDetails).length > 0, reValidate]);

  function handleLoadMore() {
    setBtnDisable(true);
    document.getElementById("load").innerHTML = "Loading...";
    setTimeout(() => {
      setLimit(limit + 10);
      document.getElementById("load").innerHTML = "Load More";
      setBtnDisable(false);
    }, 300);
  }

  return (
    <>
      <div className="feed-list">
        {posts.length > 0 &&
          posts.map((item, index) => {
            const child =
              item.children.length > 0 &&
              postChildren.filter((ch) => ch.parentPostId == item.postId);
            const user =
              users.length > 0 &&
              users.find((user) => user.userId == item.postedUserId);
            const favData =
              favList.length > 0 &&
              favList.find((fv) => fv.post_id == item.postId);
            const like = item.myReactions.includes("like");
            const love = item.myReactions.includes("love");
            const commentCount = item.commentsCount;
            if (index < limit) {
              if (item?.metadata?.isLink) {
                return (
                  <React.Fragment key={index}>
                    <LinkPost
                      user={user}
                      date={item.createdAt}
                      tick={true}
                      url={item.metadata.preview_json.url}
                      imgSrc={item.metadata.preview_json.images[0]}
                      postTitle={item.data.text}
                      linkTitle={item.metadata.preview_json.title}
                      linkDesc={item.metadata.preview_json.description}
                      commentCount={commentCount}
                      reactionsCount={
                        item.reactions?.like ? item.reactions.like : 0
                      }
                      loveReactionCount={
                        item.reactions?.love ? item.reactions.love : 0
                      }
                      like={like}
                      love={love}
                      postId={item.postId}
                      setLoading={setLoading}
                      type={"link"}
                      dontShow={false}
                    />
                  </React.Fragment>
                );
              }

              if (item?.metadata?.isArticle) {
                return (
                  <React.Fragment key={index}>
                    <LinkPost
                      user={user}
                      date={item.createdAt}
                      tick={true}
                      imgSrc={
                        Array.isArray(item.metadata.images) &&
                        item.metadata.images[0]?.imgLink
                      }
                      postTitle={item.data.text}
                      linkTitle={item.metadata.title}
                      linkDesc={item.metadata.subtitle}
                      commentCount={commentCount}
                      reactionsCount={
                        item.reactions?.like ? item.reactions.like : 0
                      }
                      loveReactionCount={
                        item.reactions?.love ? item.reactions.love : 0
                      }
                      like={like}
                      love={love}
                      postId={item.postId}
                      setLoading={setLoading}
                      type={"article"}
                      dontShow={false}
                    />
                  </React.Fragment>
                );
              }

              if (item?.metadata?.isEvent) {
                return (
                  <React.Fragment key={index}>
                    <LinkPost
                      user={user}
                      date={item.createdAt}
                      tick={true}
                      url={`/event-details/${item.metadata.preview_event_json.event_slug}/${item.metadata.preview_event_json.event_id}`}
                      imgSrc={
                        item.metadata.preview_event_json.hasPic
                          ? `${API_URL}uploads/events/500/${item.metadata.preview_event_json.image}`
                          : item.metadata.preview_event_json.image
                      }
                      postTitle={item.data.text}
                      linkTitle={item.metadata.preview_event_json.title}
                      linkDesc={item.metadata.preview_event_json.description}
                      commentCount={commentCount}
                      reactionsCount={
                        item.reactions?.like ? item.reactions.like : 0
                      }
                      loveReactionCount={
                        item.reactions?.love ? item.reactions.love : 0
                      }
                      like={like}
                      love={love}
                      postId={item.postId}
                      setLoading={setLoading}
                      type={"event"}
                      dontShow={false}
                    />
                  </React.Fragment>
                );
              }
            }
          })}
      </div>
      {posts && posts.length > 0 && posts.length > limit && (
        <div className="feed-action">
          <button
            type="button"
            className="btn-action"
            id="load"
            onClick={() => handleLoadMore()}
            disabled={btnDisable}
          >
            Load More
          </button>
        </div>
      )}

      <LoaderFull loaderActive={loading} />
    </>
  );
};

export default OtherProfilePosts;
