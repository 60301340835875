export default function UnfriendModal({
  id,
  name,
  removeReq,
  unfriend,
  connected,
}) {
  return (
    <div
      className="modal event-delete-modal fade"
      data-backdrop="static"
      id={"deleteModal" + id}
      tabIndex="-1"
      aria-labelledby="deleteModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered modal-sm">
        <div className="modal-content">
          <div className="modal-body">
            <div className="delete-content">
              <div className="icon">
                <i className="fa-solid fa-triangle-exclamation"></i>
              </div>
              <div className="text">
                <div className="main-title">
                  {connected === 1 ? "Remove request?" : `Unfriend?`}
                </div>
                {connected === 1 && (
                  <div className="des">
                    Are you sure you want to remove {name}'s connection request?
                  </div>
                )}

                {connected === 2 && (
                  <div className="des">
                    Are you sure you want to unfriend {name}?
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="modal-footer">
            {connected === 1 && (
              <button
                type="button"
                className="btn btn-close"
                data-dismiss="modal"
                onClick={() => removeReq(id)}
              >
                Confirm
              </button>
            )}
            {connected === 2 && (
              <button
                type="button"
                className="btn btn-close"
                data-dismiss="modal"
                onClick={() => unfriend(id)}
              >
                Confirm
              </button>
            )}
            <button
              type="button"
              className="btn btn-confirm"
              data-dismiss="modal"
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
