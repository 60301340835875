import { useEffect }from 'react';
import SecAllVideo from './Components/SecAllVideo';
import SecVideoBanner from './Components/SecVideoBanner';
import './Css/Video.css';

const Video = (props) => {
  useEffect(() => {
    document.title = props.title || "";
  }, [props.title]);
  
  return (
    <>
      <SecVideoBanner/>
      <SecAllVideo/>
    </>
  );
};

export default Video;