import { createSlice } from "@reduxjs/toolkit";
import { apiCallBegan } from "./api";
import BASE_URL from "../config/host";

export const categorySlice = createSlice({
  name: "categories",
  loading: false,
  moreloading: false,
  initialState: {
    categoryList: [],
    count: 0,
    loading: false,
  },
  reducers: {
    categoryRequested: (state, action) => {
      state.loading = true;
    },
    categoryRequestFailed: (state, action) => {
      state.loading = false;
      state.categoryList = "";
      //console.log(action.payload);
    },
    categoryListReceived: (state, action) => {
      state.loading = false;
      if (action.payload.count > 0) {
        state.categoryList = action.payload.data;
        state.count = action.payload.count;
      }
    },
  },
});

export const {
  categoryRequestFailed,
  categoryListReceived,
  categoryRequested,
} = categorySlice.actions;

export const fetchCategories = (body) =>
  apiCallBegan({
    url: "/category/list",
    method: "post",
    data: body,
    baseUrl: BASE_URL,
    onSuccess: categoryListReceived.type,
    onStart: categoryRequested.type,
    onError: categoryRequestFailed.type,
  });
