import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import Profile from './Components/Profile';
import './Css/ProfileLanding.css';
import LoaderFull from '../../components/LoaderFull';
import { useSelector } from 'react-redux';
const ProfileLanding = () => {
  const [toggleProfileForm, setToggleProfileForm] = useState(false);
  const { profileDetails,loading } = useSelector((state) => state.users);

   
  const togglePanel = event => {
    setToggleProfileForm(true);
  }
 
  
// console.log(profileDetails);
  return (
    <>
    {profileDetails && <>
      <section className={'sec-profile-landing ' + (toggleProfileForm ? 'inactive' : '')}>
        <div className='container'>
          <div className='profile-landing-content'>
            <h1 className='profile-landing-title'>HEY, {profileDetails?.first_name}!<br/>YOUR JOURNEY IS ABOUT TO BEGIN.</h1>
            <div className='profile-landing-actions'>
              <button type='button' className='btn-action' onClick={()=>togglePanel()}>Complete profile</button> 
              <Link to="/" className="btn-action alt">Maybe later</Link>
            </div>
          </div>
        </div>
      </section>
      {profileDetails !== undefined && <Profile profileData={profileDetails} toggleProfileForm={toggleProfileForm} setToggleProfileForm={setToggleProfileForm} />}
      
      </>
    }
      <LoaderFull loaderActive={loading}/> 
      </>
  );
};

export default ProfileLanding;