import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { fetchCategories } from "../../store/category";
import BASE_URL from "../../config/host";
import axios from "axios";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./Css/cms.css";

const SupportContact = () => {
  const dispatch = useDispatch();
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [errorMsg, setErrorMsg] = useState("");
  const [successMsg, setSuccessMsg] = useState("");
  const [message, setMessage] = useState("");
  const { categoryList } = useSelector((state) => state.categories);
  const [category, setCategory] = useState(null);
  const [count, setCount] = useState("");

  useEffect(() => {
    dispatch(fetchCategories({ category_type: "faqs" }));
  }, [dispatch]);

  function handleSubmit(e) {
    e.preventDefault();

    axios
      .post(BASE_URL + "add-contact", {
        name: name,
        contact_email: email,
        message: message,
        subject: "Contact",
        category_id: category,
      })
      .then(function (response) {
        if (response.data.status === 400) {
          setErrorMsg(response.data.message);
          // console.log("data==", response.data)
        }
        if (response.data.status === 200) {
          setSuccessMsg(response.data.message);
          setName("");
          setEmail("");
          setCategory("");
          setMessage("");
        }
      })
      .catch(function (error) {
        toast.error(error.message, { theme: "colored" });
      });
  }

  const handleKeyDown = (e) => {
    e.target.style.height = `40px`;
    e.target.style.height = `${e.target.scrollHeight + 10}px`;
  };

  const handleWordCount = (e) => {
    const charCount = e.target.value.length;
    setCount(charCount);
  };

  return (
    <>
      <section className="support-section">
        <div className="container">
          <div className="row justify-content-between">
            <div className="col-md-5">
              <div className="content">
                <div className="title">Need help?</div>
                <div className="description">
                  <p>
                    Fill in the form so that we can help you. The more
                    information, the better!
                  </p>
                </div>
                <div className="text">You can also access our FAQ here</div>
              </div>
            </div>
            <div className="col-md-6">
              <form className="form-content" id="registration-form">
                {errorMsg && <div class="error-msg">{errorMsg}</div>}
                {successMsg && <div class="success-msg">{successMsg}</div>}
                <div className="form-group">
                  <div className={"input-style " + (name ? "hasValue" : "")}>
                    <input
                      className="form-control"
                      name="name"
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                    />
                    <div className="float-label">Your name*</div>
                  </div>
                </div>
                <div className="form-group">
                  <div className={"input-style " + (email ? "hasValue" : "")}>
                    <input
                      className="form-control"
                      name="email"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                    />
                    <div className="float-label">Email address*</div>
                  </div>
                </div>
                <div className="form-group">
                  <div className={"input-style hasValue"}>
                    <div className="dropdown">
                      <button
                        type="button"
                        className="dropdown-toggle"
                        data-toggle="dropdown"
                        aria-expanded="false"
                      >
                        {category
                          ? categoryList.find((item) => item._id === category)
                              ?.name || ""
                          : "Select Category"}
                      </button>
                      <div className="dropdown-menu dropdown-menu-right">
                        {categoryList.length > 0 &&
                          categoryList.map((item) => {
                            return (
                              <button
                                type="button"
                                className="dropdown-item"
                                value={category}
                                key={item._id}
                                onClick={() => {
                                  setCategory(item._id);
                                }}
                              >
                                {item.name}
                              </button>
                            );
                          })}
                      </div>
                    </div>
                    <div className="float-label">Select category</div>
                  </div>
                </div>

                <div className="form-group">
                  <div
                    className={
                      "input-style input-style-textarea " +
                      (message ? "hasValue" : "")
                    }
                  >
                    <textarea
                      className="form-control"
                      name="about"
                      autoComplete="off"
                      value={message}
                      onChange={(e) => {
                        setMessage(e.target.value);
                        handleKeyDown(e);
                        handleWordCount(e);
                      }}
                      onPaste={handleKeyDown}
                      maxLength="300"
                    ></textarea>
                    <div className="input-count">{count} / 300</div>
                    <div className="float-label">Leave your message here</div>
                  </div>
                </div>
                <div className="form-action">
                  <button
                    className="btn-submit"
                    type="submit"
                    onClick={(e) => handleSubmit(e)}
                  >
                    Submit
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default SupportContact;
