import { createSlice } from "@reduxjs/toolkit";
import { apiCallBegan } from "./api";
import BASE_URL from "../config/host";
import axios from "axios";

export const getProfile = async (body) => {
  const res = await axios.post(BASE_URL + "/profile-details", body, {
    headers: {
      "Content-type": "application/json",
      Authorization: `Bearer ${localStorage.getItem("accesstoken")}`,
    },
  });
  if (res.data.status === 200) {
    return res.data.data;
  } else {
    localStorage.removeItem("accesstoken");
  }
};

export const getOtherProfile = async (body) => {
  const res = await axios.post(BASE_URL + "/profile-details", body, {
    headers: {
      "Content-type": "application/json",
      Authorization: `Bearer ${localStorage.getItem("accesstoken")}`,
    },
  });
  if (res.data.status === 200) {
    return res.data;
  } else {
    localStorage.removeItem("accesstoken");
  }
};

export const deleteDraftPost = async (body) => {
  const res = await axios.post(BASE_URL + "draft-post/delete", body, {
    headers: {
      "Content-type": "application/json",
      Authorization: `Bearer ${localStorage.getItem("accesstoken")}`,
    },
  });

  return res.data;
};

export const userLogin = async (body) => {
  const res = await axios.post(BASE_URL + "auth/login", body);
  return res.data;
};

export const getUsers = async (body) => {
  const res = await axios.post(BASE_URL + "user-search", body, {
    headers: {
      "Content-type": "application/json",
      Authorization: `Bearer ${localStorage.getItem("accesstoken")}`,
    },
  });
  return res.data;
};

export const addStep = async (body) => {
  const res = await axios.post(BASE_URL + "add-step", body, {
    headers: {
      "Content-type": "application/json",
      Authorization: `Bearer ${localStorage.getItem("accesstoken")}`,
    },
  });
  return res.data;
};

export const verifyToken = async (body) => {
  const res = await axios.post(BASE_URL + "verify-jwt", body, {
    headers: {
      "Content-type": "application/json",
      Authorization: `Bearer ${localStorage.getItem("accesstoken")}`,
    },
  });
  return res.data;
};

export const getAbout = async (body) => {
  const res = await axios.post(BASE_URL + "get-about", body, {
    headers: {
      "Content-type": "application/json",
      Authorization: `Bearer ${localStorage.getItem("accesstoken")}`,
    },
  });
  return res.data;
};

export const addUserTags = async (body) => {
  const res = await axios.post(BASE_URL + "profile-about", body, {
    headers: {
      "Content-type": "application/json",
      Authorization: `Bearer ${localStorage.getItem("accesstoken")}`,
    },
  });
  return res.data;
};

export const addTags = async (body) => {
  const res = await axios.post(BASE_URL + "/subject/add", body, {
    headers: {
      "Content-type": "application/json",
      Authorization: `Bearer ${localStorage.getItem("accesstoken")}`,
    },
  });
  return res.data.data;
};

export const acceptUser = async (body) => {
  const res = await axios.post(BASE_URL + "accept-connection", body, {
    headers: {
      "Content-type": "application/json",
      Authorization: `Bearer ${localStorage.getItem("accesstoken")}`,
    },
  });
  return res.data;
};

export const unfriendUser = async (body) => {
  const res = await axios.post(BASE_URL + "unfriend-connection", body, {
    headers: {
      "Content-type": "application/json",
      Authorization: `Bearer ${localStorage.getItem("accesstoken")}`,
    },
  });
  return res.data;
};

export const removeConnectionReq = async (body) => {
  const res = await axios.post(BASE_URL + "remove-connection", body, {
    headers: {
      "Content-type": "application/json",
      Authorization: `Bearer ${localStorage.getItem("accesstoken")}`,
    },
  });
  return res.data;
};

export const sendConnectReq = async (body) => {
  const res = await axios.post(BASE_URL + "send-connection", body, {
    headers: {
      "Content-type": "application/json",
      Authorization: `Bearer ${localStorage.getItem("accesstoken")}`,
    },
  });
  return res.data;
};

export const resendsendOtp = async (body) => {
  const res = await axios.post(BASE_URL + "auth/resend-otp", body, {
    headers: {
      "Content-type": "application/json",
    },
  });
  return res.data;
};

export const verifyOtp = async (body) => {
  const res = await axios.post(BASE_URL + "auth/verify-user", body, {
    headers: {
      "Content-type": "application/json",
    },
  });
  return res.data;
};

export const changePassword = async (body) => {
  const res = await axios.post(BASE_URL + "auth/change-password", body, {
    headers: {
      "Content-type": "application/json",
    },
  });
  return res.data;
};

export const userSlice = createSlice({
  name: "users",
  loading: false,
  discoverLoading: false,
  suggestionloading: false,
  initialState: {
    signUpdata: [],
    authData: [],
    profileData: [],
    updateprofileData: [],
    expprofileData: [],
    subjects: [],
    communityList: [],
    communityRequestList: [],
    eduprofileData: [],
    discoverList: [],
    experienceGetList: [],
    educationGetList: [],
    expEditList: [],
    eduEditList: [],
    draftEventList: [],
    draftPostList: [],
    profileDetails: {},
    profileDetailsStatus: false,
    suggestion: [],
  },
  reducers: {
    Request: (state) => {
      state.loading = true;
    },
    Failed: (state) => {
      state.loading = false;
      state.signUpdata = "";
    },
    Received: (state, action) => {
      state.loading = false;
      state.signUpdata = action.payload;
    },
    authRequest: (state) => {
      state.loading = true;
    },
    authFailed: (state) => {
      state.loading = false;
      state.authData = "";
    },
    authReceived: (state, action) => {
      state.loading = false;
      state.authData = action.payload;
    },
    profileUpdateRequest: (state) => {
      state.loading = true;
    },
    profileUpdateFailed: (state) => {
      state.loading = false;
      state.updateprofileData = "";
    },
    profileUpdateReceived: (state, action) => {
      state.loading = false;
      state.updateprofileData = action.payload.data;
    },
    profileExpRequest: (state) => {
      state.loading = true;
    },
    profileExpFailed: (state) => {
      state.loading = false;
      state.expprofileData = "";
    },
    profileExpReceived: (state, action) => {
      state.loading = false;
      state.expprofileData = action.payload.data;
    },
    profileEduRequest: (state) => {
      state.loading = true;
    },
    profileEduFailed: (state) => {
      state.loading = false;
      state.eduprofileData = "";
    },
    profileEduReceived: (state, action) => {
      state.loading = false;
      state.eduprofileData = action.payload.data;
    },
    stepRequest: (state) => {
      state.loading = true;
    },
    stepFailed: (state) => {
      state.loading = false;
      state.profileStep = "";
    },
    stepReceived: (state, action) => {
      state.loading = false;
      state.profileStep = action.payload.data;
    },
    subjectRequest: (state) => {
      state.loading = true;
    },
    subjectFailed: (state) => {
      state.loading = false;
      state.subjects = "";
    },
    subjectReceived: (state, action) => {
      state.loading = false;
      state.subjects = action.payload.data;
    },
    communityRequest: (state) => {
      state.communityList = [];
      state.loading = true;
    },
    communityFailed: (state) => {
      state.loading = false;
      state.communityList = {};
    },
    communityReceived: (state, action) => {
      state.loading = false;
      state.communityList = action.payload;
    },
    moreCommunityReceived: (state, action) => {
      state.loading = false;
      state.communityList = {
        ...state.communityList,
        ...action.payload,
        data: [...state.communityList.data, ...action.payload.data],
      };
    },
    communityRequestRequest: (state, action) => {
      state.communityRequestList = action.payload?.data
        ? action.payload.data
        : [];
      state.loading = true;
    },
    communityRequestFailed: (state) => {
      state.loading = false;
      state.communityRequestList = "";
    },
    communityRequestReceived: (state, action) => {
      state.loading = false;
      state.communityRequestList = action.payload;
    },
    discoverRequest: (state) => {
      state.discoverList = [];
      state.discoverLoading = true;
    },
    discoverFailed: (state) => {
      state.discoverLoading = false;
      state.discoverList = {};
    },
    discoverReceived: (state, action) => {
      state.discoverLoading = false;
      state.discoverList = action.payload;
    },
    moreDiscoverReceived: (state, action) => {
      state.loading = false;
      state.discoverList = {
        ...state.discoverList,
        ...action.payload,
        data: [...state.discoverList.data, ...action.payload.data],
      };
    },
    experienceGetRequest: (state) => {
      state.experienceGetList = [];
      state.loading = true;
    },
    experienceGetFailed: (state) => {
      state.loading = false;
      state.experienceGetList = "";
    },
    experienceGetReceived: (state, action) => {
      state.loading = false;
      state.experienceGetList = action.payload.data;
    },
    educationGetRequest: (state) => {
      state.educationGetList = [];
      state.loading = true;
    },
    educationGetFailed: (state) => {
      state.loading = false;
      state.educationGetList = "";
    },
    educationGetReceived: (state, action) => {
      state.loading = false;
      state.educationGetList = action.payload.data;
    },
    expEditRequest: (state) => {
      state.expEditList = [];
      state.loading = true;
    },
    expEditFailed: (state) => {
      state.loading = false;
      state.expEditList = "";
    },
    expEditReceived: (state, action) => {
      state.loading = false;
      state.expEditList = action.payload.data;
    },
    eduEditRequest: (state) => {
      state.eduEditList = [];
      state.loading = true;
    },
    eduEditFailed: (state) => {
      state.loading = false;
      state.eduEditList = "";
    },
    eduEditReceived: (state, action) => {
      state.loading = false;
      state.eduEditList = action.payload.data;
    },
    removeInterestRequest: (state) => {
      state.loading = true;
    },
    removeInterestFailed: (state) => {
      state.loading = false;
    },
    removeInterestReceived: (state) => {
      state.loading = false;
    },
    draftEventRequest: (state) => {
      state.draftEventList = [];
      state.loading = true;
    },
    draftEventFailed: (state) => {
      state.loading = false;
      state.draftEventList = "";
    },
    draftEventReceived: (state, action) => {
      state.loading = false;
      state.draftEventList = action.payload.data;
    },
    draftPostRequest: (state) => {
      state.draftPostList = [];
      state.loading = true;
    },
    draftPostFailed: (state) => {
      state.loading = false;
      state.draftPostList = "";
    },
    draftPostReceived: (state, action) => {
      state.loading = false;
      state.draftPostList = action.payload.data;
    },
    profileDetailsRequest: (state) => {
      state.profileDetails = state.profileDetails;
      state.loading = true;
      state.profileDetailsStatus = state.profileDetailsStatus;
    },
    profileDetailsFailed: (state) => {
      state.loading = false;
      state.profileDetails = "";
      state.profileDetailsStatus = false;
    },
    profileDetailsReceived: (state, action) => {
      state.loading = false;
      state.profileDetails = action.payload.data;
      state.profileDetailsStatus = action.payload.status;
    },
    suggestionRequest: (state) => {
      state.suggestion = [];
      state.suggestionloading = true;
    },
    suggestionFailed: (state) => {
      state.suggestionloading = false;
      state.suggestion = "";
    },
    suggestionReceived: (state, action) => {
      state.suggestionloading = false;
      state.suggestion = action.payload.data;
    },
  },
});

export const {
  Received,
  Request,
  Failed,
  authReceived,
  authRequest,
  authFailed,
  profileUpdateReceived,
  profileUpdateRequest,
  profileUpdateFailed,
  profileExpReceived,
  profileExpRequest,
  profileExpFailed,
  profileEduReceived,
  profileEduRequest,
  profileEduFailed,
  stepReceived,
  stepRequest,
  stepFailed,
  subjectReceived,
  subjectRequest,
  subjectFailed,
  communityReceived,
  communityRequest,
  communityFailed,
  communityRequestReceived,
  communityRequestRequest,
  communityRequestFailed,
  discoverReceived,
  discoverRequest,
  discoverFailed,
  experienceGetRequest,
  experienceGetFailed,
  experienceGetReceived,
  educationGetRequest,
  educationGetFailed,
  educationGetReceived,
  expEditRequest,
  expEditFailed,
  expEditReceived,
  eduEditRequest,
  eduEditFailed,
  eduEditReceived,
  removeInterestReceived,
  removeInterestRequest,
  removeInterestFailed,
  draftEventRequest,
  draftEventFailed,
  draftEventReceived,
  draftPostRequest,
  draftPostFailed,
  draftPostReceived,
  profileDetailsReceived,
  profileDetailsRequest,
  profileDetailsFailed,
  suggestionReceived,
  suggestionRequest,
  suggestionFailed,
  moreCommunityReceived,
  moreDiscoverReceived,
} = userSlice.actions;

export const userSignup = (body) =>
  apiCallBegan({
    url: "/signup",
    method: "post",
    data: body,
    baseUrl: BASE_URL,
    onSuccess: Received.type,
    onStart: Request.type,
    onError: Failed.type,
  });

export const authUser = (body) =>
  apiCallBegan({
    url: "/auth/verify",
    method: "post",
    data: body,
    baseUrl: BASE_URL,
    onSuccess: authReceived.type,
    onStart: authRequest.type,
    onError: authFailed.type,
  });

export const updateProfile = (body) =>
  apiCallBegan({
    url: "/profile-update",
    method: "post",
    data: body,
    baseUrl: BASE_URL,
    onSuccess: profileUpdateReceived.type,
    onStart: profileUpdateRequest.type,
    onError: profileUpdateFailed.type,
  });

export const updateExperience = (body) =>
  apiCallBegan({
    url: "/public-profile/experience-add",
    method: "post",
    data: body,
    baseUrl: BASE_URL,
    onSuccess: profileExpReceived.type,
    onStart: profileExpRequest.type,
    onError: profileExpFailed.type,
  });

export const updateEducation = (body) =>
  apiCallBegan({
    url: "/public-profile/education-add",
    method: "post",
    data: body,
    baseUrl: BASE_URL,
    onSuccess: profileEduReceived.type,
    onStart: profileEduRequest.type,
    onError: profileEduFailed.type,
  });

export const getSubject = (body) =>
  apiCallBegan({
    url: "/subjects",
    method: "post",
    data: body,
    baseUrl: BASE_URL,
    onSuccess: subjectReceived.type,
    onStart: subjectRequest.type,
    onError: subjectFailed.type,
  });

export const userStep = (body) =>
  apiCallBegan({
    url: "/get-step",
    method: "post",
    data: body,
    baseUrl: BASE_URL,
    onSuccess: stepReceived.type,
    onStart: stepRequest.type,
    onError: stepFailed.type,
  });

export const getCommunity = (body) =>
  apiCallBegan({
    url: "/connection-list",
    method: "post",
    data: body,
    baseUrl: BASE_URL,
    onSuccess: communityReceived.type,
    onStart: communityRequest.type,
    onError: communityFailed.type,
  });

export const getMoreCommunity = (body) =>
  apiCallBegan({
    url: "/connection-list",
    method: "post",
    data: body,
    baseUrl: BASE_URL,
    onSuccess: moreCommunityReceived.type,
  });

export const getCommunityRequest = (body) =>
  apiCallBegan({
    url: "/connection-list",
    method: "post",
    data: body,
    baseUrl: BASE_URL,
    onSuccess: communityRequestReceived.type,
    onStart: communityRequestRequest.type,
    onError: communityRequestFailed.type,
  });

export const getDiscover = (body) =>
  apiCallBegan({
    url: "/discover-connection",
    method: "post",
    data: body,
    baseUrl: BASE_URL,
    onSuccess: discoverReceived.type,
    onStart: discoverRequest.type,
    onError: discoverFailed.type,
  });

export const getMoreDiscover = (body) =>
  apiCallBegan({
    url: "/discover-connection",
    method: "post",
    data: body,
    baseUrl: BASE_URL,
    onSuccess: moreDiscoverReceived.type,
  });

export const getExperience = (body) =>
  apiCallBegan({
    url: "/public-profile/experience-get-data",
    method: "post",
    data: body,
    baseUrl: BASE_URL,
    onSuccess: experienceGetReceived.type,
    onStart: experienceGetRequest.type,
    onError: experienceGetFailed.type,
  });

export const getEducation = (body) =>
  apiCallBegan({
    url: "/public-profile/education-get-data",
    method: "post",
    data: body,
    baseUrl: BASE_URL,
    onSuccess: educationGetReceived.type,
    onStart: educationGetRequest.type,
    onError: educationGetFailed.type,
  });

export const editExperience = (body) =>
  apiCallBegan({
    url: "/public-profile/experience-edit",
    method: "post",
    data: body,
    baseUrl: BASE_URL,
    onSuccess: expEditReceived.type,
    onStart: expEditRequest.type,
    onError: expEditFailed.type,
  });

export const editEducation = (body) =>
  apiCallBegan({
    url: "/public-profile/education-edit",
    method: "post",
    data: body,
    baseUrl: BASE_URL,
    onSuccess: eduEditReceived.type,
    onStart: eduEditRequest.type,
    onError: eduEditFailed.type,
  });

export const removeInterest = (body) =>
  apiCallBegan({
    url: "/profile-about/remove-interest",
    method: "post",
    data: body,
    baseUrl: BASE_URL,
    onSuccess: removeInterestReceived.type,
    onStart: removeInterestRequest.type,
    onError: removeInterestFailed.type,
  });

export const delEducation = (body) =>
  apiCallBegan({
    url: "/public-profile/education-delete",
    method: "post",
    data: body,
    baseUrl: BASE_URL,
  });

export const deldraftPost = (body) => {
  apiCallBegan({
    url: "/draft-post/delete",
    method: "post",
    data: body,
    baseUrl: BASE_URL,
  });
};

export const delExperience = (body) =>
  apiCallBegan({
    url: "/public-profile/experience-delete",
    method: "post",
    data: body,
    baseUrl: BASE_URL,
  });

export const getDraftEvent = (body) =>
  apiCallBegan({
    url: "/event/list",
    method: "post",
    data: body,
    baseUrl: BASE_URL,
    onSuccess: draftEventReceived.type,
    onStart: draftEventRequest.type,
    onError: draftEventFailed.type,
  });

export const getDraftPost = (body) =>
  apiCallBegan({
    url: "/draft-post/list",
    method: "post",
    data: body,
    baseUrl: BASE_URL,
    onSuccess: draftPostReceived.type,
    onStart: draftPostRequest.type,
    onError: draftPostFailed.type,
  });

export const getProfileDetails = (body) =>
  apiCallBegan({
    url: "/profile-details",
    method: "post",
    data: body,
    baseUrl: BASE_URL,
    onSuccess: profileDetailsReceived.type,
    onStart: profileDetailsRequest.type,
    onError: profileDetailsFailed.type,
  });

export const getSuggestion = (body) =>
  apiCallBegan({
    url: "/discover-interest-connection",
    method: "post",
    data: body,
    baseUrl: BASE_URL,
    onSuccess: suggestionReceived.type,
    onStart: suggestionRequest.type,
    onError: suggestionFailed.type,
  });
