import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import LoaderFull from "../../../components/LoaderFull";
import NotificationLoader from "../../../components/NotificationLoader";
import { addNotification } from "../../../store/notification";
import { getSuggestion, sendConnectReq } from "../../../store/user";

const FeedFollows = () => {
  const { suggestion, suggestionloading } = useSelector((state) => state.users);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getSuggestion({}));
  }, []);

  const { profileDetails } = useSelector((state) => state.users);
  const [disabled, setDisabled] = useState([]);
  const [loading, setLoading] = useState(false);

  async function sendReq(receiver_user_id) {
    try {
      setLoading(true);

      await sendConnectReq({ receiver_user_id: receiver_user_id });

      document.getElementById("connectBtn-" + receiver_user_id).innerHTML =
        "Sent!";
      setDisabled([...disabled, receiver_user_id]);
      await addNotification({
        source_id: profileDetails._id,
        sender_id: profileDetails._id,
        receiver_id: receiver_user_id,
        source_type: "connection",
        content: "{{userName}} send you a connection request",
      }).then(() => {
        setLoading(false);
      });
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  }

  return (
    <>
      <div className="follows">
        <h2 className="follows-title">People to follow</h2>
        {suggestionloading ? (
          <NotificationLoader />
        ) : (
          <div className="follows-list">
            {suggestion.length > 0
              ? suggestion.map((item, index) => (
                  <div className="follows-item" key={index}>
                    <div className="item-inner">
                      <Link
                        to={/profile/ + item.user_slug}
                        className="item-image"
                      >
                        <img src={item.image} alt="" />
                      </Link>

                      <Link
                        to={/profile/ + item.user_slug}
                        className="item-content"
                      >
                        <h4 className="item-name">{item.name}</h4>
                        <div className="item-designation">{item.headline}</div>
                      </Link>
                      <div className="item-action">
                        <button
                          type="button"
                          className="btn-action"
                          id={"connectBtn-" + item._id}
                          onClick={() => {
                            sendReq(item._id);
                          }}
                          disabled={loading || disabled?.includes(item._id)}
                        >
                          Connect
                        </button>
                      </div>
                    </div>
                  </div>
                ))
              : "No Data"}
          </div>
        )}
      </div>
      {loading && <LoaderFull />}
    </>
  );
};

export default FeedFollows;
