import React from 'react'
import { useState } from 'react';
import { useEffect } from 'react'
import { fetchTracks } from '../../../store/track';
import { Link } from 'react-router-dom';

function PodcastItem({item}) {
    const [trackList, setTrackList] = useState([]);
    useEffect(()=>{
        const loadtracks = async() => {
            try {
              setTrackList([]);
              if(item?.playlist_id !== undefined){
                const  list = await fetchTracks({playlist_id:item?.playlist_id});
                const filter = list.filter((item)=>item.track !== null);
                console.log(filter);
                setTrackList(filter);
              }
             //const featured =  props.featured?props.featured:false;
              
              } catch (error) {
               
              }
           }
           loadtracks();
    },[item])

    return (
        <><div className="search-podcast-list">
        
        {trackList.length>0 && trackList.map((it,index)=>{
              return(<>
              <div className='search-podcast-item'>
              <Link to={"/podcast/"+it?.track?.id} className="item-inner">
                <div className='item-thumbnail'><img src={it?.track?.album?.images[0].url} alt=''/></div>
                <div className='item-content'>
                  <div className='item-title'>{it.track?.name}</div>
                </div>
              </Link>
            </div></>);
        })}
      
      </div></>
    )
}

export default PodcastItem
