import ContentLoader from "react-content-loader";

const SkeletonLoader = (props) => (
  <>
    <div className={"loader-item mt-3"}>
      <div className="loader-item-inner">
        <ContentLoader
          speed={2}
          width={1050}
          height={160}
          viewBox="0 0 1050 160"
          backgroundColor="#252525"
          foregroundColor="#393939"
          {...props}
        >
          <rect x="49" y="8" rx="3" ry="3" width="98" height="6" />
          <rect x="49" y="26" rx="3" ry="3" width="52" height="6" />
          <rect x="0" y="66" rx="3" ry="3" width="580" height="6" />
          <rect x="0" y="82" rx="3" ry="3" width="550" height="6" />
          <rect x="0" y="98" rx="3" ry="3" width="520" height="6" />
          <rect x="0" y="114" rx="3" ry="3" width="450" height="6" />
          <rect x="0" y="114" rx="3" ry="3" width="400" height="6" />
          <rect x="0" y="130" rx="3" ry="3" width="380" height="6" />
          <rect x="0" y="146" rx="3" ry="3" width="350" height="6" />
          <circle cx="21" cy="21" r="21" />
        </ContentLoader>
      </div>
    </div>

    <div className={"loader-item"}>
      <div className="loader-item-inner">
        <ContentLoader
          speed={2}
          width={1050}
          height={160}
          viewBox="0 0 1050 160"
          backgroundColor="#252525"
          foregroundColor="#393939"
          {...props}
        >
          <rect x="49" y="8" rx="3" ry="3" width="98" height="6" />
          <rect x="49" y="26" rx="3" ry="3" width="52" height="6" />
          <rect x="0" y="66" rx="3" ry="3" width="580" height="6" />
          <rect x="0" y="82" rx="3" ry="3" width="550" height="6" />
          <rect x="0" y="98" rx="3" ry="3" width="520" height="6" />
          <rect x="0" y="114" rx="3" ry="3" width="450" height="6" />
          <rect x="0" y="114" rx="3" ry="3" width="400" height="6" />
          <rect x="0" y="130" rx="3" ry="3" width="380" height="6" />
          <rect x="0" y="146" rx="3" ry="3" width="350" height="6" />
          <circle cx="21" cy="21" r="21" />
        </ContentLoader>
      </div>
    </div>

    <div className={"loader-item"}>
      <div className="loader-item-inner">
        <ContentLoader
          speed={2}
          width={1050}
          height={160}
          viewBox="0 0 1050 160"
          backgroundColor="#252525"
          foregroundColor="#393939"
          {...props}
        >
          <rect x="49" y="8" rx="3" ry="3" width="98" height="6" />
          <rect x="49" y="26" rx="3" ry="3" width="52" height="6" />
          <rect x="0" y="66" rx="3" ry="3" width="580" height="6" />
          <rect x="0" y="82" rx="3" ry="3" width="550" height="6" />
          <rect x="0" y="98" rx="3" ry="3" width="520" height="6" />
          <rect x="0" y="114" rx="3" ry="3" width="450" height="6" />
          <rect x="0" y="114" rx="3" ry="3" width="400" height="6" />
          <rect x="0" y="130" rx="3" ry="3" width="380" height="6" />
          <rect x="0" y="146" rx="3" ry="3" width="350" height="6" />
          <circle cx="21" cy="21" r="21" />
        </ContentLoader>
      </div>
    </div>
  </>
);

export default SkeletonLoader;
