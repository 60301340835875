import SecAllPodcast from './Components/SecAllPodcast';
import SecPodcastBanner from './Components/SecPodcastBanner';
import './Css/Podcast.css';

export default function Podcast() {
  document.title = "CEO | Podcast"
  return (
    <>
      <SecPodcastBanner/>
      <SecAllPodcast/>
    </>
  );
};
