import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

const ProfileFormFinalize = () => {
  const [show, setShow] = useState(false);
  const [progressPercentage, setProgressPercentage] = useState(0);

  const step = 1
  const interval = 50
  const maxProgress = 50

  useEffect(() => {
    const updateProgress = () => setProgressPercentage(progressPercentage + step)
    if (progressPercentage < maxProgress) {
      setTimeout(updateProgress, interval)
    }else{
      let timer = setTimeout(() => setShow(true), 200);
      return () => {
        clearTimeout(timer);
      };
    }
  }, [progressPercentage]);

  return (
    <>
      <div className='profile-forms'>
        <div className='container'>
          <div className='row'>
            <div className='col-lg-4'>
              { show ?(
              <div className='profile-forms-content'>
                <div className='profile-forms-icon'><i className='fa-light fa-fw fa-check'></i></div>
                <h1 className='profile-forms-title'>All ready here!</h1>
                <div className='profile-action'>
                  <Link to='/' className='btn-action'>Lets go</Link> 
                </div>
              </div>
              ):(
              <div className='profile-forms-content'>
                <h1 className='profile-forms-title'>Creating your profile...</h1>
                <div className='profile-finalize'>
                  <div className='profile-progress'>
                    <div className='profile-progress-bar' style={{width: progressPercentage + '%'}}></div>
                  </div>
                </div>
              </div>
              ) }
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ProfileFormFinalize;