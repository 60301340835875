import React, { useState, useEffect, useCallback, useContext } from "react";
import { useLocation, NavLink, useNavigate, Link } from "react-router-dom";
import Authentication from "./Authentication";
import { useSelector, useDispatch } from "react-redux";
import { getSettings } from "../store/settings";
import { getProfileDetails } from "../store/user";
import { getNotifications } from "../store/notification";
import { SocketContext } from "../context/socket";
import { updateNotification } from "../store/notification";
import moment from "moment";
import API_URL from "../config/apiHost";
const DefaultHeader = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const [authenticationActive, setAuthenticationActive] = useState(false);
  const [notifications, setNotifications] = useState([]);
  const [limit] = useState(10);
  const [offset, setoffset] = useState(10);
  const [count, setCount] = useState(0);
  const [unseencount, setUnseenCount] = useState(0);
  const [loadingNoti, setLoading] = useState(false);
  const [navigationActive, setNavigationActive] = useState(false);
  const socket = useContext(SocketContext);
  const { settingData, loading } = useSelector((state) => state.settings);
  const { profileDetails } = useSelector((state) => state.users);
  const token = localStorage.getItem("accesstoken");

  useEffect(() => {
    dispatch(getSettings());
    dispatch(getProfileDetails({}));
    const getNotify = async () => {
      try {
        const notification = await getNotifications({ limit: limit });
        setNotifications(notification?.data);
        setCount(notification?.totalcount);
        setUnseenCount(notification?.unseenCount);
      } catch (error) {
        console.log(error);
      }
    };

    getNotify();
  }, [dispatch]);

  const day = moment(notifications?.created_at).format("D");
  const today = moment().format("D");

  const signout = () => {
    localStorage.removeItem("accesstoken");
    window.location.href = "/";
  };

  const webhookEventResponse = useCallback((data) => {
    const getNotifySocket = async () => {
      const notification = await getNotifications();
      const sortNotification = notification.data.sort();
      setNotifications(sortNotification);
    };

    getNotifySocket();
  }, []);

  useEffect(() => {
    socket.on("webhookEventResponse", webhookEventResponse);
    return () => {
      socket.off("webhookEventResponse", webhookEventResponse);
    };
  }, [webhookEventResponse, socket]);

  async function handleLinkClick(id) {
    await updateNotification({ id: id, seen: true }).then(() => {});
  }

  const onScroll = async (e) => {
    const window = e.target;
    if (
      Math.ceil(window.scrollTop + window.clientHeight) >=
        window.scrollHeight &&
      token !== ""
    ) {
      setLoading(true);
      if (!loadingNoti) {
        const list = await getNotifications({
          limit: limit,
          offset: offset,
        });
        setoffset((prev) => prev + limit);

        setNotifications([...notifications, ...list.data]);
        setLoading(false);
      }
    }
  };

  return (
    <>
      <Authentication
        authenticationActive={authenticationActive}
        setAuthenticationActive={setAuthenticationActive}
      />
      <header>
        <div className="container-fluid">
          <div className="brand">
            {!loading && (
              <Link className="logo" to="/">
                <img
                  src={
                    settingData?.logo
                      ? settingData?.logo
                      : "assets/img/logo.svg"
                  }
                  alt="CEO Logo"
                />
              </Link>
            )}
          </div>

          {location.pathname !== "/" && (
            <div className="header-responsive-title">{document.title}</div>
          )}

          {location.pathname !== "/profile-landing" &&
            location.pathname !== "/membership" && (
              <>
                <div
                  className={"navigation " + (navigationActive ? "active" : "")}
                >
                  <div
                    className="panel-close"
                    onClick={() => setNavigationActive(!navigationActive)}
                  >
                    <i className="fa-light fa-xmark"></i>
                  </div>
                  <div className="nav-menu">
                    <div className="nav-item">
                      <NavLink
                        to="/"
                        className={({ isActive }) =>
                          isActive ? "nav-link active" : "nav-link"
                        }
                        onClick={() => setNavigationActive(!navigationActive)}
                      >
                        Home
                      </NavLink>
                    </div>
                    {token && (
                      <div className="nav-item">
                        <NavLink
                          to="/feed"
                          className={({ isActive }) =>
                            isActive ? "nav-link active" : "nav-link"
                          }
                          onClick={() => setNavigationActive(!navigationActive)}
                        >
                          Feed
                        </NavLink>
                      </div>
                    )}
                    <div className="nav-item">
                      <NavLink
                        to="/video"
                        className={({ isActive }) =>
                          isActive ? "nav-link active" : "nav-link"
                        }
                        onClick={() => setNavigationActive(!navigationActive)}
                      >
                        Videos
                      </NavLink>
                    </div>
                    <div className="nav-item">
                      <NavLink
                        to="/podcast"
                        className={({ isActive }) =>
                          isActive ? "nav-link active" : "nav-link"
                        }
                        onClick={() => setNavigationActive(!navigationActive)}
                      >
                        Podcast
                      </NavLink>
                    </div>
                    <div className="nav-item">
                      <NavLink
                        to="/stories"
                        className={({ isActive }) =>
                          isActive ? "nav-link active" : "nav-link"
                        }
                        onClick={() => setNavigationActive(!navigationActive)}
                      >
                        Stories
                      </NavLink>
                    </div>
                    <div className="nav-item">
                      <NavLink
                        to="/schedule"
                        className={({ isActive }) =>
                          isActive ? "nav-link active" : "nav-link"
                        }
                        onClick={() => setNavigationActive(!navigationActive)}
                      >
                        Schedule
                      </NavLink>
                    </div>
                    {token && (
                      <div className="nav-item">
                        <NavLink
                          to="/community"
                          className={({ isActive }) =>
                            isActive ? "nav-link active" : "nav-link"
                          }
                          onClick={() => setNavigationActive(!navigationActive)}
                        >
                          Community
                        </NavLink>
                      </div>
                    )}
                    <div className="nav-item">
                      <NavLink
                        to="/search"
                        className={({ isActive }) =>
                          isActive ? "nav-link active" : "nav-link"
                        }
                      >
                        <i className="fa-regular fa-fw fa-search"></i>
                      </NavLink>
                    </div>

                    {!token && (
                      <div className="responsive-action">
                        <button
                          type="button"
                          className="btn-action btn-login"
                          onClick={() => setAuthenticationActive(true)}
                        >
                          Login
                        </button>

                        <button
                          type="button"
                          className="btn-action btn-membership"
                          onClick={() => navigate("/membership")}
                        >
                          Membership
                        </button>
                      </div>
                    )}
                    {token && (
                      <div className="profile-responsive">
                        <div className="user-profile">
                          {token && profileDetails && (
                            <Link to="/profile" className="user-image">
                              <img
                                src={
                                  profileDetails?.image
                                    ? profileDetails?.image
                                    : API_URL + "./assets/img/no-img-user.png"
                                }
                                alt=""
                                id="headerImg"
                              />
                            </Link>
                          )}
                          <div className="user-data">
                            <div className="user-name">
                              {profileDetails?.name}
                            </div>
                            <div className="user-type">My Account</div>
                          </div>
                        </div>
                        <div className="logout-action">
                          <button
                            type="button"
                            className="btn-logout"
                            onClick={() => signout()}
                          >
                            Log out
                          </button>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </>
            )}
          {!token && (
            <div
              className={
                "header-options" +
                (location.pathname === "/membership" ? " membership" : "")
              }
            >
              <button
                type="button"
                className="btn-action btn-login"
                onClick={() => setAuthenticationActive(true)}
              >
                Login
              </button>
              <button
                type="button"
                className="btn-action btn-membership"
                onClick={() => navigate("/membership")}
              >
                Membership
              </button>
            </div>
          )}

          {token && (
            <div className="notification dropdown mr-5">
              <button className="dropdown-toggle" data-toggle="dropdown">
                {unseencount > 0 && <span className="indicator">{unseencount > 100 ? "99+" : unseencount }</span>}
                <i className="fa-light fa-fw fa-bell"></i>
              </button>
              <div className="dropdown-menu dropdown-menu-right">
                <div className="dropdown-header">
                  <div className="dropdown-title">Notifications</div>
                </div>
                <div
                  className="dropdown-body scrollbar"
                  onScroll={(e) => count > offset && onScroll(e)}
                >
                  <div className="notification-list">
                    {Array.isArray(notifications) &&
                      notifications.length !== 0 &&
                      notifications.map((item, index) => {
                        let sender_image =
                          item.sender_info && item.sender_info?.length > 0
                            ? item?.sender_info[0]?.image
                            : "";
                        let sender_name =
                          item.sender_info && item.sender_info?.length > 0
                            ? item?.sender_info[0]?.name
                            : "";
                        let replace = "";
                        if (item.source_type === "event") {
                          let eventName =
                            item?.event_info && item?.event_info?.length > 0
                              ? item?.event_info[0]?.title.length > 30 ? item?.event_info[0]?.title.substring(0, 30) + "..." : item?.event_info[0]?.title
                              : "";

                          replace = item.content
                            .replace(/{{userName}}/i, sender_name)
                            .replace(/{{eventName}}/i, eventName);
                        } else {
                          replace = item.content.replace(
                            /{{userName}}/i,
                            sender_name
                          );
                        }

                        return (
                          <React.Fragment key={index}>
                            <div>
                              {item.source_type === "event" ? (
                                <Link
                                  to={`/event-details/${item?.event_info[0]?.slug}/${item?.event_info[0]?.event_id}`}
                                  className={
                                    item?.seen === false
                                      ? "notification-item unread"
                                      : "notification-item"
                                  }
                                  onClick={() =>
                                    handleLinkClick(item?._id, item.source_id)
                                  }
                                >
                                  <div className="notification-image">
                                    <img src={sender_image} alt="" />
                                  </div>
                                  <div className="notification-content">
                                    <div className="title">{replace}</div>
                                    <div className="time">
                                      {today === day
                                        ? moment(item?.created_at).fromNow()
                                        : moment(item?.created_at).format(
                                            "Do MMMM YYYY, h:mm A"
                                          )}
                                    </div>
                                  </div>
                                </Link>
                              ) : item.source_type === "connection" ? (
                                <Link
                                  to="/connections"
                                  className={
                                    item?.seen === false
                                      ? "notification-item unread"
                                      : "notification-item"
                                  }
                                  onClick={() =>
                                    handleLinkClick(item?._id, item.source_id)
                                  }
                                >
                                  <div className="notification-image">
                                    <img src={sender_image} alt="" />
                                  </div>
                                  <div className="notification-content">
                                    <div className="title">{replace}</div>
                                    <div className="time">
                                      {today === day
                                        ? moment(item?.created_at).fromNow()
                                        : moment(item?.created_at).format(
                                            "Do MMMM YYYY, h:mm A"
                                          )}
                                    </div>
                                  </div>
                                </Link>
                              ) : (
                                <Link
                                  to={`/post/${item.source_id}`}
                                  className={
                                    item?.seen === false
                                      ? "notification-item unread"
                                      : "notification-item"
                                  }
                                  onClick={() =>
                                    handleLinkClick(item?._id, item.source_id)
                                  }
                                >
                                  <div className="notification-image">
                                    <img src={sender_image} alt="" />
                                  </div>
                                  <div className="notification-content">
                                    <div className="title">{replace}</div>
                                    <div className="time">
                                      {today === day
                                        ? moment(item?.created_at).fromNow()
                                        : moment(item?.created_at).format(
                                            "Do MMMM YYYY, h:mm A"
                                          )}
                                    </div>
                                  </div>
                                </Link>
                              )}
                            </div>
                          </React.Fragment>
                        );
                      })}
                  </div>
                </div>
                <div className="text-center">
                  {loadingNoti && (
                    <div
                      className="fad fa-spinner-third fa-spin"
                      style={{
                        color: "violet",
                        fontSize: "1.9em",
                        animationDuration: "1s",
                      }}
                    ></div>
                  )}
                </div>
              </div>
            </div>
          )}

          {location.pathname !== "/profile-landing" &&
            location.pathname !== "/membership" && (
              <>
                <div
                  className="btn-menu-responsive"
                  onClick={() => setNavigationActive(!navigationActive)}
                >
                  <div className="line-1"></div>
                  <div className="line-2"></div>
                </div>
              </>
            )}
          {token && profileDetails && profileDetails._id !== undefined && (
            <div className="header-options dropdown">
              <div
                className="user-account dropdown-toggle"
                data-toggle="dropdown"
                aria-expanded="false"
              >
                {token && profileDetails && profileDetails?.image && (
                  <Link to="/profile" className="user-image">
                    <img
                      src={profileDetails?.image ? profileDetails?.image : ""}
                      alt=""
                      id="headerImg"
                    />
                  </Link>
                )}
              </div>

              <div className="dropdown-menu dropdown-menu-right">
                <div className="profile-user">
                  <div className="user-image">
                    <img src={profileDetails?.image} alt="" />
                  </div>
                  <div className="user-data">
                    <div className="user-name">{profileDetails?.name}</div>
                    <div className="user-type">Premium Member</div>
                  </div>
                </div>
                <Link className="dropdown-item" to="/profile">
                  My panel
                </Link>
                <Link className="dropdown-item" to="/messages">
                  Messages
                </Link>
                <Link className="dropdown-item" to="/saved-content">
                  Saved Content
                </Link>
                <Link className="dropdown-item" to="/about">
                  About Me
                </Link>
                <div className="action">
                  <button
                    type="button"
                    className="user-action"
                    onClick={() => signout()}
                  >
                    Sign out
                  </button>
                </div>
              </div>
            </div>
          )}
        </div>
      </header>
    </>
  );
};

export default DefaultHeader;
