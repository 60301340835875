import { useNavigate } from 'react-router-dom';
const ProfileFilter = ({ notification }) => {
  const navigate = useNavigate();
  const filterRadio = (e) => {
    navigate('/'+e.target.value);
  }

  return (
    <>
      <div className="profile-filter-section">
        <div className="filter">
          <input type="radio" name='filter' value='posts' className='filter-radio' checked={window.location.pathname.split("/")[1] === 'profile' || window.location.pathname.split("/")[1] === 'posts'} onChange={(e) => filterRadio(e)} />
          <div className='filter-label'>Your posts</div>
        </div>
        <div className="filter">
          <input type="radio" name='filter' value='connections' className='filter-radio' checked={window.location.pathname.split("/")[1] === 'connections'} onChange={(e) => filterRadio(e)} />
          <div className='filter-label'>Connections{notification ? <span style={{ height: '0.5em',width: '0.5em', backgroundColor: "#ce2c2c", borderRadius: '50%', marginLeft: '7px'}}></span> : ''}</div>
          
        </div>
        <div className="filter">
          <input type="radio" name='filter' value='messages' className='filter-radio' checked={window.location.pathname.split("/")[1] === 'messages'} onChange={(e) => filterRadio(e)} />
          <div className='filter-label'>Messages</div>
        </div>
        <div className="filter">
          <input type="radio" name='filter' value='events' className='filter-radio' checked={window.location.pathname.split("/")[1] === 'events'} onChange={(e) => filterRadio(e)} />
          <div className='filter-label'>Events</div>
        </div>
        <div className="filter">
          <input type="radio" name='filter' value='about' className='filter-radio' checked={window.location.pathname.split("/")[1] === 'about'} onChange={(e) => filterRadio(e)} />
          <div className='filter-label'>About</div>
        </div>

        <div className="filter">
          <input type="radio" name='filter' value='saved-content' className='filter-radio' checked={window.location.pathname.split("/")[1] === 'saved-content'} onChange={(e) => filterRadio(e)} />
          <div className='filter-label'>Saved Content</div>
        </div>
        <div className="filter">
          <input type="radio" name='filter' value='drafts' className='filter-radio' checked={window.location.pathname.split("/")[1] === 'drafts'} onChange={(e) => filterRadio(e)} />
          <div className='filter-label'>Drafts</div>
        </div>
      </div>
    </>
  );
};

export default ProfileFilter;