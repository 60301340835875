import React from "react";

function FilterChat({keyword,setKeyword}) {
  return (
    <>
      <div className="search-input">
        <input className="form-control" value={keyword} type="text" placeholder="Search" onChange={(e)=>setKeyword(e.target.value)}/>
      </div>
    </>
  );
}

export default FilterChat;
