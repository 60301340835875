import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import SecHomeStoriesItem from "./SecHomeStoriesItem";
import { featuredBlogs, fetchBlogs } from "../../../store/blog";
import Fade from "react-reveal/Fade";

const SecHomeStories = () => {
  const dispatch = useDispatch();
  const { bloglist } = useSelector((state) => state.blogs);
  const { featuredbloglist } = useSelector((state) => state.blogs);
  const { settingData } = useSelector((state) => state.settings);

  const { homeContentdata } = useSelector((state) => state.pages);

  useEffect(() => {
    if (settingData && settingData?.ghost_api !== undefined) {
      dispatch(
        featuredBlogs({
          limit: 1,
          featured: true
        })
      );
      dispatch(
        fetchBlogs({
          limit: 4,
          featured: false,
        })
      );
    }
  }, [dispatch, settingData]);

  return (
    <>
      <section className="home-stories">
        <div className="container">
          <div className="stories-content">
            <Fade bottom>
              <h2 className="stories-content-title">
                {homeContentdata?.home_stories?.title}
              </h2>
            </Fade>
            <Fade>
              <Link
                to={"/story/" + featuredbloglist.length > 0 ? featuredbloglist[0]?.id : bloglist.length > 0 ? bloglist[0]?.id : ""}
                className="stories-highlight"
              >
                <div className="stories-image">
                  <img src={featuredbloglist.length > 0 ? featuredbloglist[0]?.feature_image : bloglist.length > 0 ? bloglist[0]?.feature_image : ""} alt="" />
                </div>
                <div className="stories-details">
                  <h3 className="stories-title">
                    {featuredbloglist.length > 0 ? featuredbloglist[0]?.title : bloglist.length > 0 ? bloglist[0]?.title : ""}
                  </h3>
                </div>
              </Link>
            </Fade>
          </div>
          <Fade right cascade>
            <div className="stories-list">
              {bloglist.length > 0 &&
                bloglist.map((item, index) => (
                  <SecHomeStoriesItem
                    key={index}
                    name={item.title}
                    id={item.id}
                    image={item.feature_image}
                  />
                ))}
            </div>
          </Fade>
          <div className="stories-action">
            <Fade bottom>
              <Link to="/stories" className="btn-action">
                <i className="fa-solid fa-fw fa-newspaper mr-2"></i>Browse more
                Stories
              </Link>
            </Fade>
          </div>
        </div>
      </section>
    </>
  );
};

export default SecHomeStories;
