import React, { useState } from 'react';
import { useNavigate, Link } from "react-router-dom";
import './Css/Search.css';
import { globalSearch } from '../../store/Pages/page';

const Search = () => {
  const [search, setSearch] = useState('');
  const [searchFilter, setSearchFilter] = useState([]);
  const [load, setLoad] = useState(false);

  const resetValue = event => {
    setSearch(current => '');
    setSearchFilter([]);
  }
  let navigate = useNavigate(); 
  const routeChange = () =>{ 
    let path = `/search-result?query=`+search; 
    navigate(path);
  }


  const inputSearch = async(value) =>{
   
    if(value){
      setLoad(true);
      const filterData = await globalSearch({keyword:value});
     setSearchFilter(filterData);
     setLoad(false);
    }else{
      setSearchFilter([]);
    }
  }

  const selectSearch = value =>{
    setSearch(value);
    setSearchFilter([]);
  }


  return (
    <>
      <section className='sec-search'>
        <div className='container'>
          <div className='row justify-content-center'>
            <div className='col-xl-8'>
              <form className="form-content" id="search-form">
                <div className="form-group">
                  <div className={"input-style " + (search ? 'hasValue' : '')}>
                    <input type="text" className="form-control" name='search' autoComplete='off' value={search} onChange={(e) => {inputSearch(e.target.value); setSearch(e.target.value)} } />
                    {load && <button type='button' className='input-reset'><i className='fa-light fa-fw fa-sync fa-spin'></i></button>}
                    {!load && <button type='button' className='input-reset' onClick={resetValue}><i className='fa-light fa-fw fa-times'></i></button> }
                    <div className="float-label">Search on CEO</div>
                  </div>
                  {
                    searchFilter.length > 0 &&
                    <div className='search-suggestion'>
                      <div className='suggestion-list'>
                        {searchFilter.map((item, index) => (
                        <button type='button' className='suggestion-item' key={index} onClick={() => selectSearch(item.name)}>
                          <div className='item-icon'><i className={'fa-solid fa-fw fa-' + (item.icon)}></i></div>
                         <div className='item-content'>
                            <div className='item-title'>{item.name}</div>
                            <div className='item-text'>Found on {item.type}</div>
                          </div>
                        </button>
                        ))}
                      </div>
                    </div>
                  }
                  <div className={'search-action ' + (search ? 'active' : '')}>
                    <button type='button' className='btn-action' onClick={routeChange}><i className='fa-light fa-fw fa-search mr-2'></i>Search on CEO</button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Search;