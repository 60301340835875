import { combineReducers } from "redux";
import { categorySlice } from "./category";
import { faqSlice } from "./faq";
import { bannerSlice } from "./banner";
import { countrySlice } from "./country";
import { userSlice } from "./user";
import { settingSlice } from "./settings";
import { blogSlice } from "./blog";
import { playlistSlice } from "./playlist";
import { subjectSlice } from "./subject";
import { eventSlice } from "./event";
import { pageSlice } from "./Pages/page";

export default combineReducers({
  categories: categorySlice.reducer,
  banners: bannerSlice.reducer,
  countries: countrySlice.reducer,
  users: userSlice.reducer,
  settings: settingSlice.reducer,
  blogs: blogSlice.reducer,
  playlist: playlistSlice.reducer,
  faqs: faqSlice.reducer,
  subjects: subjectSlice.reducer,
  events: eventSlice.reducer,
  pages: pageSlice.reducer,
});
