import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import "../Css/MembershipRegistration.css";
import { useSelector, useDispatch } from "react-redux";
import { userSignup } from "../../../store/user";
const SecMembershipRegistration = ({
  registrationActive,
  setRegistrationActive,
}) => {
  const dispatch = useDispatch();
  const { signUpdata, loading } = useSelector((state) => state.users);
  const [submitView, setSubmitView] = useState(false);
  const [fname, setFname] = useState("");
  const [lname, setLname] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [headline, setHeadline] = useState("");
  const [company, setCompany] = useState("");
  const [role, setRole] = useState("");
  const [password, setPassword] = useState("");
  const [terms, setTerms] = useState(false);

  const [fnameMsg, setFnameMsg] = useState(false);
  const [lnameMsg, setLnameMsg] = useState(false);
  const [emailMsg, setEmailMsg] = useState(false);
  const [phoneMsg, setPhoneMsg] = useState(false);
  const [headlineMsg, setHeadlineMsg] = useState(false);
  const [companyMsg, setCompanyMsg] = useState(false);
  const [roleMsg, setRoleMsg] = useState(false);
  const [passwordMsg, setPasswordMsg] = useState(false);

  const closePanel = () => {
    setRegistrationActive(false);
    setSubmitView(false);
    setFname("");
    setLname("");
    setEmail("");
    setPhone("");
    setHeadline("");
    setCompany("");
    setRole("");
    setPassword("");
    setTerms(false);
    ///validation
    setFnameMsg("");
    setLnameMsg("");
    setEmailMsg("");
    setPhoneMsg("");
    setCompanyMsg("");
    setRoleMsg("");
    setPasswordMsg("");
  };

  const switchPanel = () => {
    dispatch(
      userSignup({
        first_name: fname,
        last_name: lname,
        name: fname + " " + lname,
        email: email,
        phone: phone,
        headline: headline,
        company_name: company,
        role: role,
        password: password,
        accept_terms: terms,
        url: window.location.origin,
      })
    );
  };

  useEffect(() => {
    if (signUpdata.status === 400) {
      signUpdata.message.first_name
        ? setFnameMsg(signUpdata.message.first_name[0])
        : setFnameMsg(false);
      signUpdata.message.last_name
        ? setLnameMsg(signUpdata.message.last_name[0])
        : setLnameMsg(false);
      signUpdata.message.email
        ? setEmailMsg(signUpdata.message.email[0])
        : setEmailMsg(false);
      signUpdata.message.phone
        ? setPhoneMsg(signUpdata.message.phone[0])
        : setPhoneMsg(false);
      signUpdata.message.headline
        ? setHeadlineMsg(signUpdata.message.headline[0])
        : setHeadlineMsg(false);
      signUpdata.message.company_name
        ? setCompanyMsg(signUpdata.message.company_name[0])
        : setCompanyMsg(false);
      signUpdata.message.role
        ? setRoleMsg(signUpdata.message.role[0])
        : setRoleMsg(false);
      signUpdata.message.password
        ? setPasswordMsg(signUpdata.message.password[0])
        : setPasswordMsg(false);
    }
    if (signUpdata.status === 200) {
      setSubmitView(true);
    }

  }, [signUpdata]);

  return (
    <>
      <div
        className={"registration-panel " + (registrationActive ? "active" : "")}
      >
        {submitView ? (
          <>
            <div className="container">
              <div className="row">
                <div className="col-lg-6">
                  <div className="registration-success-content">
                    <div className="registration-success-icon">
                      <i className="fa-light fa-fw fa-check"></i>
                    </div>
                    <h1 className="registration-success-title">
                      Well done!
                      <br />
                      Now it's up to us.
                    </h1>
                    <div className="registration-success-text">
                      <p>
                        Successfully registered with us.please check your email
                        and verify your account
                      </p>
                    </div>
                    <div className="registration-success-action">
                      <button
                        type="button"
                        className="btn-action"
                        onClick={closePanel}
                      >
                        Okay!
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="registration-image">
              <img src="assets/img/brand.svg" alt="" />
            </div>
          </>
        ) : (
          <>
            <button type="button" className="panel-close" onClick={closePanel}>
              <i className="fa-light fa-fw fa-times"></i>
            </button>
            <div className="container">
              <div className="row">
                <div className="col-lg-6">
                  <div className="registration-content">
                    <h1 className="registration-title">
                      The first step of a great journey.
                    </h1>
                    <div className="registration-text">
                      <p>
                        Please fill in the fields on the side, this will allow
                        us to get to know you better.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6">
                  <form
                    className={
                      "registration-form " +
                      (fname && lname && email && phone && headline && terms
                        ? "active"
                        : "")
                    }
                    id="registration-form"
                  >
                    <div className="form-group">
                      <div
                        className={"input-style " + (fname ? "hasValue" : "")}
                      >
                        <input
                          type="text"
                          className="form-control"
                          name="fname"
                          value={fname}
                          onChange={(e) => setFname(e.target.value)}
                        />
                        <div className="float-label">First name*</div>
                      </div>
                      {fnameMsg && <div>{fnameMsg}</div>}
                    </div>
                    <div className="form-group">
                      <div
                        className={"input-style " + (lname ? "hasValue" : "")}
                      >
                        <input
                          type="text"
                          className="form-control"
                          name="lname"
                          value={lname}
                          onChange={(e) => setLname(e.target.value)}
                        />
                        <div className="float-label">Last name*</div>
                      </div>
                      {lnameMsg && <div>{lnameMsg}</div>}
                    </div>
                    <div className="form-group">
                      <div
                        className={"input-style " + (email ? "hasValue" : "")}
                      >
                        <input
                          type="email"
                          className="form-control"
                          autoComplete="off"
                          name="email"
                          value={email}
                          onChange={(e) => setEmail(e.target.value)}
                        />
                        <div className="float-label">E-mail*</div>
                      </div>
                      {emailMsg && <div>{emailMsg}</div>}
                    </div>
                    <div className="form-group">
                      <div
                        className={"input-style " + (phone ? "hasValue" : "")}
                      >
                        <input
                          type="tel"
                          className="form-control"
                          name="phone"
                          value={phone}
                          onChange={(e) => setPhone(e.target.value)}
                        />
                        <div className="float-label">Phone number*</div>
                      </div>
                      {phoneMsg && <div>{phoneMsg}</div>}
                    </div>
                    <div className="form-group">
                      <div
                        className={
                          "input-style " + (headline ? "hasValue" : "")
                        }
                      >
                        <input
                          type="tel"
                          className="form-control"
                          name="phone"
                          value={headline}
                          onChange={(e) => setHeadline(e.target.value)}
                        />
                        <div className="float-label">Headline*</div>
                      </div>
                      {headlineMsg && <div>{headlineMsg}</div>}
                    </div>
                    <div className="form-group">
                      <div
                        className={"input-style " + (company ? "hasValue" : "")}
                      >
                        <input
                          type="text"
                          className="form-control"
                          name="company"
                          value={company}
                          onChange={(e) => setCompany(e.target.value)}
                        />
                        <div className="float-label">Company name*</div>
                      </div>
                      {companyMsg && <div>{companyMsg}</div>}
                    </div>
                    <div className="form-group">
                      <div
                        className={"input-style " + (role ? "hasValue" : "")}
                      >
                        <input
                          type="text"
                          className="form-control"
                          name="role"
                          value={role}
                          onChange={(e) => setRole(e.target.value)}
                        />
                        <div className="float-label">Role*</div>
                      </div>
                      {roleMsg && <div>{roleMsg}</div>}
                    </div>

                    <div className="form-group">
                      <div
                        className={
                          "input-style " + (password ? "hasValue" : "")
                        }
                      >
                        <input
                          type="password"
                          className="form-control"
                          autoComplete="new-password"
                          name="password"
                          value={password}
                          onChange={(e) => setPassword(e.target.value)}
                        />
                        <div className="float-label">Password*</div>
                      </div>
                      {passwordMsg && <div>{passwordMsg}</div>}
                    </div>

                    <div className="form-options">
                      <div className="form-check">
                        <input
                          type="checkbox"
                          className="form-check-input"
                          name="terms"
                          checked={terms}
                          onChange={(e) => setTerms(!terms)}
                        />
                        <div className="form-check-label">
                          I agree with the{" "}
                          <Link to="/terms-of-use" target="_blank">
                            terms of use
                          </Link>{" "}
                          and{" "}
                          <Link to="/privacy-policy" target="_blank">
                            privacy policy
                          </Link>
                          .
                        </div>
                      </div>
                    </div>
                    <div className="form-group form-action">
                      <button
                        type="button"
                        className="btn-action"
                        onClick={() => switchPanel()}
                      >
                        {loading && <i className="fa fa-spinner fa-spin"></i>}{" "}
                        Send request
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default SecMembershipRegistration;
