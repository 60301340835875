import { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  resendsendOtp,
  userLogin,
  verifyOtp,
  changePassword,
} from "../store/user";

const Authentication = ({ authenticationActive, setAuthenticationActive }) => {
  const [loader, setLoader] = useState(false);
  const [email, setEmail] = useState("");
  const [otp, setOtp] = useState(null);
  const [password, setPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [againNewPassword, setAgainNewPassword] = useState("");

  let navigate = useNavigate();
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const [isNewPasswordVisible, setIsNewPasswordVisible] = useState(false);
  const [isForgotToggle, setIsForgotToggle] = useState(false);
  const [forgotEmail, setForgotEmail] = useState("");
  const [errorMsg, setErrorMsg] = useState(""); //setOTPSec
  const [successMsg, setSuccessMsg] = useState("");
  const [errorOtpMsg, setErrorOtpMsg] = useState("");
  const [OtpSec, setOtpSec] = useState(false);
  const [changePasswordSec, setChangePasswordSec] = useState(false);

  const switchType = (event) => {
    setIsPasswordVisible((current) => !current);
  };

  const switchNewType = (event) => {
    setIsNewPasswordVisible((current) => !current);
  };

  const closePanel = (event) => {
    setAuthenticationActive(false);
    setIsPasswordVisible(false);
    setIsForgotToggle(false);
  };
  const forgotToggle = (event) => {
    setIsForgotToggle((current) => !current);
  };

  const login = async (e) => {
    e.preventDefault();
    setLoader(true);
    try {
      const data = await userLogin({
        type: "user",
        email: email,
        password: password,
        login_for: "user",
      });
      //console.log(data);
      if (data.status === 200 && data.data.access_token) {
        localStorage.setItem("accesstoken", data.data.access_token);
        // navigate("/profile");
        window.location.href = "/profile";
      } else {
        // console.log(data.message);
        setErrorMsg(data.message);
      }
      setLoader(false);
    } catch (error) {}
  };

  async function handleResend() {
    await resendsendOtp({ email: forgotEmail }).then((res) => {
      if (res.status === 200) {
        setErrorOtpMsg("");
        setOtpSec(true);
      } else if (res.status === 400) {
        setErrorOtpMsg(res.message);
      }
      //console.log(res);
    });
  }

  async function handleVerify() {
    await verifyOtp({ email: forgotEmail, otp: parseInt(otp) }).then((res) => {
      if (res.status === 200) {
        setOtpSec(false);
        setErrorOtpMsg("");
        setChangePasswordSec(true);
      } else if (res.status === 400) {
        setErrorOtpMsg(res.message);
      }
      //console.log(res); changePassword
    });
  }

  async function handlechangePassword() {
    await changePassword({
      email: forgotEmail,
      password: newPassword,
      confirm_password: againNewPassword,
    }).then((res) => {
      if (res.status === 200) {
        setErrorOtpMsg("");
        setSuccessMsg(res.message);
        setTimeout(() => {
          setChangePasswordSec(false);
          setOtpSec(false);
          setOtp("");
          setAgainNewPassword("");
          setNewPassword("");
          setIsForgotToggle(false);
        }, 2000);
      } else if (res.status === 400) {
        setErrorOtpMsg(res.message);
      }
      //console.log(res); //changePassword
    });
  }

  const handlePaste = () => {
    const myInput = document.getElementById("myInput");
    myInput.onPaste = (e) => e.preventDefault();
  };

  return (
    <>
      <div
        className={
          "authentication-panel " + (authenticationActive ? "active" : "")
        }
      >
        <div className="panel-backdrop"></div>
        <div className="panel-content">
          <div className="panel-header">
            <div className="panel-title">Welcome back</div>
            <br />
            <p className="text-danger">{errorMsg}</p>
            <p className="text-success">{successMsg}</p>
            <button type="button" className="panel-close" onClick={closePanel}>
              <i className="fa-light fa-fw fa-times"></i>
            </button>
          </div>
          <div className="panel-body">
            <form
              className={"form-content " + (email && password ? "active" : "")}
              id="authentication-form"
              onSubmit={(e) => login(e)}
            >
              <div className="form-group">
                <div className={"input-style " + (email ? "hasValue" : "")}>
                  <input
                    type="text"
                    className="form-control"
                    name="email"
                    autoComplete="new-email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                  <div className="float-label">Email</div>
                </div>
              </div>
              <div className="form-group">
                <div className={"input-style " + (password ? "hasValue" : "")}>
                  <input
                    type={isPasswordVisible ? "text" : "password"}
                    className="form-control"
                    name="password"
                    autoComplete="new-password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                  />
                  <button
                    type="button"
                    className={
                      "btn-password " + (isPasswordVisible ? "active" : "")
                    }
                    onClick={switchType}
                  >
                    <i
                      className={
                        "fa-light fa-fw " +
                        (isPasswordVisible ? "fa-eye" : "fa-eye-slash")
                      }
                    ></i>
                  </button>
                  <div className="float-label">Password</div>
                </div>
              </div>
              <div className="form-group form-action">
                <button type="submit" className="btn-action">
                  Login{"  "}
                  {loader && <i className="fa fa-spinner fa-spin"></i>}
                </button>
              </div>
              <div className="form-options">
                <button
                  type="button"
                  className="option-link"
                  onClick={forgotToggle}
                >
                  Forgot Password?
                </button>
              </div>
            </form>
          </div>
        </div>
        <div
          className={
            "panel-content forgot-panel " + (isForgotToggle ? "active" : "")
          }
        >
          <div className="panel-header">
            <div className="panel-title">
              {OtpSec
                ? "Enter OTP"
                : changePasswordSec
                ? "Set new password"
                : "Reset password"}
            </div>
            <div className="panel-text">
              {OtpSec
                ? "We have sent an otp to your email "
                : changePasswordSec
                ? ""
                : "Confirm your email so we can send an otp to it."}
            </div>
            <button type="button" className="panel-back" onClick={forgotToggle}>
              <i className="fa-light fa-fw fa-chevron-left"></i>
            </button>
            <p className="text-danger">{errorOtpMsg}</p>
            <p className="text-success">{successMsg}</p>
          </div>
          <div className="panel-body">
            <form
              className={"form-content " + (forgotEmail ? "active" : "")}
              id="forgot-form"
            >
              {!OtpSec && !changePasswordSec && (
                <div className="form-group">
                  <div
                    className={"input-style " + (forgotEmail ? "hasValue" : "")}
                  >
                    <input
                      type="text"
                      className="form-control"
                      name="forgotEmail"
                      autoComplete="new-email"
                      value={forgotEmail}
                      onChange={(e) => setForgotEmail(e.target.value)}
                    />
                    <div className="float-label">Email</div>
                  </div>
                </div>
              )}
              {OtpSec && (
                <div className="form-group">
                  <div className={"input-style " + (otp ? "hasValue" : "")}>
                    <input
                      type={isPasswordVisible ? "text" : "password"}
                      className="form-control"
                      name="otp"
                      value={otp}
                      onChange={(e) => setOtp(e.target.value)}
                    />
                    <button
                      type="button"
                      className={
                        "btn-password " + (isPasswordVisible ? "active" : "")
                      }
                      onClick={switchType}
                    >
                      <i
                        className={
                          "fa-light fa-fw " +
                          (isPasswordVisible ? "fa-eye" : "fa-eye-slash")
                        }
                      ></i>
                    </button>
                    <div className="float-label">Enter OTP</div>
                  </div>
                </div>
              )}

              {changePasswordSec && (
                <>
                  <div className="form-group">
                    <div
                      className={
                        "input-style " + (newPassword ? "hasValue" : "")
                      }
                    >
                      <input
                        type={isPasswordVisible ? "text" : "password"}
                        className="form-control"
                        name="password"
                        value={newPassword}
                        onChange={(e) => setNewPassword(e.target.value)}
                      />
                      <button
                        type="button"
                        className={
                          "btn-password " + (isPasswordVisible ? "active" : "")
                        }
                        onClick={switchType}
                      >
                        <i
                          className={
                            "fa-light fa-fw " +
                            (isPasswordVisible ? "fa-eye" : "fa-eye-slash")
                          }
                        ></i>
                      </button>
                      <div className="float-label">Enter new password</div>
                    </div>
                  </div>
                  <div className="form-group">
                    <div
                      className={
                        "input-style " + (againNewPassword ? "hasValue" : "")
                      }
                    >
                      <input
                        type={isNewPasswordVisible ? "text" : "password"}
                        className="form-control"
                        name="confirm-password"
                        value={againNewPassword}
                        onChange={(e) => setAgainNewPassword(e.target.value)}
                      />
                      <button
                        type="button"
                        className={
                          "btn-password " +
                          (isNewPasswordVisible ? "active" : "")
                        }
                        onClick={switchNewType}
                      >
                        <i
                          className={
                            "fa-light fa-fw " +
                            (isNewPasswordVisible ? "fa-eye" : "fa-eye-slash")
                          }
                        ></i>
                      </button>
                      <div
                        className="float-label"
                        id="myInput"
                        onPaste={handlePaste}
                      >
                        Confirm new password
                      </div>
                    </div>
                  </div>
                </>
              )}
              <div className="form-group form-action">
                {OtpSec ? (
                  <button
                    type="button"
                    className="btn-action"
                    onClick={handleVerify}
                  >
                    Verify OTP
                  </button>
                ) : changePasswordSec ? (
                  <button
                    type="button"
                    className="btn-action"
                    onClick={handlechangePassword}
                  >
                    Save Password
                  </button>
                ) : (
                  <button
                    type="button"
                    className="btn-action"
                    onClick={handleResend}
                  >
                    Send OTP
                  </button>
                )}
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default Authentication;
