import React, { useEffect } from "react";
import {
  getFavorites,
  getSavedPosts,
  getUserPosts,
  updatePost,
} from "../../../store/feed";
import { useState } from "react";
import { useSelector } from "react-redux";
import LinkPost from "./LinkPost";
import API_URL from "../../../config/apiHost";
import SkeletonLoader from "../../../components/SkeletonLoader";
import SharedModal from "./SharedModal";
const MyFeeds = () => {
  const [posts, setPost] = useState([]);
  const [hasNext, setHasNext] = useState(false);
  const [btnDisable, setBtnDisable] = useState(false);
  const [postIds, setPostids] = useState([]);
  const [postChildren, setpostChildren] = useState([]);
  const [files, setFiles] = useState([]);
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(false);
  const [limit, setLimit] = useState(20);
  const [offset, setOffset] = useState(0);
  const [favList, setFavorites] = useState([]);
  const [favs, setFavs] = useState([]);
  const [userId, setUserId] = useState([]);
  const [reValidate, setreValidate] = useState(null);
  const { profileDetails } = useSelector((state) => state.users);
  const [postId, setPostId] = useState(false);
  const [editPostTitle, setEditPostTitle] = useState("");
  const [editPostLinkDesc, setEditPostLinkDesc] = useState("");
  const [editPostLinkTitle, setEditPostLinkTitle] = useState("");
  const [metaData, setMetaData] = useState({});
  const [isEdit, setIsEdit] = useState(null);
  const [isOpaque, setIsOpaque] = useState(null);

  useEffect(() => {
    const getProfileData = async () => {
      setLoading(true);
      
      const fav = await getFavorites();
      setFavs(fav);

      const getFavorite = await getUserPosts({ limit: limit, offset: offset });
      
      setHasNext(getFavorite.hasNext);
      setFavorites(getFavorite.data === [] ? [] : getFavorite.data);
      getFavorite.data.length > 0 &&
        getFavorite.data.map((item) => {
          postIds.push(item.post_id);
        });

      setPostids([...new Set(postIds)]);
      
      try {
        const savePosts = await getSavedPosts({
          postIds: [...new Set(postIds)],
        });

        if (savePosts.status === 200) {
          
          setPost([...posts, ...savePosts.data.posts.reverse()]);
          setpostChildren([...postChildren, ...savePosts.data.postChildren]);
          setFiles([...files, ...savePosts.data.files]);
          setUsers([...users, ...savePosts.data.users]);
          setPostids([]);
        }
      } catch (error) {}
      setLoading(false);
    };
    getProfileData();
  }, [reValidate, offset]);

  const sharePost = (postId) => {
    setPostId(postId);
  };

  const eidtPostDetails = (postId, title, metadata, type) => {
    setMetaData(metadata);
    if (type === "article") {
      setEditPostLinkTitle(metadata.title);
      setEditPostLinkDesc(metadata.description);
    } else if (type === "event") {
      setEditPostLinkTitle(metadata.preview_event_json.title);
      setEditPostLinkDesc(metadata.preview_event_json.description);
    } else {
      setEditPostLinkTitle(metadata.preview_json.title);
      setEditPostLinkDesc(metadata.preview_json.description);
    }
    setPostId(postId);
    setEditPostTitle(title);
  };

  const [deleteLink, setDeleteLink] = useState(true);

  async function handleSubmit() {
    setPost([]);
    setpostChildren([]);
    if (deleteLink) {
      await updatePost({
        postId: postId,
        data: {
          text: editPostTitle,
        },
        metadata: metaData.isArticle
          ? {
              ...metaData,
              title: editPostLinkTitle,
              description: editPostLinkDesc,
            }
          : metaData.isEvent
          ? {
              ...metaData,
              preview_event_json: {
                ...metaData.preview_event_json,
                title: editPostLinkTitle,
                description: editPostLinkDesc,
              },
            }
          : {
              ...metaData,
              preview_json: {
                ...metaData.preview_json,
                title: editPostLinkTitle,
                description: editPostLinkDesc,
              },
            },
      }).then(() => {
        setreValidate(Math.floor(Math.random() * 100) + 1);
      });
    } else {
      await updatePost({
        postId: postId,
        data: {
          text: editPostTitle,
        },
        metadata: metaData.isArticle
          ? {
              ...metaData,
              title: editPostLinkTitle,
              description: editPostLinkDesc,
            }
          : metaData.isEvent
          ? {
              ...metaData,
              preview_event_json: {
                ...metaData.preview_event_json,
                title: editPostLinkTitle,
                description: editPostLinkDesc,
              },
            }
          : {
              ...metaData,
              preview_json: {
                ...metaData.preview_json,
                title: editPostLinkTitle,
                description: editPostLinkDesc,
              },
            },
      }).then(() => {
        setreValidate(Math.floor(Math.random() * 100) + 1);
      });
    }
  }
  return (
    <>
      <div className={"feed-list " + (isOpaque ? "opaque" : "")}>
        {posts.length > 0 &&
          posts.map((item, index) => {
            const user =
              users.length > 0 &&
              users.find((user) => user.userId == item.postedUserId);
            const favData =
              favs.length > 0 && favs.find((fv) => fv.post_id == item.postId);
            let wishlist = favData !== undefined ? favData.status : false;
            const like = item.myReactions.includes("like");
            const love = item.myReactions.includes("love");
            const commentCount = item.commentsCount;

            if (item?.metadata?.isLink) {
              return (
                <React.Fragment key={index}>
                  <LinkPost
                    user={user}
                    date={item.createdAt}
                    tick={true}
                    url={item.metadata.preview_json.url}
                    imgSrc={item.metadata.preview_json?.images[0]}
                    postTitle={item.data.text}
                    linkTitle={item.metadata.preview_json?.title ? item.metadata.preview_json.title : item.metadata.preview_json?.url}
                    linkDesc={item.metadata.preview_json.description}
                    commentCount={commentCount}
                    reactionsCount={
                      item.reactions?.like ? item.reactions.like : 0
                    }
                    loveReactionCount={
                      item.reactions?.love ? item.reactions.love : 0
                    }
                    like={like}
                    love={love}
                    wishlist={wishlist}
                    postId={item.postId}
                    sharePost={sharePost}
                    myfeed={profileDetails?._id === item.postedUserId}
                    setLoading={setLoading}
                    type={"link"}
                    setIsEdit={setIsEdit}
                    isEdit={isEdit}
                    isOpaque={isOpaque}
                    setIsOpaque={() => {}}
                    deleteLink={deleteLink}
                    setDeleteLink={() => {}}
                    eidtPostDetails={eidtPostDetails}
                    setEditPostTitle={setEditPostTitle}
                    setEditPostLinkDesc={setEditPostLinkDesc}
                    setEditPostLinkTitle={setEditPostLinkTitle}
                    handleSubmit={handleSubmit}
                    item={item}
                  />
                </React.Fragment>
              );
            }

            if (item?.metadata?.isArticle) {
              return (
                <React.Fragment key={index}>
                  <LinkPost
                    user={user}
                    date={item.createdAt}
                    tick={true}
                    url={"/post/" + postId}
                    imgSrc={
                      item?.metadata?.images
                        ? item?.metadata?.images[0]?.imgLink
                        : API_URL + "uploads/events/no-image.png"
                    }
                    postTitle={item.data.text}
                    linkTitle={item.metadata.title}
                    linkDesc={item.metadata.description}
                    commentCount={commentCount}
                    reactionsCount={
                      item.reactions?.like ? item.reactions.like : 0
                    }
                    loveReactionCount={
                      item.reactions?.love ? item.reactions.love : 0
                    }
                    like={like}
                    love={love}
                    wishlist={wishlist}
                    postId={item.postId}
                    eidtPostDetails={eidtPostDetails}
                    myfeed={profileDetails?._id === item.postedUserId}
                    sharePost={sharePost}
                    setLoading={setLoading}
                    setDeleteLink={() => {}}
                    type={"article"}
                    setIsEdit={setIsEdit}
                    setEditPostTitle={setEditPostTitle}
                    setEditPostLinkDesc={setEditPostLinkDesc}
                    setEditPostLinkTitle={setEditPostLinkTitle}
                    handleSubmit={handleSubmit}
                    isEdit={isEdit}
                    isOpaque={isOpaque}
                    setIsOpaque={() => {}}
                    item={item}
                  />
                </React.Fragment>
              );
            }

            if (item?.metadata?.isEvent) {
              return (
                <React.Fragment key={index}>
                  <LinkPost
                    user={user}
                    date={item.createdAt}
                    tick={true}
                    url={`/event-details/${item.metadata.preview_event_json.event_slug}/${item.metadata.preview_event_json.event_id}`}
                    imgSrc={
                      item.metadata.preview_event_json.hasPic
                        ? `${API_URL}uploads/events/500/${item.metadata.preview_event_json.image}`
                        : item.metadata.preview_event_json.image
                    }
                    postTitle={item.data.text}
                    linkTitle={item.metadata.preview_event_json.title}
                    linkDesc={item.metadata.preview_event_json.description}
                    commentCount={commentCount}
                    reactionsCount={
                      item.reactions?.like ? item.reactions.like : 0
                    }
                    loveReactionCount={
                      item.reactions?.love ? item.reactions.love : 0
                    }
                    like={like}
                    love={love}
                    wishlist={wishlist}
                    postId={item.postId}
                    myfeed={profileDetails?._id === item.postedUserId}
                    sharePost={sharePost}
                    setLoading={setLoading}
                    type={"event"}
                    setIsEdit={setIsEdit}
                    isEdit={isEdit}
                    isOpaque={isOpaque}
                    setDeleteLink={() => {}}
                    setIsOpaque={() => {}}
                    eidtPostDetails={eidtPostDetails}
                    setEditPostTitle={setEditPostTitle}
                    setEditPostLinkDesc={setEditPostLinkDesc}
                    setEditPostLinkTitle={setEditPostLinkTitle}
                    handleSubmit={handleSubmit}
                    item={item}
                  />
                </React.Fragment>
              );
            }
          })}
        {loading && <SkeletonLoader />}
      </div>
      <SharedModal postId={postId} userId={profileDetails?._id} />
      {hasNext && (
        <div className="feed-action">
          <button
            type="button"
            className="btn-action"
            id="load"
            onClick={() => setOffset(limit + offset)}
            disabled={btnDisable}
          >
            Load More
          </button>
        </div>
      )}
    </>
  );
};

export default MyFeeds;
