import { useState } from "react";
import {
  TwitterIcon,
  TwitterShareButton,
  WhatsappShareButton,
  WhatsappIcon,
  FacebookShareButton,
  FacebookIcon,
} from "react-share";

const ShareModal = ({
  blogdetails,
  playlistdetails,
  podcatstdetails,
  blogID,
  playlist_id,
  videoID,
  type,
  videoDetails,
  track_id,
}) => {
  const [isCopy, setIscopy] = useState(false);

  const copyLink = () => {
    setIscopy(true);
    navigator.clipboard.writeText(
      playlistdetails
        ? window.location.origin + `/playlist/${playlist_id}`
        : blogdetails
        ? window.location.origin + `/story/${blogID}`
        : videoID
        ? window.location.origin + `/video/${videoID}?type=${type}`
        : podcatstdetails
        ? window.location.origin + `/podcast/${track_id}`
        : ""
    );
    setTimeout(() => {
      setIscopy(false);
    }, 1000);
  };

  return (
    <>
      <div
        className="modal event-delete-modal fade"
        data-backdrop="static"
        id="shareModal"
        tabIndex="-1"
        aria-labelledby="deleteModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered modal-sm">
          <div className="modal-content">
            <div className="modal-body">
              <div className="delete-content">
                <div className="main-title">Social Share</div>
                <div
                  className="share-actions"
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    columnGap: "10px",
                    margin: "10px 0",
                  }}
                >
                  <button
                    onClick={copyLink}
                    type="button"
                    className="btn-copy"
                    style={{
                      width: "42px",
                      height: "42px",
                      backgroundColor: "#6A00E5",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      borderRadius: "50%",
                      fontSize: "20px",
                      padding: 0,
                    }}
                  >
                    <i className="fa-regular fa-fw fa-copy"></i>
                  </button>
                  {isCopy && <p>Link Copied</p>}
                  <TwitterShareButton
                    title={
                      blogdetails
                        ? blogdetails?.title
                        : playlistdetails
                        ? playlistdetails?.name
                        : podcatstdetails
                        ? podcatstdetails?.name
                        : videoDetails
                        ? videoDetails?.title
                        : ""
                    }
                    url={
                      playlistdetails
                        ? window.location.origin + `/playlist/${playlist_id}`
                        : blogdetails
                        ? window.location.origin + `/story/${blogID}`
                        : videoDetails
                        ? window.location.origin +
                          `/video/${videoID}?type=${type}`
                        : podcatstdetails
                        ? window.location.origin + `/podcast/${track_id}`
                        : ""
                    }
                  >
                    <TwitterIcon size={42} round={true} />
                  </TwitterShareButton>{" "}
                  <FacebookShareButton
                    title={
                      blogdetails
                        ? blogdetails?.title
                        : playlistdetails
                        ? playlistdetails?.name
                        : podcatstdetails
                        ? podcatstdetails?.name
                        : videoDetails
                        ? videoDetails?.title
                        : ""
                    }
                    url={
                      playlistdetails
                        ? window.location.origin + `/playlist/${playlist_id}`
                        : blogdetails
                        ? window.location.origin + `/story/${blogID}`
                        : videoDetails
                        ? window.location.origin +
                          `/video/${videoID}?type=${type}`
                        : podcatstdetails
                        ? window.location.origin + `/podcast/${track_id}`
                        : ""
                    }
                  >
                    <FacebookIcon size={42} round={true} />
                  </FacebookShareButton>{" "}
                  <WhatsappShareButton
                    title={
                      blogdetails
                        ? blogdetails?.title
                        : playlistdetails
                        ? playlistdetails?.name
                        : podcatstdetails
                        ? podcatstdetails?.name
                        : videoDetails
                        ? videoDetails?.title
                        : ""
                    }
                    url={
                      playlistdetails
                        ? window.location.origin + `/playlist/${playlist_id}`
                        : blogdetails
                        ? window.location.origin + `/story/${blogID}`
                        : videoDetails
                        ? window.location.origin +
                          `/video/${videoID}?type=${type}`
                        : podcatstdetails
                        ? window.location.origin + `/podcast/${track_id}`
                        : ""
                    }
                  >
                    <WhatsappIcon size={42} round={true} />
                  </WhatsappShareButton>{" "}
                </div>
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-dismiss="modal"
              >
                Close
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ShareModal;
