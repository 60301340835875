import FilterChat from "./Chat/FilterChat";
import ChatItem from "./Chat/ChatItem";
import { useEffect } from "react";
import { listChannel } from "../../../store/feed";
import { useState } from "react";
import { useSelector } from "react-redux";
import LoaderFull from "../../../components/LoaderFull";
import { SocketContext } from "../../../context/socket";
import { useCallback } from "react";
import { useContext } from "react";
const ProfileMessage = ({ details }) => {
  const socket = useContext(SocketContext);
  const [channels, setChannel] = useState([]);
  const [members, setMember] = useState([]);
  const [keyword, setKeyword] = useState("");
  const [users, setUser] = useState([]);
  const { profileDetails } = useSelector((state) => state.users);
  const [loading, setLoading] = useState(false);
  const webhookEventResponse = useCallback(
    async (data) => {
      if (data.data.event === "message.didCreate") {
        const list = await listChannel({ keyword: keyword });
        setChannel(list.data.channels);
        setMember(list.data.channelUsers);
        setUser(list.data.users);
      }
    },
    [channels]
  );


  useEffect(() => {
    socket.on("webhookEventResponse", webhookEventResponse);
    return () => {
      socket.off("webhookEventResponse", webhookEventResponse);
    };
  }, [webhookEventResponse, socket]);

  const [token, setToken] = useState("");

  useEffect(() => {
    const loadChannel = async () => {
      setLoading(true);
      const list = await listChannel({
        keyword: keyword,
        limit: 10,
      });


      if (list.data.paging && list.data.paging.next && list.data.paging.next !== undefined) {
        setToken(list.data.paging.next);
      }

      setChannel(list.data.channels);
      setMember(list.data.channelUsers);
      setUser(list.data.users);
      setLoading(false);
    };

    loadChannel();
  }, []);



  const onScroll = async (e) => {
    const window = e.target;
    //console.log(e.target);
    //console.log('scroll height',window.scrollHeight);
    //console.log('client height',window.clientHeight);
    // console.log(
    //   Math.ceil(window.scrollTop + window.clientHeight) >= window.scrollHeight
    // );
    if (
      Math.ceil(window.scrollTop + window.clientHeight) >=
      window.scrollHeight &&
      token !== ""
    ) {
      setLoading(true);
      if (!loading) {
        const list = await listChannel({
          keyword: keyword,
          limit: 10,
          token: token,
        });
        // console.log(list);
        setToken(list?.data?.paging?.next ? list?.data?.paging.next : "");
        setChannel([...channels, ...list.data.channels]);
        setMember([...members, ...list.data.channelUsers]);
        setUser([...users, ...list.data.users]);
      }
      setLoading(false);
    }
  };

  useEffect(() => {
    if (users.length > 0) {
      for (const user of users) {
        socket.emit('checkOnlineUser', user?.userId)
      }
    }
  }, [socket, users])

  return (
    <>
      <section className="message-container">
        <div className="message-user-panel">
          <div className="user-search">
            <div className="search-icon">
              <i className="fal fa-fw fa-search"></i>
            </div>
            <FilterChat keyword={keyword} setKeyword={setKeyword} />
          </div>
          <div className="user-list scrollbar" onScroll={onScroll}>
            {channels.length > 0 &&
              channels.map((item, index) => {
                const member = members.find(
                  (it, i) =>
                    it.channelId === item.channelId &&
                    it.userId !== profileDetails._id
                );
                const user = member
                  ? users.find((t, i) => t.userId === member.userId)
                  : 0;
                const mem = members.find(
                  (it, i) =>
                    it.userId === profileDetails._id &&
                    it.channelId === item.channelId
                );
                const unseen = item.messageCount - mem.readToSegment;
                if(user?.displayName?.toLowerCase()?.includes(keyword.toLowerCase())) {
                  return (
                    <ChatItem
                      key={index}
                      user={user}
                      item={item}
                      unseen={unseen}
                    />
                  );
                }
                
              })}
          </div>
        </div>
      </section>
      <LoaderFull loaderActive={loading} />
    </>
  );
};

export default ProfileMessage;
