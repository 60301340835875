import { useState } from "react";
import { Link } from "react-router-dom";

const SecHomeEventsItem = ({ name, time, link, image, setListIsHovering }) => {
  const [isHovering, setIsHovering] = useState(false);
  const handleMouseOver = () => {
    setIsHovering(true);
    setListIsHovering(true);
  };
  const handleMouseOut = () => {
    setIsHovering(false);
    setListIsHovering(false);
  };
  return (
    <>
      <div
        className={"events-item " + (isHovering ? "active" : "")}
        onMouseOver={handleMouseOver}
        onMouseOut={handleMouseOut}
      >
        <Link to={link} className="events-inner">
          <div className="item-image">
            <img src={image} alt="" />
          </div>
          <div className="item-content">
            <div className="item-title">{name}</div>
            <div className="item-time">{time}</div>
            <div className="item-action">
              <div className="btn-action">More details</div>
            </div>
          </div>
        </Link>
      </div>
    </>
  );
};

export default SecHomeEventsItem;
