import { createSlice } from "@reduxjs/toolkit";
import { apiCallBegan } from "./api";
import BASE_URL from "../config/host";
import axios from "axios";
import API_URL from "../config/apiHost";

export const getBlogs = async (body) => {
  const res = await axios.post(BASE_URL + "/blog/list", body);
  return res.data.data;
};
export const blogSlice = createSlice({
  name: "blogs",
  loading: false,
  loader: false,
  initialState: {
    bloglist: [],
    count: 0,
    blogdetails: [],
    next: 1,
    featuredbloglist: {},
  },
  reducers: {
    listRequested: (state) => {
      state.loader = true;
    },
    listRequestFailed: (state) => {
      state.loader = false;
      state.bloglist = "";
    },
    listReceived: (state, action) => {
      state.loader = false;
      state.bloglist = action.payload.posts ? action.payload.posts : [];
      state.count = action.payload.total;
      state.next = action.payload.meta.pagination.next;
    },
    featuredReceived: (state, action) => {
      state.loading = false;
      state.featuredbloglist = action.payload.posts ? action.payload.posts : [];
      state.count = action.payload.total;
      state.next = action.payload.meta.pagination.next;
    },
    featuredRequested: (state) => {
      state.loading = true;
    },
    featuredRequestFailed: (state) => {
      state.loading = false;
      state.featuredbloglist = "";
    },
    morelistRequested: (state) => {
      state.loader = true;
    },
    morelistRequestFailed: (state) => {
      state.loader = false;
      state.bloglist = "";
    },
    morelistReceived: (state, action) => {
      state.loader = false;
      state.bloglist = [...state.bloglist, ...action.payload.posts];
      state.count = action.payload.total;
      state.next = action.payload.meta.pagination.next;
    },
    detailRequested: (state) => {
      state.loading = true;
    },
    detailRequestFailed: (state) => {
      state.loading = false;
      state.blogdetails = "";
    },
    detailReceived: (state, action) => {
      state.loading = false;
      state.blogdetails = action.payload.posts ? action.payload.posts[0] : "";
    },
  },
});

export const {
  listReceived,
  listRequested,
  listRequestFailed,
  morelistReceived,
  morelistRequested,
  morelistRequestFailed,
  detailReceived,
  detailRequested,
  detailRequestFailed,
  featuredRequested,
  featuredReceived,
  featuredRequestFailed,
} = blogSlice.actions;

export const fetchBlogs = (body) => {

  return apiCallBegan({
    url: `${BASE_URL}ghost-api`,
    method: "post",
    data: body,
    baseUrl: BASE_URL,
    onSuccess: listReceived.type,
    onStart: listRequested.type,
    onError: listRequestFailed.type,
  });
};

export const featuredBlogs = (body) =>
  apiCallBegan({
    url:
      `${BASE_URL}ghost-api`,
      method: "post",
      data: body,
      baseUrl: BASE_URL,
      onSuccess: featuredReceived.type,
      onStart: featuredRequested.type,
      onError: featuredRequestFailed.type,
  });

export const morefetchBlogs = (body) =>
  apiCallBegan({
    url: `${BASE_URL}ghost-api`,
    method: "post",
    data: body,
    baseUrl: BASE_URL,
    onSuccess: morelistReceived.type,
    onStart: morelistRequested.type,
    onError: morelistRequestFailed.type,
  });

export const blogDetails = (body) =>
  apiCallBegan({
    url: `${body?.ghost_url}/ghost/api/content/posts/${body?.id}/?key=${body?.key}`,
    method: "get",
    data: body,
    baseUrl: BASE_URL,
    onSuccess: detailReceived.type,
    onStart: detailRequested.type,
    onError: detailRequestFailed.type,
  });
