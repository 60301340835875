import { useState } from "react";
import { Link } from "react-router-dom";
import "flatpickr/dist/themes/dark.css";
import Flatpickr from "react-flatpickr";
import { updateExperience } from "../../../store/user";
import { useDispatch } from "react-redux";
import { addStep } from "../../../store/user";
import moment from "moment";
const ProfileFormExperience = ({ formView, setFormView }) => {
  const dispatch = useDispatch();
  const [role, setRole] = useState("");
  const [company_name, setCompany] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [currentJob, setCurrentJob] = useState("");
  
  const [formValues, setFormValues] = useState([
    {
      title: "",
      company_name: "",
      start_date: "",
      end_date: "",
      is_currently_working: false,
    },
  ]);

  const switchPanel = async () => {
    setFormView({
      ...formView,
      fourth: { active: false, completed: true },
      fifth: { active: true, completed: false },
    });
    let dt = {
      ...formView,
      fourth: { active: false, completed: true },
      fifth: { active: true, completed: false },
    };
    await addStep({ step: dt });
    dispatch(updateExperience({ data: formValues }));
  };
  const addFormFields = () => {
    setFormValues([
      ...formValues,
      {
        title: "",
        company_name: "",
        start_date: "",
        end_date: "",
        is_currently_working: false,
      },
    ]);
  };

  const handleChange = (i, e) => {
    let newFormValues = [...formValues];
    newFormValues[i][e.target.name] = e.target.value;
    setFormValues(newFormValues);
  };

  const removeFormFields = (i) => {
    let newFormValues = [...formValues];
    newFormValues.splice(i, 1);
    setFormValues(newFormValues);
   
  };

  const clickJob = (i, e) => {
    setCurrentJob(e.target.checked);
    let newFormValues = [...formValues];
    newFormValues[i]["is_currently_working"] = e.target.checked;
    setFormValues(newFormValues);
    let checkbox = document.querySelectorAll('.form-check-input')
   if(e.target.checked){
         for(let i = 0 ; i< checkbox.length; i++){
             if(checkbox[i].checked === false){
                 checkbox[i].disabled = 'true';
             }  
         }
      }else{
         for(let i = 0 ; i< checkbox.length; i++){
             checkbox[i].removeAttribute('disabled');
         }
         
      }
   
 
  };

  return (
    <>
      <div className="profile-forms">
        <div className="container">
          <div className="row">
            <div className="col-lg-4">
              <div className="profile-forms-content">
                <h1 className="profile-forms-title">
                  Tell us more about your work.
                </h1>
              </div>
            </div>

            <div className="col-lg-6 offset-lg-2">
              <form
                className={
                  "form-input-content " +
                  (role && company_name && startDate && endDate ? "active" : "")
                }
              >
                {formValues.map((element, index) => {
                  return (
                    <>
                      <div className="form-group">
                        <div
                          className={
                            "input-style z-up " + (role ? "hasValue" : "")
                          }
                        >
                          <input
                            type="text"
                            className="form-control"
                            name="title"
                            autoComplete="off"
                            value={element.title || ""}
                            onChange={(e) => handleChange(index, e)}
                          />
                          <div className="float-label">Role</div>
                        </div>
                      </div>
                      <div className="form-group">
                        <div
                          className={
                            "input-style " + (company_name ? "hasValue" : "")
                          }
                        >
                          <input
                            type="text"
                            className="form-control"
                            name="company_name"
                            autoComplete="off"
                            value={element.company_name || ""}
                            onChange={(e) => handleChange(index, e)}
                          />
                          <div className="float-label">Company</div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-6">
                          <div className="form-group">
                            <div
                              className={
                                "input-style " + (startDate ? "hasValue" : "")
                              }
                            >
                              {/* <input type="text" className="form-control" name='startDate' autoComplete='off' value={startDate} onChange={(e) => setStartDate(e.target.value)}/> */}
                              <Flatpickr
                                className="form-control"
                                value={element.start_date || ""}
                                onChange={([date]) => {
                                  let newFormValues = [...formValues];
                                  newFormValues[index]["start_date"] = new Date(
                                    date
                                  ).toISOString();
                                  setFormValues(newFormValues);
                                }}
                               
                              />
                              <div className="input-icon">
                                <i className="fa-light fa-fw fa-calendar"></i>
                              </div>
                              <div className="float-label">Start Date</div>
                            </div>
                          </div>
                        </div>
                       <div className="col-md-6">
                          <div className="form-group">
                            <div
                              className={
                                "input-style " + (endDate ? "hasValue" : "")
                              }
                            >
                              <Flatpickr
                                className="form-control"
                                value={element.end_date || ""}
                                onChange={([date]) => {
                                  let newFormValues = [...formValues];
                                  newFormValues[index]["end_date"] = new Date(
                                    date
                                  ).toISOString();
                                  setFormValues(newFormValues);
                                }}
                                options = {{
                                  minDate: moment(element.start_date).toISOString()
                                }}
                              />
                              <div className="input-icon">
                                <i className="fa-light fa-fw fa-calendar"></i>
                              </div>
                              <div className="float-label">End Date</div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="form-group">
                        <div className="form-check">
                          <input
                            type="checkbox"
                            className="form-check-input"
                            name="is_currently_working"
                            value={element.is_currently_working || false}
                            checked={element.is_currently_working || false}
                            onChange={(e) => clickJob(index, e)}
                          />
                          <div className="form-check-label">
                            This is my current job
                          </div>
                        </div>

                        {index ? (
                          <div className="form-options">
                            <button
                              type="button"
                              className="btn-option"
                              onClick={() => removeFormFields(index)}
                            >
                              <i className="fa-light fa-fw fa-trash-alt mr-2"></i>
                              Delete job
                            </button>
                          </div>
                        ) : null}
                      </div>
                    </>
                  );
                })}
                <div className="form-options">
                  <button
                    type="button"
                    className="btn-action"
                    onClick={() => addFormFields()}
                  >
                    <i className="fa-light fa-fw fa-plus mr-2"></i>Company
                  </button>
                </div>

                <div className="form-group form-action">

                 {formValues[0].title && formValues[0].company_name && formValues[0].start_date && <button
                    type="button"
                    className="btn-action "
                    onClick={() => switchPanel()}
                  >
                    Continue
                  </button>}
                  <Link to="/" className="btn-action alt">
                    Skip for now
                  </Link>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ProfileFormExperience;
