import { useEffect, useState } from "react";
import SecVideoList from "./SecVideoList";
import SecFilter from "../../../components/SecFilter";
import {
  directfetchPlaylist,
} from "../../../store/playlist";
import moment from "moment/moment";
import LoaderFull from "../../../components/LoaderFull";

const SecAllVideo = () => {
  const [dateRange, setDateRange] = useState([]);
  const [searchValue, setSearch] = useState([]);
  const [hasNext, setHasNext] = useState(false);
  const [videoplayList, setVideoplayList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [limit, setLimit] = useState(10);
  const [featured, setFeatured] = useState(false);

  const handleDateRangeSearch = (value, dateStatus) => {
    console.log(dateStatus);
    if (dateStatus === true) {
      setSearch(value);
      const start = moment()
        .subtract(1, value)
        .startOf(value)
        .format("YYYY-MM-DD");
      const end = moment().subtract(1, value).endOf(value).format("YYYY-MM-DD");
      const range = {
        start: new Date(start).toISOString(),
        end: new Date(end).toISOString(),
      };

      setDateRange(range);
      setFeatured(false);
    } else {
      setFeatured(true);
      setSearch(value);
      setDateRange([]);
    }
  };

  useEffect(() => {
    const loadData = async () => {
      setLoading(true);
      let playlist = [...videoplayList];
      if (dateRange && dateRange.start) {
        playlist = await directfetchPlaylist({
          status: true,
          type: "youtube,vimeo",
          dateRange: dateRange,
        });
      } else if (featured === true) {
        playlist = await directfetchPlaylist({
          status: true,
          type: "youtube,vimeo",
          featured: featured,
          sortByField: "ordering",
          sortByValue: 1,
        });
      } else {
        playlist = await directfetchPlaylist({
          status: true,
          type: "youtube,vimeo",
          limit: limit,
          sortByField: "ordering",
          sortByValue: 1,
        });
      }

      setVideoplayList([...new Map(playlist.data.map(item =>
        [item['_id'], item])).values()]);
      setHasNext(playlist.hasNext);
      setLoading(false);
    };

    loadData();
  }, [dateRange, featured, limit]);

  return (
    <>
      <section className="sec-videos">
        <div className="container">
          <SecFilter
            handleDateRangeSearch={handleDateRangeSearch}
            searchValue={searchValue}
          />
        </div>
        {Array.isArray(videoplayList) &&
          videoplayList.length > 0 &&
          videoplayList.map((item) => {
            return <SecVideoList item={item} key={item._id} />;
          })}

        {hasNext && (
          <div className="video-action">
            <button
              id="load1"
              type="button"
              className="btn-action"
              onClick={() => setLimit((data) => data + 10)}
            >
              Load More
            </button>
          </div>
        )}
      </section>
      <LoaderFull loaderActive={loading} />
    </>
  );
};

export default SecAllVideo;
