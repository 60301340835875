import { useEffect, useState } from "react";
import axios from "axios";
import BASE_URL from "../../../config/host";
import Fade from "react-reveal/Fade";
import { useSelector } from "react-redux";

const SecMembershipFeatures = () => {
  const [membership, setMembership] = useState([]);
  const { homeContentdata } = useSelector((state) => state.pages);
  useEffect(() => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    };
    axios
      .post(
        BASE_URL + "how-it-work/list",
        { status: true, sortByField: "ordering", sortByValue: 1 },
        { headers: headers }
      )
      .then(function (response) {
        setMembership(response.data.data);
      })
      .catch(function (error) {});
  }, []);
  return (
    <>
      <section className="sec-membership-features">
        <div className="container">
          <div className="row">
            <div className="col-lg-6">
              <Fade left>
                <div className="features-content">
                  <h2 className="features-title">
                    {homeContentdata?.exclusive_community?.title}
                  </h2>
                </div>
              </Fade>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-6 offset-lg-6">
              <div className="features-content">
                <div className="features-list">
                  <Fade bottom cascade>
                    {membership.length > 0 &&
                      membership.map((item, index) => {
                        return (
                          <div className="feature-item" key={index}>
                            <div className="item-icon">
                              <span>{index + 1}</span>
                              <svg>
                                <circle cx="28" cy="28" r="28"></circle>
                                <circle cx="28" cy="28" r="28"></circle>
                              </svg>
                            </div>
                            <div className="item-content">
                              <h4 className="item-title">{item.title}</h4>
                              <div className="item-text">
                                <p>{item.description}</p>
                              </div>
                            </div>
                          </div>
                        );
                      })}
                  </Fade>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default SecMembershipFeatures;
