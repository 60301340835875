import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { getFavorites, getSavedPosts } from "../../../store/feed";
import MyFeedItem from "../../Feed/Components/MyFeedItem";
import LoaderFull from "../../../components/LoaderFull";
import { getProfile } from "../../../store/user";
import API_URL from "../../../config/apiHost";
import LinkPost from "../../Feed/Components/LinkPost";
function ProfileContactSaved() {
  const [postIds, setPostids] = useState([]);
  const [hasNext, setHasNext] = useState(false);
  const [loading, setLoading] = useState(false);
  const [posts, setPost] = useState([]);
  const [limit, _] = useState(20);
  const [offset, setOffset] = useState(0);
  const [postChildren, setpostChildren] = useState([]);
  const [files, setFiles] = useState([]);
  const [users, setUsers] = useState([]);
  const [profileInfo, setProfileData] = useState([]);
  const [favList, setFavorites] = useState([]);
  useEffect(() => {
    const getSavedContact = async () => {
      setLoading(true);
      const profileData = await getProfile({});
      setProfileData(profileData);
      const getFavorite = await getFavorites({ limit: limit, offset: offset});
      setHasNext(getFavorite.hasNext);
      setFavorites(getFavorite.data);
      getFavorite.data.map((item) => {
        postIds.push(item.post_id);
      });

      setPostids(postIds);

      try {
        const savePosts = await getSavedPosts({ postIds: postIds });
        if (savePosts.status === 200) {
          setPost(savePosts.data.posts.reverse());
          setpostChildren(savePosts.data.postChildren);
          setFiles(savePosts.data.files);
          setUsers(savePosts.data.users);
        }
      } catch (error) {}
      setLoading(false);
    };

    getSavedContact();
  }, [offset]);

  return (
    <>
      <div className="feed-list">
        {posts.length > 0 &&
          posts.map((item, index) => {
            const child =
              item.children.length > 0 &&
              postChildren.filter((ch) => ch.parentPostId == item.postId);
            const user =
              users.length > 0 &&
              users.find((user) => user.userId == item.postedUserId);
            const favData =
              favList.length > 0 &&
              favList.find((fv) => fv.post_id == item.postId);
            let wishlist = favData !== undefined ? favData.status : false;
            const like = item.myReactions.includes("like");
            const love = item.myReactions.includes("love");
            const commentCount = item.commentsCount;
            
              if (item?.metadata?.isLink) {
                return (
                  <React.Fragment key={index}>
                    <LinkPost
                      user={user}
                      date={item.createdAt}
                      tick={true}
                      url={item.metadata.preview_json.url}
                      imgSrc={ item?.metadata?.preview_json?.images[0]
                        ? item.metadata.preview_json.images[0]
                        : API_URL + "uploads/events/no-image.png"}
                      postTitle={item.data.text}
                      linkTitle={item.metadata.preview_json.title}
                      linkDesc={item.metadata.preview_json.description}
                      commentCount={commentCount}
                      reactionsCount={
                        item.reactions?.like ? item.reactions.like : 0
                      }
                      loveReactionCount={
                        item.reactions?.love ? item.reactions.love : 0
                      }
                      like={like}
                      love={love}
                      wishlist={wishlist}
                      postId={item.postId}
                      setLoading={setLoading}
                      type={"link"}
                    />
                  </React.Fragment>
                );
              }

              if (item?.metadata?.isArticle) {
              return (
                <React.Fragment key={index}>
                  <LinkPost
                    user={user}
                    date={item.createdAt}
                    tick={true}
                    url={"/post/" + item.postId}
                    imgSrc={item?.metadata?.images
                      ? item?.metadata?.images[0]?.imgLink
                      : API_URL + "uploads/events/no-image.png"}
                    postTitle={item.data.text}
                    linkTitle={item.metadata.title}
                    linkDesc={item.metadata.subtitle}
                    commentCount={commentCount}
                    reactionsCount={
                      item.reactions?.like ? item.reactions.like : 0
                    }
                    loveReactionCount={
                      item.reactions?.love ? item.reactions.love : 0
                    }
                    like={like}
                    love={love}
                    wishlist={wishlist}
                    postId={item.postId}
                    setLoading={setLoading}
                    type={"article"}
                  />
                </React.Fragment>
              );
            }

            if (item?.metadata?.isEvent) {
              return (
                <React.Fragment key={index}>
                  <LinkPost
                    user={user}
                    date={item.createdAt}
                    tick={true}
                    url={`/event-details/${item.metadata.preview_event_json.event_slug}/${item.metadata.preview_event_json.event_id}`}
                    imgSrc={
                      item.metadata.preview_event_json.hasPic
                        ? `${API_URL}uploads/events/500/${item.metadata.preview_event_json.image}`
                        : item.metadata.preview_event_json.image
                    }
                    postTitle={item.data.text}
                    linkTitle={item.metadata.preview_event_json.title}
                    linkDesc={item.metadata.preview_event_json.description}
                    commentCount={commentCount}
                    reactionsCount={
                      item.reactions?.like ? item.reactions.like : 0
                    }
                    loveReactionCount={
                      item.reactions?.love ? item.reactions.love : 0
                    }
                    like={like}
                    love={love}
                    wishlist={wishlist}
                    postId={item.postId}
                    setLoading={setLoading}
                    type={"event"}
                  />
                </React.Fragment>
              );
            }
          })}
      </div>
      {hasNext && (
        <div className="feed-action">
          <button
            type="button"
            className="btn-action"
            id="load"
            onClick={() => setOffset(limit+offset)}
          >
            Load More
          </button>
        </div>
      )}
      <LoaderFull loaderActive={loading} />
    </>
  );
}

export default ProfileContactSaved;
