import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, EffectFade } from "swiper";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/effect-fade";
import LoaderFull from "../../../components/LoaderFull";
import { featuredBlogs } from "../../../store/blog";
import { useDispatch, useSelector } from "react-redux";

const SecStoriesBanner = () => {
  const dispatch = useDispatch();
  const { bloglist, featuredbloglist, loading } = useSelector((state) => state.blogs);
  const { settingData } = useSelector((state) => state.settings);
  useEffect(() => {
    if (settingData?.ghost_api) {
      dispatch(
        featuredBlogs({
          limit: 1,
          featured: true
        })
      );
    }
  }, [dispatch, settingData?.ghost_api]);

  return (
    <>
      <section className="sec-stories-banner">
        <Swiper
          modules={[Pagination, EffectFade]}
          spaceBetween={0}
          slidesPerView={1}
          pagination={{
            el: ".swiper-pagination",
            type: "bullets",
            clickable: true,
          }}
          effect="fade"
        >
          {featuredbloglist.length > 0 ?
            featuredbloglist.map((item, i) => {
              return (
                <React.Fragment key={i}>
                  <SwiperSlide>
                    <div className="swiper-slide">
                      <Link to={`/story/${item.id}`} className="stories">
                        <div className="stories-image">
                          <img src={item.feature_image} alt="" />
                        </div>
                        <div className="container">
                          <div className="stories-content">
                            <h1 className="stories-title">{item.title}</h1>
                          </div>
                        </div>
                      </Link>
                    </div>
                  </SwiperSlide>
                </React.Fragment>
              );
            })

            : 

            bloglist?.map((item, i) => {
              if(i === 0) {
                return (
                  <React.Fragment key={i}>
                    <SwiperSlide>
                      <div className="swiper-slide">
                        <Link to={`/story/${item.id}`} className="stories">
                          <div className="stories-image">
                            <img src={item.feature_image} alt="" />
                          </div>
                          <div className="container">
                            <div className="stories-content">
                              <h1 className="stories-title">{item.title}</h1>
                            </div>
                          </div>
                        </Link>
                      </div>
                    </SwiperSlide>
                  </React.Fragment>
                )
              }
            })
          }
        </Swiper>
        <div className="container">
          <div className="swiper-pagination"></div>
        </div>
      </section>
      <LoaderFull loaderActive={loading} />
    </>
  );
};

export default SecStoriesBanner;
