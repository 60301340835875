import { Outlet } from "react-router-dom";
import BackHeader from "../shared/BackHeader";
import DefaultFooter from "../shared/DefaultFooter";

const BackCtaLessLayout = () => {
  return (
    <>
      <BackHeader />
      <Outlet />
      <DefaultFooter />
    </>
  );
};

export default BackCtaLessLayout;
