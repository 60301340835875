import { Link } from 'react-router-dom';

const SecStoriesListItem = ({item}) => {
  function createMarkup() {
    return {__html: item.title};
  }
  
  return (
    <>
      <Link to={"/story/"+item.id} className="stories-item">
        <div className='item-image'><img src={item.feature_image} alt=''/></div>
        <div className='item-content'>
          <h4 className='item-title' dangerouslySetInnerHTML={createMarkup()}></h4>
        </div>
      </Link>
    </>
  );
};

export default SecStoriesListItem;