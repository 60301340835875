import { useState } from "react";
import ConnectionsCard from "../../../components/ConnectionsCard";

const OthersProfileConection = ({ common, otherUserConnection }) => {
  const [toggleClass, setToggleClass] = useState(false);
  const [toggleShare, setToggleShare] = useState(false);
  return (
    <>
      <div className="connection">
        <h4
          className="connection-title"
          onClick={() => setToggleClass(!toggleClass)}
          style={{ cursor: "pointer" }}
        >
          In common
        </h4>
      
          <div className="connection-list">
            {common?.data?.length > 0 && common.data.map((item, index) => (
              <div className="connection-item" key={index}>
                <ConnectionsCard
                 connection = {false}
                 channel_id={item?.channel_id}
                 receiver_user_id={item?.receiver_user_id}
                  name={item?.user_name}
                  image={item?.user_image}
                  designation={item?.user_headline}
                  connected={true}
                  link={item?.user_slug}
                />
              </div>
            ))}
          </div>
        
      </div>
      <div className="connection">
        <h4
          className="connection-title"
          onClick={() => setToggleShare(!toggleShare)}
          style={{ cursor: "pointer" }}
        >
          Connections
        </h4>
        
          <div className="connection-list">
            {otherUserConnection?.data?.map((item, index) => (
              <div className="connection-item" key={index}>
                <ConnectionsCard
                  connection = {true}
                 channel_id={item?.channel_id}
                  name={item.user_name}
                  image={item.user_image}
                  designation={item.user_headline}
                  connected={false}
                  link={item.user_slug}
                />
              </div>
            ))}
          </div>
       
      </div>
    </>
  );
};

export default OthersProfileConection;
