import { useState } from "react";
import { useSelector } from "react-redux";
import { addComment } from "../../../store/feed";
import { addNotification } from "../../../store/notification";

function AddComment({
  postId,
  parentComment,
  postuserId,
  setParentComment,
  parentUserId,
  setParentUserId,
}) {
  const { profileDetails } = useSelector((state) => state.users);
  const handleKeyDown = (e) => {
    e.target.style.height = `40px`;
    e.target.style.height = `${e.target.scrollHeight}px`;
  };
  const [comment, setComment] = useState("");
  const saveComment = async () => {
    document.getElementById("saveCmt").innerHTML = "Submitting...";
    let add = [];
    if (parentComment !== null) {
      add = await addComment({
        referenceId: postId,
        comment: comment,
        parentId: parentComment,
      });
      setParentComment(null);

      if (parentUserId !== profileDetails?._id) {
        await addNotification({
          source_id: postId,
          receiver_id: parentUserId,
          source_type: "comment",
          content: "{{userName}} replied to your comment",
        });
        setParentUserId(null);
      }
    } else {
      add = await addComment({
        referenceId: postId,
        comment: comment,
      });

      if (postuserId !== profileDetails?._id) {
        await addNotification({
          source_id: postId,
          receiver_id: postuserId,
          source_type: "comment",
          content: "{{userName}} commented on your post",
        });
      }
    }

    setComment("");
  };

  const editComment = (e) => {
    setComment(e.target.value);
  };

  const cancelComment = (e) => {
    setComment("");
  };

  return (
    <>
      <div className="comment-write">
        <div className="form-group">
          <div
            className={
              "input-style input-style-textarea" + (comment ? "hasValue" : "")
            }
          >
            <input
              className="form-control"
              id="cmt"
              name="comment"
              autoComplete="off"
              value={comment}
              onKeyDown={(e) =>
                e.key === "Enter" && comment !== "" && saveComment()
              }
              onChange={(e) => {
                editComment(e);
                handleKeyDown(e);
              }}
            ></input>
            <div className="float-label">Write your comment</div>
          </div>
        </div>

        <div className="post-action">
          <button
            className="btn save"
            id="saveCmt"
            type="button"
            onClick={() => saveComment()}
            disabled={comment === "" ? true : false}
          >
            Submit
          </button>
          <div
            className="btn cancel"
            type="button"
            onClick={() => cancelComment()}
          >
            Cancel
          </div>
        </div>
      </div>
    </>
  );
}

export default AddComment;
