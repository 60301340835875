import { useState } from "react";
import "./Css/ShareLinks.css";
import axios from "axios";
import BASE_URL from "../../config/host";
import LoaderFull from "../../components/LoaderFull";

import { createMyPost, createPost } from "../../store/feed";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import API_URL from "../../config/apiHost";
const ShareLinks = () => {
  const navigate = useNavigate();
  const [search, setSearch] = useState("");
  const [loading, setLoading] = useState(false);
  const [shareDesc, setShareDesc] = useState("");
  const [name, setName] = useState("");
  const [linkDetails, setLinkDetails] = useState({});
  const [shareContent, setShareContent] = useState(false);
  const [about, setAbout] = useState("");
  const [count, setCount] = useState("");
  const [image, setImage] = useState("");
  const [alertMessage, setAlertMessage] = useState("");

  const { settingData } = useSelector((state) => state.settings);

  const resetValue = (event) => {
    setSearch((current) => "");
  };

  const handleKeyDown = (e) => {
    e.target.style.height = `40px`;
    e.target.style.height = `${e.target.scrollHeight}px`;
    if(e.target.value === "") {
      setAlertMessage("This Cannot be empty!")
    } else {
      setAlertMessage("");
    }
  };
  const handleWordCount = (e) => {
    const charCount = e.target.value.length;
    setCount(charCount);
  };
  const checkUrl = (string) => {
    let givenURL;
    try {
      givenURL = new URL(string);
    } catch (error) {
      return false;
    }
    return true;
  };
  const routeChange = async () => {
    let cheCkUrl = checkUrl(search);
    if (cheCkUrl) {
      try {
        setLoading(true);
        const result = await axios.post(BASE_URL + "/link-preview", {
          url: search,
        });
        if (result) {
          setAlertMessage(false);
          setShareContent(true);
          setShareDesc(result.data?.description ? result.data?.description : "");
          setName(result.data?.title ? result.data?.title : search);
          setLinkDetails(result.data);
          setImage(result.data?.images[0] ? result.data?.images[0] : "");
        }
      } catch (error) {
        setAlertMessage(error?.response?.data?.message);
      }

      setLoading(false);
    } else {
      setAlertMessage("Invalid URL");
    }
  };

  const handleAction = async () => {
    
    if(about === ""){
      setAlertMessage("This Cannot be empty")
    }else{
      setLoading(true);

      await createPost({
        data: {
          text: about,
        },
        targetType: "community",
        targetId: settingData?.community_id,
        metadata: {
          preview_json: image.length !== 0 ? linkDetails : {...linkDetails, images: [API_URL+"uploads/events/no-image.png"], title: name, description: ""},
          isLink: true,
        },
      }).then(async (res) => {
        // console.log(res);
        await createMyPost({
          post_id: res.data?.posts[0]?._id,
        }).then(() => {
          setLoading(false);
          navigate("/feed");
        });
      });
    }
    
  };

  return (
    <>
      {shareContent ? (
        <section className="share-content-section">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-md-8">
                <div className="share-content">
                  <div className="share-item">
                    <div className="share-image">
                      <img src={image.length !== 0 ? image : API_URL+"uploads/events/no-image.png"} alt="" />
                    </div>
                    <div className="share-content">
                      <div className="share-title">{name.length > 30 ? name.substring(0,60) + "..." : name}</div>
                      <div className="share-title">{shareDesc}</div>
                      <div className="share-action">
                        <button
                          className="edit-action"
                          onClick={() => setShareContent(false)}
                        >
                          <i className="fa-solid fa-pen mr-3"></i>Resume editing
                        </button>
                        {/* <button type="button" className="edit-action alt" data-toggle="modal" data-target="#deleteModal">
                      <i className="fa-regular fa-trash-can mr-2"></i>Delete draft
                    </button> */}
                      </div>
                    </div>
                  </div>
                  <div className="form-group">
                    <div
                      className={
                        "input-style input-style-textarea " +
                        (about ? "hasValue" : "")
                      }
                    >
                      <textarea
                        className="form-control"
                        name="about"
                        autoComplete="off"
                        value={about}
                        onChange={(e) => {
                          setAbout(e.target.value);
                          handleKeyDown(e);
                          handleWordCount(e);
                        }}
                        onPaste={handleKeyDown}
                        maxLength="200"
                      ></textarea>
                      {alertMessage && (
                      <p style={{ color: "red" }}>{alertMessage}</p>
                    )}
                      <div className="input-count">{count} / 200</div>
                      <div className="float-label">
                        Say something about that
                      </div>
                    </div>
                  </div>
                  <div className="share-action-btn">
                    <button
                      type="button"
                      className="btn-action"
                      onClick={handleAction}
                    >
                      Share on CEO
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      ) : (
        <section className="share-links-section">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-md-8">
                <form className="form-content" id="search-form" onSubmit={(e) => {
                            e.preventDefault();
                            routeChange();
                          }}>
                  <div className="form-group">
                    <div
                      className={"input-style " + (search ? "hasValue" : "")}
                    >
                      <input
                        type="text"
                        className="form-control"
                        name="search"
                        autoComplete="off"
                        value={search}
                        onChange={(e) => {
                          setSearch(e.target.value);
                        }}
                      />
                      <button
                        type="button"
                        className="input-reset"
                        onClick={resetValue}
                      >
                        <i className="fa-light fa-fw fa-times"></i>
                      </button>

                      <div className="float-label">Paste a link</div>
                    </div>
                    {alertMessage && (
                      <p style={{ color: "red" }}>{alertMessage}</p>
                    )}
                    <div
                      className={"search-action " + (search ? "active" : "")}
                    >
                      <button
                        type="button"
                        className="btn-action"
                        onClick={routeChange}
                      >
                        Continue
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </section>
      )}
      <LoaderFull loaderActive={loading} />
    </>
  );
};

export default ShareLinks;
