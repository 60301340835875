import React from "react";
import { Link } from "react-router-dom";
import Fade from "react-reveal/Fade";

const SecHomeStoriesItem = ({ name, id, image }) => {
  return (
    <>
      <div className="stories-item">
        <Fade>
          <Link to={"/story/" + id} className="stories-inner">
            <div className="stories-info">
              <h4 className="stories-title">{name}</h4>
            </div>
            <div className="stories-image">
              <img src={image} alt="" />
            </div>
          </Link>
        </Fade>
      </div>
    </>
  );
};

export default SecHomeStoriesItem;
