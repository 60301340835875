import { useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { addNotification } from "../store/notification";
import { sendConnectReq } from "../store/user";

const ConnectionsCard = ({
  connection,
  receiver_user_id,
  channel_id,
  name,
  image,
  designation,
  connected,
  link,
}) => {
  const [loadrequest, setLoadRequest] = useState(false);
  const [disabled, setDisabled] = useState(false); 
  const { profileDetails } = useSelector((state) => state.users);
  async function sendReq(receiver_user_id) {
    setLoadRequest(true);
    await sendConnectReq({ receiver_user_id: receiver_user_id });
    document.getElementById("sendBtn" + receiver_user_id).innerHTML =
      "Request Sent!";
      setDisabled(true);
    setLoadRequest(false);
    await addNotification({
      source_id: profileDetails._id,
      sender_id: profileDetails._id,
      receiver_id: receiver_user_id,
      source_type: "connection",
      content: "{{userName}} send you a connection request",
    });
  }

  return (
    <>
      <div className="connection-card">
        <Link to={"/profile/" + link} className="connection-inner">
          <div className="connection-image">
            <img src={image} alt="" />
          </div>
          <div className="connection-name">{name}</div>
          <div className="connection-designation">{designation}</div>
        </Link>
        {!connection && (
          <div className="connection-action">
            {connected ? (
              <button
                type="button"
                className={"btn-action" + (connected ? " alt" : "")}
              >
                <Link to={"/message/" + channel_id}>
                  <i className="fal fa-fw fa-comment mr-2"></i>Message
                </Link>
              </button>
            ) : (
              <button
                type="button"
                className="btn-action"
                onClick={() => sendReq(receiver_user_id)}
                id={"sendBtn" + receiver_user_id}
                disabled={disabled}
              >
                {loadrequest ? (
                  <i className="fal fa-fw fa-spin fa-spinner mr-2"></i>
                ) : (
                  <i className="fal fa-fw fa-user-plus mr-2"></i>
                )}
                Connect
              </button>
            )}
          </div>
        )}
      </div>
    </>
  );
};

export default ConnectionsCard;
