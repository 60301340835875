import { useNavigate } from "react-router-dom";

export default function ConfirmationModal() {
    const navigate = useNavigate();
  return (
    <div
      className="modal event-delete-modal fade"
      data-backdrop="static"
      id="deleteModal"
      tabIndex="-1"
      aria-labelledby="deleteModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered modal-sm">
        <div className="modal-content">
          <div className="modal-body">
            <div className="delete-content">
              <div className="icon">
                <i className="fa-solid fa-triangle-exclamation"></i>
              </div>
              <div className="text">
                <div className="main-title">Are you sure?</div>
                <div className="des">
                  You have not saved your draft yet. If you leave It will be
                  lost.
                </div>
              </div>
            </div>
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-close"
              data-dismiss="modal"
            >
              Continue editing
            </button>
            <button
              type="button"
              className="btn btn-confirm"
              data-dismiss="modal"
              onClick={() => navigate(-1)}
            >
              Exit without saving
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
