import { Link } from 'react-router-dom';

const SecStoriesSliderItem = ({item}) => {
  return (
    <>
      <Link to={"/story/"+item.id} className="stories-content">
        <div className="stories-image"><img src={item.feature_image} alt=""/></div>
        <h2 className="stories-title">{item.title}</h2>
      </Link>
    </>
  );
};

export default SecStoriesSliderItem;