import { createSlice } from "@reduxjs/toolkit";
import { apiCallBegan } from "./api";
import BASE_URL from "../config/host";
import axios from "axios";

export const fetchPlaylistDetails = async (body) => {
  const res = await axios.post(BASE_URL + "/video-playlist/details", body);
  return res.data.data;
};

export const fetchPodcastPlaylist = async (body) => {
  const res = await axios.post(BASE_URL + "/video-playlist/list", body);
  return res.data;
};

export const directfetchPlaylist = async (body) => {
  const res = await axios.post(BASE_URL + "/video-playlist/list", body);
  return res.data;
};

export const playlistSlice = createSlice({
  name: "playlist",
  loading: false,
  initialState: {
    playList: [],
    bannerplayList: [],
    podcastplayList: [],
    count: 0,
    loading: false,
    videoplayList: [],
  },
  reducers: {
    Requested: (state) => {
      state.loading = true;
    },
    RequestFailed: (state) => {
      state.loading = false;
      state.playList = "";
    },
    Received: (state, action) => {
      state.loading = false;
      state.playList = action.payload.data;
      state.count = action.payload.count;
    },
    RequestedPlaylist: (state) => {
      state.loading = true;
      state.videoplayList = [];
    },
    RequestPlaylistFailed: (state) => {
      state.loading = false;
      state.videoplayList = [];
    },
    ReceivedPlaylist: (state, action) => {
      state.loading = false;
      state.videoplayList = action.payload.data;
      state.count = action.payload.count;
    },
    RequestedBanner: (state) => {
      state.loading = true;
    },
    RequestBannerFailed: (state) => {
      state.loading = false;
      state.bannerplayList = "";
    },
    ReceivedBanner: (state, action) => {
      state.loading = false;
      state.bannerplayList = action.payload.data;
      state.count = action.payload.count;
    },

    RequestedPodcast: (state) => {
      state.loading = true;
    },
    RequestPodcastFailed: (state) => {
      state.loading = false;
      state.bannerplayList = "";
    },
    ReceivedPodcast: (state, action) => {
      state.loading = false;
      state.bannerplayList = action.payload.data;
      state.count = action.payload.count;
    },

    moreRequested: (state) => {
      state.loading = true;
    },
    moreRequestFailed: (state) => {
      state.loading = false;
      state.playList = "";
    },
    moreReceived: (state, action) => {
      state.loading = false;
      state.playList = [...state.playList, ...action.payload.data];
      state.count = action.payload.count;
    },

    PodcastRequested: (state) => {
      state.loading = true;
    },
    PodcastRequestFailed: (state) => {
      state.loading = false;
      state.podcastplayList = "";
    },
    PodcastReceived: (state, action) => {
      state.loading = false;
      state.podcastplayList = [...state.playList, ...action.payload.data];
      state.count = action.payload.count;
    },
  },
});

export const {
  RequestFailed,
  Received,
  Requested,
  moreReceived,
  moreRequested,
  moreRequestFailed,
  ReceivedBanner,
  RequestedBanner,
  RequestBannerFailed,
  ReceivedPodcast,
  RequestedPodcast,
  RequestPodcastFailed,
  ReceivedPlaylist,
  RequestedPlaylist,
  RequestPlaylistFailed,
  PodcastReceived,
  PodcastRequested,
  PodcastRequestFailed,
} = playlistSlice.actions;

export const fetchPlaylist = (body) =>
  apiCallBegan({
    url: "/video-playlist/list",
    method: "post",
    data: body,
    baseUrl: BASE_URL,
    onSuccess: Received.type,
    onStart: Requested.type,
    onError: RequestFailed.type,
  });

export const fetchVideoPlaylist = (body) =>
  apiCallBegan({
    url: "/video-playlist/list",
    method: "post",
    data: body,
    baseUrl: BASE_URL,
    onSuccess: ReceivedPlaylist.type,
    onStart: RequestedPlaylist.type,
    onError: RequestPlaylistFailed.type,
  });

export const fetchPlaylistForBanner = (body) =>
  apiCallBegan({
    url: "/video-playlist/list",
    method: "post",
    data: body,
    baseUrl: BASE_URL,
    onSuccess: ReceivedBanner.type,
    onStart: RequestedBanner.type,
    onError: RequestBannerFailed.type,
  });

export const fetchPlaylistPodcast = (body) =>
  apiCallBegan({
    url: "/video-playlist/list",
    method: "post",
    data: body,
    baseUrl: BASE_URL,
    onSuccess: ReceivedPodcast.type,
    onStart: RequestedPodcast.type,
    onError: RequestPodcastFailed.type,
  });

export const morefetchPlaylist = (body) =>
  apiCallBegan({
    url: "/video-playlist/list",
    method: "post",
    data: body,
    baseUrl: BASE_URL,
    onSuccess: moreReceived.type,
    onStart: moreRequested.type,
    onError: moreRequestFailed.type,
  });
