import { useEffect } from "react";

import VideoItem from "./VideoItem";
import { Link } from "react-router-dom";

const SearchVideo = ({ videoData,setVidCount }) => {
  useEffect(()=>{
    setVidCount(videoData.length);
  },[videoData]);
  return (
    <>
      <div className="search-video">
        {videoData.map((item) => {
          return (
            <>
              <div className="search-video-header">
                <h2 className="search-video-title">{item.name}</h2>
                <Link className="btn-viewAll" to={"/playlist/"+item.id}>
                  See All
                </Link>
              </div>
             <VideoItem item={item}/>
            </>
          );
        })}
      </div>
    </>
  );
};

export default SearchVideo;
