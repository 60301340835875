import React from "react";
import ProfileInterestedEvent from "./ProfileInterestedEvent";
import ProfileOwnEvent from "./ProfileOwnEvent";

const ProfileEvents = () => {
  return (
    <>
      <div className="">
        <ProfileOwnEvent />
        {/* <ProfileInterestedEvent/> */}
      </div>
    </>
  );
};

export default ProfileEvents;
