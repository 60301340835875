import CommentList from "./CommentList";
import AddComment from "./AddComment";
import { useState } from "react";

function PostComments({ commentCount, postId, setCommentCount, postuserId }) {
  const [parentComment, setParentComment] = useState(null);
  const [parentUserId, setParentUserId] = useState(null);
  return (
    <>
      <div className="post-comment">
        <div className="total-comments">{commentCount} Comments</div>
        <AddComment
          postId={postId}
          parentComment={parentComment}
          postuserId={postuserId}
          setParentComment={setParentComment}
          parentUserId={parentUserId}
          setParentUserId={setParentUserId}
        />
        <CommentList
          postId={postId}
          setParentComment={setParentComment}
          setCommentCount={setCommentCount}
          commentCount={commentCount}
          postuserId={postuserId}
          setParentUserId={setParentUserId}
        />
      </div>
    </>
  );
}

export default PostComments;
