import React, { useState,useRef, useEffect } from 'react';
import Webcam from "react-webcam";
import { useDispatch } from 'react-redux';
import { updateProfile,addStep } from '../../../store/user';
const ProfileFormInformation = ({ formView, setFormView,profileData }) => {
  const dispatch = useDispatch();
  const [fname, setFname] = useState('');
  const [lname, setLname] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [picture, setPicture] = useState('');
  const [isTake, setTake] = useState(false);
  const [chgImg, changeImg] = useState(false);
  const webcamRef = useRef(null);
  const allowedExts = ['jpg', 'jpeg', 'png','gif', 'svg'];
  const switchPanel = async() => {
    setFormView({ ...formView, first: { active: false, completed: true }, second: { active: true, completed: false } });
    let dt = { ...formView, first: { active: false, completed: true }, second: { active: true, completed: false } };
    await addStep({step:dt});
    chgImg && dispatch(updateProfile({name:fname+' '+lname,email:email,phone:phone,image:picture}));
    !chgImg && dispatch(updateProfile({name:fname+' '+lname,email:email,phone:phone}));

  }
 const takeImg = () =>{
    setTake(true);
  }

  

  const captureImg = React.useCallback(() => {
    const imageSrc = webcamRef.current.getScreenshot();
    setPicture(imageSrc);
    setTake(false);
    changeImg(true);
  }, [webcamRef, setPicture]);

  useEffect(()=>{
   
    if(profileData._id !== undefined ){
      setFname(profileData?.first_name);
      setLname(profileData?.last_name);
      setEmail(profileData?.email);
      setPhone(profileData?.phone);
      setPicture(profileData?.image);
    }
   
  },[profileData])


  const getUploadImg = e => {
    let base64String = "";
    let file = e.target.files[0];
    const ext = e.target.files[0].type.split('/')[1];
    if(!allowedExts.includes(ext)) {
      alert('Please upload a valid image.');
      changeImg(false);
    }else{
    if(file instanceof File && file.name) {
      const reader = new FileReader();
      
      reader.onload = () => {
        base64String = reader.result;
        const imageBase64Stringsep = base64String;
       setPicture(imageBase64Stringsep);
       changeImg(true);
      }
  
      reader.readAsDataURL(file);
    }
  }
  }
 
  return (
    <>
      <div className='profile-forms'>
        <div className='container'>
          <div className='row'>
            <div className='col-lg-4'>
              <div className='profile-forms-content'>
                <h1 className='profile-forms-title'>Is this information correct?</h1>
              </div>
            </div>
            <div className='col-lg-6 offset-lg-2'>
              <form className={"form-input-content " + ((fname && lname && email && phone) ? 'active' : '')}>
                <div className="form-group">
                  <div className={"input-style " + (fname ? 'hasValue' : '')}>
                    <input type="text" className="form-control" name='fname' value={fname} onChange={(e) => setFname(e.target.value)}/>
                    <div className="float-label">First name*</div>
                  </div>
                </div>
                <div className="form-group">
                  <div className={"input-style " + (lname ? 'hasValue' : '')}>
                    <input type="text" className="form-control" name='lname' value={lname} onChange={(e) => setLname(e.target.value)}/>
                    <div className="float-label">Last name*</div>
                  </div>
                </div>
                <div className="form-group">
                  <div className={"input-style " + (email ? 'hasValue' : '')}>
                    <input type="email" className="form-control" name='email' value={email} onChange={(e) => setEmail(e.target.value)} disabled/>
                    <div className="float-label">E-mail*</div>
                  </div>
                </div>
                <div className="form-group">
                  <div className={"input-style " + (phone ? 'hasValue' : '')}>
                    <input type="tel" className="form-control" name='phone' value={phone} onChange={(e) => setPhone(e.target.value)}/> 
                    <div className="float-label">Phone number*</div>
                  </div>
                </div>
                <div className="form-group">
                  <div className='profile-picture'>
                    <div className='profile-picture-image'>
                      {!isTake && <img src={picture?picture:''} alt=''/>}
                      {isTake && <Webcam ref={webcamRef} style={{width: '120px',height: '120px',borderRadius: '50%',objectFit: 'cover'}}/>} 
                      </div>
                    <div className='profile-picture-content'>
                      <div className='profile-picture-title'>Profile picture</div>
                      <div className='profile-picture-text'>We made this NFT image especially for you to use as an avatar. You can also change it later.</div>
                      <div className='profile-picture-action'>
                      {!isTake &&
                        <button type='button' className='btn-action' onClick={takeImg}><i className='fa-light fa-fw fa-camera mr-2'></i>Take new photo</button>
                      }
                      {isTake &&
                        <button type='button' className='btn-action' onClick={captureImg}><i className='fa-light fa-fw fa-camera mr-2'></i>Capture</button>
                      }
                        <button type='button' className='btn-action'>
                          <input type='file' className='file-input' value="" name='image' onChange={(e) =>getUploadImg(e)}/>
                          <i className='fa-light fa-fw fa-cloud-upload mr-2'></i>Upload from device
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="form-options">
                  <div className="form-check">
                    <input type="checkbox" className="form-check-input" name='picture' checked={picture} onChange={(e) => setPicture(!picture)}/>
                    <div className="form-check-label">I don't want to use a profile picture right now.</div>
                  </div>
                </div>
                <div className="form-group form-action"> 
                  <button type="button" className="btn-action btn-activeable" onClick={ () => switchPanel()}>Continue</button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ProfileFormInformation;