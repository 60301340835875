import { Outlet, useLocation } from 'react-router-dom';
import CallToAction from '../components/CallToAction';
import DefaultFooter from '../shared/DefaultFooter';
import DefaultHeader from '../shared/DefaultHeader';

const DefaultLayout = () => {
  const location = useLocation();
  return (
    <>
      <DefaultHeader />
      <Outlet />
      {location.pathname !== '/' && 
      <CallToAction />
      }
      <DefaultFooter />
    </>
  );
};

export default DefaultLayout;