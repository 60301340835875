import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { trackDetails } from "../../../store/track";
import { useDispatch } from "react-redux";
import "react-h5-audio-player/lib/styles.css";
import LoaderFull from "../../../components/LoaderFull";
import SpotifyPlayer from "react-spotify-player";
import ShareModal from "../../Story/Components/shareModal";

const SecPodcastPlay = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { track_id } = useParams();
  const [trackList, setTrackList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [modal, setModal] = useState(false);

  useEffect(() => {
    const loadTrackDetails = async () => {
      setLoading(true);
      const load = await trackDetails({ track_id: track_id });
      setTrackList(load);
      setLoading(false);
    };

    loadTrackDetails();
  }, [dispatch]);

  const size = {
    width: "100%",
    height: 300,
  };

  return (
    <>
      <section className="section-podcast-play">
        <div className="container">
          <div className="row">
            <div className="col-lg-6">
              <div className="podcast-content">
                <button
                  type="button"
                  className="btn-back mb-5"
                  onClick={() => navigate(-1)}
                >
                  <i className="fa-light fa-fw fa-chevron-left"></i>
                </button>
                <h1 className="podcast-title">{trackList.name}</h1>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-12">
              <div className="podcast-content">
                <SpotifyPlayer
                  token="BQAGGmi1DX3fe954XtB16SMA99zF1Tjunjb1lGFDIxl8HT8KynIqGUGF3fQkI8deqvJA3wnUPQVmTB3yujM0MhY3pt08PIBRaw-NUm_R5ANz25UDSmk"
                  uri={trackList.uri}
                  theme="black"
                  size={size}
                />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-12">
              <div className="podcast-options">
                <div className="podcast-share">
                  <div className="share-title">Share</div>
                  <div className="share-part">
                    <button
                      type="button"
                      className="share-action"
                      data-toggle="modal"
                      data-target="#shareModal"
                      style={{ backgroundColor: "transparent" }}
                      onClick={() => setModal(true)}
                    >
                      <i className="fa-light fa-fw fa-arrow-up-from-bracket"></i>
                    </button>
                  </div>
                  {modal ? (
                    <ShareModal
                      podcatstdetails={trackList}
                      track_id={track_id}
                    />
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <LoaderFull loaderActive={loading} />
    </>
  );
};

export default SecPodcastPlay;
