import moment from "moment/moment";

const OthersProfileMetaItem = ({
  title,
  subtitle,
  startDate,
  endDate,
  type,
  isWorking,
}) => {
  return (
    <>
      <div className="profile-meta-item">
        {title && <div className="profile-meta-title">{title}</div>}
        {subtitle && <div className="profile-meta-subtitle">{subtitle}</div>}

        {isWorking ? (
          <div className="profile-meta-date">
            {moment(new Date(startDate)).format("MMM YYYY")} - Present
          </div>
        ) : (
          type === "experience" && (
            <div className="profile-meta-date">
              {moment(new Date(startDate)).format("MMM YYYY")} -{" "}
              {moment(new Date(endDate)).format("MMM YYYY")}
            </div>
          )
        )}
      </div>
    </>
  );
};

export default OthersProfileMetaItem;
