import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import LoaderFull from "../components/LoaderFull";
import { useDispatch, useSelector } from "react-redux";
import { verifyToken } from "../store/user";
export const ProtectedRoute = (props) => {
  const selector = useSelector((state) => state.users);
  const { profileData } = selector;

  const dispatch = useDispatch();

  const navigate = useNavigate();
  const [isLoader, setIsLoader] = useState(true);
  const [auth, setAuth] = useState(false);
  let token_ = localStorage.getItem("accesstoken");
  useEffect(() => {
    verifyToken({})
      .then((res) => {
        setIsLoader(true);
        if (res.token_verified) {
        } else {
          localStorage.removeItem("accesstoken");
          navigate("/");
        }
      })
      .catch(() => {});

    if (profileData !== undefined && token_) {
      //console.log(TokenData);
      localStorage.setItem("userId", profileData._id);
      setIsLoader(false);
      setAuth(true);
      return;
    } else {
      // alert('gi');
      setAuth(false);
      setIsLoader(false);
      navigate("/");
    }

    if (token_) {
    } else {
      //alert('gi');
      setIsLoader(false);
      navigate("/");
    }
  }, [profileData, dispatch]);

  return (
    <>
      {isLoader && <LoaderFull />}

      {auth && props.children}
    </>
  );
};
