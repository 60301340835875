import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import BASE_URL from "../config/host";
import { apiCallBegan } from "./api";

export const addEvent = async (body) => {
  const res = await axios.post(BASE_URL + "/event/create", body, {
    headers: {
      "Content-type": "application/json",
      Authorization: `Bearer ${localStorage.getItem("accesstoken")}`,
    },
  });
  return res.data.data;
};

export const editEvent = async (body) => {
  const res = await axios.post(BASE_URL + "/event/edit", body, {
    headers: {
      "Content-type": "application/json",
      Authorization: `Bearer ${localStorage.getItem("accesstoken")}`,
    },
  });
  return res.data;
};

export const eventDetails = async (body) => {
  const res = await axios.post(BASE_URL + "/event/details", body, {
    headers: {
      "Content-type": "application/json",
      Authorization: `Bearer ${localStorage.getItem("accesstoken")}`,
    },
  });
  return res.data.data;
};

export const upcomingEventDetails = async (body) => {
  const res = await axios.post(
    BASE_URL + "/event/details-upcoming-event",
    body,
    {
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("accesstoken")}`,
      },
    }
  );
  return res.data;
};

export const deleteEvent = async (body) => {
  const res = await axios.post(BASE_URL + "/event/delete", body, {
    headers: {
      "Content-type": "application/json",
      Authorization: `Bearer ${localStorage.getItem("accesstoken")}`,
    },
  });
  return res.data;
};

export const statusChangeEvent = async (body) => {
  const res = await axios.post(BASE_URL + "/event/status-change", body, {
    headers: {
      "Content-type": "application/json",
      Authorization: `Bearer ${localStorage.getItem("accesstoken")}`,
    },
  });
  return res.data;
};

export const interestedUsers = async (body) => {
  const res = await axios.post(
    BASE_URL + "/event/interested-event-users",
    body,
    {
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("accesstoken")}`,
      },
    }
  );
  return res.data.data;
};

export const globalEventDetails = async (body) => {
  const res = await axios.post(BASE_URL + "/event/global-event-details", body, {
    headers: {
      "Content-type": "application/json",
      Authorization: `Bearer ${localStorage.getItem("accesstoken")}`,
    },
  });
  return res.data.data;
};

export const tagList = async (body) => {
  const res = await axios.post(BASE_URL + "tags", body, {
    headers: {
      "Content-type": "application/json",
      Authorization: `Bearer ${localStorage.getItem("accesstoken")}`,
    },
  });
  return res.data.data;
};

export const addInterest = async (body) => {
  const res = await axios.post(BASE_URL + "/event/manage-interest", body, {
    headers: {
      "Content-type": "application/json",
      Authorization: `Bearer ${localStorage.getItem("accesstoken")}`,
    },
  });
  return res.data;
};

export const eventSlice = createSlice({
  name: "events",
  loading: false,
  count: 0,
  interestedCount: 0,
  initialState: {
    globalEventlist: [],
    interestEventList: [],
  },
  reducers: {
    listRequested: (state) => {
      state.loading = true;
      state.globalEventlist = [];
    },
    listRequestFailed: (state) => {
      state.loading = false;
      state.globalEventlist = "";
    },
    listReceived: (state, action) => {
      state.globalEventlist = action.payload.data ? action.payload.data : [];
      state.loading = false;
      state.count = action?.payload?.count ? action.payload.count : 0;
    },
    listInterestRequested: (state) => {
      state.loading = true;
      state.interestEventList = [];
    },
    listInterestRequestFailed: (state) => {
      state.loading = false;
      state.interestEventList = "";
    },
    listInterestReceived: (state, action) => {
      state.loading = false;
      state.interestEventList = action.payload.data ? action.payload.data : [];
      state.interestedCount = action?.payload?.count ? action.payload.count : 0;
    },
    moreeventListReceived: (state, action) => {
      state.loading = false;
      state.globalEventlist = [
        ...state.globalEventlist,
        ...action.payload.data,
      ];
    },
  },
});

export const {
  listReceived,
  listRequested,
  listRequestFailed,
  listInterestReceived,
  listInterestRequested,
  listInterestRequestFailed,
  moreeventListReceived,
} = eventSlice.actions;

export const globalEvents = (body) =>
  apiCallBegan({
    url: "/event/global-list",
    method: "post",
    data: body,
    baseUrl: BASE_URL,
    onSuccess: listReceived.type,
    onStart: listRequested.type,
    onError: listRequestFailed.type,
  });

export const moreGlobalEvents = (body) =>
  apiCallBegan({
    url: "/event/global-list",
    method: "post",
    data: body,
    baseUrl: BASE_URL,
    onSuccess: moreeventListReceived.type,
  });

export const interestedEvents = (body) =>
  apiCallBegan({
    url: "/event/interested-event-list",
    method: "post",
    data: body,
    baseUrl: BASE_URL,
    onSuccess: listInterestReceived.type,
    onStart: listInterestRequested.type,
    onError: listInterestRequestFailed.type,
  });
