import Fade from 'react-reveal/Fade';

const SecMembershipBanner = () => {
  return (
    <>
      <section className='sec-membership-banner' id="scroll-section">
        <div className='container-fluid'>
          <div className='banner-content'>
            <Fade bottom>
              <h5 className='banner-subtitle'>Fly higher with</h5>
            </Fade>
            <h1 className='banner-title'>
              <Fade left>
                <span>Premium</span>
              </Fade>
              <Fade right>
                <span>Access</span>
              </Fade>
            </h1>
          </div>
        </div>
      </section>
    </>
  );
};

export default SecMembershipBanner;