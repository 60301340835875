import { useEffect, useState } from "react";
import SecStoriesListItem from "./SecStoriesListItem";
import { fetchBlogs, morefetchBlogs } from "../../../store/blog";
import { useDispatch, useSelector } from "react-redux";

const SecStoriesList = ({ featured }) => {
  const dispatch = useDispatch();
  const { bloglist, loader, next } = useSelector((state) => state.blogs);
  const { settingData } = useSelector((state) => state.settings);

  const [limit] = useState(4);

  useEffect(() => {
    if (settingData?.ghost_api) {
      if (featured === true) {
        dispatch(
          fetchBlogs({
            limit: limit,
            key: settingData?.ghost_api,
            ghost_url: settingData?.ghost_url,
            featured: true,
          })
        );
      } else {
        dispatch(
          fetchBlogs({
            limit: limit,
            key: settingData?.ghost_api,
            ghost_url: settingData?.ghost_url,
            featured: false,
          })
        );
      }
    }
  }, [dispatch, settingData?.ghost_api, featured]);

  const loadMore = async () => {
    dispatch(
      morefetchBlogs({
        limit: limit,
        page: next,
      })
    );
  };

  return (
    <>
      <div className="sec-stories-list">
        <div className="container">
          <div className="stories-list">
            {bloglist.length > 0 &&
              bloglist.map((item, index) => {
                return <SecStoriesListItem item={item} key={index} />;
              })}
          </div>
        </div>
        {next !== null && (
          <div className="container">
            <div className="stories-action">
              <button
                type="button"
                className="btn-action"
                onClick={() => loadMore()}
              >
                {loader && <i className="fa fa-spin fa-spinner mr-2"></i>}Load
                more
              </button>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default SecStoriesList;
