import axios from "axios";
import * as actions from '../api';

const api = ({dispatch}) => next => async action => {
    let token = localStorage.getItem('accesstoken');
    if(action.type !== actions.apiCallBegan.type){
        return next(action)
    }
    const {url, method, data, onStart, onSuccess, onError, baseUrl} = action.payload;
    if(onStart){
        dispatch({
            type: onStart
        })
    }
    next(action);
 
    try{
        var response = '';
        if(token){
             response = await axios.request({
                baseURL: baseUrl,
                url,
                method,
                data,
                headers: {
                    Authorization: `Bearer ${token}`,
                   }
            });
        }else{
             response = await axios.request({
                baseURL: baseUrl,
                url,
                method,
                data
            });
        }
      

        dispatch(actions.apiCallSuccess(response.data));
       // console.log(response.data);
        if(onSuccess){
            dispatch({
                type: onSuccess,
                payload: response.data
            })
            
        }

    }catch(err){
        if(err.response.data == undefined){
        }
       
        dispatch(actions.apiCallFailed(err.response.data));
        if(onError){
            dispatch({
                type: onError,
                payload: err.response.data
            })
        }
    }
}

export default api