import { useState } from "react";
import { Link } from "react-router-dom";
import { addInterest } from "../../../store/event";

const ScheduleEventItem = ({
  id,
  name,
  category,
  time,
  link,
  image,
  setListIsHovering,
  filter,
  eventIds,
  setEventIds,
}) => {
  const [isHovering, setIsHovering] = useState(false);
  const handleMouseOver = () => {
    setIsHovering(true);
    setListIsHovering(true);
  };
  const handleMouseOut = () => {
    setIsHovering(false);
    setListIsHovering(false);
  };

  function handleClick(e) {
    addInterest({ event_id: id }).then((data) => {
     
      if (filter === "interested") {
        let tmp = [...eventIds];
        let filterArr = tmp.filter((val) => val !== id);
        setEventIds(filterArr);
        console.log(filterArr);
        document.getElementById("int-btn" + id).innerHTML = "Removed";
        document.getElementById("int-btn" + id).disabled = true;
        document.getElementById("rmv" +id).remove();
        document.getElementById("int-btn"+ id).style.cursor = "not-allowed";
      } else {
       if(data.data.status===true){
          setEventIds([...eventIds, id]);
          document.getElementById("int-btn" + id).innerHTML = "Interested";
          document.getElementById("int-btn" + id).disabled = true;
          document.getElementById("int-btn"+ id).style.cursor = "not-allowed";
        }
       
      }
    });
  }
  return (
    <>
      <div
        className={"events-item " + (isHovering ? "active" : "") }
        onMouseOver={handleMouseOver}
        onMouseOut={handleMouseOut}
        id={"rmv"+id}
      >
        <div className="events-inner">
          <div className="item-image">
            <img src={image} alt="" />
          </div>
          <div className="item-content">
            <div className="item-subtitle">{category}</div>
            <div className="item-title">{name}</div>
            <div className="item-time">
              {time}
            </div>

            <div className="item-action">
            

           <button
                type="button"
                id={"int-btn" + id}
                className="btn-action alt"
                onClick={handleClick}
                disabled={localStorage.getItem("accesstoken") ? false : true}
              >
                {filter === "interested"
                  ? "Remove"
                  : eventIds?.includes(id)
                  ? "interested"
                  : "I'm interested"}
              </button>

              <Link className="btn-action" to={link}>
                More details
              </Link>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ScheduleEventItem;
