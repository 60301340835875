import React from 'react';

const ProfileProgress = ({formView}) => {
  return (
    <>
      <div className='profile-progress'>
        <button type="button" className={'progress-item ' + (formView.first.completed ? 'completed' : '') + (formView.first.active ? 'active' : '')}>
          <div className='progress-item-stage'>1</div>
          <div className='progress-item-name'>Personal information</div>
        </button>
        <button type="button" className={'progress-item ' + (formView.second.completed ? 'completed' : '') + (formView.second.active ? 'active' : '')}>
          <div className='progress-item-stage'>2</div>
          <div className='progress-item-name'>Location</div>
        </button>
        <button type="button" className={'progress-item ' + (formView.third.completed ? 'completed' : '') + (formView.third.active ? 'active' : '')}>
          <div className='progress-item-stage'>3</div>
          <div className='progress-item-name'>Education</div>
        </button>
        <button type="button" className={'progress-item ' + (formView.fourth.completed ? 'completed' : '') + (formView.fourth.active ? 'active' : '')}>
          <div className='progress-item-stage'>4</div>
          <div className='progress-item-name'>Work experience</div>
        </button>
        <button type="button" className={'progress-item ' + (formView.fifth.completed ? 'completed' : '') + (formView.fifth.active ? 'active' : '')}>
          <div className='progress-item-stage'>5</div>
          <div className='progress-item-name'>About you</div>
        </button>
      </div>
    </>
  );
};

export default ProfileProgress;