export const getSlug = (url) =>  {
    let regex = /(youtu.*be.*)\/(watch\?v=|embed\/|v|shorts|)(.*?((?=[&#?])|$))/gm;
    return regex.exec(url)[3];
  }

  export const getVimeoSlug = (url) => {

    // Look for a string with 'vimeo', then whatever, then a
    // forward slash and a group of digits.
    var match = /vimeo.*\/(\d+)/i.exec( url );
  
    // If the match isn't null (i.e. it matched)
    if ( match ) {
      // The grouped/matched digits from the regex
      return match[1];
    }
  }

  export const convertISO8601ToStringWithColons = string => {
    let hours, minutes, seconds;

    if (string.includes("H")) {
        hours = string.slice(2, string.indexOf("H"));
    } else {
        hours = false;
    }

    if (string.includes("S")) {
        // checks if number is one-digit and inserts 0 in front of it
        if (isNaN(parseInt(string.charAt(string.indexOf("S") - 2)))) {
            seconds = "0" + string.charAt(string.indexOf("S") - 1)
        } else {
            seconds = string.slice(-3, -1)
        }
    } else {
        seconds = "00"
    }
    
    // determines how minutes are displayed, based on existence of hours and minutes
    if (hours) {
        if (string.includes("M")) {
            if (string.indexOf("M") - string.indexOf("H") === 3) {
                minutes = string.slice(string.indexOf("H") + 1, string.indexOf("M"))
            } else {
                minutes = "0" + string.charAt(string.indexOf("M") - 1)
            }
        } else {
            minutes = "00"
        }
    } else {
        if (string.includes("M")) {
            minutes = string.slice(2, (string.indexOf("M")))
        } else {
            minutes = "0"
        }   
    }

    // distinction because livestreams (P0D) are not considered
    return string === "P0D" ? "Live" : `${hours ? hours + "h " : ""}${minutes? minutes + " mins":""}`
}

export const secondsToHms= (seconds) => {

    let d = Number(seconds);
  
    if(d <= 0){
       return '00:00:00'
    }else{
      let h = Math.floor(d / 3600);
      let m = Math.floor(d % 3600 / 60);
      let s = Math.floor(d % 3600 % 60);
  
      let hDisplay = h <= 9 ?h: h;
      let mDisplay = m <= 9 ? m : m;
      let sDisplay = s <= 9 ? '0'+ s : s;
  
     if(hDisplay === 0){
      return mDisplay+' mins';
     }else{
     return hDisplay+'h ' + mDisplay+' mins'; 
     }
      
  
    }
}

export const secondsTomins= (millis) => {

    var minutes = Math.floor(millis / 60000);
  var seconds = ((millis % 60000) / 1000).toFixed(0);
  return minutes + ":" + (seconds < 10 ? '0' : '') + seconds;
}


export const youtube_parser = (url) =>{
    var regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;
    var match = url.match(regExp);
    return (match&&match[7].length==11)? match[7] : false;
}