import moment from "moment";
import { Link } from "react-router-dom";
import API_URL from "../../../../config/apiHost";
import { useEffect,useCallback, useContext } from "react";
import { useState } from "react";
import { SocketContext } from "../../../../context/socket";

function ChatItem({ user, item, unseen }) {
  const socket = useContext(SocketContext);
  const day = moment(item.lastActivity).format('D');
  const today = (moment().format('D'))
  const [isOnline, setIsOnline] = useState(false);

  socket.on("online", (data) => {
    if (user.userId === data) {
      setIsOnline(true);
    }
  });

  socket.on("offline", (data) => {
    if (user.userId === data) {
      setIsOnline(false);
    }
  });


  return (

    <>
      <Link to={"/message/" + item._id} className={'user'+ ((isOnline)?' online':'')}>
        <div className="user-image-wrap">
          <div className="user-image">
            <img
              src={
                API_URL + "uploads/profile-images/100/" + user?.userId + ".png"
              }
              alt=""
            />
          </div>
          {/* <div className="user-status"></div> */}
        </div>
        <div className="user-content">
          {unseen > 0 && <span className="unseen_badge">{unseen}</span>}
          <div className="user-name">{user?.displayName}</div>
          {item?.metadata?.last_message &&
            <div className="user-comment">
              {item?.metadata?.last_message}
            </div>
          }
          <div className="user-comment-date">
            {today === day ?
              moment(item.lastActivity).fromNow() :
              moment(item.lastActivity).format("Do MMMM YYYY, h:mm A")
            }
          </div>

        </div>
      </Link>
    </>
  );
}

export default ChatItem;
