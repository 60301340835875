import { createSlice } from "@reduxjs/toolkit";
import { apiCallBegan } from "./api";
import BASE_URL from "../config/host";

export const settingSlice = createSlice({
  name: "settings",
  loading: false,
  initialState: {
    settingData: [],
  },
  reducers: {
    settingReceived: (state, action) => {
      state.loading = false;
      state.settingData = action.payload.data ? action.payload.data : [];
    },
    settingRequest: (state) => {
      state.loading = true;
    },
    settingFailed: (state) => {
      state.loading = false;
      state.settingData = "";
    },
  },
});

export const { settingReceived, settingRequest, settingFailed } =
  settingSlice.actions;
export const getSettings = (body) =>
  apiCallBegan({
    url: "/site-setting/details",
    method: "post",
    data: body,
    baseUrl: BASE_URL,
    onSuccess: settingReceived.type,
    onStart: settingRequest.type,
    onError: settingFailed.type,
  });
