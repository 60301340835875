import React from "react";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { getProfileDetails, updateProfile } from "../../../store/user";

function ProfileSidePanel({ profileDetails, savedpicture, togglePanel }) {
  const dispatch = useDispatch();
  const [active, setActive] = useState(false);
  const [name, setName] = useState(profileDetails?.name || "");

  return (
    <>
      <div className="panel-side">
        <div className="panel-side-content">
          <div className="profile-card">
            <div className="own-profile-image">
              <div>
                {profileDetails?.image && (
                  <img
                    src={savedpicture ? savedpicture : profileDetails?.image}
                    style={{
                      width: 140,
                      height: 140,
                      borderRadius: "50%",
                    }}
                    alt=""
                  />
                )}
              </div>

              <div
                className="upload-photo"
                data-toggle="modal"
                data-target="#profileModal"
              >
                <input className="input-file" type="file" />
                <div className="upload-icon">
                  <i className="fa-solid fa-camera"></i>
                </div>
              </div>
            </div>

            {active ? (
              <h2>
                <input
                  className="profile-name"
                  style={{ backgroundColor: "black" }}
                  defaultValue={profileDetails?.name}
                  onChange={(e) => setName(e.target.value)}
                />
              </h2>
            ) : (
              <h2 className="profile-name">{profileDetails?.name}</h2>
            )}
            {active ? (
              <div style={{ display: "inline-flex" }}>
                <a
                  className="fa-solid fa-lg fa-floppy-disk"
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    dispatch(
                      updateProfile({ name: name || profileDetails?.name, first_name: name ? name.split(" ")[0] : profileDetails?.first_name, last_name: name ? name.split(" ").slice(1).join(" ") : profileDetails?.last_name })
                    ).then(() => {
                      dispatch(getProfileDetails());
                    })
                    
                    setActive(false);
                  }}
                ></a>
                <div className="px-2"></div>
                <a
                  style={{ cursor: "pointer" }}
                  onClick={() => setActive(false)}
                  className="fa-light fa-lg fa-xmark"
                ></a>
              </div>
            ) : (
              <a
                style={{ cursor: "pointer" }}
                onClick={() => setActive(true)}
                className="fa-solid fa-lg fa-pen-to-square"
              ></a>
            )}

            <div className="profile-designation">
              {profileDetails?.headline}
            </div>
            <div className="profile-meta">
              {/* <div className='meta-item'>
                      <div className='meta-data'>100+</div>
                      <div className='meta-label'>Connections</div>
                    </div> */}
            </div>
            <div className="profile-actions">
              <button
                type="button"
                className="btn-action"
                onClick={togglePanel}
              >
                <i className="fa-light fa-plus mr-2"></i>Share something
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ProfileSidePanel;
