import moment from "moment";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import LoaderFull from "../../../components/LoaderFull";
import EventShareModal from "./eventShareModal";
import {
  globalEventDetails,
  interestedUsers,
  upcomingEventDetails,
} from "../../../store/event";
import API_URL from "../../../config/apiHost";
import { useSelector } from "react-redux";
import { addInterest } from "../../../store/event";

const ProfileEventDetails = () => {
  const { id } = useParams();
  const [modal, setModal] = useState(false);
  const navigate = useNavigate();
  const [Interested, setIntersted] = useState([]);
  const [count, setCount] = useState();
  const [eventData, setEventData] = useState(null);
  const [upcomingEventData, setUpcomingEventData] = useState([]);
  const [loading, setLoading] = useState(false);
  const { profileDetails, profileDetailsStatus } = useSelector(
    (state) => state.users
  );
  const [changeText, setChangeText] = useState();
  const token = localStorage.getItem("accesstoken");

  const shareEvent = () => {
    setModal(true);
  };

  useEffect(() => {
    setLoading(true);
    const callFunctions = async () => {
      if (profileDetailsStatus === 200) {
        const eventData1 = await globalEventDetails({
          event_id: id,
          user_id: profileDetails?._id,
        });
        const up = await upcomingEventDetails({ id: eventData1._id });
        setUpcomingEventData(up.data);
        setEventData(eventData1);
        setChangeText(eventData1?.is_interseted);
        setLoading(false);
        const result = await interestedUsers({ id: eventData1._id });
        setIntersted(result);
        setCount(result.count);
        setLoading(false);
      } else if (profileDetailsStatus === 400) {
        const eventData1 = await globalEventDetails({ event_id: id });
        const up = await upcomingEventDetails({ id: eventData1._id });
        setUpcomingEventData(up.data);

        setEventData(eventData1);
        setChangeText(eventData1?.is_interseted);
        setLoading(false);
        const result = await interestedUsers({ id: eventData1._id });
        setIntersted(result);
        setCount(result.count);
        setLoading(false);
      }
    };
    callFunctions();
  }, [profileDetailsStatus]);

  const handleChange = () => {
    addInterest({ event_id: eventData?._id }).then((res) => {
      // console.log(res);
      if (res.status === 200) {
        setChangeText(!changeText);
      }
    });
  };

  return (
    <>
      <section className="profile-event-content-section">
        <div className="container">
          <div className="btn-back mb-5" onClick={() => navigate(-1)}>
            <i className="fa-light fa-fw fa-chevron-left"></i>
          </div>
          <div className="row justify-content-center">
            <div className="col-xl-8">
              <div className="details-content">
                <div className="img-section">
                  {eventData && eventData.image && (
                    <img src={eventData ? eventData.image : ""} alt="" />
                  )}
                </div>
                <div className="event-edit-section">
                  <div className="event-title">
                    {eventData ? eventData.title : ""}
                  </div>
                  <div className="event-author">
                    By {eventData ? eventData.user_name : "🤷‍♂️"}
                  </div>
                </div>
                {token && (
                  <>
                    {changeText ? (
                      <div className="event-action">
                        <button
                          type="button"
                          className="link-action"
                          onClick={() => handleChange()}
                        >
                          {" "}
                          <i className="fa-light fa-xmark mr-3"></i> Remove
                          Interest
                        </button>
                      </div>
                    ) : (
                      <div className="event-action">
                        <button
                          type="button"
                          className="link-action"
                          onClick={() => handleChange()}
                        >
                          {" "}
                          <i className="fa-light"></i> I'm Interested
                        </button>
                      </div>
                    )}
                  </>
                )}

                <div className="details-event">
                  <div className="title">Details</div>
                  <div className="event-arrangement">
                    <div className="event-timings">
                      <div className="data">
                        {eventData
                          ? moment(new Date(eventData.start_date)).format(
                              "ddd DD MMM, YYYY"
                            )
                          : ""}
                      </div>
                      {eventData?.end_time !== "" ? (
                        <div className="data">
                          {moment(eventData?.start_time, "HH:mm:ss").format(
                            "hh:mm A"
                          )}{" "}
                          -{" "}
                          {moment(eventData?.end_time, "HH:mm:ss").format(
                            "hh:mm A"
                          )}
                        </div>
                      ) : (
                        <div className="data">
                          {moment(eventData?.start_time, "HH:mm:ss").format(
                            "hh:mm A"
                          )}
                        </div>
                      )}
                    </div>
                    {eventData?.online_event_link !== undefined ? (
                      <div className="event-meetings">
                        <a
                          href={eventData?.online_event_link}
                          type="button"
                          className="btn-join"
                        >
                          Join the meeting
                        </a>
                      </div>
                    ) : (
                      <div className="event-meetings">
                        <button
                          type="button"
                          className="btn-join"
                          disable={true}
                        >
                          Join the meeting
                        </button>
                      </div>
                    )}
                  </div>
                  <div className="event-people">
                    <div className="people">
                      {Interested &&
                        Interested.length > 0 &&
                        Interested.map((item) => {
                          return (
                            <>
                              <div className="img-wrap">
                                <img
                                  src={
                                    API_URL +
                                    "uploads/profile-images/100/" +
                                    item?.user_id +
                                    ".png"
                                  }
                                  alt=""
                                />
                              </div>
                            </>
                          );
                        })}
                    </div>
                    {count && (
                      <div className="total">{count} people interested</div>
                    )}
                  </div>
                </div>
                {eventData && eventData.description && (
                  <div className="details-description">
                    <div className="title">About the event</div>
                    <div className="descriptions">
                      {eventData ? eventData.description : ""}
                    </div>
                  </div>
                )}

                {eventData && eventData.eventTags.length > 0 && (
                  <div className="tags-section">
                    <div className="title">Tags</div>
                    <div className="themetags">
                      <div className="themetag-list">
                        {eventData &&
                          eventData.eventTags.map((item, index) => (
                            <div className="tag" key={index}>
                              <input
                                type="checkbox"
                                className="tag-check"
                                name="theme-tags"
                                value={item._id}
                              />
                              <div className="tag-label">{item.title}</div>
                            </div>
                          ))}
                      </div>
                    </div>
                  </div>
                )}
              </div>

              <div className="share-part">
                <button
                  type="button"
                  className="share-action"
                  data-toggle="modal"
                  data-target="#shareModal"
                  style={{ backgroundColor: "transparent" }}
                  onClick={() => shareEvent()}
                >
                  <i className="fa-light fa-arrow-up-from-bracket"></i>
                </button>
              </div>
            </div>
          </div>
        </div>
      </section>

      {modal ? <EventShareModal eventData={eventData} /> : ""}

      <div
        className="modal event-delete-modal fade"
        data-backdrop="static"
        id="deleteModal"
        tabindex="-1"
        aria-labelledby="deleteModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered modal-sm">
          <div className="modal-content">
            <div className="modal-body">
              <div className="delete-content">
                <div className="icon">
                  <i className="fa-solid fa-triangle-exclamation"></i>
                </div>
                <div className="text">
                  <div className="main-title">Cancel event?</div>
                  <div className="des">
                    This action cannot be undone. Guests will be notified.
                  </div>
                </div>
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-close"
                data-dismiss="modal"
              >
                Close
              </button>
              <button type="button" className="btn btn-confirm">
                Confirm
              </button>
            </div>
          </div>
        </div>
      </div>
      <LoaderFull loaderActive={loading} />
    </>
  );
};

export default ProfileEventDetails;
