import CommunityCardItem from "./CommunityCardItem";
import LoaderFull from "../../../components/LoaderFull";
const CommunityRequest = ({ communityList, loading }) => {
  return (
    <>
      <div className="discover-section">
        <div className="text">
          You Have {communityList?.data?.length} Requests
        </div>

        <div className="discover-part">
          <div className="community-cards-part">
            {communityList?.data?.map((item, index) => (
              <div className="connection-item" key={index}>
                <CommunityCardItem
                  name={item.user_name}
                  id={item._id}
                  senderId={item.sender_user_id}
                  image={item.user_image}
                  designation={item.designation}
                  connected={item.status}
                  link={item.user_slug}
                />
              </div>
            ))}
          </div>
        </div>
      </div>
      <LoaderFull loaderActive={loading} />
    </>
  );
};

export default CommunityRequest;
