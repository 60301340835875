import { Outlet } from "react-router-dom";
import CloseHeader from "../shared/CloseHeader";

const BlankLayout = () => {
  return (
    <>
      <CloseHeader />
      <Outlet />
    </>
  );
};

export default BlankLayout;
