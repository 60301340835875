import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { verifyToken } from "../store/user";

export const PublicAuthRoute = (props) => {
  const selector = useSelector((state) => state.users);
  const { profileData } = selector;
  const { settingData } = useSelector((state) => state.settings);

  const dispatch = useDispatch();

  const navigate = useNavigate();
  const token_ = localStorage.getItem("accesstoken");
  const _spotifySecretId = settingData?.spotify_secret_id;
  const _spotifyCLientId = settingData?.spotify_cllient_id;

  useEffect(() => {
    if (token_) {
      verifyToken({})
        .then((res) => {
          if (res.token_verified) {
          } else {
            localStorage.removeItem("accesstoken");
            navigate("/");
          }
        })
        .catch(() => {});
    }
  }, [profileData, dispatch]);

  useEffect(() => {
    if (_spotifyCLientId !== undefined && _spotifySecretId !== undefined) {
      const getSToken = async () => {
        const client_id = _spotifyCLientId;
        const client_secret = _spotifySecretId;
        const url = "https://accounts.spotify.com/api/token";

        await fetch(url, {
          method: "POST",
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
            Authorization:
              "Basic " + window.btoa(client_id + ":" + client_secret),
          },
          body: "grant_type=client_credentials",
        })
          .then((response) => response.json())
          .then((data) => {
            const access_token = data.access_token;
            // console.log(access_token);
            localStorage.setItem("stoken", access_token);
            return access_token;
          })
          .catch((error) => console.log(error));
      };

      if (
        window.location.pathname === "/podcast" ||
        window.location.pathname === "/"
      ) {
        getSToken();
      }
    }
  }, [window.location.pathname, _spotifyCLientId, _spotifySecretId]);

  return <>{props.children}</>;
};
