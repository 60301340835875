import { Link } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import Fade from "react-reveal/Fade";
import { useSelector } from "react-redux";
import { fetchMembers } from "../../../store/Pages/page";
import { useEffect, useState } from "react";

const SecMembershipBenefit = () => {
  const { homeContentdata } = useSelector((state) => state.pages);
  const [member, setMembers] = useState([]);

  useEffect(() => {
    const loadMember = async () => {
      try {
        const list = await fetchMembers({
          type: "receive",
          limit: 99999,
          sortByField: "ordering",
          sortByValue: 1,
          status: true,
        });
        setMembers(list);
      } catch (error) {}
    };
    loadMember();
  }, []);

  return (
    <>
      <section className="sec-membership-benefit">
        <div className="container">
          <div className="membership-benefit-content">
            <Fade left>
              <h2 className="membership-benefit-title">
                {homeContentdata?.members_recieve?.title}
              </h2>
            </Fade>
          </div>
        </div>
        <Fade cascade>
          <div className="membership-benefit-slider">
            <Swiper
              spaceBetween={0}
              slidesPerView={1.2}
              breakpoints={{
                480: {
                  slidesPerView: 2,
                  spaceBetween: 10,
                },
                768: {
                  slidesPerView: 2,
                  spaceBetween: 20,
                },
                1024: {
                  slidesPerView: 3,
                  spaceBetween: 20,
                },
                1200: {
                  slidesPerView: 3,
                  spaceBetween: 40,
                },
                1600: {
                  slidesPerView: 4,
                  spaceBetween: 50,
                },
              }}
            >
              {Array.isArray(member) &&
                member.map((item, index) => {
                  return (
                    <SwiperSlide key={index}>
                      <Fade>
                        <Link to="/" className="benefit-item">
                          <div className="benefit-item-inner">
                            <div className="benefit-item-thumbnail">
                              <img src={item?.image} alt="" />
                            </div>
                            <div className="benefit-item-content">
                              <div className="benefit-item-icon">
                                <span>{index + 1}</span>
                                <svg>
                                  <circle cx="28" cy="28" r="28"></circle>
                                  <circle cx="28" cy="28" r="28"></circle>
                                </svg>
                              </div>
                              <h4 className="benefit-item-title">
                                {item?.description}
                              </h4>
                            </div>
                          </div>
                        </Link>
                      </Fade>
                    </SwiperSlide>
                  );
                })}
            </Swiper>
          </div>
        </Fade>
      </section>
    </>
  );
};

export default SecMembershipBenefit;
