import axios from "axios";

export const getSpotifyToken = async () => {
  const res = await axios.get("https://ceofront.iosx.in/spotify_token.php");
  return res.data;
};

export const fetchTracks = async (body) => {
  let token = localStorage.getItem("stoken");
  const res = await axios.get(
    "https://api.spotify.com/v1/playlists/" + body.playlist_id + "/tracks",
    {
      headers: {
        "Content-type": "application/x-www-form-urlencoded",
        Accept: "application/json",
        Authorization: "Bearer " + token,
      },
    }
  );

  return res.data.items;
};

export const fetchArtist = async (body) => {
  let token = localStorage.getItem("stoken");
  const res = await axios.get(
    "https://api.spotify.com/v1/artists/" + body.artist_id,
    {
      headers: {
        "Content-type": "application/x-www-form-urlencoded",
        Accept: "application/json",
        Authorization: "Bearer " + token,
      },
    }
  );
  return res.data;
};

export const trackDetails = async (body) => {
  let token = localStorage.getItem("stoken");
  const res = await axios.get(
    "https://api.spotify.com/v1/tracks/" + body.track_id,
    {
      headers: {
        "Content-type": "application/x-www-form-urlencoded",
        Accept: "application/json",
        Authorization: "Bearer " + token,
      },
    }
  );
  return res.data;
};
