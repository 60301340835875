import moment from "moment/moment";
import { Link } from "react-router-dom";

const ProfileEventItem = ({
  name,
  category,
  start_time,
  end_time = "",
  start_date,
  link,
  image,
  filter,
  id,
}) => {
  return (
    <>
      <div className="events-item ">
        <div className="events-inner">
          <div className="item-image">
            <img src={image} alt="" />
          </div>
          <div className="item-content">
            <div className="item-subtitle">{category}</div>
            <div className="item-title">{name}</div>
            <div className="item-time">
              {end_time !== "" &&
                (moment(new Date(start_date)).format("ddd, MMM DD"),
                moment(start_time, "HH:mm:ss").format("hh:mm A") +
                  "-" +
                  moment(end_time, "HH:mm:ss").format("hh:mm A"))}
              {end_time === "" &&
                (moment(new Date(start_date)).format("ddd, MMM DD"),
                moment(start_time, "HH:mm:ss").format("hh:mm A"))}
            </div>
            <div className="item-action">
              {filter ? (
                ""
              ) : (
                <button type="button" className="btn-action alt">
                  Remove Interest
                </button>
              )}
              <Link
                className="btn-action"
                to={"/profile-event-details/" + link}
              >
                More details
              </Link>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ProfileEventItem;
{
  /* <Link type='button' to='/profile-event-details' className='btn-action alt'>Edit Event</Link> */
}
