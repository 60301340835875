import React from "react";

function LoadMore({ next, loadData }) {
  return (
    <>
      <button
        type="button"
        className="btn-action"
        id="load"
        onClick={() => loadData(next)}
      >
        Load More
      </button>
    </>
  );
}

export default LoadMore;
