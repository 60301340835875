import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { fetchMembers } from "../../../store/Pages/page";
import Fade from "react-reveal/Fade";
import { useSelector } from "react-redux";

const SecHomeCTA = () => {
  let token = localStorage.getItem("accesstoken");
  const [member, setMembers] = useState([]);

  const { homeContentdata } = useSelector((state) => state.pages);

  useEffect(() => {
    const loadMember = async () => {
      try {
        const list = await fetchMembers({
          type: "home",
          limit: 99999,
          sortByField: "ordering",
          sortByValue: 1,
          status: true,
        });
        setMembers(list);
      } catch (error) {}
    };
    loadMember();
  }, []);

  return (
    <>
      <section className="home-calltoaction">
        <Fade bottom cascade>
          <div className="calltoaction-background">
            {Array.isArray(member) && (
              <div className="cta-row">
                {member.slice(0, 8).map((item, index) => {
                  return (
                    <React.Fragment key={index}>
                      <div className="member">
                        <div className="member-image">
                          <img src={item.image} alt="" />
                        </div>
                      </div>
                    </React.Fragment>
                  );
                })}
              </div>
            )}
            {Array.isArray(member) && (
              <div className="cta-row">
                {member.slice(9, 18).map((item, index) => {
                  return (
                    <React.Fragment key={index}>
                      <div className="member">
                        <div className="member-image">
                          <img src={item.image} alt="" />
                        </div>
                      </div>
                    </React.Fragment>
                  );
                })}
              </div>
            )}
            {Array.isArray(member) && (
              <div className="cta-row">
                {member.slice(19, 27).map((item, index) => {
                  return (
                    <React.Fragment key={index}>
                      <div className="member">
                        <div className="member-image">
                          <img src={item.image} alt="" />
                        </div>
                      </div>
                    </React.Fragment>
                  );
                })}
              </div>
            )}
          </div>
        </Fade>
        {!token && (
          <div className="container">
            <div className="calltoaction-content">
              <Fade top>
                <h2 className="calltoaction-title">
                  {homeContentdata?.home_cta?.title}
                </h2>
              </Fade>

              <div className="calltoaction-action">
                <Fade bottom>
                  <Link to="/membership" className="btn-action">
                    Membership
                  </Link>
                </Fade>
              </div>
            </div>
          </div>
        )}
      </section>
    </>
  );
};

export default SecHomeCTA;
