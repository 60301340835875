import { useState } from "react";
import "../Css/PostComment.css";
import "../Css/Feed.css";
import { useEffect } from "react";
import { feedDetails, getFavorites } from "../../../store/feed";
import { Link, useParams } from "react-router-dom";
import PostComments from "./PostComments";
import LoaderFull from "../../../components/LoaderFull";
import ReactPlayer from "react-player";
import { useSelector } from "react-redux";
import SharedModal from "./SharedModal";
import { useContext } from "react";
import { SocketContext } from "../../../context/socket";
import { useCallback } from "react";
import UserImage from "./UserImage";
import FeedReaction from "./FeedReaction";
import UserInfo from "./UserInfo";
import API_URL from "../../../config/apiHost";

const PostDetails = () => {
  const socket = useContext(SocketContext);
  const { id } = useParams();
  const [postData, setPostData] = useState([]);
  const [image, setImage] = useState(false);
  const [loading, setLoading] = useState([]);
  const [commentCount, setCommentCount] = useState();
  const [reactionsCount, setReactionsCount] = useState([]);
  const [favs, setFavs] = useState([]);
  const [loveReactionCount, setLoveReactionCount] = useState([]);
  const [like, setLike] = useState();
  const [love, setLove] = useState();
  const [postuserId, setUserId] = useState(false);
  const [userData, setUserData] = useState(null);
  const { profileDetails } = useSelector((state) => state.users);
  
  useEffect(() => {
    const getDetails = async () => {
      setLoading(true);
      const fav = await getFavorites();
      setFavs(fav);
      const details = await feedDetails({ postId: id });
      if (details.status === 200) {
        const postuserid = details.data.users.find(
          (item) => item.userId === details.data.posts[0].postedUserId
        );
        setUserData(postuserid);

        setUserId(postuserid.userId);

        setPostData(details.data.posts);
        setCommentCount(details.data.posts[0].commentsCount);
        setReactionsCount(
          details.data.posts[0].reactions?.like
            ? details.data.posts[0].reactions?.like
            : 0
        );
        setLoveReactionCount(
          details.data.posts[0].reactions?.love
            ? details.data.posts[0].reactions?.love
            : 0
        );
        const lk = details.data.posts[0].myReactions.includes("like");
        const lv = details.data.posts[0].myReactions.includes("love");
        setLike(lk);
        setLove(lv);
        if (details.data.posts.length > 0) {
          const children =
            details.data.postChildren.length > 0 &&
            details.data.postChildren.find(
              (item) => item.parentPostId == details.data.posts[0].postId
            );
          const imageId = children.dataType === "image" && children.data.fileId;

          const file =
            details.data.files.length > 0 &&
            details.data.files.find((item) => item.fileId == imageId);

          if (file) {
            setImage(file.fileUrl);
          }
        }
        setLoading(false);
      }
    };

    getDetails();
  }, [id]);

  const webhookEventResponse = useCallback((data) => {
    if (data.data.event === "v3.post.didAddReaction") {
      setReactionsCount(
        data.data.data.posts[0].reactions?.like
          ? data.data.data.posts[0].reactions?.like
          : 0
      );
      setLoveReactionCount(
        data.data.data.posts[0].reactions?.love
          ? data.data.data.posts[0].reactions?.love
          : 0
      );
    }
    if (data.data.event === "v3.post.didRemoveReaction") {
      setReactionsCount(
        data.data.data.posts[0].reactions?.like
          ? data.data.data.posts[0].reactions?.like
          : 0
      );
      setLoveReactionCount(
        data.data.data.posts[0].reactions?.love
          ? data.data.data.posts[0].reactions?.love
          : 0
      );
    }
  }, []);

  useEffect(() => {
    socket.on("webhookEventResponse", webhookEventResponse);
    return () => {
      socket.off("webhookEventResponse", webhookEventResponse);
    };
  }, [webhookEventResponse, socket]);
  const favData =
    favs?.data?.length > 0 && favs?.data?.find((fv) => fv.post_id == id);
  let wishlist = favData !== undefined ? favData.status : false;

  return (
    <>
      {userData && (
        <Link
          to={`/profile/${userData?.metadata?.slug}`}
          className="post-details-user"
        >
          <UserImage commentuser={userData} /> <div className="p-2"></div>
          <UserInfo commentuser={userData} date={postData[0].createdAt} />
        </Link>
      )}
      {postData.length > 0 && (
        <section className="post-comment-section">
          <div className="descriptions">{postData[0].data?.text}</div>

          {postData[0].children.length > 0 && image && (
            <div className="post-image">
              <img src={image + "?size=full"} alt="" />
            </div>
          )}

          <div className="post-content" id="addCmt">
            {postData[0]?.metadata?.isLink && (
              <a
                href={postData[0].metadata.preview_json.url}
                target="_blank"
                className="feed-share"
                rel="noreferrer"
              >
                <div className="feed-share-image">
                  <img
                    src={postData[0].metadata.preview_json.images[0] ? postData[0].metadata.preview_json.images[0] : API_URL+"uploads/events/no-image.png"}
                    alt={"linkImage"}
                  />
                </div>

                <div className="feed-share-content">
                  <div className="feed-share-title">
                    {postData[0].metadata.preview_json.title}
                    <i className="fa-solid fa-external-link ml-3"></i>
                  </div>
                  <div className="feed-share-link">
                    {postData[0].metadata.preview_json.description}
                  </div>
                </div>
              </a>
            )}
            {postData[0]?.metadata?.isArticle && (
              <div className="feed-share-content mt-3">
                <div className="feed-share-title h4">
                  {postData[0].metadata.title}
                </div>
                <div className="feed-share-title text-secondary">
                  {postData[0].metadata.subtitle}
                </div>
                <div className="mt-3 h5">
                  {postData[0].metadata.description}
                </div>
              </div>
            )}
            <div className="post-reaction-action">
              <FeedReaction
                commentCount={commentCount}
                reactionsCount={reactionsCount}
                loveReactionCount={loveReactionCount}
                others={null}
                like={like}
                love={love}
                wishlist={wishlist}
                postId={postData[0]._id}
                sharePost={() => {}}
                postuserId={postuserId}
              />
            </div>
            <PostComments
              commentCount={commentCount}
              postId={id}
              setCommentCount={setCommentCount}
              postuserId={postuserId}
            />
          </div>
        </section>
      )}
      <>
        <SharedModal postId={id} userId={profileDetails?._id} />
        <LoaderFull loaderActive={loading} />
      </>
    </>
  );
};

export default PostDetails;
