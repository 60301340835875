import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { authUser } from "../store/user";

function VerifyRoute(props) {
  const { authData } = useSelector((state) => state.users);
  const { token } = useParams();
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(authUser({ token: token }));
  }, [token]);

  useEffect(() => {
    if (authData.data?.access_token !== undefined) {
      localStorage.setItem("accesstoken", authData.data?.access_token);
      window.location.reload();
    }
  }, [authData.data?.access_token]);
  return <>{props.children}</>;
}

export default VerifyRoute;
