import { useEffect } from "react";
import { Link } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, EffectFade } from "swiper";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/effect-fade";
import { fetchPlaylistPodcast } from "../../../store/playlist";
import LoaderFull from "../../../components/LoaderFull";
import { useDispatch, useSelector } from "react-redux";

const SecPodcastBanner = () => {
  const dispatch = useDispatch();
  const { bannerplayList, loading } = useSelector((state) => state.playlist);
  useEffect(() => {
    dispatch(
      fetchPlaylistPodcast({ status: true, featured: true, type: "spotify" })
    );
  }, [dispatch]);

  return (
    <>
      <section className="sec-podcast-banner">
        <Swiper
          modules={[Pagination, EffectFade]}
          spaceBetween={0}
          slidesPerView={1}
          pagination={{ clickable: true }}
          effect="fade"
        >
          {bannerplayList.length > 0 &&
            bannerplayList.map((item, index) => {
              return (
                <SwiperSlide key={index}>
                  <div className="swiper-slide">
                    <Link to="" className="podcast">
                      <div className="container">
                        <div className="podcast-image">
                          <div className="podcast-image-content"></div>
                          <div className="podcast-image-container">
                            <img src={item.image} alt="" />
                          </div>
                        </div>
                        <div className="podcast-content">
                          <h1 className="podcast-title">{item.name}</h1>
                        </div>
                      </div>
                    </Link>
                  </div>
                </SwiperSlide>
              );
            })}
        </Swiper>
      </section>

      <LoaderFull loaderActive={loading} />
    </>
  );
};

export default SecPodcastBanner;
