import CommunityCardItem from "./CommunityCardItem";
import LoaderFull from "../../../components/LoaderFull";
import { useDispatch } from "react-redux";
import { getMoreCommunity } from "../../../store/user";
import { useState } from "react";
const CommunityConnections = ({ communityList, loading }) => {
  const removedArr = [];
  const dispatch = useDispatch();

  const [limit, _] = useState(10);
  const [offset, setOffset] = useState(limit);

  const loadData = async (limit, offset) => {
    document.getElementById("load1").innerHTML = "Loading...";

    dispatch(
      getMoreCommunity({ status: 2, keyword: "", limit: limit, offset: offset })
    )
      .then(() => {
        document.getElementById("load1").innerHTML = "Load More";
      })
      .catch(() => {
        document.getElementById("load1").innerHTML = "Load More";
      });

    setOffset(offset + limit);
  };
  return (
    <>
      <div className="discover-section">
        <div className="text">{communityList.count} Connections</div>

        <div className="discover-part">
          <div className="community-cards-part">
            {Array.isArray(communityList.data) && communityList.data?.map((item, index) => {
              if (!removedArr.includes(item._id)) {
                return (
                  <div className="connection-item" key={index}>
                    <CommunityCardItem
                      id={item._id}
                      name={item.user_name}
                      channelId={item.channel_id}
                      image={item.user_image}
                      designation=""
                      connected={item.status}
                      link={item.user_slug}
                    />
                  </div>
                );
              }
            })}
          </div>
        </div>
      </div>
      {( communityList.count > offset ) && (
        <div className="schedule-action">
          <button
            type="button"
            className="btn-action"
            id="load1"
            onClick={() => loadData(limit, offset)}
          >
            Load More
          </button>
        </div>
      )}
      <LoaderFull loaderActive={loading} />
    </>
  );
};

export default CommunityConnections;
