import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import ProfileProgress from './ProfileProgress';
import ProfileFormInformation from './ProfileFormInformation';
import ProfileFormLocation from './ProfileFormLocation';
import ProfileFormEducation from './ProfileFormEducation';
import ProfileFormExperience from './ProfileFormExperience';
import ProfileFormAbout from './ProfileFormAbout';
import ProfileFormFinalize from './ProfileFormFinalize';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
const Profile = ({toggleProfileForm, setToggleProfileForm,profileData}) => {
  const dispatch = useDispatch();
  
  const { settingData } = useSelector(state => state.settings);
  const { profileStep } = useSelector(state => state.users);
  const [formView, setFormView] = useState({
    first: {
      active: true,
      completed: false
    },
    second: {
      active: false,
      completed: false
    },
    third: {
      active: false,
      completed: false
    },
    fourth: {
      active: false,
      completed: false
    },
    fifth: {
      active: false,
      completed: false
    },
    sixth: {
      active: false,
      completed: false
    },  
  });
  const closePanel = event => {
    setToggleProfileForm(false);
  }

  
 

  useEffect(()=>{
    //console.log(profileStep);
    if(profileStep && profileStep !== undefined){
      profileStep && profileStep.length > 0 && profileStep[0]?.step !== null && setFormView(profileStep[0]?.step);
    }
  },[profileStep])

  
//console.log(profileStep);
  return (
    <>
      <section className={'profile-panel ' + (toggleProfileForm ? 'active' : '') }>
        <div className='profile-panel-header'>
          <div className='container-fluid'>
            { !formView.sixth.active &&
            <div className='row'>
              <div className='col-lg-6'>
                <div className="brand">
                  <Link className="logo" to="/"><img src={settingData?.logo} alt="CEO Logo" /></Link>
                </div>
              </div>
              <div className='col-lg-6 d-flex'>
                <ProfileProgress formView={formView}/>
                <button type="button" className="panel-close" onClick={closePanel}><i className="fa-light fa-fw fa-times"></i></button>
              </div>
            </div>
            }
          </div>
        </div>
        <div className='profile-panel-body'>
        {
          formView.first.active && profileData &&
          <ProfileFormInformation profileData={profileData} formView={formView} setFormView={setFormView}/>
        }
        {
          formView.second.active &&
          <ProfileFormLocation formView={formView} setFormView={setFormView} />
        }
        {
          formView.third.active &&
          <ProfileFormEducation formView={formView} setFormView={setFormView}/>
        }
        {
          formView.fourth.active &&
          <ProfileFormExperience formView={formView} setFormView={setFormView}/>
        }
        {
          formView.fifth.active &&
          <ProfileFormAbout formView={formView} setFormView={setFormView}/>
        }
        {
          formView.sixth.active &&
          <ProfileFormFinalize formView={formView} setFormView={setFormView}/>
        }
        </div>
      </section>
    </>
  );
};

export default Profile;