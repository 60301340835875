import moment from "moment";
import { useState } from "react";
import SecFilter from "../../../components/SecFilter";
import SecGridListSwitch from "../../../components/SecGridListSwitch";
import SecStoriesGrid from "./SecStoriesGrid";
import SecStoriesList from "./SecStoriesList";

const SecAllStories = () => {
  const [isGrid, setIsGrid] = useState(true);
  const [dateRange, setDateRange] = useState([]);
  const [featured, setFeatured] = useState(false);
  const [searchValue, setSearch] = useState([]);

  const handleDateRangeSearch = (value, dateStatus) => {
    if (dateStatus === true) {
      setSearch(value);
      const start = moment()
        .subtract(1, value)
        .startOf(value)
        .format("YYYY-MM-DD");
      const end = moment().subtract(1, value).endOf(value).format("YYYY-MM-DD");
      const range = {
        start: new Date(start).toISOString(),
        end: new Date(end).toISOString(),
      };

      setDateRange(range);
      setFeatured(false);
    } else {
      setFeatured(true);
      setSearch(value);
      setDateRange([]);
    }
  };

  return (
    <>
      <section className="sec-stories">
        <div className="container d-flex flex-wrap align-items-center justify-content-between">
          <SecFilter
            handleDateRangeSearch={handleDateRangeSearch}
            searchValue={searchValue}
          />
          <SecGridListSwitch setIsGrid={setIsGrid} />
        </div>
        {isGrid ? (
          <SecStoriesGrid dateRange={dateRange} featured={featured} />
        ) : (
          <SecStoriesList dateRange={dateRange} featured={featured} />
        )}
      </section>
    </>
  );
};

export default SecAllStories;
