const SecMembershipDetails = ({
  name,
  text,
  icon,
  type,
  image,
  bgClass,
  setRegistrationActive,
}) => {
  const openRegistration = () => {
    setRegistrationActive((current) => !current);
  };
  return (
    <>
      <section className={"sec-membership-details " + bgClass}>
        <div className="container">
          <div className="row">
            <div className="col-md-6 col-lg-5">
              <div className="membership-details-content">
                <div className="membership-details-icon">
                  <i className={"fa-solid fa-fw fa-" + icon}></i>
                </div>
                <h2 className="membership-details-title">{name}</h2>
                <div className="membership-details-text">{text}</div>
                <div className="membership-details-option">
                  <button
                    type="button"
                    className="btn-option"
                    onClick={openRegistration}
                  >
                    <i className="fa-solid fa-fw fa-star mr-3"></i>
                    {type}
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="membership-details-image">
            <img src={image} alt="" />
          </div>
        </div>
      </section>
    </>
  );
};

export default SecMembershipDetails;
