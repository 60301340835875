import axios from "axios";
import BASE_URL from "../config/host";

export const fetchFeed = async (body) => {
  const res = await axios.post(BASE_URL + "amity/feed/list", body, {
    headers: {
      "Content-type": "application/json",
      Accept: "application/json",
      Authorization: "Bearer " + localStorage.getItem("accesstoken"),
    },
  });
  return res.data;
};

export const fetchUserFeed = async (body) => {
  const res = await axios.post(BASE_URL + "amity/user/feed", body, {
    headers: {
      "Content-type": "application/json",
      Accept: "application/json",
      Authorization: "Bearer " + localStorage.getItem("accesstoken"),
    },
  });
  return res.data;
};

export const feedDetails = async (body) => {
  const res = await axios.post(BASE_URL + "amity/feed/details", body, {
    headers: {
      "Content-type": "application/json",
      Accept: "application/json",
      Authorization: "Bearer " + localStorage.getItem("accesstoken"),
    },
  });
  return res.data;
};

export const feedDelete = async (body) => {
  const res = await axios.post(BASE_URL + "amity/feed/delete", body, {
    headers: {
      "Content-type": "application/json",
      Accept: "application/json",
      Authorization: "Bearer " + localStorage.getItem("accesstoken"),
    },
  });
  return res.data;
};

export const manageFavorite = async (body) => {
  const res = await axios.post(BASE_URL + "post/manage-favorite", body, {
    headers: {
      "Content-type": "application/json",
      Accept: "application/json",
      Authorization: "Bearer " + localStorage.getItem("accesstoken"),
    },
  });
  return res.data;
};

export const getFavorites = async (body) => {
  const res = await axios.post(BASE_URL + "post/favorite-list", body, {
    headers: {
      "Content-type": "application/json",
      Accept: "application/json",
      Authorization: "Bearer " + localStorage.getItem("accesstoken"),
    },
  });
  return res.data;
};

export const getUserPosts = async (body) => {
  const res = await axios.post(BASE_URL + "post/list", body, {
    headers: {
      "Content-type": "application/json",
      Accept: "application/json",
      Authorization: "Bearer " + localStorage.getItem("accesstoken"),
    },
  });
  return res.data;
};

export const userPostDelete = async (body) => {
  const res = await axios.post(BASE_URL + "post/delete", body, {
    headers: {
      "Content-type": "application/json",
      Accept: "application/json",
      Authorization: "Bearer " + localStorage.getItem("accesstoken"),
    },
  });
  return res.data.data;
};

export const getSavedPosts = async (body) => {
  const res = await axios.post(BASE_URL + "amity/fevorite-feed", body, {
    headers: {
      "Content-type": "application/json",
      Accept: "application/json",
      Authorization: "Bearer " + localStorage.getItem("accesstoken"),
    },
  });
  return res.data;
};

export const manageLike = async (body) => {
  const res = await axios.post(BASE_URL + "amity/reaction/add", body, {
    headers: {
      "Content-type": "application/json",
      Accept: "application/json",
      Authorization: "Bearer " + localStorage.getItem("accesstoken"),
    },
  });
  return res.data.data;
};

export const deleteLike = async (body) => {
  const res = await axios.post(BASE_URL + "amity/reaction/delete", body, {
    headers: {
      "Content-type": "application/json",
      Accept: "application/json",
      Authorization: "Bearer " + localStorage.getItem("accesstoken"),
    },
  });
  return res.data.data;
};

export const addComment = async (body) => {
  const res = await axios.post(BASE_URL + "amity/comment/create", body, {
    headers: {
      "Content-type": "application/json",
      Accept: "application/json",
      Authorization: "Bearer " + localStorage.getItem("accesstoken"),
    },
  });
  return res.data.data;
};

export const commentDelete = async (body) => {
  const res = await axios.post(BASE_URL + "amity/comment/delete", body, {
    headers: {
      "Content-type": "application/json",
      Accept: "application/json",
      Authorization: "Bearer " + localStorage.getItem("accesstoken"),
    },
  });
  return res.data.data;
};

export const commentList = async (body) => {
  const res = await axios.post(BASE_URL + "amity/comment/list", body, {
    headers: {
      "Content-type": "application/json",
      Accept: "application/json",
      Authorization: "Bearer " + localStorage.getItem("accesstoken"),
    },
  });
  return res.data.data;
};

export const createPost = async (body) => {
  const res = await axios.post(BASE_URL + "amity/feed/create", body, {
    headers: {
      "Content-type": "application/json",
      Accept: "application/json",
      Authorization: "Bearer " + localStorage.getItem("accesstoken"),
    },
  });
  return res.data;
};

export const draftPost = async (body) => {
  const res = await axios.post(BASE_URL + "draft-post/add", body, {
    headers: {
      "Content-type": "application/json",
      Accept: "application/json",
      Authorization: "Bearer " + localStorage.getItem("accesstoken"),
    },
  });
  return res.data;
};

export const editDraftPost = async (body) => {
  const res = await axios.post(BASE_URL + "draft-post/edit", body, {
    headers: {
      "Content-type": "application/json",
      Accept: "application/json",
      Authorization: "Bearer " + localStorage.getItem("accesstoken"),
    },
  });
  return res.data;
};

export const deleteDraftPost = async (body) => {
  const res = await axios.post(BASE_URL + "draft-post/delete", body, {
    headers: {
      "Content-type": "application/json",
      Accept: "application/json",
      Authorization: "Bearer " + localStorage.getItem("accesstoken"),
    },
  });
  return res.data;
};

export const updatePost = async (body) => {
  const res = await axios.post(BASE_URL + "amity/feed/update", body, {
    headers: {
      "Content-type": "application/json",
      Accept: "application/json",
      Authorization: "Bearer " + localStorage.getItem("accesstoken"),
    },
  });
  return res.data;
};

export const createMyPost = async (body) => {
  const res = await axios.post(BASE_URL + "post/create", body, {
    headers: {
      "Content-type": "application/json",
      Accept: "application/json",
      Authorization: "Bearer " + localStorage.getItem("accesstoken"),
    },
  });
  return res.data;
};

export const imageUploadPost = async (body) => {
  const res = await axios.post(BASE_URL + "amity/file/upload", body, {
    headers: {
      "Content-type": "multipart/form-data",
      Accept: "*/*",
      Authorization: "Bearer " + localStorage.getItem("accesstoken"),
    },
  });
  return res.data;
};

export const imageDeletePost = async (body) => {
  const res = await axios.post(BASE_URL + "amity/file/delete", body, {
    headers: {
      "Content-type": "application/json",
      Accept: "*/*",
      Authorization: "Bearer " + localStorage.getItem("accesstoken"),
    },
  });
  return res.data;
};

export const createChannel = async (body) => {
  const res = await axios.post(BASE_URL + "amity/channel/create", body, {
    headers: {
      "Content-type": "application/json",
      Accept: "*/*",
      Authorization: "Bearer " + localStorage.getItem("accesstoken"),
    },
  });
  return res.data;
};

export const updateChannel = async (body) => {
  const res = await axios.post(BASE_URL + "amity/channel/update", body, {
    headers: {
      "Content-type": "application/json",
      Accept: "*/*",
      Authorization: "Bearer " + localStorage.getItem("accesstoken"),
    },
  });
  return res.data;
};

export const deleteChannel = async (body) => {
  const res = await axios.post(BASE_URL + "amity/channel/close", body, {
    headers: {
      "Content-type": "application/json",
      Accept: "*/*",
      Authorization: "Bearer " + localStorage.getItem("accesstoken"),
    },
  });
  return res.data;
};

export const seenChannel = async (body) => {
  const res = await axios.post(BASE_URL + "amity/channel/seen", body, {
    headers: {
      "Content-type": "application/json",
      Accept: "*/*",
      Authorization: "Bearer " + localStorage.getItem("accesstoken"),
    },
  });
  return res.data;
};

export const listChannel = async (body) => {
  const res = await axios.post(BASE_URL + "amity/channel/list", body, {
    headers: {
      "Content-type": "application/json",
      Accept: "*/*",
      Authorization: "Bearer " + localStorage.getItem("accesstoken"),
    },
  });
  if (res.data && res.data.status !== 400) {
    return res.data;
  } else {
    localStorage.removeItem("accesstoken");
    window.location.href = "/";
  }
};

export const channelDetails = async (body) => {
  const res = await axios.post(BASE_URL + "amity/channel/details", body, {
    headers: {
      "Content-type": "application/json",
      Accept: "*/*",
      Authorization: "Bearer " + localStorage.getItem("accesstoken"),
    },
  });
  return res.data;
};

export const messageList = async (body) => {
  const res = await axios.post(BASE_URL + "amity/message/list", body, {
    headers: {
      "Content-type": "application/json",
      Accept: "*/*",
      Authorization: "Bearer " + localStorage.getItem("accesstoken"),
    },
  });
  return res.data;
};

export const messageDelete = async (body) => {
  const res = await axios.post(BASE_URL + "amity/message/delete", body, {
    headers: {
      "Content-type": "application/json",
      Accept: "*/*",
      Authorization: "Bearer " + localStorage.getItem("accesstoken"),
    },
  });
  return res.data;
};

export const sendMessage = async (body) => {
  const res = await axios.post(BASE_URL + "amity/message/create", body, {
    headers: {
      "Content-type": "application/json",
      Accept: "*/*",
      Authorization: "Bearer " + localStorage.getItem("accesstoken"),
    },
  });
  return res.data;
};

export const uploadMsgFile = async (body) => {
  const res = await axios.post(BASE_URL + "amity/message/file", body, {
    headers: {
      "Content-type": "multipart/form-data",
      Accept: "*/*",
      Authorization: "Bearer " + localStorage.getItem("accesstoken"),
    },
  });
  return res.data;
};

export const draftFeedDetails = async (body) => {
  const res = await axios.post(BASE_URL + "/draft-post/details", body, {
    headers: {
      "Content-type": "application/json",
      Accept: "application/json",
      Authorization: "Bearer " + localStorage.getItem("accesstoken"),
    },
  });
  return res.data;
};
