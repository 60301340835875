import { useLocation, Link } from "react-router-dom";
import { useSelector } from "react-redux";
import Fade from "react-reveal/Fade";
import Zoom from "react-reveal/Zoom";

const DefaultFooter = () => {
  const location = useLocation();
  const { settingData } = useSelector((state) => state.settings);

  return (
    <>
      {location.pathname !== "/profile-landing" && (
        <footer>
          <div className="footer-top">
            <div className="container">
              <div className="row justify-content-between">
                <div className="col-md-4 col-lg-5">
                  <Zoom>
                    <div className="footer-brand">
                      <div className="brand">
                        <Link to="/" className="logo">
                          <img src={settingData?.footer_logo} alt="" />
                        </Link>
                      </div>
                      <div
                        className="brand-slogan"
                        dangerouslySetInnerHTML={{
                          __html: settingData.description,
                        }}
                      ></div>
                    </div>
                  </Zoom>
                </div>
                <div className="col-md-8 col-lg-5">
                  <div className="row">
                    <div className="col-md-4 col-6">
                      <div className="footer-content">
                        <h2 className="footer-title">Product</h2>
                        <Fade bottom cascade>
                          <div className="footer-link">
                            <div className="item">
                              <Link className="link" to="/video">
                                Videos
                              </Link>
                            </div>
                            <div className="item">
                              <Link className="link" to="/podcast">
                                Podcasts
                              </Link>
                            </div>
                            <div className="item">
                              <Link className="link" to="/stories">
                                Stories
                              </Link>
                            </div>
                            <div className="item">
                              <Link className="link" to="/schedule">
                                Schedule
                              </Link>
                            </div>
                            <div className="item">
                              <Link className="link" to="/search">
                                Search
                              </Link>
                            </div>
                          </div>
                        </Fade>
                      </div>
                    </div>
                    <div className="col-md-4 col-6">
                      <div className="footer-content">
                        <h2 className="footer-title">Legal</h2>
                        <Fade bottom cascade>
                          <div className="footer-link">
                            <div className="item">
                              <Link className="link" to="/help">
                                Help
                              </Link>
                            </div>
                            <div className="item">
                              <Link className="link" to="/terms-of-use">
                                Terms of use
                              </Link>
                            </div>
                            <div className="item">
                              <Link className="link" to="/privacy-policy">
                                Privacy policy
                              </Link>
                            </div>
                            <div className="item">
                              <Link className="link" to="/cookies">
                                Cookies
                              </Link>
                            </div>
                          </div>
                        </Fade>
                      </div>
                    </div>
                    <div className="col-md-4 col-6">
                      {settingData && (
                        <div className="footer-content">
                          <h2 className="footer-title">Social</h2>
                          <Fade bottom cascade>
                            <div className="footer-link">
                              <div className="item">
                                <a
                                  className="link"
                                  href={settingData.twitter_link}
                                  target="_blank"
                                >
                                  Twitter
                                </a>
                              </div>
                              <div className="item">
                                <a
                                  className="link"
                                  href={
                                    settingData.Linkedin_link
                                      ? settingData.Linkedin_link
                                      : "#"
                                  }
                                  target="_blank"
                                >
                                  LinkedIn
                                </a>
                              </div>
                              <div className="item">
                                <a
                                  className="link"
                                  href={
                                    settingData.facebook_link
                                      ? settingData.facebook_link
                                      : "#"
                                  }
                                  target="_blank"
                                >
                                  Facebook
                                </a>
                              </div>
                            </div>
                          </Fade>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {settingData && (
            <div className="footer-bottom">
              <div className="container">
                <div className="row">
                  <div className="col-md-8 offset-md-4 col-lg-5 offset-lg-7">
                    <Fade>
                      <div className="footer-copyright">
                        {settingData.copyright}
                      </div>
                    </Fade>
                  </div>
                </div>
              </div>
            </div>
          )}
        </footer>
      )}
    </>
  );
};

export default DefaultFooter;
