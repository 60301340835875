import { useState } from "react";
import { useNavigate } from "react-router-dom";
import SearchFilter from "./Components/SearchFilter";
import SearchVideo from "./Components/SearchVideo";
import SearchPodcast from "./Components/SearchPodcast";
import SearchStory from "./Components/SearchStory";
import "./Css/SearchResult.css";
import { useEffect } from "react";
import { globalSearch } from "../../store/Pages/page";
import LoaderFull from "../../components/LoaderFull";

const SearchResult = () => {
  const navigate = useNavigate();
  const query = new URLSearchParams(window.location.search).get("query");
  const [storyCount, setStoryCount] = useState(0);
  const [filterData, setFilterData] = useState("all");
  const [storyData, setStoryData] = useState([]);
  const [videoData, setVideoData] = useState([]);
  const [castData, setCastData] = useState([]);
  const [castCount, setCastCount] = useState(0);
  const [vidCount, setVidCount] = useState(0);
  const [loading, setLoad] = useState(false);
  const filter = (cl) => {
    setLoad(true);
    setFilterData(cl);
    setTimeout(() => {
      setLoad(false);
    }, 1000);
  };

  useEffect(() => {
    const loadSearch = async () => {
      setLoad(true);
      const filterData = await globalSearch({ keyword: query });
      const castfilter = filterData.filter((item) => {
        return item.type === "Podcast";
      });
      const videofilter = filterData.filter((item) => {
        return item.type === "Videos";
      });
      const storyfilter = filterData.filter((item) => {
        return item.type === "Stories";
      });
      setStoryData(storyfilter);
      setVideoData(videofilter);
      setCastData(castfilter);
      setLoad(false);
    };

    loadSearch();
  }, [query]);

  return (
    <>
      <section className="sec-search-result">
        <div className="container">
          <div className="row">
            <div className="col-xl-2">
              <button
                type="button"
                className="btn-back"
                onClick={() => navigate(-1)}
              >
                <i className="fa-light fa-fw fa-chevron-left"></i>
              </button>
            </div>
            <div className="col-xl-10">
              <div className="search-result-content">
                <h1 className="search-query">{query}</h1>
                <SearchFilter
                  storyCount={storyCount}
                  vidCount={vidCount}
                  castCount={castCount}
                  filter={filter}
                />
                {filterData === "videos" && videoData.length > 0 && (
                  <SearchVideo
                    videoData={videoData}
                    setVidCount={setVidCount}
                  />
                )}
                {filterData === "podcasts" && castData.length > 0 && (
                  <SearchPodcast
                    castData={castData}
                    setCastCount={setCastCount}
                  />
                )}
                {filterData === "stories" && storyData.length > 0 && (
                  <SearchStory
                    storyData={storyData}
                    setStoryCount={setStoryCount}
                  />
                )}
                {filterData === "all" && (
                  <>
                    {videoData.length > 0 && (
                      <SearchVideo
                        videoData={videoData}
                        setVidCount={setVidCount}
                      />
                    )}
                    {castData.length > 0 && (
                      <SearchPodcast
                        castData={castData}
                        setCastCount={setCastCount}
                      />
                    )}
                    {storyData.length > 0 && (
                      <SearchStory
                        storyData={storyData}
                        setStoryCount={setStoryCount}
                      />
                    )}
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </section>
      <LoaderFull loaderActive={loading} />
    </>
  );
};

export default SearchResult;
