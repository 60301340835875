import React, { useState } from "react";
import { Link } from "react-router-dom";
import "./Css/Share.css";

const Share = ({ toggleShare, setToggleShare }) => {
  const closePanel = (event) => {
    setToggleShare(false);
  };
  return (
    <>
      <section className={"share-panel " + (toggleShare ? "active" : "")}>
        <div className="overlay"></div>
        <div className="share-panel-header">
          <div className="container-fluid">
            <div className="row">
              <div className="col-lg-6 d-flex ml-auto">
                <button type="button" className="panel-close" onClick={closePanel}>
                  <i className="fa-light fa-fw fa-times"></i>
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="share-panel-body">
          <div className="container">
            <div className="share-title">Share something</div>
            <div className="share-options">
              <div className="share-item">
                <Link to="/share-links" className="item-inner">
                  <div className="item-icon">
                    <i className="fa-light fa-fw fa-link-simple"></i>
                  </div>
                  <div className="item-title">Share a link</div>
                </Link>
              </div>
              <Link to="/create-article" className="share-item">
                <button  type="button" className="item-inner">
                  <div className="item-icon">
                    <i className="fa-light fa-fw fa-newspaper"></i>
                  </div>
                  <div className="item-title">Create an article</div>
                </button>
              </Link>
              <div className="share-item">
                <Link to="/create-event" type="button" className="item-inner">
                  <div className="item-icon">
                    <i className="fa-light fa-fw fa-calendar"></i>
                  </div>
                  <div className="item-title">Create an event</div>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Share;
