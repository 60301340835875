import { createSlice } from "@reduxjs/toolkit";
import { apiCallBegan } from "./api";
import BASE_URL from "../config/host";

export const subjectSlice = createSlice({
  name: "subjects",
  loading: false,
  initialState: {
    subjectlist: [],
  },
  reducers: {
    listRequested: (state) => {
      state.loading = true;
    },
    listRequestFailed: (state) => {
      state.loading = false;
      state.subjectlist = "";
    },
    listReceived: (state, action) => {
      state.loading = false;
      state.subjectlist = action.payload.data ? action.payload.data : [];
    },
  },
});

export const { listReceived, listRequested, listRequestFailed } =
  subjectSlice.actions;
export const fetchSubject = (body) =>
  apiCallBegan({
    url: "/subjects",
    method: "post",
    data: body,
    baseUrl: BASE_URL,
    onSuccess: listReceived.type,
    onStart: listRequested.type,
    onError: listRequestFailed.type,
  });
