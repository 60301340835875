import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { addStep, updateEducation } from "../../../store/user";

const ProfileFormEducation = ({ formView, setFormView }) => {
  const dispatch = useDispatch();
  const [formValues, setFormValues] = useState([{ institution: "" }]);
  const [insValues, setInsValues] = useState([
    { institution: "", graduation: [{ name: "" }] },
  ]);

  const switchPanel = async () => {
    setFormView({
      ...formView,
      third: { active: false, completed: true },
      fourth: { active: true, completed: false },
    });
    let dt = {
      ...formView,
      third: { active: false, completed: true },
      fourth: { active: true, completed: false },
    };
    await addStep({ step: dt });
    dispatch(updateEducation({ data: insValues }));
  };
  // console.log(insValues);
  const addFormFields = (i) => {
    const insval = insValues;
    insval[i] = {
      ...insval[i],
      graduation: [...insval[i].graduation, { name: "" }],
    };
    setFormValues([...formValues, { graduation: "" }]);
  };
  const addIns = () => {
    setInsValues([
      ...insValues,
      { institution: "", graduation: [{ name: "" }] },
    ]);
  };
  const handleChange = (i, e) => {
    const insval = insValues;
    insval[i]["institution"] = e.target.value;
    setInsValues([...insval]);
  };

  const handleGraduation = (self_i, ins_i, e) => {
    const insval = insValues;
    insval[ins_i].graduation[self_i].name = e.target.value;
    // insval[ins_i] = {
    //   ...insval[ins_i],
    //   graduation : [
    //     ...insval[ins_i].graduation
    //   ]
    // }
    setInsValues([...insval]);
  };

  const removeFormFields = (i, ins_i) => {
    const insval = insValues;
    const graduation = insValues[ins_i].graduation.filter(
      (item, index) => index !== i
    );
    insval[ins_i] = {
      ...insval[ins_i],
      graduation,
    };
    setInsValues([...insValues]);
  };

  const removeIns = (i) => {
    let newVal = [...insValues];
    newVal.splice(i, 1);
    setInsValues(newVal);
  };



  return (
    <>
      <div className="profile-forms">
        <div className="container">
          <div className="row">
            <div className="col-lg-4">
              <div className="profile-forms-content">
                <h1 className="profile-forms-title">
                  Your knowledge and skills
                </h1>
              </div>
            </div>
            <div className="col-lg-6 offset-lg-2">
              <form className={"form-input-content active"}>
                {insValues.map((e, i) => (
                  <React.Fragment key={i}>
                    <div className="form-group">
                      <div className={"input-style z-up hasValue"}>
                        <input
                          type="text"
                          className="form-control"
                          name="institution"
                          autoComplete="off"
                          value={e.institution || ""}
                          onChange={(e) => handleChange(i, e)}
                        />
                        <div className="float-label">Institution</div>
                      </div>
                    </div>
                    {e.graduation.map((element, index) => (
                      <div className="form-group" key={index}>
                        <div
                          className={
                            "input-style " + (element.name ? "hasValue" : "")
                          }
                        >
                          <input
                            type="text"
                            className="form-control"
                            name="graduation"
                            autoComplete="off"
                            value={element.name || ""}
                            onChange={(e) => handleGraduation(index, i, e)}
                          />
                          <div className="float-label">Graduation</div>
                          {index ? (
                            <button
                              type="button"
                              className="input-reset"
                              onClick={() => removeFormFields(index, i)}
                            >
                              <i className="fa-light fa-fw fa-times"></i>
                            </button>
                          ) : null}
                        </div>
                      </div>
                    ))}

                    <div className="form-options">
                      <button
                        type="button"
                        className="btn-option btn-activeable"
                        onClick={() => addFormFields(i)}
                      >
                        <i className="fa-light fa-fw fa-plus mr-2"></i>
                        Graduation
                      </button>
                      {i ? (
                        <button
                          type="button"
                          className="btn-option btn-activeable"
                          onClick={() => removeIns(i)}
                        >
                          <i className="fa-light fa-fw fa-trash-alt mr-2"></i>
                          Delete institution
                        </button>
                      ) : null}
                    </div>
                  </React.Fragment>
                ))}
                <div className="form-options">
                  <button
                    type="button"
                    className="btn-action btn-activeable"
                    onClick={() => addIns()}
                  >
                    <i className="fa-light fa-fw fa-plus mr-2"></i>Institute
                  </button>
                </div>
                <div className="form-group form-action">
                 {insValues[0].institution && insValues[0].graduation[0].name && <button
                    type="button"
                    className="btn-action btn-activeable"
                    onClick={() => switchPanel()}
                  >
                    Continue
                  </button>}
                  <Link to="/" className="btn-action alt">
                    Skip for now
                  </Link>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ProfileFormEducation;
