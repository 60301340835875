import { useEffect } from "react";
import { getDraftEvent } from "../../../store/user";
import { useDispatch, useSelector } from "react-redux";
import ProfileEventItem from "./ProfileEventItem";
import LoaderFull from "../../../components/LoaderFull";

const ProfileOwnEvent = () => {
  const dispatch = useDispatch();
  const { draftEventList, loading } = useSelector((state) => state.users);

  useEffect(() => {
    dispatch(getDraftEvent({ status: 1, tags: [] }));
  }, []);



  return (
    <>
      <div className="schedule-event">
        <div className="schedule-title">Created by you</div>
        <div className="schedule-event-list">
          {draftEventList
            ? draftEventList.map((item, eventIndex) => (
              
              item.end_time ?
                <ProfileEventItem
                  key={eventIndex}
                  name={item.title}
                  category={item.category}
                  start_date={item.start_date}
                  start_time={item.start_time}
                  end_time={item.end_time}
                  link={item.slug + "/" + item.event_id}
                  image={item.image}
                  filter={"published"}
                  id={item._id}
                />
                : 
                <ProfileEventItem
                  key={eventIndex}
                  name={item.title}
                  category={item.category}
                  start_date={item.start_date}
                  start_time={item.start_time}
                  link={item.slug + "/" + item.event_id}
                  image={item.image}
                  filter={"published"}
                  id={item._id}
                />
              ))
            : "No Data"}
        </div>
        <LoaderFull loaderActive={loading} />
      </div>
    </>
  );
};

export default ProfileOwnEvent;
