import { useState } from "react";
import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { eventDetails, editEvent, tagList } from "../../store/event";
import "./Css/Events.css";
import Flatpickr from "react-flatpickr";
import { useSelector } from "react-redux";
import { getUsers } from "../../store/user";
import LoaderFull from "../../components/LoaderFull";
import { createPost } from "../../store/feed";
import API_URL from "../../config/apiHost";
import ConfirmationModal from "../../components/ConfirmationModal";

const Events = () => {
  const { id } = useParams();
  const { settingData } = useSelector((state) => state.settings);

  const [eventData, setEventData] = useState(null);
  useEffect(() => {
    const eventData1 = eventDetails({ event_id: id });
    eventData1.then((res) => {
      setEventData(res);
    });
  }, []);

  const [about, setAbout] = useState("");
  const [place, setPlace] = useState("");
  const [count, setCount] = useState("");
  const [keyword, setKeyword] = useState("");
  const [keywordTag, setKeywordTag] = useState("");
  const [user, setUser] = useState([]);
  const [userId, setUserId] = useState([]);
  const [active, setActive] = useState(false);
  const [userData, setUserData] = useState([]);
  const { profileDetails } = useSelector((state) => state.users);
  const [selectedTags, setSelectedTags] = useState([]);
  const [selectedTagIds, setSelectedTagIds] = useState([]);
  const [tagFilter, setTagFilter] = useState([]);
  const [picture, setPicture] = useState("");
  const [links, SetLinks] = useState("");
  const [allDay, setAllDay] = useState(false);
  const [start_date, setStart_date] = useState(
    new Date().toISOString().slice(0, 10)
  );
  const [end_date, setEnd_date] = useState("2022-11-02");
  const [hasEnd_date, setHasEnd_date] = useState(false);
  const [start_time, setStart_time] = useState("");
  const [end_time, setEnd_time] = useState("");
  const [location, setLocation] = useState(false);
  const [toggleTime, setToggleTime] = useState(false);
  const [extraText, setExtraText] = useState("");
  const [loading, setLoading] = useState(false);
  const allowedExts = ["jpg", "jpeg", "png", "gif", "svg"];
  const [activePreview, setActivePreview] = useState(false);
  const [shaer_on_ceo, setShaer_on_ceo] = useState(true);
  const [search, setSearch] = useState("");
  const toggleTimePanel = () => {
    setToggleTime(true);
  };

  useEffect(() => {
    setSearch(eventData?.title ? eventData?.title : "");
    setStart_date(eventData?.start_date);
    setEnd_date(eventData?.end_date);
    SetLinks(eventData?.online_event_link);
    setStart_time(eventData?.start_time ? eventData?.start_time : "");
    setEnd_time(eventData?.end_time ? eventData?.end_time : "");
    setAllDay(eventData?.is_all_day ? eventData?.is_all_day : false); //
    setLocation(
      eventData?.is_online_event ? eventData?.is_online_event : false
    );
    setPicture(eventData?.image ? eventData?.image : "");
    setAbout(eventData?.description ? eventData?.description : "");
    setHasEnd_date(eventData?.end_date ? true : false);
    setPlace(eventData?.location ? eventData?.location : "");
    setUserId(eventData?.invited_user ? eventData?.invited_user : []);
    setSelectedTags(eventData?.eventTags ? eventData?.eventTags : []);
  }, [eventData]);

  useEffect(() => {
    if (eventData?.eventTags) {
      let a = eventData.eventTags;
      let ids = [];
      a.map((item) => {
        ids.push(item._id);
      });
      setSelectedTagIds(ids);
    }
  }, [eventData]);

  useEffect(() => {
    if (eventData?.invited_user) {
      let a = eventData.invited_user;
      let ids = [];
      a.map((item) => {
        ids.push(item._id);
      });
      setUserId(ids);
    }
  }, [eventData]);

  const getUploadImg = (e) => {
    let base64String = "";
    let file = e.target.files[0];
    const ext = e.target.files[0].type.split("/")[1];
    if (!allowedExts.includes(ext)) {
      alert("Please upload a valid image.");
    } else {
      if (file instanceof File && file.name) {
        const reader = new FileReader();

        reader.onload = () => {
          base64String = reader.result;
          const imageBase64Stringsep = base64String;
          setPicture(imageBase64Stringsep);
        };

        reader.readAsDataURL(file);
      }
    }
  };

  useEffect(() => {
    tagList({ tag_type: 1, keyword: keywordTag, status: true })
      .then((res) => {
        setTagFilter(res);
      })
      .catch(() => {});
  }, [keywordTag]);

  useEffect(() => {
    async function fetchdata() {
      await getUsers({ type: "user" })
        .then((res) => {
          setUserData(res.data);
        })
        .catch(() => {});
    }
    fetchdata();
  }, []);

  useEffect(() => {
    if (keyword === "") {
      async function fetchdata() {
        await getUsers({ type: "user" })
          .then((res) => {
            setUserData(res.data);
          })
          .catch(() => {});
      }
      fetchdata();
    } else {
      const condition = new RegExp(keyword, "i");

      let result = userData.filter(function (el) {
        return condition.test(el.name);
      });

      setUserData(result);
    }
  }, [keyword]);

  useEffect(() => {
    let tmp = [];
    userData.forEach((item) => {
      if (userId?.length > 0 && userId.includes(item._id)) {
        tmp.push({ _id: item._id, name: item.name });
      }
    });

    setUser(tmp);
  }, [userId, userData]);

  const navigate = useNavigate();

  const handleKeyDown = (e) => {
    e.target.style.height = `40px`;
    e.target.style.height = `${e.target.scrollHeight}px`;
  };

  const handleWordCount = (e) => {
    const charCount = e.target.value.length;
    setCount(charCount);
  };

  async function handleSubmitPublish() {
    try {
      if (allDay) {
        let eventData = {
          event_id: id,
          title: search,
          status: 1,
          start_date: start_date,
          end_date: end_date,
          start_time: start_time,
          end_time: end_time,
          is_all_day: allDay,
          location: place,
          description: about,
          online_event_link: links,
          tag_ids: selectedTagIds,
          user_ids: userId,
          is_online_event: location,
          image: picture,
        };
        if (["http", "https"].some((link) => picture.includes(link))) {
          delete eventData.image;
        }

        await editEvent(eventData);
      } else if (!hasEnd_date) {
        let eventData = {
          event_id: id,
          title: search,
          status: 1,
          start_date: start_date,
          end_date: end_date,
          start_time: start_time,
          end_time: end_time,
          is_all_day: allDay,
          location: place,
          description: about,
          online_event_link: links,
          tag_ids: selectedTagIds,
          user_ids: userId,
          is_online_event: location,
          image: picture,
        };
        if (["http", "https"].some((link) => picture.includes(link))) {
          delete eventData.image;
        }
        await editEvent(eventData);
      } else {
        let eventData = {
          event_id: id,
          title: search,
          status: 1,
          start_date: start_date,
          end_date: end_date,
          start_time: start_time,
          end_time: end_time,
          is_all_day: allDay,
          location: place,
          description: about,
          online_event_link: links,
          tag_ids: selectedTagIds,
          user_ids: userId,
          is_online_event: location,
          image: picture,
        };
        if (["http", "https"].some((link) => picture.includes(link))) {
          delete eventData.image;
        }
        await editEvent(eventData);
      }
      navigate("/events");
    } catch (error) {}
  }

  useEffect(() => {
    let a = tagFilter;
    let temp = a.filter((val) => {
      return !selectedTagIds.includes(val._id);
    });
    setTagFilter(temp);
  }, [selectedTagIds]);

  const handleAction = async () => {
    setLoading(true);
    try {
      await createPost({
        data: {
          text: extraText ? extraText : "",
        },
        targetType: "community",
        targetId: settingData?.community_id,
        metadata: {
          preview_event_json: {
            event_id: eventData.event_id,
            title: search,
            description: about,
            event_slug: eventData.slug,
            hasPic: eventData?.image ? true : false,
            image: eventData?.image
              ? eventData?.image.split("/").slice(-1)[0]
              : API_URL + "uploads/events/no-image.png",
          },
          isEvent: true,
        },
      }).then(async () => {
        setLoading(false);
        navigate("/feed");
      });
    } catch (error) {}
  };

  function isValidURL(str) {
    let pattern = new RegExp(
      "^(https?:\\/\\/)?" + // protocol
        "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // domain name
        "((\\d{1,3}\\.){3}\\d{1,3}))" + // OR ip (v4) address
        "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // port and path
        "(\\?[;&a-z\\d%_.~+=-]*)?" + // query string
        "(\\#[-a-z\\d_]*)?$",
      "i"
    ); // fragment locator
    return !!pattern.test(str);
  }

  return (
    <>
      <div className="event-panel-edit">
        <div className="edit-panel add-new">
          <div className="panel-header">
            <div className="container-fluid">
              <div className="row">
                <div className="col-lg-6 d-flex ml-auto">
                  {(start_date || start_time || search || place) && (
                    <button
                      className="panel-close"
                      data-toggle="modal"
                      data-target="#deleteModal"
                    >
                      <i className="fa-light fa-fw fa-times"></i>
                    </button>
                  )}
                  {start_date === "" &&
                    start_time === "" &&
                    search === "" &&
                    place === "" && (
                      <button
                        className="panel-close"
                        onClick={() => navigate(-1)}
                      >
                        <i className="fa-light fa-fw fa-times"></i>
                      </button>
                    )}
                </div>
              </div>
            </div>
          </div>
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-md-8 pt-5">
                {!activePreview && (
                  <div className={"input-style " + (search ? "hasValue" : "")}>
                    <input
                      value={search}
                      onChange={(e) => {
                        setSearch(e.target.value);
                      }}
                      placeholder="Name this event"
                      type="text"
                      className="form-control"
                      name="search"
                      autoComplete="off"
                    />
                  </div>
                )}

                <div
                  className={"event-other-details " + (search ? "active" : "")}
                >
                  <div className="event-timing-start">
                    {!activePreview && (
                      <>
                        <form className="form-content">
                          <div className="form-row justify-content-between ">
                            <div className="col-md-6">
                              <div className="form-group">
                                <div className={"input-style hasValue"}>
                                  <Flatpickr
                                    className="form-control"
                                    value={start_date}
                                    onChange={([date]) => {
                                      setStart_date(
                                        new Date(date).toISOString()
                                      );
                                    }}
                                  />

                                  <div className="float-label">Start Date</div>
                                </div>
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="form-group">
                                <div className={"input-style hasValue"}>
                                  <Flatpickr
                                    className="form-control"
                                    options={{
                                      noCalendar: true,
                                      enableTime: true,
                                    }}
                                    value={start_time}
                                    onChange={([startTimeStr]) => {
                                      setStart_time(
                                        startTimeStr.toTimeString()
                                      );
                                    }}
                                  />
                                  <div className="float-label">Start Time</div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </form>
                        <div className="end-timing">
                          <form className="form-content">
                            {!toggleTime && (
                              <div className="end-header">
                                <div className="text" onClick={toggleTimePanel}>
                                  Add end date and time
                                </div>
                              </div>
                            )}

                            <div
                              className={
                                "time-panel " + (toggleTime ? "active" : "")
                              }
                            >
                              <div
                                className="remove"
                                onClick={() => setToggleTime(false)}
                              >
                                Close
                              </div>
                              <div className="form-row justify-content-between">
                                <div className="col-md-6">
                                  <div className="form-group">
                                    <div className={"input-style hasValue"}>
                                      {end_date && (
                                        <Flatpickr
                                          className="form-control"
                                          value={end_date}
                                          onChange={([date]) => {
                                            setEnd_date(
                                              new Date(date).toISOString()
                                            );
                                          }}
                                        />
                                      )}

                                      {!end_date && (
                                        <Flatpickr
                                          className="form-control"
                                          value={end_date}
                                          onChange={([date]) => {
                                            setEnd_date(
                                              new Date(date).toISOString()
                                            );
                                          }}
                                        />
                                      )}

                                      <div className="float-label">
                                        End Date
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className="col-md-6">
                                  <div className="form-group">
                                    <div className={"input-style hasValue"}>
                                      <Flatpickr
                                        className="form-control"
                                        options={{
                                          noCalendar: true,
                                          enableTime: true,
                                        }}
                                        value={end_time}
                                        onChange={([e]) => {
                                          setEnd_time(e.toTimeString());
                                        }}
                                      />
                                      <div className="float-label">
                                        End Time
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </form>
                        </div>
                      </>
                    )}

                    {!activePreview && (
                      <div className="event-location-edit">
                        <div className="event-offline">
                          <div className="location-part-main">
                            <div className="input-style hasValue">
                              <input
                                className="form-control"
                                value={place}
                                onChange={(e) => setPlace(e.target.value)}
                              />
                              <div className="float-label">Location</div>
                            </div>
                            <div className="icon">
                              <i className="fa-regular fa-location-dot"></i>
                            </div>
                          </div>
                        </div>

                        <div className="location-switch">
                          <input
                            type="checkbox"
                            className="location-switch-input"
                            name="location"
                            checked={location}
                            onChange={(e) => setLocation(!location)}
                          />
                          <div className="location-switch-label">
                            Online Event
                          </div>
                        </div>
                        {location && (
                          <div className="location-content">
                            <div className="upload-image">
                              <div className="upload-photo">
                                <input
                                  className="input-file"
                                  type="file"
                                  onChange={(e) => getUploadImg(e)}
                                />
                                <div className="upload">
                                  <div className="uploadicon">
                                    <i className="fa-light fa-cloud-arrow-up"></i>
                                  </div>
                                  <div className="text">Upload Cover Image</div>
                                </div>
                              </div>
                              <div
                                className={
                                  "preview-image " + (picture ? "active" : "")
                                }
                              >
                                <div className="img-wrap">
                                  <img src={picture} alt="" />
                                </div>
                                <button
                                  type="button"
                                  className="delete-action"
                                  style={{ zIndex: 2 }}
                                  onClick={() => setPicture(null)}
                                >
                                  <i className="fa-light fa-trash-can"></i>
                                </button>
                              </div>
                            </div>

                            <div className="others-text">
                              <div className="field-part-main descriptions">
                                <div className="form-group">
                                  <div
                                    className={
                                      "input-style input-style-textarea " +
                                      (links ? "hasValue" : "")
                                    }
                                  >
                                    <input
                                      className="form-control"
                                      name="links"
                                      type={"url"}
                                      autoComplete="off"
                                      value={links}
                                      onChange={(e) => {
                                        SetLinks(e.target.value);
                                      }}
                                      maxLength="1000"
                                    ></input>
                                    <div className="input-count">
                                      Paste External Links{" "}
                                      {links !== "" && !isValidURL(links) && (
                                        <div className="text-danger">
                                          Enter a valid URL
                                        </div>
                                      )}
                                    </div>
                                    <div className="float-label">
                                      https://example.com
                                    </div>
                                  </div>
                                </div>
                              </div>

                              <div className="field-part-main descriptions">
                                <div className="form-group">
                                  <div
                                    className={
                                      "input-style input-style-textarea " +
                                      (about ? "hasValue" : "")
                                    }
                                  >
                                    <textarea
                                      className="form-control"
                                      name="about"
                                      autoComplete="off"
                                      value={about}
                                      onChange={(e) => {
                                        setAbout(e.target.value);
                                        handleKeyDown(e);
                                        handleWordCount(e);
                                      }}
                                      onPaste={handleKeyDown}
                                      maxLength="1000"
                                    ></textarea>
                                    <div className="input-count">
                                      {about.length} / 1000
                                    </div>
                                    <div className="float-label">
                                      Event description
                                    </div>
                                  </div>
                                </div>
                              </div>

                              {/* Invite Users */}

                              {/* Tags */}
                              <div className="profile-content-section">
                                <h2 className="profile-content-title">Tags</h2>
                                <div className="profile-interest-list">
                                  {selectedTags &&
                                    selectedTags?.map((item, index) => (
                                      <div
                                        className="profile-interest-item"
                                        key={index}
                                      >
                                        {item.title}
                                        <button
                                          type="button"
                                          className="remove-action"
                                          onClick={() => {
                                            let tmp = selectedTags;
                                            let tmpIds = selectedTagIds;
                                            let newTmp = tmp.filter((val) => {
                                              return val._id !== item._id;
                                            });
                                            let newTmpIds = tmpIds.filter(
                                              (val) => {
                                                return val !== item._id;
                                              }
                                            );
                                            setSelectedTags(newTmp);
                                            setSelectedTagIds(newTmpIds);
                                          }}
                                        >
                                          <i className="fa-light fa-xmark"></i>
                                        </button>{" "}
                                      </div>
                                    ))}
                                </div>

                                <div
                                  className={
                                    "input-style mt-5" +
                                    (keywordTag ? " hasValue" : "")
                                  }
                                >
                                  <input
                                    className="form-control"
                                    value={keywordTag}
                                    onFocus={() => setActive(true)}
                                    onBlur={() =>
                                      setTimeout(() => {
                                        setActive(false);
                                        setKeywordTag("");
                                      }, 200)
                                    }
                                    onChange={(e) =>
                                      setKeywordTag(e.target.value)
                                    }
                                  />

                                  <div className="float-label">Add Tags</div>
                                  {active &&
                                    tagFilter &&
                                    tagFilter?.length > 0 && (
                                      <div className="suggestions">
                                        <div className="suggestion-list">
                                          {tagFilter.map((item, index) => (
                                            <button
                                              type="button"
                                              className="suggestion-item"
                                              key={index}
                                              onClick={(e) => {
                                                if (
                                                  !selectedTagIds.includes(
                                                    item._id
                                                  )
                                                ) {
                                                  let a = {
                                                    _id: item._id,
                                                    title: item.title,
                                                  };
                                                  setSelectedTags([
                                                    ...selectedTags,
                                                    a,
                                                  ]);
                                                  setSelectedTagIds([
                                                    ...selectedTagIds,
                                                    item._id,
                                                  ]);
                                                }
                                              }}
                                            >
                                              {item.title}
                                            </button>
                                          ))}
                                        </div>
                                      </div>
                                    )}
                                </div>
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                    )}
                    {activePreview && "Preview"}
                    {activePreview && (
                      <>
                        <div className="share-item">
                          <div className="share-image">
                            <img
                              src={
                                picture
                                  ? picture
                                  : API_URL + "uploads/events/no-image.png"
                              }
                              alt=""
                            />
                          </div>
                          <div className="share-content">
                            <div className="share-title">{search}</div>
                            <div className="share-title">{about}</div>
                            <div className="share-action">
                              <button
                                className="edit-action"
                                onClick={() => setActivePreview(false)}
                              >
                                <i className="fa-solid fa-pen mr-3"></i>Go back
                              </button>
                            </div>
                          </div>
                        </div>
                        <div
                          className="profile-content-section"
                          style={{ zIndex: 1, marginTop: "13px" }}
                        >
                          <h2 className="profile-content-title">
                            Invite Users
                          </h2>
                          <div className="profile-interest-list">
                            {user &&
                              user?.map((item, index) => (
                                <div
                                  className="profile-interest-item"
                                  key={index}
                                >
                                  {item.name}
                                  <button
                                    type="button"
                                    className="remove-action"
                                    onClick={() => {
                                      let tmp = user;
                                      let tmpIds = userId;
                                      let newTmp = tmp.filter((val) => {
                                        return val._id !== item._id;
                                      });
                                      let newTmpIds = tmpIds.filter((val) => {
                                        return val !== item._id;
                                      });
                                      setUser(newTmp);
                                      setUserId(newTmpIds);
                                    }}
                                  >
                                    <i className="fa-light fa-xmark"></i>
                                  </button>{" "}
                                </div>
                              ))}
                          </div>

                          <div
                            className={
                              "input-style mt-5" + (keyword ? " hasValue" : "")
                            }
                          >
                            <input
                              className="form-control"
                              value={keyword}
                              onFocus={() => setActive(true)}
                              onBlur={() =>
                                setTimeout(() => {
                                  setActive(false);
                                }, 200)
                              }
                              onChange={(e) => setKeyword(e.target.value)}
                            />
                            <div className="float-label">Search Users</div>
                            {active && userData && userData?.length > 0 && (
                              <div
                                className="suggestions"
                                style={{ zIndex: 5 }}
                              >
                                <div className="suggestion-list">
                                  {userData.map((item, index) => {
                                    if (item._id !== profileDetails._id) {
                                      return (
                                        <button
                                          type="button"
                                          className="suggestion-item"
                                          key={index}
                                          onClick={() => {
                                            if (!userId.includes(item._id)) {
                                              let a = {
                                                _id: item._id,
                                                name: item.name,
                                              };
                                              setUser([...user, a]);
                                              setUserId([...userId, item._id]);
                                            }
                                          }}
                                        >
                                          {item.name}
                                        </button>
                                      );
                                    }
                                    return null;
                                  })}
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                        <div className="event-location-edit" >
                          <div className="location-switch" style={{display:'none'}}>
                            <input
                              type="checkbox"
                              className="location-switch-input"
                              name="location"
                              checked={shaer_on_ceo}
                              onChange={(e) => setShaer_on_ceo(!shaer_on_ceo)}
                            />
                            <div className="location-switch-label">
                              Share on CEO
                            </div>
                          </div>
                        </div>
                        <div className="form-group mt-5">
                          {shaer_on_ceo && (
                            <div
                              className={
                                "input-style " + (extraText ? "hasValue" : "")
                              }
                            >
                              <input
                                className="form-control"
                                name="extraText"
                                autoComplete="off"
                                value={extraText}
                                style={{ fontSize: "22px" }}
                                onChange={(e) => {
                                  setExtraText(e.target.value);
                                  handleKeyDown(e);
                                  handleWordCount(e);
                                }}
                                onPaste={handleKeyDown}
                                maxLength="200"
                              ></input>
                              <div className="input-count">
                                {extraText?.length} / 200
                              </div>
                              <div className="float-label">
                                Say something about that
                              </div>
                            </div>
                          )}
                        </div>
                        <div className="d-flex">
                          <div className="save-action">
                            {shaer_on_ceo && extraText !== "" && (
                              <button
                                type="button"
                                className="btn-save"
                                onClick={() => handleAction()}
                              >
                                Create Event
                              </button>
                            )}

                            {!shaer_on_ceo && (
                              <button
                                type="button"
                                className="btn-save"
                                onClick={() => handleSubmitPublish()}
                              >
                                Create Event
                              </button>
                            )}
                          </div>
                        </div>
                      </>
                    )}

                    {!activePreview &&
                      start_date &&
                      start_time &&
                      search &&
                      place && (
                        <div className="d-flex">
                          <div className="save-action">
                            {links === "" ||
                            (links !== "" && isValidURL(links)) ? (
                              <button
                                type="button"
                                className="btn-save"
                                onClick={() => {
                                  setActivePreview(true);
                                  window.scrollTo({
                                    top: 0,
                                    behavior: "smooth",
                                  });
                                }}
                              >
                                Continue
                              </button>
                            ) : (
                              " "
                            )}
                          </div>
                        </div>
                      )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ConfirmationModal />
      <LoaderFull loaderActive={loading} />
    </>
  );
};

export default Events;
