import SecAllStories from './Components/SecAllStories';
import SecStoriesBanner from './Components/SecStoriesBanner';
import './Css/Stories.css';

export default function Stories() {
  document.title = "CEO | Stories";
  return (
    <>
      <SecStoriesBanner/>
      <SecAllStories/>
    </>
  );
};