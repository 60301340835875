const SecMembershipMembers = ({ member }) => {
  return (
    <>
      <section className="sec-membership-members">
        <div className="container-fluid">
          <div className="membership-members">
            {Array.isArray(member) &&
              member.slice(0, 6).map((item) => {
                return (
                  <>
                    <div className="member">
                      <div className="member-image">
                        <img src={item.image} alt="" />
                      </div>
                    </div>
                  </>
                );
              })}
          </div>
        </div>
      </section>
    </>
  );
};

export default SecMembershipMembers;
