import { Link } from "react-router-dom";

const SecVideoListItem = ({ item, type }) => {
  return (
    <>
      {item && type === "youtube" && (
        <Link
          to={"/video/" + item?.snippet?.resourceId?.videoId + "?type=youtube"}
          className="video-layout-item"
        >
          <div className="video-layout-inner">
            <div className="video-layout-thumbnail">
              <img
                src={
                  "https://img.youtube.com/vi/" +
                  item.snippet?.resourceId.videoId +
                  "/mqdefault.jpg"
                }
                alt=""
              />
            </div>
            <div className="video-layout-content">
              <h4 className="video-title">{item?.snippet?.title}</h4>
            </div>
          </div>
        </Link>
      )}

      {item && item.uri !== undefined && type === "vimeo" && (
        <Link
          to={"/video/" + item.uri.split("/")[2] + "?type=vimeo"}
          className="video-layout-item"
        >
          <div className="video-layout-inner">
            <div className="video-layout-thumbnail">
              <img src={item.pictures.base_link} alt="" />
            </div>
            <div className="video-layout-content">
              <h4 className="video-title">{item.name}</h4>
            </div>
          </div>
        </Link>
      )}
    </>
  );
};

export default SecVideoListItem;
