import axios from "axios";

export const loadMoreVideos = async (body) => {
  let res = [];
  if (body.type === "youtube") {
    if (body.pageToken) {
      res = await axios.get(
        `https://www.googleapis.com/youtube/v3/playlistItems?key=${body.key}&part=snippet&playlistId=${body.playlist_id}&maxResults=15&pageToken=${body.pageToken}`
      );
    } else {
      res = await axios.get(
        `https://www.googleapis.com/youtube/v3/playlistItems?key=${body.key}&part=snippet&playlistId=${body.playlist_id}&maxResults=15`
      );
    }

    const filter = res.data;

    return filter;
  } else if (body.type === "vimeo") {
    const res = await axios.get(
      `https://api.vimeo.com/channels/${body.playlist_id}/videos?page=${body.page}&per_page=15`,
      {
        headers: {
          "Content-type": "application/x-www-form-urlencoded",
          Accept: "application/json",
          Authorization: `Bearer ${body.vimeo_key}`,
        },
      }
    );
    return res.data;
  }
};

export const fetchVideos = async (body) => {
  if (body.type === "youtube") {
    const res = await axios.get(
      `https://www.googleapis.com/youtube/v3/playlistItems?key=${body.key}&part=snippet&playlistId=${body.playlist_id}&maxResults=8`
    );
    const filter = res.data.items.filter((item) => {
      return item.snippet.title !== "Private video";
    });

    return filter;
  } else if (body.type === "vimeo") {
    const res = await axios.get(
      "https://api.vimeo.com/channels/" +
        body.playlist_id +
        "/videos?per_page=8",
      {
        headers: {
          "Content-type": "application/x-www-form-urlencoded",
          Accept: "application/json",
          Authorization: `Bearer ${body.vimeo_key}`,
        },
      }
    );
    return res.data.data;
  }
};

export const fetchVideoDetails = async (body) => {
  if (body.type === "youtube") {
    const res = await axios.get(
      `https://www.googleapis.com/youtube/v3/videos?key=${body.key}&part=snippet&id=` +
        body.id
    );
    return res.data.items[0].snippet;
  } else if (body.type === "vimeo") {
    const res = await axios.get("https://api.vimeo.com/videos/" + body.id, {
      headers: {
        "Content-type": "application/x-www-form-urlencoded",
        Accept: "application/json",
        Authorization: `Bearer ${body.vimeo_key}`,
      },
    });
    return res.data;
  }
};
